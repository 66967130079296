import $ from 'jquery';
import { makeModalWindow } from '@/utils';

type Options = {
  $trigger?: JQuery;
  title?: string;
};

export default function ($textarea, opts: Options = {}) {
  const title = opts.title || '';
  const $trigger = opts.$trigger || $textarea;

  $trigger.on('click', () => {
    const $modalTextarea = $(
      '<textarea class="form-control" style="width: 100%; height: 500px; resize: none"/>'
    );
    const $modal = makeModalWindow(title, $modalTextarea, null, null, {
      closeSign: '<i class="glyphicon glyphicon-minus"/>',
    });
    $modal.addClass('modal-textarea');

    $modalTextarea.on('input', () => {
      $textarea.val($modalTextarea.val());
    });

    $modal.on('shown.bs.modal', function () {
      $modalTextarea.val($textarea.val());
      $modalTextarea.trigger('focus');
    });

    $modal.modal('show');
  });
}
