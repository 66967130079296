import $ from 'jquery';

import { isAllowedToContinueNavigation, removeLoadingOverlay } from '@/utils';

function didahH(E: Element, S?: string) {
  while (E.tagName != 'TR') {
    E = E.parentElement;
  }
  if (S != null) E.className = S;
  else E.className = '';
}

function QC(VAL, ECF?: number) {
  let CQ = '';
  let CC = 0;
  let error = '0';
  const arr = [];
  let e;
  const dTableName = '#new_table' + VAL;
  if ($(dTableName).toArray().length > 0) {
    const dtTable = $(dTableName).DataTable();
    const dtTableRows = dtTable.rows({ selected: true });
    const dtTableData = dtTableRows.data().toArray();
    const dtTableNodes = dtTableRows.nodes().toArray();
    if (ECF) {
      if (dtTableData.length > 0) {
        CQ = dtTableData[0].s_0;
        if (dtTableData.length > 1) {
          dtTable.rows(dtTableNodes.slice(1)).deselect();
        }
      }
    } else {
      const errorFlag = dtTableData.some(function (item) {
        return item['s_7'] == '1';
      });
      CQ = dtTableData
        .map(function (dt) {
          return dt.s_0;
        })
        .join(', ');
      if (errorFlag) {
        error = '-17';
      }
    }
  } else {
    for (let i = 0; i < window.didah.elements.length; i++) {
      e = window.didah.elements[i] as HTMLInputElement;
      if (
        e.type == 'checkbox' &&
        e.checked &&
        e.name != e.value &&
        (VAL == e.value ||
          VAL + '01' == e.value ||
          VAL + '02' == e.value ||
          VAL + '04' == e.value)
      ) {
        if (ECF && CC > 0) {
          e.checked = false;
          didahH(e);
        } else {
          CQ += (CQ == '' ? '' : ',') + e.name;
          if (e.value == VAL + '04') {
            error = '-17';
          }
        }
        CC++;
      }
    }
  }
  arr[0] = CQ;
  arr[1] = error;

  return arr;
}

function PR(strName, iW, iH, TOP, LEFT, R, S, SC, T, TB, L, DI, URL, TYPE) {
  if (TYPE == 'print') {
    let sF = '';
    sF += iW ? 'width=' + iW + ',' : '';
    sF += iH ? 'height=' + iH + ',' : '';
    sF += R ? 'resizable=' + R + ',' : '';
    sF += S ? 'status=' + S + ',' : '';
    sF += SC ? 'scrollbars=' + SC + ',' : '';
    sF += T ? 'titlebar=' + T + ',' : '';
    sF += TB ? 'toolbar=' + TB + ',' : '';
    sF += TB ? 'menubar=' + TB + ',' : '';
    sF += TOP ? 'top=' + TOP + ',' : '';
    sF += LEFT ? 'left=' + LEFT + ',' : '';
    sF += L ? 'location=' + L + ',' : '';
    sF += DI ? 'directories=' + DI + ',' : '';

    if (!isAllowedToContinueNavigation()) {
      setTimeout(() => removeLoadingOverlay(), 500);
      throw new Error('Navigation is not allowed');
    }

    return window
      .open(URL ? URL : 'about:blank', strName ? strName : '', sF)
      .focus();
  }
}

export { PR, QC, didahH };
