import { loadExternalScript } from '@/utils';

declare let google;

let isLoaded = false;
function loadGoogleCharts(cb?: () => void) {
  if (isLoaded && cb) {
    cb();
  } else {
    loadExternalScript('https://www.gstatic.com/charts/loader.js', () => {
      google.charts.load('current', { packages: ['corechart'] });
      isLoaded = true;

      if (cb) {
        cb();
      }
    });
  }
}

export { loadGoogleCharts };
