import $ from 'jquery';

import { onHeaderClick } from '@/common/features/datatables';

type PostCreatedRowsParams = {
  flagStr: string;
  lan357: string;
};

const getPostCreatedRow =
  ({ flagStr, lan357 }: PostCreatedRowsParams): PostCreatedRow =>
  (row, data) => {
    const image_sec = {
      '1': '<i class="fa fa-exclamation fa-fw didah-dt-lock" aria-hidden="true"/>',
      '2': '<i class="fa fa-unlock-alt fa-fw didah-dt-lock" aria-hidden="true"/>',
      '4': '<i class="fa fa-lock fa-fw didah-dt-lock" aria-hidden="true"/>',
    };
    const rowAn = data['s_6'] === '0' ? '1 ' : data['s_7'] != '1' ? '2' : '4';

    if (data['lock'] != '-1') {
      $(row)
        .addClass('KKN')
        .addClass('dis')
        .attr('title', lan357 + data['lock']);
    } else {
      $(row).removeClass('KKN').removeClass('dis').attr('title', '');

      if (data['title'] && data['title'] != '') {
        $(row).attr('title', data['title']);
      }

      if (data['s_6'] === '0') {
        $(row).addClass('del');
      } else {
        $(row).removeClass('del');
      }

      if (data['s_6'] === '0' || data['s_7'] === '1') {
        $(row).addClass('dis');
      }

      if (data['_02790']) {
        const statusClass = window.statusToClass[data['_02790']];
        $(row).addClass(statusClass);
      }
    }

    $('td', row)
      .eq(0)
      .append(image_sec[rowAn])
      .attr('style', 'white-space: nowrap');

    if (data['_03165'] && data['_03165'] != '') {
      data['_03165'].split(',').forEach(function (entry) {
        if (flagStr[entry]['pos'] > 0)
          $('td', row)
            .eq(flagStr[entry]['pos'])
            .html(
              flagStr[entry]['langStr'].replace(
                '%THIS%',
                $('td', row).eq(flagStr[entry]['pos']).html()
              )
            );
      });
    }
  };

type GroupFieldParams = {
  alltotal: boolean;
  hideLevelGroup: string | null;
  subtotalFieldsList: any[];
};
const getGroupFields = ({
  alltotal,
  hideLevelGroup,
  subtotalFieldsList,
}: GroupFieldParams) => ({
  alltotal,
  groupColumn: [],
  groupColumnName: [],
  hash: {
    data: {},
    pos: 0,
  },
  hideLevelGroup,
  rowsData: {},
  rowsDataCount: [],
  subtotalFieldsList,
});

const createGetDataForUrl =
  (groupFields: ReturnType<typeof getGroupFields>) => () => ({
    ginit: groupFields.groupColumnName.join(','),
  });

type NewGoParams = {
  a: string;
  data: string;
  id: string;
};
const newGo = ({ a, data, id }: NewGoParams) => {
  $(`#md${id}`).modal();

  $(`#md${id} div.modal-body`).load(
    `/management.php?a=${a}&sn=5&ac=${data}&short=true&s=print form[name=myform]>table`
  );
};

type ReloadDtParams = {
  fieldName: string;
  m_row: any;
  response: { data: Dictionary[]; res: 'ok' | string };
  rowData: Dictionary;
  status: string;
};
const getReloadDT =
  ({ groupFields, postCreatedRow }) =>
  (
    datatable,
    { fieldName, m_row, response, rowData, status }: ReloadDtParams
  ) => {
    if (status === 'success' && response.res === 'ok') {
      groupFields.rowsData = {};
      m_row.data(response.data[0]).draw(false);
      postCreatedRow(m_row.node(), response.data[0], 0, datatable);
      datatable.fixedColumns().update();
    } else {
      datatable
        .cell(m_row, fieldName + ':name')
        .data(rowData[fieldName])
        .draw(false);
    }
  };

const setupHeaderDropdown = ({ datatable, field00608 }) => {
  $(document.body).on(
    'click',
    'div.didah-select-menu ul.dropdown-menu>li>a',
    function () {
      datatable.rows({ search: 'applied' }).deselect();
      const id = $(this).data('id');
      if (id === 1) {
        datatable.rows({ search: 'applied' }).select();
      } else if (id === 2) {
        datatable.rows({ search: 'applied' }).deselect();
      } else if (field00608 && id === 3) {
        datatable
          .rows(
            function (idx, data) {
              return data[field00608] == 2;
            },
            { search: 'applied' }
          )
          .select();
      } else if (field00608 && id === 4) {
        datatable
          .rows(
            function (idx, data) {
              return data[field00608] == 3;
            },
            { search: 'applied' }
          )
          .select();
      } else if (id === 5) {
        datatable
          .rows(
            function (idx, data) {
              return data.s_6 != 0 && data.s_7 == 0;
            },
            { search: 'applied' }
          )
          .select();
      } else if (id === 6) {
        datatable
          .rows(
            function (idx, data) {
              return data.s_6 != 0 && data.s_7 != 0;
            },
            { search: 'applied' }
          )
          .select();
      }
    }
  );
};

export * from './add-other-btns';
export * from './misc';
export * from './search';
export * from './setup-print';
export * from './signatures-widget-modal';

export type { ReloadDtParams };

export {
  createGetDataForUrl,
  getGroupFields,
  getPostCreatedRow,
  getReloadDT,
  newGo,
  onHeaderClick,
  setupHeaderDropdown,
};
