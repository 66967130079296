import type { HoursPickerValue } from './types';

function validateValue(value: HoursPickerValue) {
  if (value.hours >= 0 && value.minutes >= 0) {
    return value;
  }

  return null;
}

function formatMinutes(minutes: number) {
  return String(minutes).padStart(2, '0');
}

function getDisplayedValue(value: HoursPickerValue) {
  return `${value.hours}:${formatMinutes(value.minutes)}`;
}

export { formatMinutes, getDisplayedValue, validateValue };
