import type { MediaExplorerModalParams } from '@/react-app/media';
import { mediaExplorerModal } from '@/react-app/media';
import { displayError, setAsFileUploader } from '@/utils';

function updateBadge($btn: JQuery, cnt: number) {
  const $badge = $btn.find('.badge');
  if (cnt && cnt > 0) {
    if ($badge.length) {
      $badge.text(cnt);
    } else {
      $btn.prepend(`<span class="badge">${cnt}</span >`);
    }
  } else {
    if ($badge.length) {
      $badge.remove();
    }
  }
}

function setMediaButton(
  $btn: JQuery,
  cnt: number,
  params: MediaExplorerModalParams & {
    onJustUploaderButtonSuccess?: (result: any) => void;
  },
) {
  updateBadge($btn, cnt);
  $btn.off('click');

  if (cnt && cnt > 0) {
    $btn.on('click', () => mediaExplorerModal(params));
  } else {
    if (params.hasUpload) {
      setAsFileUploader({
        $el: $btn,
        onError: () => displayError(),
        onSuccess: (result) => {
          params.onJustUploaderButtonSuccess?.(result);
          setMediaButton($btn, result.length, params);
        },
        options: {
          params: params.requestsParams.uploadOptions,
          url: params.baseURL,
        },
      });
    } else if (params.fileLibrary) {
      $btn.on('click', () => mediaExplorerModal(params));
    } else {
      $btn.prop('disabled', true);
    }
  }
}

export { setMediaButton };
