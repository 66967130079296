import { createFilesFormData } from '@/utils';
import { useRef, type PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  accept?: string;
  className?: string;
  isDisabled?: boolean;
  multiple?: boolean;
  onUpload: (formData: FormData) => void;
}>;

function Uploader({
  accept,
  children,
  className,
  isDisabled,
  multiple,
  onUpload,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const filesChange = (fileList: FileList | File[]) => {
    const formData = createFilesFormData(fileList);
    onUpload(formData);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div className={className} onClick={() => inputRef.current?.click()}>
      {children}
      <form className="tw-hidden" encType="multipart/form-data" noValidate>
        <input
          ref={inputRef}
          accept={accept}
          disabled={isDisabled}
          multiple={multiple}
          type="file"
          onChange={(e) => filesChange(e.target.files)}
        />
      </form>
    </div>
  );
}

export { Uploader };

export type { Props as UploaderProps };
