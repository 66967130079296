import jQuery from 'jquery';

import './datatables.css';
import './Select-1.3.1/css/select.dataTables.min.css';
import './Buttons-1.6.1/css/buttons.dataTables.min.css';
import './FixedColumns-3.3.0/css/fixedColumns.dataTables.min.css';

import './datatables.js';

import './Select-1.3.1/js/dataTables.select.js';
import './Buttons-1.6.1/js/dataTables.buttons.js';
import './Buttons-1.6.1/js/buttons.print.js';
import './Buttons-1.6.1/js/buttons.html5.js';

import './FixedColumns-3.3.0/js/dataTables.fixedColumns.js';

jQuery.fn.dataTable.Api.register('processing()', function (show) {
  return this.iterator('table', function (ctx) {
    ctx.oApi._fnProcessingDisplay(ctx, show);
  });
});
