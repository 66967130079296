import type { MobileDialogProps } from '../types';

import { useModalShow } from '../shared';
import { MobilePageInner, MobilePageIconWrapper } from './MobilePageInner';

const MobilePage = (props: MobileDialogProps) => {
  const debouncedIsShown = useModalShow(props.isShown);
  if (!debouncedIsShown) return null;

  return <MobilePageInner {...props} />;
};

export { MobilePage, MobilePageIconWrapper };
