import type {
  CloseSelfInput,
  CloseSelfMessage,
  CloseTopMessage,
  ForceCloseAllMessage,
  OnCloseActionsInput,
  OnCloseActionsMessage,
} from '../types';
import { MessageTypes } from '../types';

/**
 * Called in the iframe window
 * @param input
 */
function postOnCloseActionsMessage(input: OnCloseActionsInput) {
  const message: OnCloseActionsMessage = {
    payload: input,
    type: MessageTypes.OnCloseActions,
  };
  window.top.postMessage(message, window.top.origin);
}

/**
 * Called in the iframe window
 * @param input
 */
function postCloseSelfMessage(input: CloseSelfInput) {
  const message: CloseSelfMessage = {
    payload: input,
    type: MessageTypes.CloseSelf,
  };
  window.top.postMessage(message, window.top.origin);
}

/**
 * Called in the iframe window
 */
function postCloseTopMessage() {
  const message: CloseTopMessage = { type: MessageTypes.CloseTop };
  window.top.postMessage(message, window.top.origin);
}

/**
 * Called in the iframe window
 */
function postCloseAllMessage() {
  const message: ForceCloseAllMessage = { type: MessageTypes.ForceCloseAll };
  window.top.postMessage(message, window.top.origin);
}

export {
  postCloseAllMessage,
  postCloseSelfMessage,
  postCloseTopMessage,
  postOnCloseActionsMessage,
};
