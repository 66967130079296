import { cn } from '@/utils';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import type { MobileDialogProps } from '../types';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

function MobilePageIconWrapper({
  children,
  className,
  onClick,
  title,
}: PropsWithChildren<{
  className?: string;
  onClick: VoidFunction;
  title?: string;
}>) {
  return (
    <div
      className={cn('tw-gray-500 tw-text-3xl tw-leading-4', className)}
      title={title}
      onClick={() => onClick()}
    >
      {children}
    </div>
  );
}

const MobilePageInner = ({
  as: component = 'div',
  asProps,
  children: body,
  classNames,
  footer,
  header,
  isShown,
  onClose,
}: MobileDialogProps) => {
  const nodeRef = useRef<HTMLElement>();
  const Component = component as any;

  return (
    <CSSTransition
      appear={true}
      classNames="mobile-page-dialog"
      in={isShown}
      nodeRef={nodeRef}
      timeout={300}
    >
      <Component
        ref={nodeRef}
        {...asProps}
        className={cn(
          'tw-fixed tw-inset-0 tw-z-[1050] tw-bg-background dark:tw-bg-foreground-default-dark',
          asProps?.className,
        )}
      >
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-auto">
          {header && (
            <div
              className={cn(
                'tw-items-top tw-flex tw-flex-none tw-items-center tw-justify-between tw-px-4 tw-py-3',
                classNames?.headerWrapper,
              )}
            >
              <div className={classNames?.header}>{header}</div>
              {onClose && (
                <MobilePageIconWrapper onClick={onClose}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </MobilePageIconWrapper>
              )}
            </div>
          )}
          {body && (
            <div
              className={cn(
                'tw-flex-1 tw-overflow-auto tw-px-4',
                {
                  'tw-pb-3': !footer,
                },
                classNames?.body,
              )}
            >
              {body}
            </div>
          )}
          {footer && (
            <div
              className={cn(
                'tw-flex tw-flex-none tw-justify-end tw-gap-1 tw-px-4 tw-py-6',
                classNames?.footer,
              )}
            >
              {footer}
            </div>
          )}
        </div>
      </Component>
    </CSSTransition>
  );
};

export { MobilePageIconWrapper, MobilePageInner };
