import 'selectize';
import $ from 'jquery';

$(function () {
  const Selectize = (window as any).selectize;
  if (!Selectize) {
    return;
  }

  Selectize.prototype.positionDropdown = function () {
    const $control = this.$control;
    const isBodyParent = this.settings.dropdownParent === 'body';
    const offset = isBodyParent ? $control.offset() : $control.position();
    offset.top += $control.outerHeight(true);
    const css: Dictionary = {
      width: 'auto',
      minWidth: $control.outerWidth(),
      top: 'inherit',
      right: 'inherit',
      bottom: 'inherit',
      left: 'inherit',
    };

    const $selectizedInput = this.$input.next();
    const bcr = $selectizedInput.get(0).getBoundingClientRect();

    const horz = bcr.left > window.innerWidth - bcr.right ? 'right' : 'left';
    const vert = bcr.top > window.innerHeight - bcr.bottom ? 'top' : 'bottom';

    switch (`${vert}-${horz}`) {
      case 'bottom-right':
        css.top = offset.top;
        css.right = isBodyParent
          ? document.body.getBoundingClientRect().width - bcr.right
          : 0;
        break;
      case 'top-left':
        css.bottom = isBodyParent
          ? document.body.getBoundingClientRect().height - bcr.top
          : $control.outerHeight();
        css.left = offset.left;
        break;
      case 'top-right':
        css.bottom = isBodyParent
          ? document.body.getBoundingClientRect().height - bcr.top
          : $control.outerHeight();
        css.right = isBodyParent
          ? document.body.getBoundingClientRect().width - bcr.right
          : 0;
        break;
      default: // bottom-left
        css.top = offset.top;
        css.left = offset.left;
    }

    this.$dropdown.css(css);
  };
});
