import { dt_number_format } from '@/utils';

function calcSubtotals(api, groupF) {
  function getCurrentGroupKey(data, ar) {
    const key = ar
      .map(function (d1) {
        return data[d1];
      })
      .join('|');

    if (!groupF.hash.data[key]) {
      groupF.hash.data[key] = 'grp_' + groupF.hash.pos;
      groupF.hash.pos++;
    }

    return groupF.hash.data[key];
  }

  if ($.isEmptyObject(groupF.rowsData) && groupF.groupColumnName.length > 0) {
    const data = api.rows({ search: 'applied' }).data();
    const columns = api.columns().dataSrc();
    groupF.rowsData = {};
    groupF.rowsDataCount = [];
    groupF.hash = {
      data: {},
      pos: 0,
    };
    if (data.any()) {
      groupF.subtotalFieldsList.forEach(function (d) {
        const itr = columns.indexOf(d);
        if (itr >= 0) {
          groupF.rowsData[d] = [];
          groupF.groupColumnName.forEach(function (dd, i, ar) {
            const newArr = ar.slice(0, i + 1);
            groupF.rowsData[d] = data.reduce(function (a, b) {
              const key = getCurrentGroupKey(b, newArr);
              a[key] = ifNaN(a[key], 0) + b[d] * 1;
              return a;
            }, groupF.rowsData[d]);
          });
        }
      });
      groupF.groupColumnName.forEach(function (dd, i, ar) {
        const newArr = ar.slice(0, i + 1);
        groupF.rowsDataCount = data.reduce(function (a, b) {
          const key = getCurrentGroupKey(b, newArr);
          a[key] = ifNaN(a[key], 0) + 1;
          return a;
        }, groupF.rowsDataCount);
      });
    }
  }
}

function drowGroup(api, gFields) {
  if (gFields.groupColumn.length > 0) {
    const columnsVisible = api.columns(':visible');
    const pageCurrent = api.rows({ page: 'current' });

    const rows = pageCurrent.nodes();
    const last = [];
    const td_id = columnsVisible.indexes();
    const td_name = columnsVisible.dataSrc();
    const grpTRSMaster = $('<tr/>')
      .addClass('group')
      .append('<td colspan="3"></td>')
      .append('<td></td>'.repeat(td_id.length - 3));

    if (rows.any()) {
      pageCurrent.indexes().each(function (groupId, itr) {
        const currentRow = $(rows[itr]);
        const group = [];
        const groupClass = [];
        const groupClassMD5 = [];
        let currentRowClass = '';

        gFields.groupColumn.forEach(function (d, i) {
          let grpTR;
          let classString = '';
          group[i] = api.row(groupId).data()[gFields.groupColumnName[i]];
          groupClass[i] = group.join('|');

          groupClassMD5[i] = gFields.hash.data[groupClass[i]]; //'grp_'+hex_md5(groupClass[i]);

          if (last[i] !== groupClass[i]) {
            grpTR = grpTRSMaster.clone(false);
            grpTR.data('grp', groupClassMD5[i]);
            classString = groupClassMD5.slice(0, i).join(' ');
            // grpTR.addClass(groupClassMD5.slice(0,i).join(' '))

            if (gFields.hideLevelGroup !== null) {
              if (i === gFields.hideLevelGroup) {
                // grpTR.addClass('grp_pluss');
                classString += ' grp_pluss';
              }
              if (i > gFields.hideLevelGroup) {
                // grpTR.addClass('grp_hide');
                classString += ' grp_hide';
              }
            }

            grpTR.addClass(classString);

            $('td', grpTR)
              .eq(0)
              .addClass('grp_header grp_header' + i)
              .html(
                api.cell(groupId, d).render('print') +
                  ' (' +
                  dt_number_format(
                    +gFields.rowsDataCount[groupClassMD5[i]],
                    0
                  ) +
                  ')'
              );

            for (let itrv = 3; itrv < td_id.length; itrv++) {
              const cell = api.cell(groupId, td_id[itrv]);
              let cellText = '';
              if (gFields.rowsData[td_name[itrv]] !== undefined) {
                cellText = dt_number_format(
                  +gFields.rowsData[td_name[itrv]][groupClassMD5[i]],
                  2
                );
              } else {
                if (gFields.alltotal) {
                  cellText = cell.render('print');
                } else {
                  cellText = '';
                }
              }
              $('td', grpTR)
                .eq(itrv - 2)
                .html(cellText)
                .addClass($(cell.node()).attr('class'))
                .addClass($('> *', $(cell.node())).attr('class'));
            }
            if (gFields.alltotal) {
              grpTR.addClass('alltotal');
            } else {
              grpTR.removeClass('alltotal');
            }

            currentRow.before(grpTR);

            last[i] = groupClass[i];
          }
          currentRowClass += ' ' + groupClassMD5.slice(0, i + 1).join(' ');
          // currentRow.addClass(groupClassMD5.slice(0,i+1).join(' '))
        });
        currentRow.addClass(currentRowClass);
        if (gFields.hideLevelGroup !== null) {
          currentRow.addClass('grp_hide');
        } else {
          currentRow.removeClass('grp_hide');
        }
      });
    }
  }
  api.processing(false);
}

function ifNaN(num, nan) {
  return isNaN(num) ? nan : num;
}

export { calcSubtotals, drowGroup };
