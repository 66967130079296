import $ from 'jquery';
import Visibility from 'visibilityjs';

import '@/common/plugins/jquery.progressTimer';

import { debounce, localStorageExists } from '@/utils';
import prettyBg from '@/common/features/pretty-bg';
import { translate } from '@/common/features/translations';

export type Params = {
  sessionID: any;
  timeoutSeconds: number;
  warningBeforeTimeoutSeconds: number;
};
function timeout({
  sessionID,
  timeoutSeconds,
  warningBeforeTimeoutSeconds,
}: Params) {
  $(function () {
    const timeOutMs = 1000 * timeoutSeconds;
    const warningBeforeTimeoutMs = 1000 * warningBeforeTimeoutSeconds;

    const $document = $(document);

    let throttledResetTime = null;
    let onStarted = null;
    let onStopped = null;
    const listeners = {
      beforeStart: [],
      beforeStop: [],
      beforeTimeout: [],
      started: [],
      stopped: [],
      timedOut: [],
    };

    const timeoutManager = {
      addEventListener(eventName, cb) {
        if (!Object.keys(listeners).includes(eventName)) {
          return;
        }
        listeners[eventName].push(cb);
      },
      isEnabled: false,
      removeEventListener(eventName, cb) {
        if (!Object.keys(listeners).includes(eventName)) {
          return;
        }
        listeners[eventName] = listeners[eventName].filter(
          (listener) => listener !== cb
        );
      },
      async start() {
        await Promise.all(listeners.beforeStart.map((cb) => cb()));
        if (onStarted && typeof onStarted === 'function') {
          onStarted();
        }
        this.isEnabled = true;
        await Promise.all(listeners.started.map((cb) => cb()));
      },
      async stop() {
        await Promise.all(listeners.beforeStop.map((cb) => cb()));
        onStopped();
        this.isEnabled = false;
        await Promise.all(listeners.stopped.map((cb) => cb()));
      },
    };

    let intervalCheckId = null;

    function runNoLocalStorage() {
      const showTimeoutWarning = warning();

      //record activity time
      let lastMoveTimeMs = 0;

      function resetTimer() {
        lastMoveTimeMs = new Date().getTime();
      }

      throttledResetTime = debounce(resetTimer, 1000);
      onStarted = () => {
        if (timeoutManager.isEnabled) {
          return;
        }

        resetTimer();
        $document.on('mousemove', throttledResetTime);
        $document.on('click', throttledResetTime);

        //do stuff if there was no activity
        intervalCheckId = setInterval(function () {
          if (!timeoutManager.isEnabled) {
            return;
          }

          const currentTimeMs = new Date().getTime();
          if (lastMoveTimeMs + timeOutMs < currentTimeMs) {
            onFinishProgressBar();
          } else if (
            lastMoveTimeMs + timeOutMs - warningBeforeTimeoutMs <
            currentTimeMs
          ) {
            showTimeoutWarning();
          }
        }, 1500);
      };

      onStopped = () => {
        if (!timeoutManager.isEnabled) {
          return;
        }

        $document.off('mousemove', throttledResetTime);
        $document.off('click', throttledResetTime);
        if (intervalCheckId) {
          clearInterval(intervalCheckId);
          intervalCheckId = null;
        }
      };

      timeoutManager.start();
    }

    function runNormal() {
      const showTimeoutWarning = warning();
      const key = window.location.hostname;

      window.addEventListener('storage', function (event) {
        if (event.key === key) {
          hideWarningIfShown();
        }
      });

      //record activity time
      function resetTimer() {
        localStorage.setItem(
          key,
          JSON.stringify({
            lastMoveTimeMs: new Date().getTime(),
            sid: sessionID,
          })
        );

        hideWarningIfShown();
      }

      function getLsData() {
        return JSON.parse(localStorage.getItem(key));
      }

      throttledResetTime = debounce(resetTimer, 1000);
      $document.on('mousemove', throttledResetTime);
      $document.on('click', throttledResetTime);

      onStarted = () => {
        if (timeoutManager.isEnabled) {
          return;
        }
        resetTimer();

        //do stuff if there was no activity
        intervalCheckId = setInterval(function () {
          if (!timeoutManager.isEnabled) {
            return;
          }
          const currentTimeMs = new Date().getTime();
          const lsData = getLsData();
          if (!lsData) return;

          function isThisSession() {
            return lsData.sid === sessionID;
          }

          if (
            lsData.lastMoveTimeMs + timeOutMs < currentTimeMs &&
            isThisSession()
          ) {
            onFinishProgressBar();
          } else if (
            lsData.lastMoveTimeMs + timeOutMs - warningBeforeTimeoutMs <
              currentTimeMs &&
            isThisSession()
          ) {
            showTimeoutWarning();
          }
        }, 1500);
      };

      onStopped = () => {
        if (!timeoutManager.isEnabled) {
          return;
        }

        if (intervalCheckId) {
          clearInterval(intervalCheckId);
          intervalCheckId = null;
        }
      };

      timeoutManager.start();
    }

    function run() {
      if (localStorageExists()) {
        runNormal();
      } else {
        runNoLocalStorage();
      }
    }

    function onFinishProgressBar() {
      setTimeout(function () {
        $('#warningModal').modal('hide');
        showTimeoutWindow();
      }, 1000);
    }
    function warning() {
      let warningShown = false;

      const $warningModal = $(
        '<div id="warningModal" class="modal fade" role="dialog">' +
          '<div class="modal-dialog">' +
          '<div class="modal-content">' +
          '<div class="modal-header">' +
          '<h4 class="modal-title">' +
          translate('timeout_translations.timeout_warning_header') +
          '</h4>' +
          '</div>' +
          '<div class="modal-body">' +
          '<p>' +
          translate('timeout_translations.timeout_warning_message') +
          '</p>' +
          '</div>' +
          '</div>' +
          '</div>'
      );

      $warningModal.on('show.bs.modal', function () {
        $warningModal
          .find('.modal-body')
          .append($('<div id="progressTimer"></div>'));
      });

      $warningModal.on('shown.bs.modal', function () {
        $('#progressTimer').progressTimer({
          baseStyle: 'progress-bar-warning',
          completeStyle: 'progress-bar-info',
          timeLimit: warningBeforeTimeoutMs / 1000,
          warningStyle: 'progress-bar-danger',
        });
      });

      $warningModal.on('hidden.bs.modal', function () {
        $warningModal.find('#progressTimer').remove();
        warningShown = false;
      });

      function showTimeoutWarning() {
        if (!warningShown) {
          warningShown = true;
          $warningModal.modal();
        }
      }

      return showTimeoutWarning;
    }

    function hideWarningIfShown() {
      const modal = $('#warningModal');
      if (modal.hasClass('in')) {
        modal.modal('hide');
      }
    }

    async function showTimeoutWindow() {
      function stopTimers() {
        if (intervalCheckId != null) clearInterval(intervalCheckId);
        if (window.refreshId != null) Visibility.stop(window.refreshId);
        if (window.calendarIntervalID != null)
          Visibility.stop(window.calendarIntervalID);
        $('*').off();
        $(window).off();
        $.get('/settings/system-tools/sign-out');
      }

      function replaceBody() {
        $(document.body).html(`
                <div class="wrapper"
                     style="position: fixed; top: 0; bottom: 0; left: 0; right: 0;">
                    <div style="position: relative; width: 100%; height: 100%;">
                        <div class="jumbotron text-center pretty-bg-jumbotron">
                            <p style="line-height: 21px">${translate(
                              'timeout_translations.timeout_jumbotron_message'
                            )}</p>
                            <button type="button" class="btn btn-primary btn-reload">
                                ${translate(
                                  'timeout_translations.timeout_jumbotron_button_text'
                                )}
                            </button>
                        </div>
                        <canvas id="pretty-bg"/>
                    </div>
                </div>
            `);

        prettyBg(document.getElementById('pretty-bg'));

        $('.btn-reload').on('click', function () {
          location.reload();
        });
      }

      await Promise.all(listeners.beforeTimeout.map((cb) => cb()));
      stopTimers();
      replaceBody();
      await Promise.all(listeners.timedOut.map((cb) => cb()));
    }

    run();

    window.timeoutManager = timeoutManager;
  });
}

export { timeout };
