import type { HookAction } from '@/react-app/iframe/types';
import { Commands } from '@/react-app/iframe/types';
import type { CustomFormUpdateInput } from './types';

import { windows } from './storage';

function reloadTarget(target: Window) {
  const instance = windows.find(
    (vueInstance) => vueInstance.id === target.name,
  );
  if (instance) {
    instance.reload();
  } else {
    // if no instance is found, then it's top window
    window.location.reload();
  }
}

function reloadTop() {
  window.location.reload();
}

function reloadCalender(target: Window) {
  try {
    target.reloadCalender();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to run reloadCalender', e);
  }
}

function reloadDidahCalender(target: Window) {
  try {
    target.didahReloadCalender();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to run didahReloadCalender', e);
  }
}

function updateCustomForm(target: Window, { an, snr }: CustomFormUpdateInput) {
  try {
    if (snr != null) {
      target.didah.snr.value = snr;
    }
    if (an != null) {
      target.didah.an.value = an;
    }

    target.didah.submit();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to run updateCustomForm', e);
  }
}

function dispatch(source: Window, actions?: HookAction[]) {
  if (!actions) {
    return;
  }

  /**
   * We reverse the actions to achieve execution of actions in such order:
   * 1. Actions sent via closeThisIFrame (from iframe itself)
   * 2. Actions added to onCloseActions (from iframe itself)
   * 3. Actions passed when the IFrame was opened
   */
  actions.reverse().forEach(({ command, data }) => {
    switch (command) {
      case Commands.ReloadParent: {
        reloadTarget(source);
        break;
      }
      case Commands.ReloadTop: {
        reloadTop();
        break;
      }
      case Commands.ReloadCalender: {
        reloadCalender(source);
        break;
      }
      case Commands.ReloadDidahCalender: {
        reloadDidahCalender(source);
        break;
      }
      case Commands.UpdateCustomForm: {
        updateCustomForm(source, data);
        break;
      }
    }
  });
}

export { dispatch };
