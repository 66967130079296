enum Period {
  Daily = 'd',
  Hourly = 'h',
  Minutely = 'm',
  Monthly = 'M',
  Never = 'never',
  Weekly = 'w',
  Yearly = 'Y',
}

const neverOption = { id: Period.Never, label: 'Never' };

const options: PeriodOption[] = [
  neverOption,
  { id: Period.Minutely, label: 'Minutely' },
  { id: Period.Hourly, label: 'Hourly' },
  { id: Period.Daily, label: 'Daily' },
  { id: Period.Weekly, label: 'Weekly' },
  { id: Period.Monthly, label: 'Monthly' },
  { id: Period.Yearly, label: 'Yearly' },
];

type PeriodOption = {
  id: Period;
  label: string;
};

type Selection = {
  firstDate: string;
  period: Period;
};

export { Period, PeriodOption, Selection, neverOption, options };
