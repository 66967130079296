import { cn } from '@/utils';
import { forwardRef, type TextareaHTMLAttributes, type ReactNode } from 'react';

import { Error } from './Error';
import { RequiredStar } from './RequiredStar';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  classNames?: {
    formGroup?: string;
  };
  errorMessage?: ReactNode;
  isRequired?: boolean;
  label?: ReactNode;
};

const TextAreaComponent = (
  {
    className,
    classNames,
    errorMessage,
    isRequired,
    label,
    ...restProps
  }: Props,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) => {
  const textArea = (
    <textarea
      ref={ref}
      className={cn('form-control', className)}
      {...restProps}
    />
  );

  let content = textArea;

  if (label) {
    content = (
      <>
        <label htmlFor={restProps.name}>
          {label}
          {isRequired && <RequiredStar />}
        </label>
        {textArea}
      </>
    );
  }

  return (
    <div className={cn('form-group', classNames?.formGroup)}>
      {content}
      {errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(TextAreaComponent);

export { TextArea };
