import { classed } from '@tw-classed/react';

const InnerContainer = classed(
  'div',
  'tw-fixed tw-bottom-0 tw-top-0 tw-z-100 tw-cursor-pointer tw-transition-all tw-duration-100',
  'tw-flex tw-items-center tw-justify-center tw-p-12 tw-text-5xl tw-text-white',
  'hover:tw-bg-black hover:tw-bg-opacity-10',
  {
    variants: {
      variant: {
        left: 'tw-left-0',
        right: 'tw-right-0',
      },
    },
  },
);

export { InnerContainer };
