function decrypt_str(to_dec) {
  const xor_key = 6;
  let the_res = '';
  for (let i = 0; i < to_dec.length; i++) {
    the_res += String.fromCharCode(xor_key ^ to_dec.charCodeAt(i));
  }
  return the_res;
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

function xor_str(to_enc) {
  const xor_key = 6;
  let the_res = '';
  for (let i = 0; i < to_enc.length; ++i) {
    the_res += String.fromCharCode(xor_key ^ to_enc.charCodeAt(i));
  }
  return the_res;
}

export { decrypt_str, s2ab, xor_str };
