import moment from 'moment';
import $ from 'jquery';

import {
  inIframe,
  isChrome,
  isInternetExplorer,
  isEdge,
  checkMobile,
} from '@/utils';
import { translate } from '@/common/features/translations';

import './style.scss';

import chromeLogo from '@/assets/chrome_logo.png';

function incompatibleBrowserWarning() {
  const ANIMATION_DURATION = 1000;

  function makeLSKey() {
    return 'browser_warning';
  }

  function showPopup() {
    const $popup = $(`
            <div id="inc-browser" class="tw-fixed tw-top-24 tw-right-4 tw-border tw-border-gray-500 tw-border-solid tw-rounded tw-overflow-hidden tw-w-96 tw-z-[1100] tw-p-4 tw-bg-white tw-transition-all tw-duration-1000 tw-ease-linear inc-browser-hidden" style="display: none">
                <span id="inc-browser-close" class="tw-absolute tw-top-1.5 tw-right-1.5 tw-cursor-pointer">×</span>
                <div class="tw-float-left tw-mx-2.5">
                    <img class="tw-w-16" src="${chromeLogo}">
                </div>
                <p class="tw-text-base">${translate(
                  'browser_warning_translations.warning'
                )}</p>
            </div>
        `);

    $popup.find('#inc-browser-close').on('click', function () {
      dismissPopup();
    });
    $(document.body).append($popup);

    $popup.show();
    $popup.removeClass('inc-browser-hidden');
  }

  function dismissPopup() {
    setLS();

    const $popup = $('#inc-browser');
    $popup.addClass('inc-browser-hidden');
    setTimeout(function () {
      $popup.remove();
    }, ANIMATION_DURATION);
  }

  function setLS() {
    if (!localStorage) return;
    localStorage.setItem(makeLSKey(), moment().toISOString());
  }

  function getLS() {
    if (!localStorage) return null;
    return localStorage.getItem(makeLSKey());
  }

  function timeoutPassed(dateMoment) {
    const currentTime = moment();
    return Math.abs(dateMoment.diff(currentTime, 'days')) > 0;
  }

  const lsRecord = getLS();
  const lastShownDate = lsRecord ? moment(lsRecord) : null;

  if (!lastShownDate || timeoutPassed(lastShownDate)) {
    showPopup();
  }
}

function incompatibleBrowserDanger() {
  function replaceBody() {
    function myJumbotron() {
      const winHeight = $(window).height();
      const $jumbotron = $('.jumbotron');
      // make wrapper div whole height of window
      $('.wrapper').css({
        height: winHeight,
      });
      // make jumbotron be in the middle vertically
      $jumbotron.css({
        marginTop: winHeight / 2 - $jumbotron.outerHeight() / 2 + 'px',
      });
    }

    $(document.body).html(`
            <div class="wrapper">
                <div class="jumbotron text-center">
                    <p><img style="width: 100px; margin-right: 20px" src="${chromeLogo}">${translate(
      'browser_warning_translations.danger'
    )} ${window.location.hostname}</p>
                </div>
            </div>
        `);

    myJumbotron();
  }

  replaceBody();
}

export function warnAboutBrowser() {
  $(function () {
    if (
      inIframe() ||
      checkMobile() ||
      isChrome ||
      isInternetExplorer ||
      isEdge
    ) {
      return;
    }

    incompatibleBrowserWarning();
  });
}

$(function () {
  if (!isInternetExplorer) {
    return;
  }

  incompatibleBrowserDanger();
});
