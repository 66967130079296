function loadExternalScript(url: string, onLoad?: () => void) {
  const script = document.createElement('script');
  script.setAttribute('src', url);
  if (onLoad) {
    script.onload = onLoad;
  }
  document.head.appendChild(script);
}

export { loadExternalScript };
