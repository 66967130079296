// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-rest-params,@typescript-eslint/no-unused-vars */
/* eslint-disable prefer-spread */
import $ from 'jquery';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import Twix from 'twix';

import { openDidahBox } from '@/common';
import {
  displayError,
  makeCenteredModalWindow,
  removeContainerLoadingOverlay,
  setContainerLoadingOverlay,
} from '@/utils';
import { translate } from '@/common/features/translations';

import './calendar.scss';
import './style.scss';

function printList({ $printPageHTML, subtitle, title }) {
  const _relToAbs = function (href) {
    // Assign to a link on the original page so the browser will do all the
    // hard work of figuring out where the file actually is
    _link.href = href;
    let linkHost = _link.host;

    // IE doesn't have a trailing slash on the host
    // Chrome has it on the pathname
    if (linkHost.indexOf('/') === -1 && _link.pathname.indexOf('/') !== 0) {
      linkHost += '/';
    }

    return _link.protocol + '//' + linkHost + _link.pathname + _link.search;
  };
  const _link = document.createElement('a');

  const _styleToAbs = function (el) {
    const clone = $(el).clone()[0] as HTMLAnchorElement;

    if (clone.nodeName.toLowerCase() === 'link') {
      clone.href = _relToAbs(clone.href);
    }

    return clone.outerHTML;
  };

  const win = window.open('', '');

  $('a', $printPageHTML).replaceWith(function (index, oldHTML) {
    return $('<div></div>').addClass($(this).attr('class')).text(oldHTML);
  });

  win.document.close();
  let head = '<title>' + title + '</title>';
  $('style, link').each(function () {
    head += _styleToAbs(this);
  });

  try {
    win.document.head.innerHTML = head; // Work around for Edge
  } catch (e) {
    $(win.document.head).html(head); // Old IE
  }

  win.document.body.innerHTML = `<h1>${title}</h1><div>${subtitle}</div>${$printPageHTML[0].outerHTML}`;

  $(win.document.body).addClass('cal-print-view');
}

function shallowCopyDidahAppObj(original) {
  let $container;
  if (original.$container) {
    $container = original.$container;
    delete original.$container;
  }
  const copy = cloneDeep(original);

  forOwn(original, (value, key) => {
    if (moment.isMoment(value)) {
      copy[key] = value.clone();
    }
  });

  if ($container) {
    copy.$container = $container;
    original.$container = $container;
  }
  return copy;
}

/*******************************************************/

function Controller(data, parent) {
  this._data = data;
  this.parent = parent;
  this._view = undefined;
  this._model = undefined;
  this._children = {};
  this.isInitDone = false;
  this.enabled = false;
  this.hidden = false;
}

Controller.prototype.init = function () {
  const initPromise = this._doInit();

  initPromise.then((data) => {
    this._view._afterChildrenRendered();
    return data;
  });

  this._initDone(initPromise);
  return initPromise
    .then(() => {
      this.isInitDone = true;
    })
    .then(() => this);
};
Controller.prototype._initDone = function (initPromise) {
  //to implement
};

Controller.prototype.getViewContainer = function () {
  DidahErrors.notImplemented.call(null, this);
}; //to implement
Controller.prototype.model = function () {
  return shallowCopyDidahAppObj(this._model);
};
Controller.prototype.getId = function () {
  return this.model().id;
};
Controller.prototype.toChildArrayAdd = function (
  child,
  arrayPropName,
  orderNum,
) {
  if (!this[arrayPropName]) {
    this[arrayPropName] = [];
    this._children[arrayPropName] = this[arrayPropName];
  }
  if (!orderNum && orderNum !== 0) {
    this[arrayPropName].push(child);
  } else {
    this[arrayPropName].splice(orderNum, 0, child);
  }
};

Controller.prototype.fromChildArrayRemove = function (child, arrayPropName) {
  const array = this[arrayPropName];
  array.splice(array.indexOf(child), 1);
};

Controller.prototype.childAdd = function (child, propName) {
  this[propName] = child;
  this._children[propName] = child;
};

Controller.prototype.childRemoveAll = function () {
  for (const key in this._children) {
    if (Object.prototype.hasOwnProperty.call(this._children, key)) {
      delete this._children[key];
      delete this[key];
    }
  }
};

Controller.prototype._mapEachChild = function (
  f,
  filter: (arg: any) => boolean = () => true,
) {
  const childrenFlattened = [];
  for (const key in this._children) {
    if (Object.prototype.hasOwnProperty.call(this._children, key)) {
      const c = this._children[key];
      if (c instanceof Array) {
        c.filter(filter).forEach((ci) => {
          f(ci);
          childrenFlattened.push(ci);
        });
      } else {
        if (filter(c)) {
          f(c);
          childrenFlattened.push(c);
        }
      }
    }
  }
  return childrenFlattened;
};
Controller.prototype._initChildren = function () {
  return Promise.all(
    this._mapEachChild(
      (c) => c.init(),
      (c) => c._isToRender(),
    ),
  );
};

Controller.prototype.reRender = function (
  isForcedSelf = false,
  isForcedChildren = false,
) {
  return new Promise((resolve, reject) => {
    if (this._isToRender()) {
      if (this.isInitDone) {
        this._view.render(isForcedSelf);
        this._reRenderChildren(isForcedChildren)
          .then(() => this._view._afterChildrenRendered())
          .then(() => resolve());
      } else {
        this.init()
          .then(() => this._view._afterChildrenRendered())
          .then(() => resolve());
      }
    } else {
      if (this.isInitDone) {
        this._view.unrender();
      }
      resolve();
    }
  });
};
Controller.prototype._reRenderChildren = function (isForced) {
  return Promise.all(this._mapEachChild((c) => c.reRender(isForced)));
};

Controller.prototype.viewHasChild = function ($child) {
  return this._view.viewHasChild($child);
};

Controller.prototype._isToRender = function () {
  return true;
};

Controller.prototype._isToRenderHidden = function () {
  return false;
};

Controller.prototype.removeView = function () {
  this._view.removeView();
};

Controller.prototype.getApp = function () {
  return this.parent.getApp();
};

/*******************************************************/

function View(controller) {
  this.controller = controller;
  this._$view = undefined;
}

View.prototype.init = function () {
  this._doInit();
  this.render();
};
View.prototype._doInit = function () {};

View.prototype.render = function (isForced) {
  this.__viewPreparation(isForced);
  this._viewData(isForced);
  this.__addToDom(isForced);
  this._onAddedToDom();
};
View.prototype.__viewPreparation = function (isForced) {
  this._$view = $(this._getTemplate());
  this._$view.data('controller', this.controller);
  this._$view.addClass('has-controller');
};
View.prototype._viewData = function (isForced) {};
View.prototype.__addToDom = function (isForced) {
  const $container = this.controller.getViewContainer();
  if (this._$currentView) {
    //if this was already rendered inside this parent
    if (
      this.controller.parent &&
      this.controller.parent.viewHasChild(this._$currentView) &&
      !isForced
    ) {
      this._$currentView.replaceWith(this._$view);
    } else {
      this._$currentView.remove();
      $container.append(this._$view);
    }
  } else {
    $container.append(this._$view);
  }
  this._$currentView = this._$view;

  if (this.controller._isToRenderHidden()) {
    this.controller.hidden = true;
    this._$currentView.hide();
  } else {
    this.controller.hidden = false;
    this._$currentView.show();
  }
};

View.prototype._onAddedToDom = function () {};

View.prototype.unrender = function () {
  if (this._$currentView) {
    this._$currentView.remove();
  }
};

View.prototype._getTemplate = function () {
  return '';
}; //to implement
View.prototype.viewHasChild = function ($child) {
  return !!this._$view.has($child).length;
};
View.prototype._setFieldValue = function (fieldName, value) {
  if (value !== undefined) {
    this._$view.find(`.${fieldName}`).text(value);
  }
};
View.prototype.getApp = function () {
  return this.controller.getApp();
};

View.prototype._afterChildrenRendered = function () {};
/*******************************************************/
const DidahAppEvents = {
  RENDERED: 'RENDERED',
};

function DidahApp() {
  Controller.apply(this, arguments);
  this.parent = null;
  this._eventHanders = {};
  this._outerCallbacks = {};
}

DidahApp.prototype = Object.create(Controller.prototype);

DidahApp.prototype._doInit = function () {
  this._assignMV();

  this.processInitialParams(this._data);
  delete this._data;

  this._view.init();
  return this._emptyFetch();
};

DidahApp.prototype.reset = function () {
  this._children = {};
  this.enabled = false;
  this._view.unrender();

  return this._doInit();
};

DidahApp.prototype._assignMV = function () {
  //to implement
};

DidahApp.prototype._fetchDataAndInitChildren = function () {
  //to implement
};

DidahApp.prototype._extraRequestData = function () {
  return {};
};

DidahApp.prototype._emptyFetch = function () {
  this.processData({});
  return this._initChildren().then((data) => this._onRenderEventPromise(data));
};

DidahApp.prototype.addEventListener = function (name, cb) {
  if (!this._eventHanders[name]) {
    this._eventHanders[name] = [];
  }
  this._eventHanders[name].push(cb);
};

DidahApp.prototype._fireEvent = function (eventName, data) {
  const handlers = this._eventHanders[eventName];
  handlers && handlers.forEach((h) => h(data));
};

DidahApp.prototype._onRenderEventPromise = function () {
  const modelCopy = this.model();
  this._fireEvent(DidahAppEvents.RENDERED, {
    duration: modelCopy.duration,
    end: modelCopy.end,
    start: modelCopy.start,
  });
};

DidahApp.prototype.processInitialParams = function (data) {
  Object.assign(this._model, data);
  this.setOuterCallBacks(data);
};

DidahApp.prototype.outerCallbacks = [];

DidahApp.prototype.setOuterCallBacks = function (data) {
  this.outerCallbacks.forEach((cbName) => {
    this._outerCallbacks[cbName] = data[cbName];
  });
};

DidahApp.prototype.refresh = function () {
  this.enabled = true;
  this._fetchDataAndInitChildren();
};

DidahApp.prototype.getViewContainer = function () {
  return this.model().$container;
};

DidahApp.prototype.setLoading = function () {
  this._view.setLoading();
};

DidahApp.prototype.removeLoading = function () {
  this._view.removeLoading();
};

DidahApp.prototype._sendAction = function (ajaxKey) {
  new Promise((resolve, reject) => {
    this._ajax.send(ajaxKey).then(
      (data) => {
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject();
        }
      },
      (e) => {
        reject(e);
      },
    );
  })
    .then(() => this.refresh())
    .catch((e) => this.reRender(true, true));
};

DidahApp.prototype.setAjaxSender = function (ajaxSender) {
  this._ajax = ajaxSender;
};

DidahApp.prototype.getAjaxSender = function () {
  return this._ajax;
};

DidahApp.prototype.getApp = function () {
  return this;
};

DidahApp.prototype.setDidahBoxUrlParams = function (newParams) {
  this._model.didahBoxUrlParams = newParams;
};

DidahApp.prototype.__makeDidahBoxUrl = function (model, paramSet) {
  const params = this.model().didahBoxUrlParams[paramSet];
  const constantParams = map(
    params.constant,
    (value, key) => `${key}=${value}`,
  ).join('&');
  const dynamicParams = filter(
    map(params.dynamic, (value, key) => {
      return model[value] ? `${key}=${model[value]}` : '';
    }),
  ).join('&');

  return `${params.url}?${filter([dynamicParams, constantParams]).join('&')}`;
};

DidahApp.prototype.openIframe = function (model, paramSet) {
  const url = this.__makeDidahBoxUrl(model, paramSet);
  openDidahBox(url);
};

DidahApp.prototype.openModal = function (model, paramSet) {
  const params = this.model().didahBoxUrlParams[paramSet].modal;
  const $modal = makeCenteredModalWindow(
    model[params.header],
    model[params.body],
  );
  $modal.modal('show');
};

DidahApp.prototype.setActionRequestParams = function (newParams) {
  this._model.actionParams = newParams;
};

function DidahAppView() {
  View.apply(this, arguments);
}

DidahAppView.prototype = Object.create(View.prototype);

DidahAppView.prototype.setLoading = function () {
  setContainerLoadingOverlay(this.controller.getViewContainer(), {
    opacity: 0.5,
  });
};

DidahAppView.prototype.removeLoading = function () {
  removeContainerLoadingOverlay(this.controller.getViewContainer());
};

DidahAppView.prototype._getControllers = function ($el, $target) {
  const originalOpt = $el.data('original');
  const draggedController = originalOpt
    ? $(originalOpt).data('controller')
    : $el.data('controller');
  const result: { [key: string]: any } = { draggedController };
  if ($target) {
    result.targetController = $target
      .closest('.has-controller')
      .data('controller');
  }
  return result;
};

/*******************************************************/
function TabbedAppMixin() {
  const _oldProcessInitialParams = this.processInitialParams;
  this.processInitialParams = function (data) {
    _oldProcessInitialParams.apply(this, arguments);
    if (this._model.tabs && this._model.tabs.length) {
      this.__setActiveTab(this._model.tabs[0]);
    }
  };

  this.getTabs = function () {
    return this._model.tabs;
  };

  this.getActiveTab = function () {
    return this._model.activeTab;
  };

  this.__setActiveTab = function (tab) {
    this._model.activeTab = tab;
  };

  this.setActiveTab = function (tab) {
    this.__setActiveTab(tab);
    if (tab.type && tab.type === 'refresh') {
      this.refresh();
    } else {
      this.reRender();
    }
  };

  this.setTabFilterVal = function (value, tabName, silent = false) {
    tabName = tabName || this.getActiveTab().name;

    const tabOpt: { filterVal: any } | undefined = Object.values(
      this._model.tabs,
    ).find((tab) => tab.name === tabName);
    if (tabOpt) {
      tabOpt.filterVal = value;
      if (!silent) {
        this.reRender(true, true);
      }
    } else {
      throw new Error(`Incorrent tab name to set filter value for: ${tabName}`);
    }
  };

  this.getTabFilterVal = function (tabName) {
    tabName = tabName || this.getActiveTab().name;

    const tabOpt = Object.values(this._model.tabs).find(
      (tab) => tab.name === tabName,
    );
    if (tabOpt) {
      return tabOpt.filterVal || '';
    } else {
      throw new Error(`Incorrent tab name to set filter value for: ${tabName}`);
    }
  };

  const _oldExtraRequestData = this._extraRequestData;
  this._extraRequestData = function () {
    const extraData = _oldExtraRequestData.apply(this, arguments);
    const activeTab = this.getActiveTab();
    if (activeTab.type && activeTab.type === 'refresh') {
      Object.assign(extraData, activeTab.requestData);
    }
    return extraData;
  };
}

function TabbedAppViewMixin() {
  this._addTabs = function ($tabsContainer) {
    const activeTab = this.controller.getActiveTab();
    this.controller.getTabs().forEach((t) => {
      const $tab = $(`<li class="pool-tab"><a href="#">${t.label}</a></li>`);
      if (t === activeTab) $tab.addClass('active');
      $tab.data('tab', t);
      if (this.getApp().enabled) {
        $tab.click(() => this.controller.setActiveTab(t));
      }
      $tabsContainer.append($tab);
    });
  };

  this._setupTabFilter = function () {
    const controller = this.controller;
    const $filterInput = this._$view.find('.tab-filter input');
    const $filterGoBtn = this._$view.find('.tab-filter .input-group-addon');
    const $filterRemove = this._$view.find('.tab-filter .remove-filter');
    $filterInput.val(this.controller.getActiveTab().filterVal);

    const onFilterValChange = (value) => {
      controller.setTabFilterVal(value);
      this._onTabFilterChange();
    };
    if (this.getApp().enabled) {
      $filterInput.on('change', function (e) {
        onFilterValChange(this.value);
      });
      $filterGoBtn.click(() => {
        onFilterValChange($filterInput.val());
      });
      $filterRemove.click(() => {
        $filterInput.val('');
        onFilterValChange('');
      });
    }
  };

  this._onTabFilterChange = this._onTabFilterChange || (() => {});
}

const cargoMultiSelectModelKey = 'id';

function CargoAppMixin() {
  this.cargoMultiSelectModelKey = cargoMultiSelectModelKey;
}

function CargoAppViewMixin() {
  this._findMultiDragCargo = function (draggedController) {
    const app = this.getApp();

    const getMultiDragIds = () => {
      const bufferName = draggedController.bufferName();
      if (!app.selectBuffers || !app.selectBuffers[bufferName]) return [];
      return app.selectBuffers[bufferName];
    };

    const multiDragIds = getMultiDragIds();
    if (!multiDragIds.length) return [];

    return draggedController.parent.cargo.filter(
      (c) =>
        c.isInitDone &&
        multiDragIds.includes(c.model()[app.cargoMultiSelectModelKey]),
    );
  };

  this._singleDragOnShadow = function (cargo, target, $el, $container) {
    if (cargo.isBatchDragged()) {
      //for batch drag creating multiple shadows
      const cloneNumber = cargo.getQuantity() - 1;
      $container.find('.shadow-clone').remove();
      for (let i = 0; i < cloneNumber; i++) {
        const clone = $el.clone();
        clone.removeClass('didah-cargo-draggable');
        clone.addClass('shadow-clone');
        $el.after(clone);
      }
    }
  };

  this._multiDragOnShadow = function (draggedCargo, cargos, $el, $container) {
    const getCargoOrinalView = (cargo) => cargo._view._$view;
    const hideOriginalInSource = ($cargoOriginalView) =>
      $cargoOriginalView.addClass('hidden-copies-in-source');

    const cloneView = (cargo) => {
      const $originalView = getCargoOrinalView(cargo);
      hideOriginalInSource($originalView);

      const clone = $originalView.clone();
      clone.removeClass('didah-cargo-draggable');
      clone.removeClass('hidden-copies-in-source');
      clone.addClass('shadow-clone');

      return clone;
    };

    $container.find('.shadow-clone').remove();

    const preCurrent = [];
    const postCurrent = [];

    let isPreCurrent = true;
    cargos.forEach((c) => {
      if (c === draggedCargo) {
        isPreCurrent = false;
        return;
      }

      if (isPreCurrent) {
        preCurrent.push(c);
      } else {
        postCurrent.push(c);
      }
    });

    hideOriginalInSource(getCargoOrinalView(draggedCargo));
    preCurrent.forEach((c) => $el.before(cloneView(c)));
    postCurrent.reverse().forEach((c) => $el.after(cloneView(c)));
  };
}

/*******************************************************/

function DidahCalenderApp() {
  DidahApp.apply(this, arguments);
}

DidahCalenderApp.prototype = Object.create(DidahApp.prototype);

DidahCalenderApp.prototype.outerCallbacks =
  DidahApp.prototype.outerCallbacks.concat(['subtitle', 'lineRowContent']);

DidahCalenderApp.prototype.processInitialParams = function (data) {
  DidahApp.prototype.processInitialParams.apply(this, arguments);

  if (
    !(
      this.model().allDurations &&
      Object.values(this.model().allDurations).length
    )
  ) {
    this._model.allDurations = this.getDefaultDurations();
  }

  const durationId = this.model().durationId;
  if (durationId) {
    this._setDuration(durationId);
  } else {
    this._setDuration(Object.keys(this.model().allDurations)[0]);
  }

  const listDurationId = this.model().listDurationId;
  if (listDurationId) {
    this._setListDuration(listDurationId);
  } else {
    this._setListDuration(Object.keys(this.model().allDurations)[0]);
  }

  this.setAgendaView();

  this._initialStart();
};

DidahCalenderApp.prototype.getDefaultDurations = function () {
  return {
    one_month: {
      buttonText: translate('logictics_translations.delivery_month_btn'),
      duration: {
        month: 1,
      },
      type: 'month',
      weekStart: 1,
    },
    one_week: {
      buttonText: translate('logictics_translations.delivery_one_week_btn'),
      duration: {
        weeks: 1,
      },
      type: 'agenda',
      weekStart: 1,
    },
    three_days: {
      buttonText: translate('logictics_translations.delivery_three_days_btn'),
      duration: {
        days: 3,
      },
      type: 'agenda',
      weekStart: 'default',
    },
    two_weeks: {
      buttonText: translate('logictics_translations.delivery_two_weeks_btn'),
      duration: {
        weeks: 2,
      },
      hasArrows: true,
      type: 'agenda',
      weekStart: 1,
    },
  };
};

DidahCalenderApp.prototype._getDurationValueAndUnit = function () {
  const mc = this.model();
  const duration = mc.duration.duration;
  const unit = Object.keys(duration)[0];
  const value = duration[unit];
  return { unit, value };
};

DidahCalenderApp.prototype._scroll = function (direction) {
  const { unit, value } = this._getDurationValueAndUnit();
  const mc = this.model();

  if (direction === 'back') {
    this._setCurrentDate(mc.currentDate.subtract(value, unit));
  } else if (direction === 'forward') {
    this._setCurrentDate(mc.currentDate.add(value, unit));
  }

  this.updatePeriod();
};

DidahCalenderApp.prototype.scrollBack = function () {
  this._scroll('back');
};

DidahCalenderApp.prototype.scrollForward = function () {
  this._scroll('forward');
};

DidahCalenderApp.prototype.updatePeriod = function (noRefresh = false) {
  const { currentDate, duration } = this.model();
  if (duration.type === 'month') {
    this._setStartAtMonthBeginning(currentDate, duration.weekStart);
  } else if (duration.weekStart !== 'default') {
    this._setStartAtWeekDay(currentDate, duration.weekStart);
  } else if (duration.weekStart === 'default') {
    this._setStart(currentDate);
  }

  this._updateEndAccordingToPeriod();
  !noRefresh && this.refresh();
};

DidahCalenderApp.prototype.getToday = function () {
  const date = moment();
  date.set({
    hour: 0,
    minute: 0,
    second: 0,
  });
  return date;
};

DidahCalenderApp.prototype._initialStart = function () {
  const currentDate = this.model().currentDate;

  if (currentDate) {
    this._setCurrentDate(currentDate);
    this.updatePeriod(true);
  } else {
    const today = this.getToday();
    this._setStart(today);
    this.setToday(true);
  }
};

DidahCalenderApp.prototype.setToday = function (noRefresh = false) {
  const today = this.getToday();

  this._setCurrentDate(today);
  this.updatePeriod(noRefresh);
};

DidahCalenderApp.prototype._setCurrentDate = function (date) {
  this._model.currentDate = date;
};

DidahCalenderApp.prototype.getCurrentDate = function () {
  return this.model().currentDate;
};

DidahCalenderApp.prototype._setDuration = function (durationId) {
  this._model.durationId = durationId;
  this._model.duration = this.model().allDurations[durationId];
};

DidahCalenderApp.prototype._setListDuration = function (listDurationId) {
  this._model.listDurationId = listDurationId;
  this._model.listDuration = this.model().allDurations[listDurationId];
};

DidahCalenderApp.prototype._setListDurationAsAgenda = function () {
  const m = this.model();
  this.setListStart(m.start);
  this.setListEnd(m.end);
};

DidahCalenderApp.prototype._getDuration = function () {
  const m = this.model();
  return m.allDurations[m.durationId];
};

DidahCalenderApp.prototype._getListDuration = function () {
  const m = this.model();
  return m.allDurations[m.listDurationId];
};

DidahCalenderApp.prototype.getDuration = function () {
  return this.model().durationId;
};

DidahCalenderApp.prototype.getListDuration = function () {
  return this.model().listDurationId;
};

DidahCalenderApp.prototype._setStart = function (date) {
  this._model.start = date;
};

DidahCalenderApp.prototype._setEnd = function (date) {
  this._model.end = date;
};

DidahCalenderApp.prototype.setListStart = function (date) {
  this._model.listStart = date;
};

DidahCalenderApp.prototype.setListEnd = function (date) {
  this._model.listEnd = date;
};

DidahCalenderApp.prototype._setStartAtWeekDay = function (
  currentDate,
  weekDayNumber,
) {
  currentDate = currentDate.clone();

  currentDate.subtract(currentDate.isoWeekday() - weekDayNumber, 'd');
  this._setStart(currentDate);
};

DidahCalenderApp.prototype._setStartAtMonthBeginning = function (
  currentDate,
  weekDayNumber,
) {
  const initial = currentDate.clone();
  currentDate.subtract(currentDate.isoWeekday() - weekDayNumber, 'd');

  while (currentDate.date() > 1 && currentDate.isSame(initial, 'month')) {
    currentDate.subtract(1, 'w');
  }
  this._setStart(currentDate);
};

DidahCalenderApp.prototype._updateEndAccordingToPeriod = function () {
  const mc = this.model();
  let { unit, value } = this._getDurationValueAndUnit();
  if (unit === 'm' || unit === 'month') {
    value -= 1;
    const firstMonthEnd = this._getEndAtMonthEnd(
      mc.currentDate,
      mc.duration.weekStart,
    );
    this._setEnd(firstMonthEnd.add(value * 4, 'w'));
  } else {
    this._setEnd(mc.start.add(value, unit));
  }
};

DidahCalenderApp.prototype._getEndAtMonthEnd = function (
  currentDate,
  weekStart,
) {
  const weekEnd = weekStart - 1 === 0 ? 7 : weekStart - 1;
  const endOfMonth = currentDate.clone().endOf('month');
  const endOfMonthWeekDay = endOfMonth.isoWeekday();

  endOfMonth.add(weekEnd - endOfMonthWeekDay + 1, 'd');
  return endOfMonth;
};

DidahCalenderApp.prototype.setCurrentDateAndDuration = function (
  newCurrent,
  newDurationId,
) {
  this._setCurrentDate(newCurrent);
  if (newDurationId) {
    this._setDuration(newDurationId);
  }
  this.updatePeriod();
};

DidahCalenderApp.prototype.addDuration = function (
  newDurationId,
  newDuration,
  silent = false,
) {
  this._model.allDurations[newDurationId] = newDuration;
  if (!silent) {
    this.reRender(true, true);
  }
};

DidahCalenderApp.prototype.getCalenderViewContainer = function () {
  return this._view.getCalenderViewContainer();
};

DidahCalenderApp.prototype._extraRequestData = function () {
  const modelCopy = this.model();
  const isAgendaView = this.isAgendaView();
  const start = isAgendaView ? modelCopy.start : modelCopy.listStart;
  const end = isAgendaView ? modelCopy.end : modelCopy.listEnd;
  return {
    end: end.format('YYYY-MM-DD'),
    start: start.format('YYYY-MM-DD'),
  };
};

DidahCalenderApp.prototype._fetchDataAndInitChildren = function () {
  const ajaxSender = this.getAjaxSender();
  const result: { [key: string]: any } = {
    calender: {},
  };
  const extraData = this._extraRequestData();
  return ajaxSender
    .send(ajaxSender.keys.poolData, extraData)
    .then((data) => {
      result.pool = data;
      return ajaxSender.send(ajaxSender.keys.calenderData, extraData);
    })
    .then((data) => {
      result.calender.itemsData = data;
      if (this.isAgendaView() && !this.isMonthView()) {
        return ajaxSender.send(ajaxSender.keys.daysData, extraData);
      } else {
        return [];
      }
    })
    .then((data) => {
      result.calender.daysInfo = data;

      this.childRemoveAll();
      this.reRender();
      this.processData(result);
      return this._initChildren();
    })
    .then((data) => this._onRenderEventPromise(data));
};

DidahCalenderApp.prototype.setPoolRequestParams = function (newParams) {
  this._model.poolParams = newParams;
};

DidahCalenderApp.prototype.setCalenderRequestParams = function (newParams) {
  this._model.calenderParams = newParams;
};

DidahCalenderApp.prototype.setDaysInfoRequestParams = function (newParams) {
  this._model.daysInfoParams = newParams;
};

DidahCalenderApp.prototype.isMonthView = function () {
  return this.model().duration.type === 'month';
};

DidahCalenderApp.prototype.isAgendaView = function () {
  return !!this.model().isAgendaView;
};

DidahCalenderApp.prototype.setAgendaView = function () {
  this._model.isAgendaView = true;
};

DidahCalenderApp.prototype.setListView = function () {
  this._model.isAgendaView = false;
};

DidahCalenderApp.prototype.getAgendaOrListStartEnd = function () {
  const m = this.model();
  if (this.isAgendaView()) {
    return {
      end: m.end,
      start: m.start,
    };
  } else {
    return {
      end: m.listEnd,
      start: m.listStart,
    };
  }
};

DidahCalenderApp.prototype.getPrintSubtitle = function () {
  return this._outerCallbacks.subtitle ? this._outerCallbacks.subtitle() : '';
};

function DidahCalenderAppView() {
  DidahAppView.apply(this, arguments);
}

DidahCalenderAppView.prototype = Object.create(DidahAppView.prototype);

DidahCalenderAppView.prototype.setLoading = function () {
  DidahAppView.prototype.setLoading.apply(this, arguments);
  this._hideAllCalenderContent();
};

DidahCalenderAppView.prototype.removeLoading = function () {
  DidahAppView.prototype.removeLoading.apply(this, arguments);
  this._revealAllCalenderContent();
};

DidahCalenderAppView.prototype.getCalenderViewContainer = function () {
  return this._$view.find('.calender-container');
};

DidahCalenderAppView.prototype._hideAllCalenderContent = function () {
  //to implement
};

DidahCalenderAppView.prototype._revealAllCalenderContent = function () {
  //to implement
};

DidahCalenderAppView.prototype._hidePoolContainer = function () {
  this._$view.find('.pool-container').hide();
};

/*******************************************************/
function DidahCalender(data, parent) {
  Controller.apply(this, arguments);
}

DidahCalender.prototype = Object.create(Controller.prototype);

DidahCalender.prototype.getViewContainer = function () {
  return this.parent.getCalenderViewContainer();
};

DidahCalender.prototype.getDayViewContainer = function () {
  return this._view.getDayViewContainer();
};

DidahCalender.prototype.getDayInfoViewContainer = function () {
  return this._view.getDayInfoViewContainer();
};

DidahCalender.prototype.getListViewContainer = function () {
  return this._view.getListViewContainer();
};

DidahCalender.prototype.getDaysCount = function () {
  return this._children.days.length;
};

DidahCalender.prototype.onScrollBack = function () {
  return this.parent.scrollBack();
};

DidahCalender.prototype.onScrollForward = function () {
  return this.parent.scrollForward();
};

DidahCalender.prototype.onTodayButton = function () {
  return this.parent.setToday();
};

DidahCalender.prototype._getDaysAccordingToPeriod = function () {
  const app = this.getApp();
  const { end, start } = app.getAgendaOrListStartEnd();

  const result = {};
  do {
    const dayMoment = start.clone();
    result[dayMoment.format('DD-MM-YYYY')] = {
      day: dayMoment.days(),
      moment: dayMoment,
      month: dayMoment.month(),
      year: dayMoment.year(),
    };
    start.add(1, 'd');
  } while (!start.isSame(end, 'd'));

  return result;
};

function DidahCalenderView() {
  View.apply(this, arguments);
}

DidahCalenderView.prototype = Object.create(View.prototype);

DidahCalenderView.prototype._afterChildrenRendered = function () {
  this.adjustHeights();
  this.checkInfoContainer();
};
DidahCalenderView.prototype._disableBtn = function ($btn) {
  $btn.addClass('fc-state-disabled');
  $btn.prop('disabled', true);
};

DidahCalenderView.prototype._addPrintButton = function () {
  const app = this.getApp();
  const $printPageHTML = () => {
    const $appViewClone = this._$view.closest('.didah-app-root').clone();
    $appViewClone.find('*').each(function () {
      const $child = $(this);
      if (
        !(
          $child.find('.calender-list-content').length ||
          $child.is('.calender-list-content') ||
          $child.closest('.calender-list-content').length
        )
      ) {
        $child.remove();
      }
    });
    return $appViewClone;
  };
  const subtitle = app.getPrintSubtitle();

  const $printBtn =
    $(`<button type="button" class="fc-button fc-state-default fc-corner-left fc-corner-right didah-app-print-btn">
                ${translate('logictics_translations.print_btn')}
            </button>`);
  this._$view.find('.calender-controls-left').append($printBtn);

  if (app.enabled) {
    $printBtn.click(() => {
      printList({
        $printPageHTML: $printPageHTML(),
        subtitle,
        title: this._$view.find('.calender-header').text(),
      });
    });
  }
};

DidahCalenderView.prototype._addListPeriodSelectors = function () {
  const app = this.getApp();
  const appModel = app.model();
  const datePickerTemplate = () => {
    return $(`<div class="form-group">
                            <div class='input-group date'>
                                <input type='text' class="form-control"></div>
                                <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </span>
                            </div>
                        </div>`);
  };

  const datePicker = ($formGroup, options) => {
    const $dp = $formGroup.find('.date');
    $dp.datetimepicker({
      ...options,
      format: moment.localeData().longDateFormat('L'),
    });
    return $dp;
  };

  const $group = $('<form class="form-inline"></form>');
  const $dpFgStart = datePickerTemplate();
  const $dpFgEnd = datePickerTemplate();

  $group.append($dpFgStart, $dpFgEnd);

  const $dpStart = datePicker($dpFgStart, {
    date: appModel.listStart,
    locale: moment.locale(),
    maxDate: appModel.listEnd.clone().add(-1, 'd'),
  });

  const minDate = appModel.listStart.clone().add(-1, 's');
  const $dpEnd = datePicker($dpFgEnd, {
    date: appModel.listEnd.clone().add(-1, 'd'),
    locale: moment.locale(),
    minDate,
  });

  $dpStart.on('dp.change', (e: any) => {
    app.setListStart.call(app, e.date);
    app.refresh();
  });
  $dpEnd.on('dp.change', (e: any) => {
    app.setListEnd.call(app, e.date.clone().add(1, 'd'));
    app.refresh();
  });

  const disableMinDateWorkaround = () => {
    const td = $dpFgEnd.find(
      `.bootstrap-datetimepicker-widget td[data-day="${minDate.format(
        'DD.MM.YYYY',
      )}"]`,
    );
    td.addClass('disabled');
  };
  $dpEnd.on('dp.show', () => {
    disableMinDateWorkaround();
  });
  $dpEnd.on('dp.update', () => {
    disableMinDateWorkaround();
  });

  this._$view.find('.calender-controls-left').append($group);
};

DidahCalenderView.prototype._setupStaticButtons = function () {
  const application = this.getApp();
  const $scrollBackBtn = this._$view.find('.didah-app-scroll-back-btn');
  const $scrollForwardBtn = this._$view.find('.didah-app-scroll-forward-btn');
  const $todayBtn = this._$view.find('.didah-app-today-btn');
  const $goToBtn = this._$view.find('.didah-app-go-to-btn');

  if (application.enabled && application.isAgendaView()) {
    $scrollBackBtn.click(() => {
      this.controller.onScrollBack();
    });

    $scrollForwardBtn.click(() => {
      this.controller.onScrollForward();
    });

    $todayBtn.click(() => {
      this.controller.onTodayButton();
    });

    $goToBtn.dropdown();
    const $dp = this._$view.find('#dddatepicker').datetimepicker({
      defaultDate: application.model().start,
      format: moment.localeData().longDateFormat('L'),
      inline: true,
      locale: moment.locale(),
      sideBySide: true,
    });

    $dp.on('dp.change', (e: any) => {
      application._setCurrentDate(e.date);
      application.updatePeriod();
      application.refresh();
    });
  } else {
    [$scrollBackBtn, $scrollForwardBtn, $todayBtn, $goToBtn].forEach(($btn) =>
      $btn.remove(),
    );
  }
};

DidahCalenderView.prototype._setupDynamicPeriodButtons = function () {
  const app = this.getApp();
  const appMc = app.model();

  const getDurationButtons = () => {
    return Object.entries(appMc.allDurations).map(([durationId, duration]) => {
      const isSameAsCurrent = isEqual(appMc.duration, duration);
      const $btn = $(
        `<button type="button" class="fc-button fc-state-default">${duration.buttonText}</button>`,
      );
      if (app.enabled) {
        if (isSameAsCurrent && this._isAgendaView()) {
          $btn.addClass('fc-state-active');
        }
        $btn.click(() => {
          if (!isSameAsCurrent || !this._isAgendaView()) {
            app.setAgendaView();
            app._setDuration(durationId);
            app.updatePeriod();
          }
        });
      } else {
        this._disableBtn($btn);
      }
      return $btn;
    });
  };
  const getListButton = () => {
    const $listBtn = $(`
            <button type="button" class="fc-button fc-state-default fc-corner-right btn-list-view">
                 ${translate('logictics_translations.list')}
            </button>`);
    if (app.enabled) {
      !this._isAgendaView() && $listBtn.addClass('fc-state-active');
      $listBtn.click(() => {
        app.setListView();
        app._setListDurationAsAgenda();
        app.updatePeriod();
      });
    } else {
      this._disableBtn($listBtn);
    }
    return $listBtn;
  };

  const $buttons = getDurationButtons();
  $buttons.push(getListButton());

  const first = $buttons[0];
  const last = $buttons.slice(-1)[0];
  first.addClass('fc-corner-left');
  last.addClass('fc-corner-right');
  const $buttonsContainer = this._$view.find('.duration-buttons');
  $buttons.forEach(($b) => $buttonsContainer.append($b));
};

DidahCalenderView.prototype._setHeader = function () {
  const app = this.getApp();
  const appMc = app.model();
  let headerText = '';
  if (this._isMonthView() && app.isAgendaView()) {
    headerText = appMc.currentDate.format('MMMM YYYY');
  } else {
    const { end, start } = app.getAgendaOrListStartEnd();
    const globalLocale = moment.locale();
    start.locale(globalLocale);
    end.locale(globalLocale);
    const twix = new Twix(start, end.subtract(1, 'd'));
    headerText = `${twix.format({
      dayFormat: 'D',
      hideTime: true,
      hideYear: false,
      implicitYear: false,
      monthFormat: 'MMM',
      yearFormat: 'YYYY',
    })}`;
  }

  this._$view.find('.calender-header').text(headerText);
};

DidahCalenderView.prototype._setDays = function () {
  if (this._isMonthView()) {
    this._$view.find('.calender-days').addClass('days-month-view');
  }
};

DidahCalenderView.prototype._removeAgendaOrListContainer = function () {
  this._isAgendaView()
    ? this._$view.find('.calender-list').remove()
    : this._$view.find('.calender-days').remove();
};

DidahCalenderView.prototype._viewData = function () {
  const isAgendaView = this._isAgendaView();
  if (!isAgendaView) {
    this._addListPeriodSelectors();
    this._addPrintButton();
  }
  this._setupStaticButtons();
  this._setupDynamicPeriodButtons();
  this._setHeader();
  this._removeAgendaOrListContainer();
  if (isAgendaView) {
    this._setDays();
    this._setArrowsAndOnScroll();
  }
};

DidahCalenderView.prototype._toggleArrow = function (show, arrow) {
  const opacity = show ? '0.2' : '0';
  const $arrow = this._$view.find(`.scroll-arrow-${arrow}`);
  if ($arrow.css('opacity') !== opacity) {
    $arrow.css('opacity', opacity);
  }
};

DidahCalenderView.prototype._hideArrowLeft = function () {
  this._toggleArrow(false, 'left');
};

DidahCalenderView.prototype._showArrowLeft = function () {
  this._toggleArrow(true, 'left');
};

DidahCalenderView.prototype._hideArrowRight = function () {
  this._toggleArrow(false, 'right');
};

DidahCalenderView.prototype._showArrowRight = function () {
  this._toggleArrow(true, 'right');
};

DidahCalenderView.prototype._setArrowsAndOnScroll = function () {
  const adjustArrowPositions = (scroll = 0) => {
    const arrowsMarginPx = 20;

    const $arrowLeft = this._$view.find('.scroll-arrow-left');
    $arrowLeft.css('left', `${arrowsMarginPx + scroll}px`);

    const $arrowRight = this._$view.find('.scroll-arrow-right');
    $arrowRight.css('right', `${arrowsMarginPx - scroll}px`);
  };

  const self = this;
  const application = this.getApp();
  if (application.model().duration.hasArrows) {
    const $days = this._$view.find('.calender-days');
    $days.append('<span class="scroll-arrow scroll-arrow-left"></span>');
    $days.append('<span class="scroll-arrow scroll-arrow-right"></span>');
    const daysEl = $days.get(0);
    adjustArrowPositions();
    $days.on('scroll', function () {
      adjustArrowPositions(daysEl.scrollLeft);
      if (daysEl.scrollLeft !== 0) {
        self._showArrowLeft();
      } else {
        self._hideArrowLeft();
      }
      if (daysEl.scrollLeft !== daysEl.scrollWidth - daysEl.clientWidth) {
        self._showArrowRight();
      } else {
        self._hideArrowRight();
      }
    });
  }
};

DidahCalenderView.prototype.checkInfoContainer = function () {
  if (
    this._isAgendaView() &&
    !this._isMonthView() &&
    this.controller.dayinfos &&
    this.controller.dayinfos.find((di) => !di.isEmpty())
  ) {
    return;
  }
  this._$view.find('.calender-days-info').remove();
};

DidahCalenderView.prototype.adjustHeights = function () {
  if (this._isAgendaView() && this._isMonthView()) {
    const height = this._$view.find('.calender-days').height();
    const $days = this._$view.find('.calender-day');
    const adjustedHeight =
      (height / ($days.length > 42 ? 42 : $days.length)) * 7;
    $days.css('min-height', `${adjustedHeight}px`);
    $days.css('max-height', `${adjustedHeight}px`);
  }
};

DidahCalenderView.prototype._isMonthView = function () {
  return this.getApp().isMonthView();
};

DidahCalenderView.prototype._isAgendaView = function () {
  return this.getApp().isAgendaView();
};

DidahCalenderView.prototype.getDayViewContainer = function () {
  return this._$view.find('.calender-days-content');
};
DidahCalenderView.prototype.getDayInfoViewContainer = function () {
  return this._$view.find('.calender-days-info');
};

DidahCalenderView.prototype.getListViewContainer = function () {
  return this._$view.find('.calender-list');
};

/*******************************************************/
function DidahCalenderListModel(data) {
  this.days = data.days;
}

function DidahCalenderList(data, parent) {
  Controller.apply(this, arguments);
}

DidahCalenderList.prototype = Object.create(Controller.prototype);

DidahCalenderList.prototype._doInit = function () {
  this._assignMV();
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DidahCalenderList.prototype.getViewContainer = function () {
  return this.parent.getListViewContainer();
};

DidahCalenderList.prototype.model = function () {
  const days = this._model.days.map((day) => shallowCopyDidahAppObj(day));
  return { days };
};

DidahCalenderList.prototype._isToRender = function () {
  return !this.getApp().isAgendaView();
};

function DidahCalenderListView() {
  View.apply(this, arguments);
}

DidahCalenderListView.prototype = Object.create(View.prototype);

DidahCalenderListView.prototype._listData = function () {
  return []; //to override
};

DidahCalenderListView.prototype._getTemplate = function () {
  return !this._listData().length
    ? '<div class="calender-list-no-data"><span>No data</span></div>'
    : `<table class="calender-list-content">
                  <colgroup>
                    <col class="first" />
                    <col class="second" />
                    <col class="third" />
                  </colgroup>
                </table>`;
};

/*******************************************************/
function DidahCalenderDayInfoModel(data) {
  this.infoHtml = data.infoHtml;
  this.moment = data.moment;
}

function DidahCalenderDayInfo(data, parent) {
  Controller.apply(this, arguments);
}

DidahCalenderDayInfo.prototype = Object.create(Controller.prototype);

DidahCalenderDayInfo.prototype._doInit = function () {
  this._view = new DidahCalenderDayInfoView(this);
  this._model = new DidahCalenderDayInfoModel(this._data);

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DidahCalenderDayInfo.prototype.getViewContainer = function () {
  return this.parent.getDayInfoViewContainer();
};

DidahCalenderDayInfo.prototype._isToRender = function () {
  const application = this.getApp();
  return (
    application.isAgendaView() && application.model().duration.type !== 'month'
  );
};

DidahCalenderDayInfo.prototype.isEmpty = function () {
  return !this.model().infoHtml;
};

function DidahCalenderDayInfoView() {
  View.apply(this, arguments);
}

DidahCalenderDayInfoView.prototype = Object.create(View.prototype);

DidahCalenderDayInfoView.prototype._viewData = function () {
  const mc = this.controller.model();
  this._$view.html(mc.infoHtml);

  const daysCount = this.controller.parent.getDaysCount();
  const maxDisplayedAtOnce = 7;
  const displayedAtOnce =
    daysCount <= maxDisplayedAtOnce ? daysCount : maxDisplayedAtOnce;
  this._$view.css('flex-basis', `${100 / displayedAtOnce}%`);
};

DidahCalenderDayInfoView.prototype._getTemplate = function () {
  return '<div class="calender-day-info"></div>';
};

/*******************************************************/

function DidahCalenderDay(data, parent) {
  Controller.apply(this, arguments);
}

DidahCalenderDay.prototype = Object.create(Controller.prototype);

DidahCalenderDay.prototype.getViewContainer = function () {
  return this.parent.getDayViewContainer();
};

DidahCalenderDay.prototype.formattedDate = function () {
  return this.model().moment.format('YYYY-MM-DD');
};

DidahCalenderDay.prototype.getInfo = function () {
  return {
    dayDt: this.formattedDate(),
  };
};

DidahCalenderDay.prototype.isWeekend = function () {
  const m = this.model();
  return m.moment.isoWeekday() === 7 || m.moment.isoWeekday() === 6;
};

DidahCalenderDay.prototype.isToday = function () {
  const m = this.model();
  return m.moment.isSame(moment(), 'd');
};

DidahCalenderDay.prototype.isSameMonthAsCurrent = function () {
  const application = this.getApp();
  const m = this.model();
  return m.moment.isSame(application.model().currentDate, 'month');
};

DidahCalenderDay.prototype._isToRender = function () {
  return this.getApp().isAgendaView();
};

DidahCalenderDay.prototype.isCargoHasIndex = function () {
  return true;
};

DidahCalenderDay.prototype.isCargoRemovable = function () {
  return true;
};

function DidahCalenderDayView() {
  View.apply(this, arguments);
}

DidahCalenderDayView.prototype = Object.create(View.prototype);

DidahCalenderDayView.prototype._viewData = function () {
  const application = this.getApp();
  const appMc = application.model();
  const c = this.controller;
  const mc = c.model();

  const daysCount = c.parent.getDaysCount();
  const maxDisplayedAtOnce = daysCount <= 7 ? daysCount : 7;
  this._$view.css('flex-basis', `${100 / maxDisplayedAtOnce}%`);

  const $content = this._$view.find('.calender-day-content');
  const isMonthView = appMc.duration.type === 'month';

  this._setHeader();

  if (isMonthView) {
    const $label = $(
      `<div class="day-of-month">${mc.moment.format('D')}</div>`,
    );
    if (!c.isSameMonthAsCurrent()) {
      $label.addClass('not-current-month');
    }
    $content.append($label);
  }

  if (c.isToday()) {
    $content.addClass('calender-day-content-today');
  } else if (c.isWeekend()) {
    $content.addClass('calender-day-content-weekend');
  }
};

DidahCalenderDayView.prototype._setHeader = function () {
  const application = this.getApp();
  const appMc = application.model();
  const c = this.controller;
  const mc = c.model();
  const $header = this._$view.find('.calender-day-header');

  const isMonthView = appMc.duration.type === 'month';

  if (!isMonthView) {
    $header.text(mc.moment.format('W | dd D.M'));
  } else if (isMonthView && mc.orderNum < 7) {
    $header.text(mc.moment.format('dd'));
  } else {
    this._$view.addClass('not-top');
    $header.remove();
  }
};

/*******************************************************/
function AjaxSender(application) {
  this.application = application;
  this.keys = {};
}

AjaxSender.prototype.send = function (action, extraData) {
  const app = this.application;
  app.setLoading();
  const appModel = app.model();
  const data: { [key: string]: any } = {};

  if (extraData) {
    data.appData = btoa(JSON.stringify(extraData));
  }

  this.assignActionData(data, action, app);

  data.action = action;
  return $.ajax({
    contentType: 'application/x-www-form-urlencoded',
    data,
    dataType: 'json',
    method: 'POST',
    timeout: 10000,
    url: appModel.dataUrl,
  })
    .done((data) => {
      app.removeLoading();
      return data;
    })
    .fail(() => {
      displayError(
        `${translate('logictics_translations.ajax_error_message')} ${action}`,
      );
      app.removeLoading();
      app.reRender(true, true);
    });
};

AjaxSender.prototype.assignActionData = function (data, action, app) {};

/*******************************************************/
function CalenderAjaxSenderMixin() {
  this.assignActionData = function (data, action, app) {
    const appModel = app.model();
    switch (action) {
      case this.keys.poolData:
        Object.assign(data, appModel.poolParams);
        break;
      case this.keys.calenderData:
        Object.assign(data, appModel.calenderParams);
        data.cal_wide = !app.isAgendaView();
        break;
      case this.keys.daysData:
        Object.assign(data, appModel.daysInfoParams);
        break;
      default:
        Object.assign(data, appModel.actionParams);
    }
  };
}

/*******************************************************/
const cargoAppKeys = {
  addCargo: 'add-cargo',
  addCargoMulti: 'add-cargo-multi',
  moveCargo: 'move-cargo',
  removeCargo: 'remove-cargo',
};

function CargoAppAjaxSender() {
  AjaxSender.apply(this, arguments);
  Object.assign(this.keys, cargoAppKeys);
}

CargoAppAjaxSender.prototype = Object.create(AjaxSender.prototype);
/*******************************************************/
const DidahErrors = {
  notImplemented() {
    alert('Not implemented!' + this.constructor.name);
    // eslint-disable-next-line no-console
    console.trace();
  },
};
/*******************************************************/
const lineLockIcons = (lock) => {
  const eventIcons = [
    {
      '0': '<i class="fa fa-unlock-alt fa-fw" aria-hidden="true" style="color: green;"></i>',
      '1': '<i class="fa fa-lock fa-fw" aria-hidden="true" style="color: red;"></i>',
    },
    {
      '2': '<i class="fa fa-check-square-o fa-fw" aria-hidden="true" style="color:green"></i>',
      '3': '<i class="fa fa-check-square-o fa-fw" aria-hidden="true" style="color:red"></i>',
    },
    {
      '2': '<i class="glyphicon glyphicon-time fa-fw" aria-hidden="true" style="color: Blue"></i>',
      '3': '<i class="glyphicon glyphicon-download-alt fa-fw" aria-hidden="true" style="color: Blue"></i>',
      '4': '<i class="glyphicon glyphicon-ok-sign fa-fw" aria-hidden="true" style="color: Blue"></i>',
    },
  ];

  const result = [];
  for (let i = 0; i <= 2; i++) {
    result.push(eventIcons[i][lock[i]]);
  }
  return result;
};
/*******************************************************/
const cargoStatuses = {
  0: '',
  1: '#A8CCF0', //pale blue
  2: '#FFFFE0', //light yellow
  3: '#A8F0E4', //pale cyan
  4: '#D8F0A8', //pale green
  5: '#DDB3E6', //pale magenta
};
/*******************************************************/
const _cargoReceiver = {
  _getKey(cargo, ajaxSender, isMulti) {
    DidahErrors.notImplemented.call(null, this); //to implement
  },
  _sendAddCargoAjax(cargos, orderNum, singleCargoQuantity) {
    const ajaxSender = this.getApp().getAjaxSender();
    const data: { [key: string]: any } = {};
    const targetInfo = this.getInfo();
    const cargoInfos = cargos.map((c) => c.getInfo());

    let isMulti = false;
    if (cargoInfos.length === 1 && singleCargoQuantity) {
      cargoInfos[0].quantity = singleCargoQuantity;
      if (singleCargoQuantity > 1) {
        isMulti = true;
      }
    }

    Object.assign(data, targetInfo);
    data.cargos = cargoInfos;
    data.orderNum = orderNum;

    return ajaxSender.send(this._getKey(cargos[0], ajaxSender, isMulti), data);
  },
  _transferFailureMsg: 'unable to transfer cargo',
  addCargo(cargos, orderNum) {
    return new Promise((resolve, reject) => {
      this._sendAddCargoAjax(cargos, orderNum).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(this._transferFailureMsg);
          }
        },
        (e) => {
          reject(e);
        },
      );
    });
  },
  addCargoOfQuantity(sourceCargo, orderNum, singleCargoQuantity) {
    return new Promise((resolve, reject) => {
      this._sendAddCargoAjax([sourceCargo], orderNum, singleCargoQuantity).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(this._transferFailureMsg);
          }
        },
        (e) => {
          reject(e);
        },
      );
    });
  },
};
const cargoReceiver = function () {
  Object.assign(this, _cargoReceiver);
};

const _hasCargo = {
  getCargoViewContainer(cargo) {
    return this._view.getCargoViewContainer(cargo);
  },
};

const hasCargo = function () {
  Object.assign(this, _cargoReceiver);
  Object.assign(this, _hasCargo);
};

const removesCargo = function () {
  this._removeCargoMsgTemplate = function () {
    return '';
  };

  this.removeCargos = function (cargos) {
    const msg = this._removeCargoMsgTemplate().replace(
      /{cargoId}/g,
      cargos.map((c) => c.model().id).join(', '),
    );
    if (confirm(msg)) {
      this.__sendRemoveAjax(cargos);
    }
  };

  this.__sendRemoveAjax = function (cargos) {
    const application = this.getApp();
    const ajaxSender = application.getAjaxSender();
    const data = {
      cargos: cargos.map((c) => c.getInfo()),
    };
    new Promise((resolve, reject) => {
      ajaxSender.send(ajaxSender.keys.removeCargo, data).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject();
          }
        },
        (e) => {
          reject(e);
        },
      );
    })
      .then(() => application.refresh())
      .catch((e) => application.reRender(true, true));
  };
};

/*******************************************************/
function cargoConstructor(cargoData, parent) {
  if (cargoData.cargoType === 'panel') {
    return new Panel(cargoData, parent);
  } else if (cargoData.cargoType === 'equipment') {
    return new Equipment(cargoData, parent);
  } else if (cargoData.cargoType === 'material') {
    return new Material(cargoData, parent);
  }
}

/*******************************************************/
const panelsTab = {
  label: 'Panels',
  name: 'panels',
};
const equipTab = {
  label: 'Equipment',
  name: 'equip',
};
const materialsTab = {
  label: 'Materials',
  name: 'materials',
};

const CargoTabs = { equipTab, materialsTab, panelsTab };

/*******************************************************/
function CargoModel(data) {
  !data.quantity && (data.quantity = 1);
  Object.assign(this, data);
  this.quantity = +this.quantity;
}

function AbstractCargo(data, parent) {
  Controller.apply(this, arguments);
}

AbstractCargo.prototype = Object.create(Controller.prototype);
removesCargo.call(AbstractCargo.prototype);

AbstractCargo.prototype._doInit = function () {
  this._model = new CargoModel(this._data);
  this._assignV();

  this._data = undefined;
  this._view.init();
  return this._initChildren();
};

AbstractCargo.prototype._assignV = function () {
  DidahErrors.notImplemented.call(null, this); //to implement
};

AbstractCargo.prototype.getViewContainer = function () {
  return this.parent.getCargoViewContainer(this);
};

AbstractCargo.prototype.isBatchDragged = function () {
  return !!this._isBatchDragged;
};

AbstractCargo.prototype.setBatchDragged = function () {
  return (this._isBatchDragged = true);
};

AbstractCargo.prototype.cancelBatchDragged = function () {
  return (this._isBatchDragged = false);
};

AbstractCargo.prototype.exportData = function () {
  DidahErrors.notImplemented.call(null, this); //to implement
};

AbstractCargo.prototype.getInfo = function () {
  const mc = this.model();
  const result: { [key: string]: any } = {
    id2: mc.id2,
    lineId: mc.id,
    quantity: mc.quantity,
  };
  mc.dt && (result.cargoDt = mc.dt);
  return result;
};

AbstractCargo.prototype.tab = null;

AbstractCargo.prototype.getQuantity = function () {
  return +this._model.quantity;
};

AbstractCargo.prototype.setQuantity = function (quantity) {
  return (this._model.quantity = quantity.toString());
};

AbstractCargo.prototype.onRemove = function () {
  this.removeCargos([this]);
};

AbstractCargo.prototype._removeCargoMsgTemplate = function () {
  return translate('logictics_translations.delivery_remove_cargo_prompt');
};

AbstractCargo.prototype.isBlocked = function () {
  const mc = this.model();
  if (!mc.didah_lock) return false;
  return mc.didah_lock[0] === '1' || mc.didah_lock[2] === '3';
};

function AbstractCargoView() {
  View.apply(this, arguments);
}

AbstractCargoView.prototype = Object.create(View.prototype);

AbstractCargoView.prototype._viewData = function () {
  this._setColor();
  this._setText();
  if (this.getApp().model().didahBoxUrlParams) {
    this._setOnPanelClick();
    this._setOnZClick();
  }
};

AbstractCargoView.prototype._setOnPanelClick = function () {
  this._$view.on('click', (e) => {
    if (e.ctrlKey || e.metaKey) return;
    const $target = $(e.target);
    if (
      $target.prop('tagName').toLowerCase() === 'z' ||
      $target.hasClass('z-wrapped') ||
      $target.hasClass('cargo-remove-btn') ||
      $target.hasClass('cargo-handle')
    ) {
      return;
    }
    this._makeClickAction('def');
  });
};

AbstractCargoView.prototype.__shouldOpenModal = function (key) {
  return !!this.getApp().model().didahBoxUrlParams[key].modal;
};

AbstractCargoView.prototype._makeClickAction = function (key) {
  const mc = this.controller.model();
  const app = this.getApp();
  if (this.__shouldOpenModal(key)) {
    app.openModal(mc, key);
  } else {
    app.openIframe(mc, key);
  }
};

AbstractCargoView.prototype._setOnZClick = function () {
  const $title = this._$view.find('.didah-cargo-title');
  $title.find('z').wrap('<a class="z-wrapped">');
  $title.on('click', 'z', (e) => {
    if (e.ctrlKey || e.metaKey) return;
    this._makeClickAction('z');
  });
};

AbstractCargoView.prototype.modelKey = cargoMultiSelectModelKey;

AbstractCargoView.prototype._setColor = function () {
  const mc = this.controller.model();
  const color = cargoStatuses[mc.status];
  color && this._$view.css('background-color', color);
};

AbstractCargoView.prototype._setText = function () {
  const mc = this.controller.model();
  const $title = this._$view.find('.didah-cargo-title');
  $title.html(mc.title);
  const parent = this.controller.parent;
  const $indexiContainer = this._$view.find('.didah-cargo-indexi');
  if (parent.isCargoHasIndex && parent.isCargoHasIndex()) {
    const didahPosHtml =
      mc.didah_pos !== undefined ? `<b>${mc.didah_pos}</b>` : '';
    $indexiContainer.html(`${didahPosHtml}`);
  } else {
    $indexiContainer.remove();
  }

  const $removeBtn = this._$view.find('.cargo-remove-btn');
  if (!(parent.isCargoRemovable && parent.isCargoRemovable())) {
    $removeBtn.remove();
  } else {
    $removeBtn.click(() => {
      this.controller.onRemove();
    });
  }
};

AbstractCargoView.prototype._getTemplate = function () {
  return `<div class="didah-cargo ${this._getViewClass()} didah-cargo-draggable">
                    <div class="didah-cargo-icons">
                        ${
                          this.controller.getQuantity() > 1
                            ? '<span class="cargo-handle cargo-handle-batch glyphicon glyphicon-align-justify"></span>'
                            : ''
                        }
                    </div>
                    <div class="didah-cargo-indexi"></div>
                    <div class="didah-cargo-title"></div>
                    <div class="didah-cargo-icons-lower">
                        <span class="cargo-remove-btn glyphicon glyphicon-remove"></span>                        
                    </div>
                </div>`;
};
AbstractCargoView.prototype._getViewClass = function () {
  DidahErrors.notImplemented.call(null, this);
};

/*******************************************************/
function Equipment(data, parent) {
  AbstractCargo.apply(this, arguments);
}

Equipment.prototype = Object.create(AbstractCargo.prototype);

Equipment.prototype._assignV = function () {
  this._view = new EquipmentView(this);
};

Equipment.prototype.tab = equipTab;

function EquipmentView() {
  AbstractCargoView.apply(this, arguments);
}

EquipmentView.prototype = Object.create(AbstractCargoView.prototype);

EquipmentView.prototype._getViewClass = function () {
  return 'cargo-equipment';
};

/*******************************************************/
function Material(data, parent) {
  AbstractCargo.apply(this, arguments);
}

Material.prototype = Object.create(AbstractCargo.prototype);

Material.prototype._assignV = function () {
  this._view = new MaterialView(this);
};

Material.prototype.tab = materialsTab;

function MaterialView() {
  AbstractCargoView.apply(this, arguments);
}

MaterialView.prototype = Object.create(AbstractCargoView.prototype);

MaterialView.prototype._getViewClass = function () {
  return 'cargo-material';
};

/*******************************************************/
function Panel(data, parent) {
  AbstractCargo.apply(this, arguments);
}

Panel.prototype = Object.create(AbstractCargo.prototype);

Panel.prototype._assignV = function () {
  this._view = new PanelView(this);
};

Panel.prototype.tab = panelsTab;

function PanelView() {
  AbstractCargoView.apply(this, arguments);
}

PanelView.prototype = Object.create(AbstractCargoView.prototype);

PanelView.prototype._getViewClass = function () {
  return 'cargo-panel';
};

/*******************************************************/
function MultiSelectableObjectView() {
  this.bufferName = this.bufferName || (() => undefined); //to implement
  this.modelKey = this.modelKey || null; //to implement (model property to store by)

  this._setViewSelectable = function () {
    const processClick = () => {
      const bufferName = this.bufferName();
      const app = this.getApp();
      if (this.isMultiSelected()) {
        app.selectBuffers[bufferName] = app.selectBuffers[bufferName].filter(
          (key) => key !== this.controller.model()[this.modelKey],
        );
      } else {
        if (!app.selectBuffers) {
          app.selectBuffers = {};
        }
        if (!app.selectBuffers[bufferName]) {
          app.selectBuffers[bufferName] = [];
        }

        app.selectBuffers[bufferName].push(
          this.controller.model()[this.modelKey],
        );
      }

      this.controller.reRender();
    };

    this._$view.click((e) => {
      if ((e.ctrlKey || e.metaKey) && this._canSelectionHappen()) {
        processClick();
        e.stopPropagation();
      }
    });
  };

  this._canSelectionHappen = function () {
    return true;
  };

  this._addSelectedClass = function () {
    if (this.isMultiSelected()) {
      this._$view.addClass('multi-selected');
    }
  };

  this.isMultiSelected = function () {
    const app = this.getApp();
    const bufferName = this.bufferName();
    return !!(
      app.selectBuffers &&
      app.selectBuffers[bufferName] &&
      app.selectBuffers[bufferName].includes(
        this.controller.model()[this.modelKey],
      )
    );
  };
}

/*******************************************************/

const ComponentsUtils = {
  popoverClose() {
    $('[data-original-title]').popover('hide');
  },
  setPopoverClose($view) {
    $view.on('click', (e) => {
      if (
        typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')
      ) {
        this.popoverClose();
      }
    });
  },
};

/*******************************************************/
function DidahAppApi(data) {
  this._data = data;
  this.events = DidahAppEvents;
}

DidahAppApi.prototype._exposeMethod = function (methodName, chained = false) {
  const app = this._app;
  if (!app) throw new Error('Application not set up');
  if (chained) {
    this[methodName] = function () {
      app[methodName].apply(app, arguments);
      return this;
    };
  } else {
    this[methodName] = function () {
      return app[methodName].apply(app, arguments);
    };
  }
};

DidahAppApi.prototype.on = function (name, cb) {
  if (!this._app) {
    !this.__tempEventListerers && (this.__tempEventListerers = {});
    this.__tempEventListerers[name] = cb;
  } else {
    this._app.addEventListener(name, cb);
  }
  return this;
};

DidahAppApi.prototype.init = function () {
  [
    'refresh',
    'setActionRequestParams',
    'setDidahBoxUrlParams',
    'reset',
  ].forEach((methodName) => this._exposeMethod(methodName, true));

  if (!this._app) throw new Error('Application not set up');
  delete this._data;

  if (this.__tempEventListerers) {
    Object.entries(this.__tempEventListerers).forEach(([name, cb]) =>
      this._app.addEventListener(name, cb),
    );
    delete this.__tempEventListerers;
  }

  this._app.init();
  return this;
};

/*******************************************************/
function CalenderAppApi() {
  DidahAppApi.apply(this, arguments);
}

CalenderAppApi.prototype = Object.create(DidahAppApi.prototype);

CalenderAppApi.prototype.init = function () {
  [
    'setPoolRequestParams',
    'setCalenderRequestParams',
    'setDaysInfoRequestParams',
    'setCurrentDateAndDuration',
    'addDuration',
  ].forEach((methodName) => this._exposeMethod(methodName, true));

  ['getDuration', 'getListDuration', 'getCurrentDate'].forEach((methodName) =>
    this._exposeMethod(methodName),
  );
  DidahAppApi.prototype.init.apply(this, arguments);
};
/*******************************************************/

const CargoTraits = {
  _cargoReceiver,
  _hasCargo,
  cargoReceiver,
  hasCargo,
  removesCargo,
};

export {
  AbstractCargo,
  AbstractCargoView,
  AjaxSender,
  CalenderAjaxSenderMixin,
  CalenderAppApi,
  CargoAppAjaxSender,
  CargoAppMixin,
  CargoAppViewMixin,
  CargoTabs,
  CargoTraits,
  ComponentsUtils,
  Controller,
  DidahApp,
  DidahAppApi,
  DidahAppEvents,
  DidahAppView,
  DidahCalender,
  DidahCalenderApp,
  DidahCalenderAppView,
  DidahCalenderDay,
  DidahCalenderDayInfo,
  DidahCalenderDayView,
  DidahCalenderList,
  DidahCalenderListModel,
  DidahCalenderListView,
  DidahCalenderView,
  DidahErrors,
  Equipment,
  EquipmentView,
  Material,
  MaterialView,
  MultiSelectableObjectView,
  Panel,
  PanelView,
  TabbedAppMixin,
  TabbedAppViewMixin,
  View,
  cargoConstructor,
  cargoStatuses,
  lineLockIcons,
};
