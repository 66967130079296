import './exposed';
import './overrides/overrides-main';
import { init, wrapWithInit, waitForInit } from './init';
import * as CommonModule from './common';
import * as Constants from './constants';
import * as PagesModule from './pages';
import * as ReactAppModule from './react-app';
import * as Utils from './utils';
import { translate as Transl } from '@/common/features/translations';

const Common = wrapWithInit(CommonModule);
const Pages = wrapWithInit(PagesModule);
const ReactApp = wrapWithInit(ReactAppModule);

export { Common, Constants, Pages, ReactApp, Transl, Utils, init, waitForInit };
