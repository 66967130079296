import type { AutoTableOpts } from './types';

function autoTable(selector: string | Element, params: AutoTableOpts) {
  return import('./init').then(({ autoTable: fn }) => fn(selector, params));
}

export type {
  AutoTableError,
  AutoTableHandle,
  AutoTableOpts,
  Column,
  Row,
} from './types';

export { autoTable };
