import $ from 'jquery';

import searchBtnTwig from './templates/search-button.twig';

import { minimizable } from '@/common';

import type {
  ApplyInitialSearchStateParams,
  UpdateColumnSearchHandler,
} from '@/common/features/datatables';
import {
  applyInitialColumnSearchState,
  setupColumnSearch,
} from '@/common/features/datatables';

const showHideSearch = ({ datatable, id }) => {
  const $wrapper = $(`#new_table${id}_wrapper`);
  const $searchPanel = $('tr.search-panel', $wrapper);
  let spHeight = $searchPanel.height() || 0;

  $searchPanel.toggle();
  $('input:text', $searchPanel).val('');

  spHeight = spHeight == 0 ? $searchPanel.height() : -1 * spHeight;
  const scrollBody = $('.dataTables_scrollBody', $wrapper);
  scrollBody.height(scrollBody.height() - spHeight);

  datatable.columns().search('').draw();
};

const addToggleSearchBtn = ({ content, datatable, id }) => {
  const $divTopRight = $(datatable.table().container()).find(
    'div.top > .dt-top-right',
  );

  const $content = $(
    searchBtnTwig({
      content,
      id,
    }),
  );
  $content.on('click', '.dt-search', function (e) {
    e.preventDefault();
    showHideSearch({ datatable, id });
  });

  $divTopRight.append($content);
};

type SetupSearchParams = {
  content: any;
  datatable: any;
  id: string;
  initialState: ApplyInitialSearchStateParams['initialState'];
  onUpdateColumnSearch: UpdateColumnSearchHandler;
};

const setupSearch = ({
  content,
  datatable,
  id,
  initialState,
  onUpdateColumnSearch,
}: SetupSearchParams) => {
  addToggleSearchBtn({
    content,
    datatable,
    id,
  });

  setupColumnSearch({ datatable, onUpdateColumnSearch });
  applyInitialColumnSearchState({ datatable, initialState });
};

const addWarningBadge = ({ datatable, text, title }) => {
  const $divTopRight = $(datatable.table().container()).find(
    'div.top > .dt-top-right',
  );

  $divTopRight.append(
    `<span class="bcsfy b1s0 btn tw-mx-1 tw-cursor-default" title="${title}">${text}</span>`,
  );
};

const addToggleRightBlockButton = ({ datatable }) => {
  const $divTopRight = $(datatable.table().container()).find(
    'div.top > .dt-top-right',
  );
  const $otherCtrl = $divTopRight.find('.dataTables_otherctrl');

  minimizable({
    minimizable: $divTopRight,
    toggleContainer: $otherCtrl,
  });
};

export {
  addToggleRightBlockButton,
  addWarningBadge,
  applyInitialColumnSearchState as applyInitialSearchState,
  setupSearch,
};
