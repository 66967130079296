import { useMemo, useState, type PropsWithChildren } from 'react';
import { FileLibraryContext, type FileLibraryContextType } from './context';

type Props = PropsWithChildren<
  Omit<FileLibraryContextType, 'selectedPath' | 'setSelectedPath'>
>;

function FileLibraryProvider({
  children,
  filesBaseURL,
  queryURL,
  selectedFilesUrls,
  setSelectedFilesUrls,
}: Props) {
  const [selectedPath, setSelectedPath] = useState('/');

  const contextValue = useMemo<FileLibraryContextType>(
    () => ({
      filesBaseURL,
      queryURL,
      selectedFilesUrls,
      selectedPath,
      setSelectedFilesUrls,
      setSelectedPath,
    }),
    [
      filesBaseURL,
      queryURL,
      selectedFilesUrls,
      selectedPath,
      setSelectedFilesUrls,
    ],
  );

  return (
    <FileLibraryContext.Provider value={contextValue}>
      {children}
    </FileLibraryContext.Provider>
  );
}

export { FileLibraryProvider };
