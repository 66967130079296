import { cn } from '@/utils';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

import { Error } from '../Error';

type Props = PropsWithChildren<{
  className?: string;
  errors?: string[];
  inlineLabel?: boolean;
  label?: string;
  name?: string;
  sanitizeError?: boolean;
}>;

const Search = ({
  children,
  className,
  errors,
  inlineLabel,
  label,
  name,
  sanitizeError,
}: Props) => {
  const errorsArray = useMemo(() => {
    if (!errors) return [];
    return Array.isArray(errors) ? errors : [errors];
  }, [errors]);

  return (
    <div
      className={cn(
        'form-group',
        { 'form-group-inline': inlineLabel },
        className,
      )}
    >
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      {children}
      {errorsArray.length > 0 && (
        <div>
          {errors.map((error, i) =>
            sanitizeError ? (
              <Error
                key={i}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(error) }}
              />
            ) : (
              <Error key={i}>{error}</Error>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export { Search };
