function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.download = '';
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export { downloadURI };
