import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMediaDialogContext } from '../../../context';

function Download() {
  const { onDownload } = useMediaDialogContext();

  return (
    <button
      className="btn btn-default"
      type="button"
      onClick={() => onDownload()}
    >
      <FontAwesomeIcon icon={faDownload} />
    </button>
  );
}

export { Download };
