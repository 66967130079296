import type { DocumentFile } from '../../types';
import { PreviewWithIcon } from './PreviewWithIcon';

type Props = {
  classNames?: {
    withIcon?: string;
  };
  document: DocumentFile;
};

function DocumentPreview({ classNames, document }: Props) {
  if (
    document.type === 'img' ||
    (!document.type && document?.id.toLowerCase().endsWith('.png')) ||
    (!document.type && document?.id.toLowerCase().endsWith('.jpeg')) ||
    (!document.type && document?.id.toLowerCase().endsWith('.jpg'))
  ) {
    return (
      <div
        className="tw-h-full tw-w-full tw-bg-cover tw-bg-center"
        style={{
          backgroundImage: `url('${document.smallImgUrl || document.url}')`,
        }}
      />
    );
  }

  return (
    <PreviewWithIcon
      className={classNames?.withIcon}
      text={document.text}
      type={document.id?.endsWith('mpeg') ? 'audio' : document.type}
    />
  );
}

export { DocumentPreview };
