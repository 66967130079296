import $ from 'jquery';
import asFormData from 'json-form-data';

import type { AutoTableError, Row } from '@/react-app/auto-table';
import { asyncPause, displayError, isDev } from '@/utils';

import { translate } from '@/common/features/translations';
import {
  NotificationType,
  showNotification,
} from '@/common/widgets/notification';
import { autoTablesButtonsStore } from './auto-tables-buttons-store';
import { controller } from './auto-tables-controller';
import { setButtonLoading } from './common';

async function saveForTable(id: string, rows: Row[]) {
  try {
    const res = await fetch('/ajax_api.php', {
      body: asFormData({
        ...controller.getParams(id).save,
        adata: JSON.stringify(rows),
        id: 33,
      }),
      method: 'POST',
    });

    if (!res.ok) throw new Error();
    const data = (await res.json()) as { 1: Row[]; error?: string };

    controller.setRows(id, data[1]);
    controller.setErrors(id, null);
    controller.setReadOnly(id, true);

    // пауза для ререндера таблицы
    await asyncPause(25);

    return true;
  } catch (e) {
    const errors = e.response?.data?.errors
      ? (Object.values(e.response?.data?.errors) as AutoTableError[])
      : null;
    if (errors) {
      controller.setErrors(id, errors);
    }

    // пауза для ререндера таблицы
    await asyncPause(25);

    return false;
  }
}

class SaveButton {
  private readonly $btn: JQuery;
  private isSaving = false;
  private readonly content: string;
  private isWithErrors = controller.hasAnyErrors();

  constructor(selector: string, isLocked) {
    this.$btn = $(selector);
    this.content = this.$btn.html();

    this.setupClick();

    this.update(!isLocked);

    controller.subscribe('errors', (val) => {
      this.isWithErrors = val;

      this.rerender();
    });
  }

  private setupClick() {
    this.$btn.on('click', async () => {
      if (this.isSaving) {
        return;
      }

      try {
        this.isSaving = true;
        this.setLoading();

        const allRows = controller.getAllRows();

        const results = await Promise.all(
          Object.entries(allRows).map(([id, rows]) => saveForTable(id, rows)),
        );

        const isReadOnlyAfterSave = controller.isReadOnlyAll();
        autoTablesButtonsStore.lockButton?.update(isReadOnlyAfterSave);
        this.update(!isReadOnlyAfterSave);

        const allSuccess = results.every((res) => res);
        const someSuccess = results.some((res) => res);

        if (allSuccess) {
          showNotification({
            title: translate('common.success'),
            type: NotificationType.Success,
          });
        } else if (someSuccess) {
          showNotification({
            text: translate('autotable.not_all_saved'),
            title: translate('common.warning'),
            type: NotificationType.Warning,
          });
        } else {
          displayError(translate('autotable.error_saving'));
        }
      } catch (e) {
        displayError(translate('autotable.error_saving'));
        if (isDev) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } finally {
        this.isSaving = false;
        this.setNotLoading();
      }
    });
  }

  setNotLoading() {
    this.$btn.html(this.content);
  }

  setLoading() {
    setButtonLoading(this.$btn);
  }

  rerender() {
    this.update(!controller.isReadOnlyAll() && !this.isWithErrors);
  }

  update(isEnabled: boolean) {
    this.$btn.prop('disabled', !isEnabled);
  }
}

function setupSaveButton(selector: string, isLocked?: boolean) {
  autoTablesButtonsStore.saveButton = new SaveButton(selector, isLocked);
}

export { SaveButton, setupSaveButton };
