import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMediaDialogContext } from '../../../context';

function Close() {
  const { onClose } = useMediaDialogContext();

  return (
    <button className="btn btn-danger" type="button" onClick={() => onClose()}>
      <FontAwesomeIcon icon={faClose} />
    </button>
  );
}

export { Close };
