import { cn } from '@/utils';
import throttle from 'lodash/throttle';
import type { MutableRefObject } from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

type Props = PropsWithChildren<{
  anchorRef: MutableRefObject<HTMLElement>;
  className?: string;
  isOpen: boolean;
  onClose: VoidFunction;
}>;

type FixedDropdownHandle = { updateCoords: VoidFunction };

const offsetPx = 2;

const FixedDropdownComponent = (
  { anchorRef, children, className, isOpen, onClose }: Props,
  ref: React.ForwardedRef<HTMLDialogElement>,
) => {
  const [coords, setCoords] = useState<Nullable<DOMRect>>();
  const dialogRef = useRef<HTMLDialogElement>(null);

  const updateCoords = useCallback(() => {
    if (!anchorRef.current || !isOpen) {
      return;
    }

    const c = anchorRef.current.getBoundingClientRect();
    setCoords(c);
  }, [anchorRef, isOpen]);

  useEffect(() => {
    updateCoords();
  }, [updateCoords]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCoordsDebounced = useCallback(throttle(updateCoords, 50), [
    updateCoords,
  ]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!dialogRef.current || !isOpen) {
        return;
      }

      if (
        e.target === dialogRef.current ||
        dialogRef.current.contains(e.target as HTMLElement)
      ) {
        return;
      }

      onClose();
    };
    document.body.addEventListener('click', handler);

    return () => {
      document.body.removeEventListener('click', handler);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    window.addEventListener('resize', updateCoordsDebounced);
    window.addEventListener('scroll', updateCoordsDebounced);

    return () => {
      window.removeEventListener('resize', updateCoordsDebounced);
      window.removeEventListener('scroll', updateCoordsDebounced);
    };
  }, [updateCoordsDebounced]);

  useImperativeHandle(
    ref,
    () =>
      ({
        updateCoords,
      }) as any,
    [updateCoords],
  );

  const style = coords
    ? {
        left: `${coords.left}px`,
        top: `${coords.bottom + offsetPx}px`,
        width: `${coords.width}px`,
      }
    : {};

  if (!isOpen) {
    return null;
  }

  return (
    <dialog
      ref={dialogRef}
      className={cn(
        'dropdown-menu !tw-fixed tw-z-[11] tw-m-0 !tw-block tw-max-h-32 tw-overflow-auto tw-px-3 tw-py-1 tw-text-text-default dark:tw-text-text-default-dark',
        className,
      )}
      style={style}
    >
      {children}
    </dialog>
  );
};

const FixedDropdown = forwardRef<HTMLDialogElement, Props>(
  FixedDropdownComponent,
);

export { FixedDropdown, FixedDropdownHandle };
