import type { PersonOption } from '@/react-app/messages/types';

type IncorrectOptions = Array<PersonOption & { selected?: boolean }>;

function clearOptions(options: IncorrectOptions): PersonOption[] {
  return options.map(({ name, value }) => ({
    name,
    value,
  }));
}

function parseSingleOptions(options: IncorrectOptions) {
  const selectedOption = options.find((option) => !!option.selected);

  return {
    options: clearOptions(options),
    selected: selectedOption?.value,
  };
}

function parseMultiOptions(options: IncorrectOptions) {
  const selectedOptions = options.filter((option) => !!option.selected);

  return {
    options: clearOptions(options),
    selected: selectedOptions.map(({ value }) => value),
  };
}

export { parseMultiOptions, parseSingleOptions };
