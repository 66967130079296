import $ from 'jquery';

import type { BannerData } from './types';
import { asyncPauseWithCancel } from '@/utils';

type SetContentParams = {
  banner: BannerData;
  multiple?: boolean;
  next?: VoidFunction;
};

function setBannerContent({ banner, multiple, next }: SetContentParams) {
  const $container = $('#banner-carousel-container');
  const $content = $('<div class="banner-carousel-inner fwb mx5p pl10p">').html(
    `<div class="banner-carousel-inner__text">${banner.text}</div>`
  );
  $content.addClass(banner.className);
  if (multiple && next) {
    $content.addClass('banner-carousel-inner--clickable');
    $content.on('click', () => next());
  }
  $container.get(0).replaceChildren($content.get(0));
}

async function launchBannerCarousel(banners: BannerData[]) {
  if (!banners.length) return;

  let index = 0;
  let skipPause: Nullable<VoidFunction> = null;
  if (banners.length > 1) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      setBannerContent({
        banner: banners[index],
        multiple: banners.length > 1,
        next: () => skipPause?.(),
      });
      const { cancel, pause } = asyncPauseWithCancel(
        banners[index].seconds * 1000
      );
      skipPause = cancel;
      await pause;
      index = (index + 1) % banners.length;
    }
  } else {
    setBannerContent({
      banner: banners[index],
    });
  }
}

export { launchBannerCarousel };
