import { getAndClearStoredActions, windows } from '../storage';
import { dispatch } from '../hook-dispatcher';

function checkActiveWindowAllowsToContinueNavigation() {
  const topWindow = windows.slice(-1)[0];
  const topWindowIFrame = topWindow?.getWindow();
  const checkFunction =
    topWindowIFrame?.Didah?.Utils.isAllowedToContinueNavigation;
  if (!checkFunction) return true;

  return checkFunction();
}

function forceCloseAllWindows() {
  if (!checkActiveWindowAllowsToContinueNavigation()) {
    return;
  }

  const reversedWindows = [...windows].reverse();
  reversedWindows.forEach((win) => {
    win.forceClose();
    getAndClearStoredActions(win.id);
  });
  windows.length = 0;
}

function forceCloseAllWindowsAndRunFirstWindowActions() {
  if (!checkActiveWindowAllowsToContinueNavigation()) {
    return;
  }

  const firstWindow = windows[0];
  const firstWindowActions = firstWindow
    ? getAndClearStoredActions(firstWindow.id)
    : null;

  forceCloseAllWindows();

  dispatch(window.top, firstWindowActions);
}

function closeTopChildFromRoot() {
  if (!checkActiveWindowAllowsToContinueNavigation()) {
    return;
  }

  const topWindow = windows.slice(-1)[0];
  if (!topWindow) {
    return;
  }

  topWindow.close();
}

export {
  closeTopChildFromRoot,
  forceCloseAllWindows,
  forceCloseAllWindowsAndRunFirstWindowActions,
};
