import $ from 'jquery';
import { setContainerLoadingOverlay, setElementFolding } from '@/utils';

function foldable() {
  $('.contentWrpper').each(function () {
    const $wrapper = $(this);
    const $splashScreenContainer = $(
      '<div style="height: 300px; position: relative"/>'
    );

    function addSplashScreen() {
      $wrapper.parent().append($splashScreenContainer);
      setContainerLoadingOverlay($splashScreenContainer);
    }

    function prepareFoldableElements() {
      $('.didah-foldable').each(function () {
        const id = $(this).get(0).id;
        setElementFolding(id);
      });
    }

    function removeSplashAndShowElements() {
      $splashScreenContainer.remove();
      $wrapper.show();
    }

    addSplashScreen();
    prepareFoldableElements();
    removeSplashAndShowElements();
  });
}

export { foldable };
