import $ from 'jquery';

const addOtherButtons = ({ content, datatable, id }) => {
  const $divTop = $(datatable.table().container()).find('div.top');
  const $content = $(
    `<div class="dataTables_otherbtn" id="new_table${id}_otherbtn">${content}</div>`
  );
  $divTop.prepend($content);
};

export { addOtherButtons };
