import { useEffect, useRef } from 'react';

import type { Scroll } from '../types';

type Params = {
  getContainer: () => Nullable<Optional<HTMLElement>>;
};

const useContainerScroll = ({ getContainer }: Params) => {
  const containerScrollRef = useRef<Scroll>({
    scrollX: 0,
    scrollY: 0,
  });

  useEffect(() => {
    const parent = getContainer();

    const scrollEventHandler = () => {
      containerScrollRef.current = {
        scrollX: parent?.scrollLeft || 0,
        scrollY: parent?.scrollTop || 0,
      };
    };

    parent?.addEventListener('scroll', scrollEventHandler);
    return () => {
      parent?.removeEventListener('scroll', scrollEventHandler);
    };
  }, [getContainer]);

  return containerScrollRef.current;
};

export { useContainerScroll };
