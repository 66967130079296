import type { Column } from '@/react-app/auto-table';

type ColumnFromResponse = Omit<Column, 'rules'> & {
  rules: (string | [string, ...any])[];
} & {
  ajaxSelectDictionary?: { label: string; value: string }[];
};

const setupSelectColumn = (col: ColumnFromResponse) => {
  if (col.type !== 'select' || !col.ajaxSelectDictionary) {
    return col;
  }

  const {
    ajaxSelectDictionary,
    selectOptions, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...restOfCol
  } = col;

  return {
    ...restOfCol,
    selectOptions: [...ajaxSelectDictionary],
  };
};

const setupColumn = (col: ColumnFromResponse) => {
  return [setupSelectColumn].reduce((c, f) => f(c), col);
};

const setupColumns = (initialColumns: ColumnFromResponse[]) => {
  return initialColumns.map(setupColumn);
};

export { setupColumns };
