function round(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function isEven(n: number): boolean {
  return n % 2 === 0;
}

function isOdd(n: number): boolean {
  return Math.abs(n % 2) === 1;
}

function toFixedFix(n: number, prec: number): string {
  const k = Math.pow(10, prec);
  return '' + (Math.round(n * k) / k).toFixed(prec);
}

function number_format(number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  const dec = typeof dec_point === 'undefined' ? '.' : dec_point;
  const s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

function dt_number_format(number, decimals) {
  if (!window.number_format_data) {
    window.number_format_data = {
      dec_point: '.',
      thousands_sep: ',',
    };
  }
  return number_format(
    number,
    decimals,
    window.number_format_data.dec_point,
    window.number_format_data.thousands_sep
  );
}

export { dt_number_format, isEven, isOdd, number_format, round };
