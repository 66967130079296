type Props = {
  fields: Dictionary;
};

const HiddenInputs = ({ fields }: Props) => {
  const fieldsData = Object.entries(fields);
  return (
    <>
      {fieldsData.map(([name, value]) => (
        <input key={name} name={name} type="hidden" value={value} />
      ))}
    </>
  );
};

export { HiddenInputs };
