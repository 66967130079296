import Nanobus from 'nanobus';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { ResizeDelta } from '@/utils';
import { asyncPause, cn, makeMovable, makeResizable } from '@/utils';

import { ModalSizes, ModalTypes, type DesktopDialogProps } from '../types';

import { useEscManager, usePrevious } from '@/react-app/shared/hooks';
import { Modal } from '../modal';
import { MODAL_SHOW_DELAY } from '../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { hideBodyOverflow, showBodyOverflow } from './utils';

type Events = {
  resize: (params: ResizeDelta) => void;
};

const DesktopDialog = ({
  as: component = 'div',
  asProps,
  children: body,
  className,
  classNames,
  closeOnEsc = true,
  footer,
  header,
  isCloseShown = true,
  isPortal,
  isShown,
  movable,
  onClose,
  onResize,
  resizable,
  size = ModalSizes.Normal,
  type,
}: DesktopDialogProps) => {
  const eventBus = useRef(new Nanobus<Events>());
  const containerRef = useRef<HTMLElement>();
  const headerRef = useRef<HTMLDivElement>();

  const prevShown = usePrevious(isShown);

  const [isMovableSetupDone, setMovableSetupDone] = useState(false);
  const [isResizableSetupDone, setResizableSetupDone] = useState(false);

  const hasX = !!onClose && isCloseShown;

  useEscManager({ isEnabled: closeOnEsc && isShown, onClose });

  useEffect(() => {
    if (isShown && !prevShown) {
      hideBodyOverflow();
    } else if (!isShown && prevShown) {
      showBodyOverflow();
    }
  }, [isShown, prevShown]);

  useEffect(() => {
    (async () => {
      await asyncPause(MODAL_SHOW_DELAY);
      if (!movable || !isShown || !headerRef.current) {
        return;
      }

      if (isMovableSetupDone) {
        return;
      }

      makeMovable({
        element: containerRef.current,
        handle: headerRef.current,
        ignoreHandleChildren: true,
      });

      setMovableSetupDone(true);
    })();
  }, [movable, isMovableSetupDone, isShown]);

  const onResizeChange = useCallback(
    (params: ResizeDelta) => eventBus.current.emit('resize', params),
    [],
  );

  useEffect(() => {
    const listener = (params: ResizeDelta) => {
      onResize?.(params);
    };
    eventBus.current.on('resize', listener);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      eventBus.current.removeListener('resize', listener);
    };
  }, [onResize]);

  useEffect(() => {
    (async () => {
      await asyncPause(MODAL_SHOW_DELAY);
      if (!resizable || !isShown || !containerRef.current) {
        return;
      }

      if (isResizableSetupDone) {
        return;
      }

      makeResizable({
        element: containerRef.current,
        onResizeChange,
      });

      setResizableSetupDone(true);
    })();
  }, [resizable, isResizableSetupDone, onResizeChange, isShown]);

  const Component = component as any;

  return (
    <Modal className={className} isPortal={isPortal} isShown={isShown}>
      <div
        className={cn(
          'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center',
          classNames?.dialog,
        )}
      >
        <Component
          ref={containerRef}
          {...asProps}
          className={cn(
            'tw-relative tw-mx-2.5 tw-my-0 tw-w-[90%] tw-overflow-hidden tw-rounded-md tw-bg-white tw-p-0 tw-shadow-lg tw-transition tw-duration-300 dark:tw-bg-foreground-default-dark md:tw-mx-auto',
            {
              'md:tw-w-[30%]': size === ModalSizes.Small,
              'md:tw-w-[50%]': size === ModalSizes.Normal,
              'md:tw-w-[80%]': size === ModalSizes.Large,
            },
            asProps?.className,
            classNames?.container,
          )}
        >
          {header && (
            <div
              ref={headerRef}
              className={cn(
                'tw-relative tw-p-3.5 dark:tw-border-transparent dark:tw-text-text-default-dark',
                {
                  'tw-bg-foreground-info tw-text-text-default-contrast dark:tw-bg-foreground-info-3-dark':
                    type === ModalTypes.LightBlue,
                  'tw-bg-foreground-primary tw-text-text-default-contrast dark:tw-bg-foreground-primary-2-dark':
                    type === ModalTypes.Blue,
                  'tw-border-b tw-border-solid tw-border-gray-200':
                    (body || footer) && type === ModalTypes.Default,
                  'tw-cursor-grab': movable,
                  'tw-pr-9': hasX,
                },
                classNames?.headerWrapper,
              )}
            >
              <div
                className={cn(
                  'tw-inline-flex [&>*]:tw-my-0',
                  classNames?.header,
                )}
              >
                {header}
              </div>
              {onClose && (
                <span
                  className={cn(
                    'tw-absolute tw-right-3.5 tw-top-1/2 tw--translate-y-1/2 tw-transform tw-cursor-pointer tw-select-none tw-text-3xl',
                    {
                      'tw-text-white': [
                        ModalTypes.Blue,
                        ModalTypes.LightBlue,
                      ].includes(type),
                    },
                  )}
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
          )}
          {body && (
            <div
              className={cn(
                'tw-max-h-[75vh] tw-overflow-auto tw-p-3.5 dark:tw-bg-foreground-default-2-dark',

                classNames?.body,
              )}
            >
              {body}
            </div>
          )}
          {footer && (
            <div
              className={cn(
                'tw-flex tw-justify-end tw-gap-1.5 tw-p-3.5 dark:tw-border-transparent dark:tw-bg-foreground-default-dark',
                {
                  'tw-border-t tw-border-solid tw-border-gray-200': !!body,
                },
                classNames?.footer,
              )}
            >
              {footer}
            </div>
          )}
        </Component>
      </div>
    </Modal>
  );
};

export { DesktopDialog };
