import $ from 'jquery';

function onHeaderClick(cell: HTMLElement, dTable: any) {
  const jqCell = $(cell);
  const className = 'select-checkbox-header-selected';
  const className2 = 'select-checkbox-header-semi-selected';
  if (!jqCell.hasClass(className) && !jqCell.hasClass(className2)) {
    dTable.rows({ search: 'applied' }).select();
  } else {
    dTable.rows().deselect();
  }
}

export * from './formats';
export * from './save-settings';
export * from './search';

export { onHeaderClick };
