import React, { useState } from 'react';
import type { InputProps } from '../base';
import { Input } from '../base';
import { HoursDropdown } from './HoursDropdown';
import { formatMinutes, getDisplayedValue, validateValue } from './utils';
import type { HoursPickerValue } from './types';

type Props = {
  onValueChange: (value: HoursPickerValue) => void;
  value: HoursPickerValue;
} & Pick<
  InputProps,
  'disabled' | 'inlineLabel' | 'label' | 'placeholder' | 'name'
>;
const partialHours = /^\d+:?$/;
const partialMinutes = /^\d+:[0-5]?$/;
const completeMinutes = /^\d+:[0-5][0-9]$/;
const regexp = /^(\d+)(:)?([0-5][0-9])?$/;
const minutesStep = 5;

function HoursPicker({
  disabled,
  inlineLabel,
  name,
  onValueChange,
  placeholder,
  value,
}: Props) {
  const [internalValue, setInternalValue] = useState(
    getDisplayedValue(validateValue(value) ?? { hours: 0, minutes: 0 })
  );

  const emitValue = (newValue: HoursPickerValue) => {
    if (newValue.hours < 0) {
      newValue = { hours: 0, minutes: 0 };
    }
    onValueChange(newValue);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = e.target.value;
    const match = changedValue.match(regexp);
    const partialMatch =
      changedValue.match(partialHours) ||
      changedValue.match(partialMinutes) ||
      changedValue.match(completeMinutes);

    if (!partialMatch) {
      return;
    }

    setInternalValue(changedValue);

    if (!match) {
      return;
    }

    const hoursMatch = match[1];
    const minutesMatch = match[3];

    const parsedHours = parseInt(hoursMatch);
    const parsedMinutes = minutesMatch ? parseInt(minutesMatch) : 0;

    emitValue({ hours: parsedHours, minutes: parsedMinutes });
  };

  const setInternalAndEmit = (newValue: HoursPickerValue) => {
    setInternalValue(getDisplayedValue(newValue));
    emitValue(newValue);
  };

  const setHours = (hours: number) => {
    setInternalAndEmit({
      ...value,
      hours,
    });
  };

  const setMinutes = (minutes: number) => {
    setInternalAndEmit({
      ...value,
      minutes,
    });
  };

  const incrementHours = () => {
    const newValue = {
      ...value,
      hours: value.hours + 1,
    };

    setInternalAndEmit(newValue);
  };

  const decrementHours = () => {
    const newValue = {
      ...value,
      hours: value.hours === 0 ? 0 : value.hours - 1,
    };

    setInternalAndEmit(newValue);
  };

  const incrementMinutes = () => {
    let newHours = value.hours;
    let newMinutes = value.minutes + minutesStep;
    if (newMinutes > 59) {
      newHours = value.hours + 1;
      newMinutes = newMinutes % 60;
    }

    const newValue = { hours: newHours, minutes: newMinutes };

    setInternalAndEmit(newValue);
  };
  const decrementMinutes = () => {
    let newHours = value.hours;
    let newMinutes = value.minutes - minutesStep;
    if (newMinutes < 0) {
      newHours = value.hours - 1;
      newMinutes = newMinutes + 60;
    }

    const newValue = { hours: newHours, minutes: newMinutes };

    setInternalAndEmit(newValue);
  };

  return (
    <Input
      addon={{
        icon: (
          <HoursDropdown
            decrementHours={decrementHours}
            decrementMinutes={decrementMinutes}
            displayedHours={value.hours + ''}
            displayedMinutes={formatMinutes(value.minutes)}
            incrementHours={incrementHours}
            incrementMinutes={incrementMinutes}
            setHours={setHours}
            setMinutes={setMinutes}
          >
            <div className="tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-center">
              <i className="glyphicon glyphicon-time" />
            </div>
          </HoursDropdown>
        ),
        position: 'after',
      }}
      classNames={{
        formGroup: 'tw-max-w-[100px]',
      }}
      disabled={disabled}
      inlineLabel={inlineLabel}
      name={name}
      placeholder={placeholder}
      value={internalValue}
      onChange={onInputChange}
    />
  );
}

export { HoursPicker };
