import $ from 'jquery';

import { pxStringToNumber } from '@/utils';

import './style.scss';

function email() {
  $(function () {
    $('.emailRowHead').on('click', function (e) {
      if ($('.emailCheckBox input').is(e.target)) {
        return;
      }
      $(this).parent().siblings('.emailRow').find('.emailBody').hide(150);
      $(this).siblings('.emailBody').toggle(150);
    });
    $('#emailMenuButton').on('click', function () {
      const $emailMenu = $('#emailMenu');
      const $emailContent = $('#emailContent');
      if ($emailMenu.css('left') == '-210px') {
        if (pxStringToNumber($emailContent.css('margin-left')) < 210) {
          $emailContent.css('margin-left', '210px');
        }
        $emailMenu.animate(
          {
            left: '10px',
          },
          400
        );
      } else {
        setTimeout(function () {
          $emailContent.css('margin-left', 'auto');
        }, 200);
        $emailMenu.animate(
          {
            left: '-210px',
          },
          400
        );
      }
    });
  });
}

export { email };
