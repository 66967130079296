import { asyncPause, cn } from '@/utils';
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react';

import $ from 'jquery';
import { Error } from './Error';
import { RequiredStar } from './RequiredStar';
import moment from 'moment';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  classNames?: {
    formGroup?: string;
  };
  errorMessage?: ReactNode;
  format: string;
  inlineLabel?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  maxDate: Date | string;
  minDate: Date | string;
  onHide?: VoidFunction;
  onShow?: VoidFunction;
  onValueChange: (value: string) => void;
  parent?: HTMLElement;
  position: Nullable<{
    horizontal: 'left' | 'right';
    vertical: 'bottom' | 'top';
  }>;
};

const formattedDateToDate = (dateStr: string, format: string) => {
  if (!dateStr) {
    return null;
  }
  return moment(dateStr, format).toDate();
};

const DatePickerComponent = (
  {
    className,
    classNames,
    errorMessage,
    format,
    inlineLabel,
    isRequired,
    label,
    maxDate,
    minDate,
    onHide,
    onShow,
    onValueChange,
    parent,
    position,
    value,
    ...restProps
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const rootRef = useRef(null);
  const initDone = useRef(false);
  const datePicker = useRef<any>();
  const [isPositioned, setIsPositioned] = useState(false);

  const input = (
    <input ref={ref} className={cn('form-control', className)} {...restProps} />
  );

  let content = input;

  if (label) {
    content = (
      <>
        <label
          className={cn({
            'tw-mb-0 tw-mr-[6px] tw-whitespace-nowrap': inlineLabel,
          })}
          htmlFor={restProps.name}
        >
          {label}
          {isRequired && <RequiredStar />}
        </label>
        {input}
      </>
    );
  }

  const adaptMinMax = useCallback(
    (minMax) => {
      const value =
        typeof minMax === 'string'
          ? formattedDateToDate(minMax, format)
          : minMax;
      return value || false;
    },
    [format],
  );

  const getDatePickerAPI = useCallback(() => {
    return datePicker.current?.data('DateTimePicker');
  }, []);

  const setDatePickerValue = useCallback(
    (newValue: Nullable<string>) => {
      const dateTimePicker = getDatePickerAPI();
      if (!dateTimePicker) {
        return;
      }

      if (newValue) {
        dateTimePicker.date(moment(newValue, format));
      } else {
        dateTimePicker.clear();
      }
    },
    [format, getDatePickerAPI],
  );

  const setDatePickerMin = useCallback(
    (min) => {
      getDatePickerAPI()?.minDate(adaptMinMax(min));
    },
    [adaptMinMax, getDatePickerAPI],
  );

  const setDatePickerMax = useCallback(
    (max) => {
      getDatePickerAPI()?.maxDate(adaptMinMax(max));
    },
    [adaptMinMax, getDatePickerAPI],
  );

  const setPosition = useCallback(() => {
    if (!parent) {
      return;
    }

    const dpBcr = rootRef.current?.getBoundingClientRect() as Optional<DOMRect>;

    if (!dpBcr) {
      return;
    }

    const parentBcr = parent.getBoundingClientRect();

    const dpTop = dpBcr.top - parentBcr.top;
    const dpLeft = dpBcr.left - parentBcr.left;

    const widget = rootRef.current?.querySelector(
      '.bootstrap-datetimepicker-widget',
    );
    if (!widget) {
      return;
    }

    delete widget.style.inset;

    widget.style.top = `${dpTop + dpBcr.height * 1.5}px`;
    widget.style.left = `${dpLeft}px`;
  }, [parent]);

  useEffect(() => {
    if (!initDone.current) {
      return;
    }

    setDatePickerMin(minDate);
  }, [minDate, setDatePickerMin]);

  useEffect(() => {
    if (!initDone.current) {
      return;
    }

    setDatePickerMax(maxDate);
  }, [maxDate, setDatePickerMax]);

  useEffect(() => {
    if (!initDone.current) {
      return;
    }

    setDatePickerValue(value + '');
  }, [value, setDatePickerValue]);

  const onDpChange = useCallback(
    ({ date }: any) => {
      onValueChange(date ? date.format(format) : null);
    },
    [format, onValueChange],
  );

  useEffect(() => {
    const datePickerWrapper = datePicker.current;
    if (!initDone.current || !datePickerWrapper) {
      return;
    }

    datePickerWrapper.off('dp.change');
    datePickerWrapper.on('dp.change', onDpChange);
  }, [onDpChange]);

  const onDpShow = useCallback(async () => {
    await asyncPause(100);
    setPosition();
    setIsPositioned(true);
    onShow?.();
  }, [onShow, setPosition]);

  useEffect(() => {
    const datePickerWrapper = datePicker.current;
    if (!initDone.current || !datePickerWrapper) {
      return;
    }

    datePickerWrapper.off('dp.show');
    datePickerWrapper.on('dp.show', onDpShow);
  }, [onDpShow]);

  const onDpHide = useCallback(() => {
    onHide?.();
    setIsPositioned(false);
  }, [onHide]);

  useEffect(() => {
    const datePickerWrapper = datePicker.current;
    if (!initDone.current || !datePickerWrapper) {
      return;
    }

    datePickerWrapper.off('dp.hide');
    datePickerWrapper.on('dp.hide', onDpHide);
  }, [onDpHide]);

  useEffect(() => {
    if (initDone.current) {
      return;
    }

    const options = {
      format,
      locale: moment.locale(),
      maxDate: adaptMinMax(maxDate),
      minDate: adaptMinMax(minDate),
      useCurrent: false,
      ...(position && { widgetPositioning: position }),
    };

    const inputEl = rootRef.current?.querySelector('input');
    if (!inputEl) {
      return;
    }

    const datePickerWrapper = $(inputEl).datetimepicker(options);

    datePickerWrapper.on('dp.change', onDpChange);
    datePickerWrapper.on('dp.show', onDpShow);
    datePickerWrapper.on('dp.hide', onDpHide);

    datePicker.current = datePickerWrapper;
    setDatePickerValue(value + '');

    initDone.current = true;
  }, [
    adaptMinMax,
    format,
    maxDate,
    minDate,
    onDpChange,
    onDpHide,
    onDpShow,
    position,
    setDatePickerValue,
    value,
  ]);

  return (
    <div
      ref={rootRef}
      className={cn(
        'form-group',
        '[&_.bootstrap-datetimepicker-widget]:tw-fixed',
        {
          '[&_.bootstrap-datetimepicker-widget]:!tw-hidden': !isPositioned,
          'tw-flex tw-items-center tw-justify-start': inlineLabel,
        },
        classNames?.formGroup,
      )}
    >
      {content}
      {errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
};

const DatePicker = forwardRef<HTMLInputElement, Props>(DatePickerComponent);

export { DatePicker, Props as DatePickerProps, formattedDateToDate };
