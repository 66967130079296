import { useEscManager } from '@/react-app/shared/hooks';
import type { MediaDialogParams } from '../../types';

import { useMediaDialogContext } from './context';
import { NextPrev } from './controls';
import { DrawingCanvas, RootViewer } from './renderers';
import { Mode } from './types';
import { Modal } from '@/react-app/shared/components';
import { MediaUploader } from './uploader';

type Props = {
  activeParams: MediaDialogParams;
};

function MediaDialogModal({ activeParams }: Props) {
  const { mode } = useMediaDialogContext();
  const { closeActiveDialog, isActive, media } = activeParams;

  useEscManager({
    isEnabled: activeParams.isActive && !activeParams.options?.noClose,
    onClose: closeActiveDialog,
  });

  const content = media.files.length ? (
    <>
      <RootViewer />
      {mode === Mode.Normal && <NextPrev />}
      {mode === Mode.Drawing && <DrawingCanvas />}
    </>
  ) : (
    <MediaUploader />
  );

  return (
    <Modal className="tw-bg-black/75" isShown={isActive}>
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-auto">
        {content}
      </div>
    </Modal>
  );
}

export { MediaDialogModal };
