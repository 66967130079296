import type { RefObject } from 'react';
import { useCallback, useLayoutEffect, useRef } from 'react';

import type { Sizes } from '../../types';

type Params = {
  imageRef: RefObject<HTMLImageElement>;
  scale: number;
  url: string;
};

const useSizes = ({ imageRef, scale, url }: Params) => {
  const initialSizes = useRef<Sizes>();

  const getScaledSizes = useCallback(
    () =>
      scale !== 1 && initialSizes.current
        ? {
            height: initialSizes.current.height * scale,
            width: initialSizes.current.width * scale,
          }
        : null,
    [scale],
  );

  const scaledSizes = getScaledSizes();
  const scaledSizesPx = scaledSizes
    ? {
        height: `${scaledSizes.height}px`,
        width: `${scaledSizes.width}px`,
      }
    : {};

  const getSizes = useCallback(
    () => getScaledSizes() ?? initialSizes.current ?? { height: 0, width: 0 },
    [getScaledSizes],
  );

  useLayoutEffect(() => {
    if (!imageRef.current) return;

    const img = new Image();
    // eslint-disable-next-line func-names
    img.onload = function () {
      initialSizes.current = {
        height: imageRef.current?.clientHeight || 0,
        width: imageRef.current?.clientWidth || 0,
      };
    };
    img.src = url;
  }, [url, imageRef]);

  return {
    getSizes,
    scaledSizesPx,
  };
};

export { useSizes };
