class Timer {
  private id: Nullable<number> = null;
  isCompleted = false;
  constructor(
    private cb: () => void,
    timeout = 3000,
  ) {
    this.id = setTimeout(() => {
      cb();
      this.isCompleted = true;
    }, timeout) as unknown as number;
  }

  cancel() {
    clearTimeout(this.id);
    this.id = null;

    return this.isCompleted;
  }
}

function asyncPause(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function asyncPauseWithCancel(time: number) {
  let cancel: Nullable<VoidFunction> = null;
  const pause = new Promise<void>((resolve) => {
    const timeoutId = setTimeout(resolve, time);
    cancel = () => {
      clearTimeout(timeoutId);
      resolve();
    };
  });

  return {
    cancel,
    pause,
  };
}

export { Timer, asyncPause, asyncPauseWithCancel };
