import { createCachedFunction } from '../general';

function onLoadResizeIframe(
  elemOrId: string | HTMLIFrameElement,
  extraHeight = 0,
) {
  let iframeJq = null;
  if ('string' === typeof elemOrId) {
    iframeJq = $('#' + elemOrId);
  } else {
    iframeJq = $(elemOrId);
  }

  if (!iframeJq.length) {
    return;
  }

  const iframe = iframeJq.get(0);
  const iframewindow =
    iframe.contentWindow ||
    (iframe.contentDocument && iframe.contentDocument.defaultView) ||
    null;

  if (iframewindow) {
    iframeJq.height(iframewindow.document.body.offsetHeight + extraHeight);
  } else {
    iframeJq.height(150);
  }
}

function onLoadShowIframe($container) {
  $container.removeClass('iframe-container--loading');
  $container.find('.iframe-spinner').remove();
}

const inIframe = createCachedFunction(() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
});

function postMessageToAllWindows(message: any) {
  // Get all the open windows/frames including the top-level window
  const allWindows = [window.top, ...Array.from(window.top.frames)];

  // Iterate over each window/frame
  for (let i = 0; i < allWindows.length; i++) {
    const targetWindow = allWindows[i];

    targetWindow?.postMessage?.(message, '*');
  }
}

export {
  inIframe,
  onLoadResizeIframe,
  onLoadShowIframe,
  postMessageToAllWindows,
};
