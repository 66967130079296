import { cn } from '@/utils';
import {
  faChevronDown,
  faFolder,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import type { ITreeViewOnLoadDataProps } from 'react-accessible-treeview';
import TreeView from 'react-accessible-treeview';
import { documentDirToTreeDir, getDirChildren } from './service';
import type { FileTreeDocumentDir } from './types';
import { useFileLibraryContext } from './context';
import { queryClient } from '@/react-app/shared/utils';
import { FILE_LIBRARY_TREE_QUERY_KEY } from './constants';

const ArrowIcon = ({ isOpen }) => {
  return (
    <FontAwesomeIcon
      className={cn({
        'tw-rotate-180': isOpen,
      })}
      icon={faChevronDown}
    />
  );
};

function FileLibraryTree() {
  const { filesBaseURL, selectedPath, setSelectedPath } =
    useFileLibraryContext();
  const [data, setData] = useState<FileTreeDocumentDir[]>([
    documentDirToTreeDir(
      {
        id: '/',
        type: 'dir',
        url: '/',
      },
      null,
    ),
  ]);

  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setExpanded((prevExpanded) => {
      const id = selectedPath;
      if (!prevExpanded.includes(id) && data.some((el) => el.id === id)) {
        return [...prevExpanded, id];
      }

      return prevExpanded;
    });
  }, [data, selectedPath]);

  const updateTreeData = (
    list: FileTreeDocumentDir[],
    id: string,
    children: FileTreeDocumentDir[],
    fileCount: number,
  ) => {
    const data = list.map((node) => {
      if (node.id === id) {
        node.children = children.map((el) => {
          return el.id;
        });
        node.metadata.isChildrenLoaded = true;
        node.metadata.fileCount = fileCount;
      }
      return node;
    });
    return data.concat(children);
  };

  const loadData = useCallback(
    async (parent: FileTreeDocumentDir) => {
      const children = await queryClient.fetchQuery({
        queryFn: () => getDirChildren(parent.metadata.path, filesBaseURL),
        queryKey: [FILE_LIBRARY_TREE_QUERY_KEY, parent.metadata.path],
      });

      const subDirs = children.filter((child) => child.type === 'dir');
      const fileCount = children.filter((child) => child.type !== 'dir').length;

      setData((value) =>
        updateTreeData(
          value,
          parent.id,
          subDirs.map((child) => documentDirToTreeDir(child, parent.id)),
          fileCount,
        ),
      );
    },
    [filesBaseURL],
  );

  useEffect(() => {
    loadData(data[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadData = async ({ element }: ITreeViewOnLoadDataProps) => {
    if (element.children.length > 0 || element.metadata.isChildrenLoaded) {
      return;
    }

    await loadData(element as unknown as FileTreeDocumentDir);
  };

  if (data.length === 1 && !data[0].metadata.isChildrenLoaded) {
    return (
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
        <FontAwesomeIcon className="tw-text-3xl" icon={faSpinner} />
      </div>
    );
  }

  return (
    <TreeView
      aria-label="File tree"
      className="tw-h-full tw-overflow-auto tw-bg-foreground-default-0 tw-p-1 dark:tw-bg-foreground-default-3-dark"
      data={data}
      expandedIds={expanded}
      nodeRenderer={({
        element,
        getNodeProps,
        handleExpand,
        isBranch,
        isExpanded,
        level,
      }) => {
        const branchNode = (isExpanded, element) => {
          return isExpanded &&
            !element.children.length &&
            !element.metadata.isChildrenLoaded ? (
            <div className="tw-flex tw-size-4 tw-items-center tw-justify-center">
              <span aria-live="assertive" className="tw-sr-only" role="alert">
                loading {element.name}
              </span>
              <FontAwesomeIcon icon={faSpinner} />
            </div>
          ) : (
            <div
              className="tw-flex tw-size-4 tw-items-center tw-justify-center"
              onClick={(e) => {
                e.stopPropagation();
                handleExpand(e);
              }}
            >
              <ArrowIcon isOpen={isExpanded} />
            </div>
          );
        };

        const nodeProps = getNodeProps();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onClick, ...restNodeProps } = nodeProps;
        const isCurrentPath = selectedPath === element.metadata?.path;

        return (
          <div {...restNodeProps} style={{ marginLeft: 20 * (level - 1) }}>
            <div
              className={cn(
                'tw-my-1 tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-rounded-md hover:tw-bg-foreground-default dark:hover:tw-bg-foreground-default-4-dark',
                {
                  'tw-ring-2 tw-ring-border-default-3 tw-ring-offset-1 dark:tw-ring-border-default-dark':
                    isCurrentPath,
                },
              )}
              onClick={() =>
                setSelectedPath(element.metadata?.path + '' || '/')
              }
            >
              {isBranch && (
                <div className="tw-flex tw-size-6 tw-cursor-pointer tw-items-center tw-justify-center">
                  {branchNode(isExpanded, element)}
                </div>
              )}
              <FontAwesomeIcon icon={faFolder} />
              <span className="tw-flex tw-truncate tw-text-sm">
                {element.name}
              </span>
              {!!element.metadata.fileCount && (
                <span className="tw-text-sm tw-italic">
                  {`(${element.metadata.fileCount})`}
                </span>
              )}
            </div>
          </div>
        );
      }}
      onExpand={({ element, isExpanded }) => {
        if (!isExpanded) {
          setExpanded((prevExpanded) =>
            prevExpanded.filter((id) => id !== element.id),
          );
        } else {
          setExpanded((prevExpanded) => [...prevExpanded, element.id + '']);
        }
      }}
      onLoadData={onLoadData}
    />
  );
}

export { FileLibraryTree };
