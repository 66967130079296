import dragula from 'dragula';
import $ from 'jquery';

import { translate } from '@/common/features/translations';
import { throttle } from 'lodash';

enum Mode {
  Block = 'Block',
  Row = 'Row',
}

type Params = {
  autoScrollSpeed?: number;
  container: HTMLElement;
  mode?: Mode;
};

const SCROLL_BY = 1;
const SCROLL_INTERVAL = 2;

const createAutoScroll = (
  container: HTMLElement,
  direction: 'up' | 'down',
  speed: number,
) => {
  const speedMultiplier = speed / 10;
  const directionMultiplier = direction === 'up' ? -1 : 1;
  const multiplier = directionMultiplier * speedMultiplier;

  let scrollingInterval: Nullable<number> = null;
  const startScrolling = () => {
    if (scrollingInterval != null) return;

    scrollingInterval = setInterval(() => {
      container.scrollBy(0, multiplier * SCROLL_BY);
    }, SCROLL_INTERVAL) as unknown as number;
  };
  const stopScrolling = () => {
    if (scrollingInterval == null) return;

    clearInterval(scrollingInterval);
    scrollingInterval = null;
  };

  return [startScrolling, stopScrolling];
};

function calendarProductionDragula({
  autoScrollSpeed = 10,
  container,
  mode = Mode.Block,
}: Params) {
  $(() => {
    const onDragEnd = (el, container) => {
      if (confirm(translate('common.are_you_sure_to_apply'))) {
        const fdata = $(el).data('data');
        const tdata = $(container).data('data');

        $.ajax({
          data: {
            a: '" . $a . "',
            fdata,
            id: 28,
            tdata,
          },
          dataType: 'json',
          type: 'POST',
          url: '/ajax_api.php',
        })
          .done(function (response) {
            if (response.res == 'ok') {
              window.didah.submit();
            } else {
              window.didah.submit();
            }
          })
          .fail(function () {
            window.didah.submit();
          });
      } else {
        drake.cancel(true);
      }
    }; //todo on drag end refresh page

    const drake =
      mode === Mode.Block
        ? dragula($('.dragula-container-cell').toArray(), {
            moves: (el) => {
              const jqEl = $(el);
              return (
                jqEl.hasClass('draggable-cell-content cp') &&
                !jqEl.hasClass('draggable-cell-content cp-blocked')
              );
            },
          })
            .on('over', (el, container) => {
              $(container).children('.single-new-button').hide();
            })
            .on('out', (el, container) => {
              $(container).children('.single-new-button').show();
            })
            .on('drop', function (el, container) {
              $(container).children('.single-new-button').remove();
              onDragEnd(el, container);
            })
            .on('drag', function () {
              //todo to draw 'New' button again?
            })
        : dragula($('.draggable-cell-content').toArray(), {
            moves: (el) => {
              const jqEl = $(el);
              return (
                jqEl.hasClass('draggable-cell-content cp') &&
                !jqEl.hasClass('draggable-cell-content cp-blocked')
              );
            },
          });

    const AREA_HEIGHT = 100;
    const TOP_HEADER_HEIGHT =
      container.querySelector('thead')?.clientHeight || 0;
    const containerRect = container.getBoundingClientRect();

    const isCloseToTopEdge = (y: number) => {
      const topEdge = containerRect.top + TOP_HEADER_HEIGHT;
      return y < topEdge + AREA_HEIGHT && y > topEdge;
    };

    const isCloseToBottomEdge = (y: number) => {
      const bottomEdge = containerRect.top + containerRect.height;
      return y > bottomEdge - AREA_HEIGHT && y < bottomEdge;
    };

    const [startScrollingUp, stopScrollingUp] = createAutoScroll(
      container,
      'up',
      autoScrollSpeed,
    );
    const [startScrollingDown, stopScrollingDown] = createAutoScroll(
      container,
      'down',
      autoScrollSpeed,
    );

    // global listener to mouse move
    $(document).on(
      'mousemove',
      throttle((e) => {
        if (!drake.dragging) {
          stopScrollingUp();
          stopScrollingDown();
          return;
        }

        const y = e.pageY;
        if (isCloseToTopEdge(y)) {
          startScrollingUp();
          stopScrollingDown();
        } else if (isCloseToBottomEdge(y)) {
          startScrollingDown();
          stopScrollingUp();
        } else {
          stopScrollingUp();
          stopScrollingDown();
        }
      }, 100),
    );
  });
}

export { calendarProductionDragula };
