import { extractHtmlText } from '@/utils';
import type { ReactNode } from 'react';
import { renderToString } from 'react-dom/server';

function reactNodeToText(reactNode: ReactNode) {
  if (
    typeof reactNode === 'string' ||
    typeof reactNode === 'number' ||
    typeof reactNode === 'boolean'
  ) {
    return reactNode + '';
  }

  return extractHtmlText(renderToString(reactNode));
}

export { reactNodeToText };
