import floor from 'lodash/floor';
import type { unitOfTime } from 'moment';

import '@/common/plugins/jquery.inputFilter';

type Opts = {
  end: JQuery;
  input: JQuery<HTMLInputElement>;
  start: JQuery;
  units?: unitOfTime.Diff;
};

export default function (opts: Opts) {
  const $dpStart = opts.start;
  const $dpEnd = opts.end;
  const $input = opts.input;
  const units = opts.units || 'w';
  if (!$dpStart || !$dpEnd || !$input) {
    throw new Error('incorrect arguments for setDpPair');
  }

  const $dp1_api = $dpStart.data('DateTimePicker');
  const $dp2_api = $dpEnd.data('DateTimePicker');

  function setInput() {
    const date1 = $dp1_api.date();
    const date2 = $dp2_api.date();
    if (!date1 || !date2) return;
    $input.val(floor(Math.abs(date2.diff(date1, units, true))));
  }

  setInput();

  $dpStart.on('dp.change', () => {
    if ($input.val()) {
      $dp2_api.date($dp1_api.date().add($input.val(), units));
    } else if ($dp2_api.date()) {
      setInput();
    }
  });

  $dpEnd.on('dp.change', () => {
    if ($dp1_api.date()) {
      setInput();
    }
  });

  $input.inputFilter(function (value) {
    return /^\d*$/.test(value);
  });
  $input.on('input', () => {
    const newVal = $input.val();
    if (!newVal) return;

    if ($dp1_api.date()) {
      $dp2_api.date($dp1_api.date().add(newVal, units));
    } else if ($dp2_api.date()) {
      $dp1_api.date($dp2_api.date().add(-newVal, units));
    }
  });
}
