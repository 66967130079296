import {
  makeModalWindow,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils';

import { translate } from '@/common/features/translations';

type Params = {
  fsessFind1: string; // '" . fnEncrypt("findinfo::" , $encryption_key_256bit) . "'
  fsessFind2: string; // '" . fnEncrypt("findinforegnr::" , $encryption_key_256bit) . "'
  fsessSave: string; // '" . fnEncrypt("getDataAndSave::".$l0 , $encryption_key_256bit) . "'
};

function findInInforegistr(
  elem: HTMLElement,
  { fsessFind1, fsessFind2, fsessSave }: Params
) {
  const newUrl = {};
  $.each($(elem).closest('form').serializeArray(), function (_, kv) {
    newUrl[kv.name] = kv.value;
  });
  const p_firmName = newUrl['l15'] || '';
  const p_regNr = newUrl['l17'] || '';
  const mForm = $(
    `<div class="form-group"><div class="input-group"><input type="text" class="form-control firmname-text high-selectize" placeholder="${translate(
      'common.label'
    )}"><span class="input-group-btn"><button class="form-control find-btn1 btn btn-primary" type="button"><i class="fa fa-search" aria-hidden="true"></i> ${translate(
      'common.find'
    )}</button></span></div></div>`
  )
    .append(
      $(
        `<div class="form-group"><div class="input-group"><input type="text" class="form-control regnr-text high-selectize" placeholder="${translate(
          'inforegister.register_number'
        )}"><span class="input-group-btn"><button class="form-control find-btn2 btn btn-primary" type="button"><i class="fa fa-search" aria-hidden="true"></i> ${translate(
          'common.find'
        )}</button></span></div></div>`
      )
    )
    .append(
      $(
        '<div class="form-group"><select class="form-control firm-list ignore-sizes high-selectize" size="10"></select></div>'
      )
    );

  const mWin = makeModalWindow(
    translate('inforegister.find_in_inforegister'),
    mForm,
    translate('common.close'),
    translate('common.save')
  );
  mWin.find('input.regnr-text').val(p_regNr);
  mWin.find('input.firmname-text').val(p_firmName);
  mWin.find('select.firm-list').change(function () {
    const orgId = $(this).val();
    if (orgId != '') {
      mWin.find('button.m-save').prop('disabled', false);
    } else {
      mWin.find('button.m-save').prop('disabled', true);
    }
  });
  mWin.find('button.find-btn2').click(function () {
    const regnr = mWin.find('input.regnr-text').val();
    if (regnr !== '') {
      const fselect = mWin.find('select.firm-list');
      fselect.find('option').remove();
      mWin.find('button.m-save').prop('disabled', true);
      setLoadingOverlay();
      $.ajax({
        data: {
          fname: regnr,
          fsess: fsessFind2,
          id: 26,
        },
        dataType: 'json',
        error(e) {
          removeLoadingOverlay();
          alert(
            `${translate('inforegister.find_in_inforegister')}: ` +
              e.responseText
          );
        },
        success(response) {
          removeLoadingOverlay();
          if (response.res == 'ok') {
            $.each(response.data, function (i, val) {
              fselect.append(
                $('<option />', {
                  text: val['org-id'].substr(3) + ' ' + val['legalName'],
                  value: val['org-id'],
                })
              );
            });
          } else alert(response.error);
        },
        type: 'POST',
        url: '/ajax_api.php',
      });
    }
  });
  mWin.find('button.find-btn1').click(function () {
    const fname = mWin.find('input.firmname-text').val();
    if (fname !== '') {
      const fselect = mWin.find('select.firm-list');
      fselect.find('option').remove();
      mWin.find('button.m-save').prop('disabled', true);
      setLoadingOverlay();
      $.ajax({
        data: {
          fname,
          fsess: fsessFind1,
          id: 26,
        },
        dataType: 'json',
        error(e) {
          removeLoadingOverlay();
          alert(
            `${translate('inforegister.find_in_inforegister')}: ` +
              e.responseText
          );
        },
        success(response) {
          removeLoadingOverlay();
          if (response.res == 'ok') {
            $.each(response.data, function (i, val) {
              fselect.append(
                $('<option />', {
                  text: val['org-id'].substr(3) + ' ' + val['legalName'],
                  value: val['org-id'],
                })
              );
            });
          } else alert(response.error);
        },
        type: 'POST',
        url: '/ajax_api.php',
      });
    }
  });
  mWin
    .find('button.m-save')
    .prop('disabled', true)
    .click(function () {
      const orgId = mWin.find('select.firm-list').val();
      setLoadingOverlay();
      if (orgId != '') {
        $.ajax({
          data: {
            fsess: fsessSave,
            id: 26,
            regnr: orgId,
          },
          dataType: 'json',
          error(e) {
            removeLoadingOverlay();
            alert(
              `${translate('inforegister.find_in_inforegister')}: ` +
                e.responseText
            );
          },
          success(response) {
            removeLoadingOverlay();
            if (response.res == 'ok') {
              newUrl['ac'] = response.id;
              newUrl['ah'] = 9;
              const newLoc = '?' + $.param(newUrl);
              mWin.modal('hide');
              window.location.href = newLoc;
            } else alert(response.error);
          },
          type: 'POST',
          url: '/ajax_api.php',
        });
      }
    });
  mWin.modal('show');
}

export { findInInforegistr };
