import $ from 'jquery';

function jsBtn() {
  $(document).on('click', '.js-btn', function () {
    const btn = $(this);
    btn
      .button('loading')
      .delay(2000)
      .queue(function () {
        btn.button('reset');
        btn.dequeue();
      });
  });
}

export { jsBtn };
