enum Mode {
  Drawing = 'drawing',
  Normal = 'normal',
}

enum DrawingTool {
  Brush = 'brush',
  Pin = 'pin',
}

type CanvasData = {
  canvas: HTMLCanvasElement;
  containerScroll: {
    scrollX: number;
    scrollY: number;
  };
  sizes: { height: number; width: number };
};

type CanvasForwardedMethods = {
  exportBlob: () => Promise<Nullable<Blob>>;
};

type Sizes = { height: number; width: number };

type Scroll = {
  scrollX: number;
  scrollY: number;
};

export type { CanvasData, CanvasForwardedMethods, Scroll, Sizes };

export { DrawingTool, Mode };
