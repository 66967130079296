import $ from 'jquery';

import {
  checkMobile,
  debounce,
  getStyleSheet,
  isTextHTML,
  makeModalWindow,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils';

import { createAddWordModal } from './modal';
import { renderColumn, renderHeaderColumn, renderLangColumn } from './render';
import { translate } from '@/common/features/translations';

function makeLanguageEditor({
  addAjaxParams,
  addUrl,
  defaultLang,
  editAjaxParams,
  editUrl,
  isConfigureSession,
  items,
  langList,
  options,
  optionsAjaxParams,
  optionsUrl,
  selector,
  toShowId = false,
}) {
  const $input = $(selector);
  $input.addClass('form-control');
  $input
    .wrap(
      '<div class="didah-has-buttons-field-container-oldmode didah-oldmode-lang-container didah-lang-field-container"></div>'
    )
    .wrap('<div class="didah-has-buttons-field-container-oldmode"></div>');
  $input.after(
    '<div class="didah-inform-select-buttons"><button class="btn btn-primary didah-inform-select-button didah-lang-action-button ignore-sizes" type="button"><span class="glyphicon glyphicon-plus"></span></button></div>'
  );

  const ButtonLoading = {
    $btn: $input.parent().find('.didah-lang-action-button'),
    htmlSave: '',
    removeButtonLoading() {
      if (this.$btn.hasClass('didah-lang-action-button-loading')) {
        this.$btn.removeClass('didah-lang-action-button-loading');
        this.$btn.html(this.htmlSave);
      }
    },
    setButtonLoading() {
      if (!this.$btn.hasClass('didah-lang-action-button-loading')) {
        this.$btn.addClass('didah-lang-action-button-loading');
        this.htmlSave = this.$btn.html();
        this.$btn.html(
          '<div class="sc"><i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i></div>'
        );
      }
    },
  };

  if (options) modifyOptions(options);
  $input.selectize(getInitializationSelectizeOptions());
  setFixedHeader();
  const selectizeAPI = ($input[0] as any).selectize;
  addRemoveX(selectizeAPI);

  function addRemoveX(api) {
    const $x = $('<span class="didah-lang-x">&times;</span>');
    $input.parent().find('.selectize-control').append($x);
    $x.on('click', () => {
      api.clear();
    });
  }

  function setFixedHeader() {
    if (!checkMobile()) {
      const $selectizeDropdownContent = $input
        .closest('.didah-oldmode-lang-container')
        .find('.selectize-dropdown-content');
      const selectizeDropdownContent = $selectizeDropdownContent.get(0);
      const adjustHeader = debounce(function () {
        const $header = $selectizeDropdownContent.find(
          '.optgroup-header.didah-lang-single-row'
        );
        const header = $header.get(0);

        function getHeaderOffSetRelativeToParent() {
          return (
            header.getBoundingClientRect().top -
            selectizeDropdownContent.getBoundingClientRect().top
          );
        }

        function getClone() {
          function makeClone() {
            const $clone = $(header.outerHTML);
            $clone.addClass('optgroup-header-clone');
            $clone.outerWidth($header.outerWidth());
            $selectizeDropdownContent.append($clone);
            return $clone;
          }

          const cloneOpt = $selectizeDropdownContent.find(
            '.optgroup-header-clone'
          );
          if (cloneOpt.length) {
            return cloneOpt;
          } else {
            return makeClone();
          }
        }

        if ($header.length) {
          const offset = getHeaderOffSetRelativeToParent();
          const $clone = getClone();
          if (offset < 0) {
            $clone.show();
            $clone.css('top', $selectizeDropdownContent.get(0).scrollTop);
          } else {
            $clone.hide();
            $clone.css('top', '');
          }
        }
      }, 10);

      $selectizeDropdownContent.on('scroll', adjustHeader);
      $('.selectize-input').find('input').on('keyup', adjustHeader);
    }
  }

  function getInitializationSelectizeOptions() {
    const result: Dictionary = {
      closeAfterSelect: true,
      create(input, callback) {
        const $modal = createAddWordModal(langList);
        $modal.find(`#${defaultLang}`).val(input);
        $modal.find('button.m-save').on('click', () => {
          const result = {};
          $modal.find('.lang-field').each(function () {
            const $field = $(this);
            result['data_' + $field.attr('id')] = $field.val();
          });
          addWindowOnSaveCallBackSpecial(result, $modal, callback);
        });
        $modal.find('button.m-close').on('click', () => {
          callback();
        });
        $modal.modal('show');
      },
      delimiter: '@del@',
      items,
      labelField: 'label',
      load: optionsUrl ? getOptionsAjax : undefined,
      maxItems: 1,
      onChange: () => {
        setButton();
      },
      onLoad: adjustDropDownWidth,
      options,
      plugins: ['restore_on_backspace'],
      render: {
        item(item) {
          return `<div class="item [&_img]:tw-inline-block" data-toggle="tooltip" title="${item.values_sum}">${item.values?.[defaultLang]}</div>`;
        },
        option(item: { id: string; values: Dictionary }) {
          const cols = [
            renderColumn('id', item.id),
            ...langList.map((lang) => renderLangColumn(item.values, lang)),
          ].join('');
          return `<div class="didah-lang-single-row">${cols}</div>`;
        },
        option_create(data, escape) {
          return `<div class="create">${translate(
            'language_editor_translations.to_add'
          )} <strong>${escape(data.input)}</strong>&hellip;</div>`;
        },
      },
      searchField: 'values_sum',
      silent: false,
      valueField: 'id',
      width: 300,
    };

    if (!checkMobile()) {
      result.optgroups = [{ value: 'group' }];
      result.optgroupField = 'group';
      result.render.optgroup_header = function () {
        const cols = ['id', ...langList]
          .map((lang) => renderHeaderColumn(lang))
          .join('');
        return `<div class="optgroup-header didah-lang-single-row">${cols}</div>`;
      };
    }

    return result;
  }

  function modifyOptions(options) {
    function addValuesSum(option) {
      const langs = [];
      for (const langCode in option.values) {
        if (Object.prototype.hasOwnProperty.call(option.values, langCode)) {
          const langValue = option.values[langCode];
          if (langValue && !isTextHTML(langValue)) {
            langs.push(langValue);
          }
        }
      }
      option.values_sum = langs.join(' - ');
    }

    function addLabelField(option) {
      option.label = option.values[defaultLang];
    }

    options &&
      options.forEach((option) => {
        if (!checkMobile()) option.group = 'group'; //for header
        addValuesSum(option);
        addLabelField(option);
      });
  }

  function getOptionsAjax(query, callback) {
    const params: Dictionary = {};
    Object.assign(params, optionsAjaxParams);
    //            params.query = encodeURIComponent(query);
    params.fquery = query;
    ButtonLoading.setButtonLoading();
    $.ajax({
      data: params,
      error() {
        ButtonLoading.removeButtonLoading();
        callback();
      },
      success(res) {
        ButtonLoading.removeButtonLoading();
        modifyOptions(res);
        callback(res);
      },
      type: 'POST',
      url: optionsUrl,
    });
  }

  function adjustDropDownWidth(data) {
    const selector =
      '@media only screen and (min-width: 1250px) {.didah-oldmode-lang-container.didah-lang-field-container .selectize-dropdown';
    const sheet = getStyleSheet('dynamic.css') as any;

    if (!sheet) {
      return;
    }

    function deleteOldRule() {
      if (sheet.cssRules) {
        const theRule = sheet.cssRules[0];
        if (
          theRule &&
          (theRule as any).conditionText ===
            'only screen and (min-width: 1250px)'
        ) {
          sheet.deleteRule(0);
        }
      }
    }

    if (data && !checkMobile()) {
      const colWidth = 140;
      const shortColWidth = 60;
      const extraSpace = 30;
      const options = data[0]?.values || {};
      const keys = Object.keys(options).filter((key) => langList.includes(key));

      if (keys.length) {
        deleteOldRule();
        const hasImgCol = keys.includes('img');
        let shortColsCount = 1; // +1 for id column
        let colsCount = keys.length;
        if (hasImgCol) {
          colsCount -= 1;
          shortColsCount += 1;
        }

        const finalSum =
          colWidth * colsCount + shortColWidth * shortColsCount + extraSpace;
        sheet.insertRule(`${selector}{ width: ${finalSum}px !important;}}`, 0);
      }
    }
  }

  function setButton() {
    const $btn = $input.parent().find('.didah-lang-action-button');
    $btn.removeClass('didah-lang-action-button-loading');
    const setBtnAsAdd = () => {
      $btn.html('<span class="glyphicon glyphicon-plus"></span>');
      setButtonOnClickAsAdd($btn);
    };
    const setBtnAsEdit = () => {
      $btn.html('<span class="glyphicon glyphicon-cog"></span>');
      setButtonOnClickAsEdit($btn, selectizeAPI.options[selectizeAPI.items[0]]);
    };

    $btn.prop('onclick', null).off('click');
    if (selectizeAPI.items.length) setBtnAsEdit();
    else setBtnAsAdd();
  }

  function loadOptions() {
    if (Object.keys(selectizeAPI.options).length) return;
    optionsUrl &&
      selectizeAPI.load((callback) => {
        getOptionsAjax('', callback);
      });
  }

  function addWindowOnSaveCallBackDefault(data, $modal) {
    setLoadingOverlay();
    Object.assign(data, addAjaxParams);
    $.ajax({
      cache: false,
      data,
      error: (xhr) => {
        removeLoadingOverlay();
        alert(xhr.responseText);
        //                        $modal.modal('hide');
      },
      success: (newOptionAsResult) => {
        removeLoadingOverlay();
        selectizeAPI.addOption(newOptionAsResult);
        selectizeAPI.addItem(newOptionAsResult.id);
        $modal.modal('hide');
      },
      type: 'POST',
      url: addUrl,
    });
  }

  function addWindowOnSaveCallBackSpecial(data, $modal, selectizecallback) {
    setLoadingOverlay();
    Object.assign(data, addAjaxParams);
    $.ajax({
      cache: false,
      data,
      error: (xhr) => {
        removeLoadingOverlay();
        selectizecallback();
        alert(xhr.responseText);
        //                        $modal.modal('hide');
      },
      success: (newOptionAsResult) => {
        removeLoadingOverlay();
        $modal.modal('hide');
        selectizecallback(newOptionAsResult);
      },
      type: 'POST',
      url: addUrl,
    });
  }

  function setButtonOnClickAsAdd($btn) {
    $btn.on('click', () => {
      loadOptions();
      const $modal = createAddWordModal(langList);
      $modal.find('button.m-save').on('click', () => {
        const result = {};
        $modal.find('.lang-field').each(function () {
          const $field = $(this);
          result['data_' + $field.attr('id')] = $field.val();
        });
        addWindowOnSaveCallBackDefault(result, $modal);
      });
      $modal.modal('show');
    });
  }

  function setButtonOnClickAsEdit($btn, currentValue) {
    function onSaveCallBack(data, $modal) {
      setLoadingOverlay();
      $.ajax({
        cache: false,
        data: {
          ...data,
          ...editAjaxParams,
          data_lan: currentValue.id,
        },
        error: (xhr) => {
          removeLoadingOverlay();
          alert(xhr.responseText);
          //                        $modal.modal('hide');
        },
        success: (newOptionAsResult) => {
          removeLoadingOverlay();
          selectizeAPI.clearOptions();
          selectizeAPI.addOption(newOptionAsResult);
          selectizeAPI.addItem(newOptionAsResult.id);
          $modal.modal('hide');
        },
        type: 'POST',
        url: editUrl,
      });
    }

    function getBody() {
      function getFields() {
        function getField(field, value, isDisabled = false) {
          const disabled = isDisabled ? 'disabled' : '';
          return `<div class="form-group">
                                  <label for="${field}">${field}:</label>
                                  <textarea class="form-control lang-field" rows="3" id="${field}" ${disabled}>${value}</textarea>
                                </div>`;
        }

        function getIdField(field, value) {
          return `<div class="form-group">
                                  <label for="${field}">${field}:</label>
                                  <input class="form-control lang-field" id="${field}" value="${value}" disabled>
                                </div>`;
        }

        const fields = langList.map((lang) => {
          return getField(lang, currentValue.values[lang]);
        });
        if (toShowId) {
          fields.unshift(getIdField('id', currentValue.id));
        }
        return fields.join('');
      }

      return `<div class="row"><div class="col-xs-12">
                  <form>
                    ${getFields()}
                  </form>
                </div></div>`;
    }

    function createEditWordModal() {
      let title = `<div>${translate(
        'language_editor_translations.edit_word_title'
      )} ${currentValue.id} - ${currentValue.values[defaultLang]}</div>`;
      if (isConfigureSession) {
        title += `
                    <br>
                    <div class="alert alert-danger" style="margin-bottom: 0">
                      <strong>${translate(
                        'language_editor_translations.warning'
                      )}</strong> ${translate(
          'language_editor_translations.configure_session'
        )}
                    </div>
                `;
      }
      const body = getBody();
      const close_str = translate('language_editor_translations.close_btn');
      const save_str = translate('language_editor_translations.save_btn');
      return makeModalWindow(title, body, close_str, save_str);
    }

    $btn.on('click', () => {
      loadOptions();
      const $modal = createEditWordModal();
      $modal.find('button.m-save').on('click', () => {
        const result = {};
        $modal.find('.lang-field').each(function () {
          const $field = $(this);
          result['data_' + $field.attr('id')] = $field.val();
        });
        onSaveCallBack(result, $modal);
      });
      $modal.modal('show');
    });
  }

  setButton();
  if (options) adjustDropDownWidth(options);

  return selectizeAPI;
}

export default makeLanguageEditor;
