import $ from 'jquery';

export type InputFilter = (input: string) => boolean;

(function ($) {
  $.fn.inputFilter = function (inputFilter: InputFilter) {
    return this.on(
      'input keydown keyup mousedown mouseup select contextmenu drop',
      function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
      }
    );
  };
})($);
