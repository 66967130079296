const initialTitle = document.title;

function startNotifyingTitle(messages: string[], timeout: number) {
  const titles = [initialTitle, ...messages];

  if (!messages.length) {
    return;
  }

  let i = 1;
  return setInterval(() => {
    document.title = titles[i % titles.length];
    i++;
  }, timeout);
}

let intervalId = null;
const blinkingTitleManager = {
  intervalId: null,
  start(messages: string[], timeout: number = 1000) {
    intervalId = startNotifyingTitle(messages, timeout);
  },
  stop() {
    if (intervalId != null) {
      clearInterval(intervalId);
      intervalId = null;
    }

    document.title = initialTitle;
  },
};

export { blinkingTitleManager };
