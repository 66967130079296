import $ from 'jquery';

import { openDidahBox } from '@/common';
import { onHeaderClick } from '@/common/features/datatables';

function selectRow(dtTable, fld) {
  const dtTableRows = dtTable.rows({ selected: true });
  const dtTableData = dtTableRows.data().toArray();

  return dtTableData.map((dt) => dt[fld]).join(', ');
}

function action1(dtTable, a) {
  const res = selectRow(dtTable, 'lan_1644');
  openDidahBox(
    `/management.php?a=${a}&sn=2&ac=${res}&snp=5&s=UpdateGoods`,
    'iframe'
  );
}

function action2(dtTable, a) {
  const res = selectRow(dtTable, 'lan_1644');
  openDidahBox(
    `/management.php?a=${a}&sn=3&ac=${res}&snp=5&s=UpdateCount`,
    'iframe'
  );
}

function action3(dtTable, a) {
  const res = selectRow(dtTable, 'lan_1644');
  openDidahBox(
    `/management.php?a=${a}&sn=4&ac=${res}&snp=5&s=UpdateTypePackagingArrangement `,
    'iframe'
  );
}

type DidahReloadCalenderInput = {
  dTable: any;
  id: string;
  url: string;
};

function didahReloadCalender({ dTable, id, url }: DidahReloadCalenderInput) {
  const $newTableProcessing = $(`#new_table${id}_processing`);
  $newTableProcessing.css('display', 'block');
  const data = $(window.didah).serialize();

  $.ajax({
    data,
    dataType: 'json',
    type: 'POST',
    url,
  })
    .done(function (data) {
      dTable.clear().rows.add(data).draw();
      $newTableProcessing.css('display', 'none');
    })
    .fail(function () {
      window.didah.submit();
    });
}

export { action1, action2, action3, didahReloadCalender, onHeaderClick };
