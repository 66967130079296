import $ from 'jquery';

type Options = {
  allowEmpty?: boolean;
  multiple?: boolean;
};

export default function ($select, opts: Options = {}) {
  const allowEmpty = !!opts.allowEmpty;
  const multiple = $select.attr('multiple') || opts.multiple || false;
  $select.hide();

  const $listGroup = $('<ul class="list-group"></ul>');

  if (multiple) {
    $select.attr('multiple', 'multiple');
  }

  $select.children('option').each(function () {
    const $option = $(this);
    const $listGroupItem = $(
      `<li class="list-group-item">${$option.text()}</li>`
    );
    if ($option.attr('selected')) {
      $listGroupItem.addClass('active');
    }
    $listGroupItem.appendTo($listGroup);

    $listGroupItem.click(function () {
      const isSelected = $option.attr('selected');
      if (multiple) {
        $listGroupItem.toggleClass('active');

        if (isSelected) {
          $option.prop('selected', false);
        } else {
          $option.attr('selected', 'selected');
        }
      } else {
        $select.children('option').each(function () {
          const $option = $(this);
          $option.prop('selected', false);
        });

        $listGroup.children('.list-group-item').each(function () {
          const $item = $(this);
          $item.removeClass('active');
        });

        if (allowEmpty && isSelected) {
          $option.prop('selected', false);
          $listGroupItem.removeClass('active');
        } else {
          $listGroupItem.addClass('active');
          $option.attr('selected', 'selected');
        }
      }
    });
  });

  $listGroup.insertAfter($select);
}
