/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dt_number_format,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils';
import type { Options as TableGroupsDragulaOptions } from '@/common/features/table-groups-dragula';
import debounce from 'lodash/debounce';
import { calcSubtotals, drowGroup } from '@/common/plugins';
import { checkboxListModal, tableGroupsDragula } from '@/common';
import type {
  AddSaveSettingsButtonParams,
  ApplyInitialSearchStateParams,
  TableSettings,
} from '@/common/features/datatables';
import {
  addSaveSettingsButton,
  applyInitialColumnSearchState,
  fillSearchOptionsStorage,
  setupColumnSearch,
  type UpdateColumnSearchHandler,
} from '@/common/features/datatables';

type GroupFields = {
  act2FieldsList: Dictionary;
  actFieldsList: Dictionary;
  alltotal: boolean;
  bcFieldsList: Dictionary;
  formulaSubtotalFieldsList: Dictionary;
  groupColumn: any[];
  groupColumnName: any[];
  hash: {
    data: Dictionary;
    pos: number;
  };
  hideLevelGroup: boolean;
  linkFieldsList: Dictionary;
  rowsData: Dictionary;
  rowsDataCount: any[];
  subtotalFieldsList: Dictionary;
};

type TableOptions = {
  DIDAH_VERSION: string;
  beforeInit?: VoidFunction;
  buttons: {
    printall: string;
  };
  cblmParams: Dictionary;
  columnSearch?: {
    initialValue: ApplyInitialSearchStateParams['initialState'];
    onChange: UpdateColumnSearchHandler;
  };
  columnsAndOther: Dictionary & {
    columns: any[];
    fixedColumns: {
      heightMatch: 'semiauto';
      leftColumns: number;
    };
  };
  dHeight: string;
  didahEditDatatable: boolean;
  fixedColumnsCount: number;
  getCurrentSettings: () => TableSettings;
  groupFields: GroupFields;
  id: string;
  initData: Dictionary;
  langJSON: Dictionary;
  mainJSONStep: number;
  numRows: number;
  onFetch?: VoidFunction;
  p: number;
  persistOptions?: Pick<
    AddSaveSettingsButtonParams,
    | 'isSaveEnabled'
    | 'isSaveForUserEnabled'
    | 'saveForUserParams'
    | 'saveParams'
  >;
  postCreatedRow: PostCreatedRow;
  printFooter: string;
  printHeader: string;
  resizeCls: string;
  scrollY: string;
  search?: {
    initialValue: string;
    onChange: (value: string) => void;
  };
  tableGroupsDragulaOptions: Pick<
    TableGroupsDragulaOptions,
    | 'simpleMode'
    | 'initialState'
    | 'onGroupingStateUpdated'
    | 'colIdToFieldName'
  >;
  tables: any[];
  tblNr: number;
  testData: Dictionary;
  translations: {
    mainHeader: string;
    mainHeaderImg: string;
  };
  url: string;
  vform: string;
};

let isLocked = false;
function selectQuery8Table({
  DIDAH_VERSION,
  beforeInit,
  buttons,
  cblmParams,
  columnSearch,
  columnsAndOther,
  dHeight,
  didahEditDatatable,
  fixedColumnsCount,
  getCurrentSettings,
  groupFields,
  id,
  initData,
  langJSON,
  mainJSONStep,
  numRows,
  onFetch,
  p: dataLength,
  persistOptions,
  postCreatedRow,
  printFooter,
  printHeader,
  resizeCls,
  scrollY,
  search,
  tableGroupsDragulaOptions,
  tables,
  tblNr,
  testData,
  translations,
  url,
  vform,
}: TableOptions) {
  beforeInit?.();
  let topY = undefined;
  if (dHeight === 'by_window_height') {
    topY = '50vh';
  }
  if (scrollY !== 'off') {
    topY = '65vh';
  }

  const table = $(`#new_table${id}`)
    .DataTable({
      ajax: {
        data(d) {
          d.q = $('input:text[name=q]').val();
          d.jsonstep = mainJSONStep;
        },
        dataSrc: 'data',
        type: 'POST',
        url,
      },
      language: langJSON,
      scrollY: topY,
      searchDelay: 1000,
      ...columnsAndOther,
      autoWidth: true,
      ...(fixedColumnsCount && {
        fixedColumns: {
          heightMatch: 'semiauto',
          leftColumns: fixedColumnsCount,
        },
      }),
      buttons: [
        {
          autoPrint: false,
          customize(win) {
            if (tblNr === 0) {
              let html = '';
              const addRow = function (d, tag) {
                let str = '<tr>';

                for (let i = 0, ien = d.length; i < ien; i++) {
                  str += '<' + tag + '>' + d[i] + '</' + tag + '>';
                }

                return str + '</tr>';
              };
              if (tables.length > 0) {
                for (let tb = 1, tben = tables.length; tb < tben; tb++) {
                  const data = tables[tb].buttons.exportData({
                    orthogonal: 'print',
                    stripHtml: false,
                  });
                  html +=
                    '<br><br><table class="' +
                    tables[tb].table().node().className +
                    '">';

                  html += '<thead>' + addRow(data.header, 'th') + '</thead>';

                  html += '<tbody>';
                  for (let i = 0, ien = data.body.length; i < ien; i++) {
                    html += addRow(data.body[i], 'td');
                  }
                  html += '</tbody>';

                  if (data.footer) {
                    html += '<tfoot>' + addRow(data.footer, 'th') + '</tfoot>';
                  }
                }
              }
              $(win.document.body).append(html);
            }

            $(win.document.body)
              .css('font-size', '8pt')
              .prepend(printHeader)
              .append(printFooter);
            $(win.document.body)
              .find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          },
          exportOptions: {
            orthogonal: 'print',
            stripHtml: true,
          },
          extend: 'print',
          footer: true,
          message: buttons.printall,
          name: 'printall',
        },
        {
          exportOptions: {
            orthogonal: 'export',
          },
          extend: 'csvHtml5',
          name: 'csvall',
        },
        {
          exportOptions: {
            format: {
              footer(data) {
                if (
                  data == '' ||
                  isNaN(
                    +data
                      .replace(
                        new RegExp(
                          window.number_format_data.thousands_sep,
                          'g',
                        ),
                        '',
                      )
                      .replace(
                        new RegExp(window.number_format_data.dec_point, 'g'),
                        '.',
                      ),
                  )
                ) {
                  return data;
                } else {
                  return +data
                    .replace(
                      new RegExp(window.number_format_data.thousands_sep, 'g'),
                      '',
                    )
                    .replace(
                      new RegExp(window.number_format_data.dec_point, 'g'),
                      '.',
                    );
                }
              },
            },
            orthogonal: 'export',
          },
          extend: 'excelHtml5',
          footer: true,
          name: 'excelall',

          title: '',
        },
      ],
      createdRow(row, data, index) {
        postCreatedRow(row, data, index, $(this).DataTable());
      },
      deferRender: true,
      dom: '<<"top clearfix"<"btn_block"B>>tr<"bottom"ip>>',
      drawCallback() {
        const api = this.api();
        drowGroup(api, groupFields);
      },
      footerCallback(row, data, start, end, display) {
        const api = this.api();
        const oSettings = this.dataTable().fnSettings();
        const columns = api.columns().dataSrc();

        window.dta = [];

        const dt = api.rows({ search: 'applied' }).data();
        groupFields.subtotalFieldsList.forEach(function (d, i) {
          const sPos = columns.indexOf(d);
          const txt = dt.reduce(function (a, b) {
            return a + (b === '' ? 0 : +b[d]);
          }, 0);
          window.dta[d] = txt;
          $(api.column(sPos).footer()).html(
            dt_number_format(txt, oSettings.aoColumns[sPos].decimal),
          );
        });
        groupFields.formulaSubtotalFieldsList.forEach(function (d, i) {
          const sFPos = columns.indexOf(d.field);
          const sFData = eval(d.formula);
          $(api.column(sFPos).footer()).html(
            dt_number_format(
              sFData === Infinity ? 0 : sFData,
              oSettings.aoColumns[sFPos].decimal,
            ),
          );
        });
      },
      initComplete() {
        const api = this.api();
        $(`.new_table${id}_f_search`).on('click', function (e) {
          e.preventDefault();
          const wrapper = $(`#new_table${id}_wrapper`);
          const searchPanel = $('tr.search-panel', wrapper);

          searchPanel.toggle();
          $('input:text', searchPanel).val('');

          api.columns().search('').draw();
        });

        $(api.table().container())
          .on(
            'keyup change clear',
            '.search-panel input:text',
            debounce(function () {
              const col = api.columns($(this).data('col-id'));
              if (col.search() !== this.value) {
                col.search(this.value).draw();
              }
            }, 1000),
          )
          .on('keypress', function (event) {
            if (event.key == '13') {
              event.preventDefault();
            }
          });

        const datatable = $(this).DataTable();
        // apply initial search value
        if (search?.initialValue) {
          datatable.search(search?.initialValue).draw();
        }

        setupColumnSearch({
          datatable,
          onUpdateColumnSearch: columnSearch.onChange,
        });
        applyInitialColumnSearchState({
          datatable,
          initialState: columnSearch.initialValue,
        });
      },
      ...(numRows <= 30 && { paging: false }),
      processing: true,
      scrollCollapse: true,
      scrollX: true,
      stateLoadCallback(settings) {
        try {
          return JSON.parse(localStorage.getItem(`DaTa_${id}${DIDAH_VERSION}`));
        } catch (e) {}
      },
      stateSave: true,
      stateSaveCallback(settings, data) {
        try {
          data.search.search = '';
          data.start = 0;
          data.length = dataLength;
          $.each(data.columns, function (i, n) {
            n.visible = true;
            n.search.search = '';
          });
          localStorage.setItem(
            `DaTa_${id}${DIDAH_VERSION}`,
            JSON.stringify(data),
          );
        } catch (e) {}
      },
    })
    .on('init.dt', async function (e, settings, ajax) {
      const dt = new $.fn.dataTable.Api(settings);

      $(this).on('click', 'td.grp_header', function () {
        const me = $(this).parent();

        me.toggleClass('grp_pluss');
        if (me.hasClass('grp_pluss')) {
          $('tr.' + me.data('grp')).addClass('grp_hide');
        } else {
          $('tr.' + me.data('grp')).removeClass('grp_hide');
          $('tr.group.' + me.data('grp')).removeClass('grp_pluss');
        }
      });

      if (didahEditDatatable) {
        if (!window.isDatatableLocked) {
          $(dt.table().container()).addClass('is-locked2');
        }

        $(
          `div.panel${tblNr}.upper-buttons-panel div.upper-buttons-panel-right div.report-buttons0`,
        ).append(
          $(`<a id="fields0${id}" href="#false" class="btn ${
            window.isDatatableLocked ? 'btn-default' : 'btn-success'
          }">
              <span class="fa fa-pencil-square-o" aria-hidden="true"></span>
          </a>`).on('click', function () {
            window.toggleDatatableEdit();
            $(this).toggleClass('btn-success');
            $(dt.table().container()).toggleClass('is-locked2');
            dt.draw(false);
            dt.fixedColumns().update();
          }),
        );
      }

      $(
        `div.panel${tblNr}.upper-buttons-panel div.upper-buttons-panel-right div.report-buttons0`,
      ).append(
        $(`<button title="${translations.mainHeader}" onclick="$('.loaderBackground').css('display', 'flex'); ${vform}.mcid.value = ''; ${vform}.submit(); return false;" class="btn btn-default">
        ${translations.mainHeaderImg}
    </button>`),
        $(`<a id="fields${id}" href="#" class="btn btn-default">
        <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
        </a>`),
      );

      let cblm: any = null;
      cblm = checkboxListModal(testData, {
        $trigger: $(`#fields${id}`),
        onClose: (data) => {
          cblm.hideModal();
          cblm.setData(testData);
        },
        onReset: () => {
          cblm.setData(initData);
        },
        onSave: (data) => {
          new Promise<void>((resolve) => {
            setLoadingOverlay();
            $.ajax({
              cache: false,
              data: {
                id: 24,
                val: data
                  .filter(function (name) {
                    return name.checked;
                  })
                  .map(function (name) {
                    return name.name;
                  }),
                ...cblmParams,
              },
              dataType: 'json',
              error(e) {
                alert('Error processing your request: ' + e.responseText);
                resolve();
              },
              success(response) {
                if (response['res'] == 'ok') {
                  window.didah.submit();
                } else {
                  resolve();
                }
              },
              type: 'POST',
              url: '/ajax_api.php',
            });
          }).then(() => {
            cblm.setData(testData);
            removeLoadingOverlay();
          });
        },
      });

      tableGroupsDragula({
        colIdToFieldName: tableGroupsDragulaOptions.colIdToFieldName,
        dt,
        groupFields,
        grpCallback(grpObj) {
          testData.forEach(function (d) {
            if (d['b'] !== 0) {
              d['disabled'] = false;
            }
            if ($.inArray(d['name'], grpObj.groupColumnName) >= 0) {
              d['disabled'] = true;
            }
          });
          cblm.setData(testData);
        },
        initialState: tableGroupsDragulaOptions.initialState,
        onGroupingStateUpdated:
          tableGroupsDragulaOptions.onGroupingStateUpdated,
        renderBtn: (btn) => {
          $(
            `div.panel${tblNr}.upper-buttons-panel div.upper-buttons-panel-right div.report-buttons0`,
          ).append(btn);
        },
        renderContainer: (container) => {
          $(dt.table().container()).find('div.top').append(container);
        },
        simpleMode: tableGroupsDragulaOptions.simpleMode,
      });

      addSaveSettingsButton({
        getCurrentSettings,
        renderBtn: (btn) => {
          $(
            `div.panel${tblNr}.upper-buttons-panel div.upper-buttons-panel-right div.report-buttons0`,
          ).append(btn);
        },
        ...persistOptions,
      });

      if (
        typeof window.SendResizeMessage !== 'undefined' &&
        typeof window.SendResizeMessage === 'function'
      ) {
        window.SendResizeMessage(resizeCls);
        setTimeout(() => window.SendResizeMessage(resizeCls), 1500);
      }
    })
    .on('xhr.dt', function (e, settings, json, xhr) {
      onFetch?.();
      groupFields.rowsData = {};

      groupFields.linkFieldsList = {};
      groupFields.bcFieldsList = {};
      groupFields.actFieldsList = {};
      groupFields.act2FieldsList = {};
      settings.aoColumns.forEach(function (d, i) {
        if (d.link !== '') {
          groupFields.linkFieldsList[d.data] = d.link;
        }
        if (d.bc !== '') {
          groupFields.bcFieldsList[d.data] = d.bc;
        }
        if (d.act !== '') {
          groupFields.actFieldsList[d.data] = d.act;
        }
        if (d.act2 !== '') {
          groupFields.act2FieldsList[d.data] = d.act2;
        }
      });

      const dt = $(this).DataTable();
      fillSearchOptionsStorage(dt);
    })
    .on('preDraw.dt', function (e, settings) {
      const dt = new $.fn.dataTable.Api(settings);
      calcSubtotals(dt, groupFields);
    })
    .on('draw.dt', function (e, settings) {
      const dt = new $.fn.dataTable.Api(settings);
      dt.processing(false);
      $(this)
        .find('tr:not(.dis) td')
        .filter(function () {
          const td = $(this);
          return !!(
            td.find('.didah-edit-date-datapicker').length ||
            td.find('.didah-edit-datetime-datapicker').length ||
            td.find('.didah-edit-input').length ||
            td.find('.didah-edit-textfield').length ||
            td.find('.didah-edit-decimal').length ||
            td.find('.didah-edit-select').length ||
            td.find('.didah-edit-checkbox').length
          );
        })
        .addClass('highlighted');
      isLocked = false;
      dt.table().columns.adjust();
    })
    .on('click', 'tr:not(.dis) td', function (e) {
      if (didahEditDatatable) {
        if (!isLocked) window.activateCell($(this), e);
      }
    })
    .on('search.dt', function (e, settings) {
      const dt = new $.fn.dataTable.Api(settings);
      $(`#${id}_search`).val(dt.search());
      if (dt.search() !== '') {
        groupFields.rowsData = {};
      }
    })
    .on('length.dt', function (e, settings, len) {
      $(`#${id}_page_length`).val(len);
    })
    .processing(true);

  return table;
}

export { selectQuery8Table };
