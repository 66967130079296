import type { DocumentFile } from '../../types';
import type { FileTreeDocumentFile } from './types';

function documentPathToName(path: string): string {
  return path.split('/').pop() || '/';
}

function isNonDirFile(file: FileTreeDocumentFile): file is DocumentFile {
  return file.type !== 'dir';
}

export { documentPathToName, isNonDirFile };
