import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getDefaultValues, getValidationSchema } from './validation';
import { FormPanel, Input, RequiredStar } from '../shared/components';
import { showNotification, translate } from '@/common';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLang } from '@/init';
import ReCAPTCHA from 'react-google-recaptcha';
import { isDarkMode } from '@/utils';
import { HiddenInputs } from '../shared/components/form/base/HiddenInputs';
import type { RegistrationParams } from './types';
import { PhoneInput } from '../shared/components/form/phone-input';
import { NotificationType } from '@/common/widgets/notification';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const CAPTCHA_KEY = process.env.CAPTCHA_KEY;
type Props = RegistrationParams;

function RegistrationForm({ endPoint, keySlc, pageId, params }: Props) {
  const {
    clearErrors,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: getDefaultValues(),
    mode: 'all',
    resolver: zodResolver(getValidationSchema()),
  });

  const lang = getLang();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordRVisible, setIsPasswordRVisible] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const [login, password, firstname, lastname, email, mobilephone] = watch([
    'login',
    'password',
    'firstname',
    'lastname',
    'email',
    'mobilephone',
  ]);

  const fieldsData = useMemo(
    () => ({
      ...params,
      l10: firstname,
      l11: lastname,
      l12: email,
      l13: mobilephone,
      l2: login,
      l3: password,
      l6: window.hex_md5(login || ''),
      l7: window.hex_md5(window.hex_md5(password || '') + keySlc),
      l8: window.hex_md5(password || ''),
      l9: window.hex_md5(window.hex_md5(login || '') + keySlc),
    }),
    [params, login, password, firstname, keySlc, lastname, email, mobilephone],
  );

  const registration = useCallback(
    async (data) => {
      if (!isValidPhone) {
        setError('mobilephone', {
          message: translate('common.forms.validation.mobilephone'),
          type: 'manual',
        });
        return;
      }
      if (!isValid) {
        return;
      }

      trigger('password_repeat');

      try {
        const res = await fetch(endPoint, {
          body: JSON.stringify({ ...data, lang, pageId, ...fieldsData }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'post',
        });

        if (!res.ok) {
          const result = await res.json();
          showNotification({
            displayTime: 10000,
            text: result?.error?.message,
            title: translate('feedback_form.sending_notify.failed_send'),
            type: NotificationType.Danger,
          });

          return;
        }

        const result = await res.json();

        showNotification({
          displayTime: 10000,
          text: result.message,
          title: translate('registration_form.success_sending'),
          type: NotificationType.Success,
        });

        reset();
      } catch (e) {
        throw new Error(e);
      }
    },
    [
      isValidPhone,
      isValid,
      trigger,
      setError,
      endPoint,
      lang,
      pageId,
      fieldsData,
      reset,
    ],
  );

  return (
    <div className="tw-ml-auto tw-mt-[50px] md:tw-ml-[25%] md:tw-w-1/2">
      <FormPanel title={translate('registration_form.title')}>
        <form onSubmit={handleSubmit(registration)}>
          <Input
            autoComplete="on"
            errorMessage={
              errors.login?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.login?.message?.toString(),
                  }}
                />
              )
            }
            isRequired
            label={translate('common.login')}
            name="login"
            type="text"
            {...register('login')}
          />

          <Input
            addon={{
              icon: (
                <span
                  className="tw-cursor-pointer"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  <FontAwesomeIcon
                    icon={isPasswordVisible ? faEye : faEyeSlash}
                  />
                </span>
              ),
            }}
            errorMessage={
              errors.password?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.password?.message?.toString(),
                  }}
                />
              )
            }
            name="password"
            placeholder={translate('common.password')}
            type={isPasswordVisible ? 'text' : 'password'}
            {...register('password')}
          />

          <Input
            addon={{
              icon: (
                <span
                  className="tw-cursor-pointer"
                  onClick={() => setIsPasswordRVisible(!isPasswordRVisible)}
                >
                  <FontAwesomeIcon
                    icon={isPasswordRVisible ? faEye : faEyeSlash}
                  />
                </span>
              ),
            }}
            errorMessage={
              errors.password_repeat?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.password_repeat?.message?.toString(),
                  }}
                />
              )
            }
            name="password_repeat"
            placeholder={translate('registration_form.labels.password_repeat')}
            type={isPasswordRVisible ? 'text' : 'password'}
            {...register('password_repeat')}
          />

          <Input
            errorMessage={
              errors.email?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.email?.message?.toString(),
                  }}
                />
              )
            }
            isRequired
            label={translate('common.email')}
            name="email"
            type="email"
            {...register('email')}
          />

          <Input
            errorMessage={
              errors.firstname?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.firstname?.message?.toString(),
                  }}
                />
              )
            }
            isRequired
            label={translate('registration_form.labels.first_name')}
            name="firstname"
            type="text"
            {...register('firstname')}
          />

          <Input
            errorMessage={
              errors.lastname?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.lastname?.message?.toString(),
                  }}
                />
              )
            }
            isRequired
            label={translate('registration_form.labels.last_name')}
            name="lastname"
            type="text"
            {...register('lastname')}
          />

          <PhoneInput
            errorMessage={
              errors.mobilephone?.message && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: errors.mobilephone?.message?.toString(),
                  }}
                />
              )
            }
            initCountryCode="ee"
            isRequired
            label={translate('registration_form.labels.mobile_phone')}
            value={mobilephone}
            onChangeValidity={(isValid: boolean) => {
              setIsValidPhone(isValid);
              if (isValid && errors.mobilephone) {
                clearErrors('mobilephone');
              } else if (!isValid && !errors.mobilephone) {
                setError('mobilephone', {
                  message: translate('common.forms.validation.phone'),
                  type: 'manual',
                });
              }
            }}
            onValueChange={(value: string) => {
              setValue('mobilephone', value, { shouldValidate: false });
            }}
          />

          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              className="h-[74px] w-[298px] overflow-hidden"
              hl={lang}
              sitekey={CAPTCHA_KEY}
              theme={isDarkMode() ? 'dark' : 'light'}
              onChange={(token: string) => {
                setValue('token', token);
                trigger('token');
              }}
            />
          </div>

          <HiddenInputs fields={fieldsData} />

          <button
            className="btn btn-success tw-mt-8"
            disabled={isSubmitting}
            type="submit"
          >
            {translate('registration_form.submit_button')}
          </button>

          <div className="tw-mt-4">
            <RequiredStar />
            {` - ${translate('common.forms.hints.required_field')}`}
          </div>
        </form>
      </FormPanel>
    </div>
  );
}

export { RegistrationForm };
