import type {
  FileLibraryParams,
  FileTreeDocumentDir,
  FileTreeDocumentFile,
} from './types';
import { documentPathToName } from './utils';

async function getDirChildren(
  path: string,
  filesBaseURL: FileLibraryParams['filesBaseURL'],
): Promise<FileTreeDocumentFile[]> {
  const basePath = filesBaseURL.endsWith('/')
    ? filesBaseURL.slice(0, -1)
    : filesBaseURL;
  const response = await fetch(basePath + path);

  if (!response.ok) {
    throw new Error('Failed to fetch files');
  }

  return response.json();
}

function documentDirToTreeDir(
  dir: FileTreeDocumentFile,
  parentId: Nullable<string>,
): FileTreeDocumentDir {
  const path = dir.id;
  return {
    children: [],
    id: dir.id,
    isBranch: true,
    metadata: {
      isChildrenLoaded: false,
      path,
    },
    name: documentPathToName(path),
    parent: parentId,
  };
}

export { documentDirToTreeDir, getDirChildren };
