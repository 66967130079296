import { createKeyboardNavigation } from '@/utils';

function bindKeyboardNav(selector: string) {
  const root = document.querySelector(`${selector}`) as HTMLFormElement;

  if (!root) {
    throw new Error(`${selector} not found`);
  }

  const inputs = root.querySelectorAll(
    'input, select, textarea, .selectize-input',
  );

  const { onKeyDown } = createKeyboardNavigation();

  inputs.forEach((input) => {
    input.addEventListener('keydown', (event) =>
      onKeyDown(event as KeyboardEvent),
    );
  });
}

export { bindKeyboardNav };
