import $ from 'jquery';

import { translate } from '@/common/features/translations';

import { controller } from './auto-tables-controller';
import { setButtonLoading } from './common';
import { autoTablesButtonsStore } from './auto-tables-buttons-store';
import type { GetDataRequest } from './request';
import { asyncPause } from '@/utils';

class LockButton {
  private readonly $btn: JQuery;

  constructor(selector: string, initialState) {
    this.$btn = $(selector);

    this.update(initialState);
    this.setupClick();
  }

  private updateText(isLocked: boolean) {
    this.$btn.html(
      translate(isLocked ? 'autotable.unlock_all' : 'autotable.lock_all'),
    );
  }

  private updateClass(isLocked: boolean) {
    const lockedClass = 'btn-success';
    const unlockedClass = 'btn-danger';
    if (isLocked) {
      this.$btn.addClass(lockedClass);
      this.$btn.removeClass(unlockedClass);
    } else {
      this.$btn.removeClass(lockedClass);
      this.$btn.addClass(unlockedClass);
    }
  }

  private setupClick() {
    this.$btn.on('click', async () => {
      const wasLocked = controller.isReadOnlyAll();

      try {
        this.setLoading();

        await Promise.all(
          controller.getIds().map(async (id) => {
            const allParams = controller.getParams(id);
            const getDataRequest = allParams.getDataRequest as GetDataRequest;

            const [, rows] = await getDataRequest({ lock: !wasLocked });
            if (rows) {
              controller.setRows(id, rows);
              // пауза для ререндера таблицы
              await asyncPause(25);
            }
          }),
        );

        const isLocked = !wasLocked;
        controller.setReadOnlyAll(isLocked);
        // пауза для ререндера таблицы
        await asyncPause(25);

        this.update(isLocked);

        autoTablesButtonsStore.saveButton?.rerender();
      } catch (e) {
        this.update(wasLocked);
      }
    });
  }

  update(isLocked: boolean) {
    this.updateClass(isLocked);
    this.updateText(isLocked);
  }

  setLoading() {
    setButtonLoading(this.$btn);
  }
}

function setupLockButton(selector: string, isLocked?: boolean) {
  autoTablesButtonsStore.lockButton = new LockButton(selector, isLocked);
}

export { LockButton, setupLockButton };
