import $ from 'jquery';
import type { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { isMac } from './browser';
import { ArrowEventKeys, KeyboardEventKey } from './keyboard-event';

type GenericKeyboardEvent = ReactKeyboardEvent | KeyboardEvent;

const createKeyboardNavigation = () => {
  const navigateVertically = (target: EventTarget, increment: number) => {
    const $target = $(target);
    const $tds = $target.closest('tr').children('td');
    const tdIndex = $tds.index($target.closest('td') as any);

    const $parentTr = $(target).closest('tr');
    const $tbody = $parentTr.closest('tbody');
    const $allTrs = $tbody.children('tr');
    const nextTrIndex = $allTrs.index($parentTr as any) + increment;
    if (nextTrIndex < 0 || nextTrIndex > $allTrs.length) {
      return;
    }

    const $nextTr = $($allTrs.get(nextTrIndex));
    const $nextTrTds = $nextTr.children('td');
    const nextTd = $nextTrTds.get(tdIndex);
    const nextInput = $(nextTd)
      .find(
        'input:not(:disabled),textarea:not(:disabled),select:not(:disabled):not(.selectized)',
      )
      .get(0);
    if (!nextInput) {
      return navigateVertically(
        target,
        increment > 0 ? increment + 1 : increment - 1,
      );
    }
    nextInput?.focus();
  };
  const navigateHorizontally = (target: EventTarget, increment: number) => {
    const $inputs = $(target).closest('tr').find('input,textarea,select');
    const nextIndex = $inputs.index(target as any) + increment;
    if (nextIndex < 0) {
      return;
    }

    const nextInput = $inputs.get(nextIndex);
    nextInput?.focus();
  };

  const onArrowUp = (target: EventTarget) => {
    navigateVertically(target, -1);
  };
  const onArrowDown = (target: EventTarget) => {
    navigateVertically(target, 1);
  };
  const onArrowLeft = (target: EventTarget) => {
    navigateHorizontally(target, -1);
  };
  const onArrowRight = (target: EventTarget) => {
    navigateHorizontally(target, 1);
  };

  const onKeyDown = (event: GenericKeyboardEvent) => {
    const isModifierPressed = isMac ? event.metaKey : event.ctrlKey;
    if (!isModifierPressed || !ArrowEventKeys.includes(event.key as any)) {
      return;
    }

    event.preventDefault();

    switch (event.key) {
      case KeyboardEventKey.ArrowDown: {
        return onArrowDown(event.target);
      }
      case KeyboardEventKey.ArrowUp: {
        return onArrowUp(event.target);
      }
      case KeyboardEventKey.ArrowLeft: {
        return onArrowLeft(event.target);
      }
      case KeyboardEventKey.ArrowRight: {
        return onArrowRight(event.target);
      }
    }
  };

  return { onKeyDown };
};

export { createKeyboardNavigation };
