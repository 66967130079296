import type { FileManagerOptions } from '@/common/features/filemanager';

type Options = FileManagerOptions & {
  resizeClass: string;
};

export async function fileBrowser({
  dataParams,
  initPath,
  maxUploadFilesCount,
  maxUploadFilesSizeTotal,
  resizeClass,
  smallTreeMode,
}: Options) {
  const fileManagerApi = await import('@/common/features/filemanager').then(
    ({ default: fileManager }) =>
      fileManager({
        dataParams,
        initPath,
        maxUploadFilesCount,
        maxUploadFilesSizeTotal,
        smallTreeMode,
      }),
  );

  if (
    window.SendResizeMessage &&
    typeof window.SendResizeMessage === 'function'
  ) {
    window.SendResizeMessage(resizeClass);
  }

  window.getDataForUrl = () => ({ O10: fileManagerApi.getPath() });
}
