import $ from 'jquery';
import throttle from 'lodash/throttle';
import { checkMobile } from '@/utils';

const CONTROLS_BUTTONS_MARGIN = 10;

function stickyControlButtonsOnMobile() {
  const $controlButtons = $('.js-btn-group-fixed-all');
  if (!$controlButtons.length) {
    return;
  }

  function positionMobileControlButtons() {
    const $menuNavigation = $('.js-menu-navigation');
    const bottom = $menuNavigation.length
      ? $menuNavigation[0].getBoundingClientRect().bottom
      : 0;
    if (bottom + CONTROLS_BUTTONS_MARGIN < pageYOffset) {
      $controlButtons.css('top', CONTROLS_BUTTONS_MARGIN);
    } else {
      $controlButtons.css('top', bottom + CONTROLS_BUTTONS_MARGIN);
    }
  }

  positionMobileControlButtons();

  const onScrollHandler = throttle(
    () => {
      positionMobileControlButtons();
    },
    10,
    { leading: false, trailing: true }
  );

  $(window).on('scroll', onScrollHandler);
}

function stickyControlButtonsOnDesktop() {
  let hasClass = checkClass();
  let positioningDoneForWithClass = false;

  function checkClass() {
    return $('.js-menu-navigation').hasClass('fixed-wrap');
  }
  function positionControlButtons(forced?: boolean) {
    if (hasClass === positioningDoneForWithClass && !forced) {
      return;
    }
    const $menuNavigation = $('.js-menu-navigation');
    if (!$menuNavigation.length) {
      return;
    }

    $('.js-btn-group-fixed-all').css(
      'top',
      $menuNavigation[0].getBoundingClientRect().bottom +
        CONTROLS_BUTTONS_MARGIN
    );

    positioningDoneForWithClass = hasClass;
  }

  positionControlButtons(true);

  const onScrollHandler = throttle(
    () => {
      hasClass = checkClass();
      positionControlButtons(!hasClass);
    },
    10,
    { leading: false, trailing: true }
  );

  $(window).on('scroll', onScrollHandler);
}

function sticky() {
  if (checkMobile()) {
    stickyControlButtonsOnMobile();
  } else {
    stickyControlButtonsOnDesktop();
  }
}

export { sticky };
