import type { MediaExplorerParams } from '../../types';

function mediaExplorer(
  selector: string | Element,
  params: MediaExplorerParams,
) {
  return import('./init').then(({ mediaExplorer: fn }) => fn(selector, params));
}

export { mediaExplorer };
