import throttle from 'lodash/throttle';
import './style.scss';
import { isScrolledToBottom } from '@/utils';
import type { OnRestoredMessage } from '../iframe-windows';
import { MessageTypes } from '../iframe-windows';

type Params = {
  bottomOffset?: number;
  onReady?: (el: HTMLElement) => void;
};

function withScrollRestoration(
  el: HTMLElement,
  id: string,
  { bottomOffset = 20, onReady }: Params = {},
) {
  if (!el) {
    console.warn(`Element with id "${id}" is not available.`);
    return;
  }

  const storageId = `persist-scroll-y-${id}`;

  const saveScroll = (scrollY: number) => {
    sessionStorage.setItem(storageId, scrollY + '');
  };

  const getScroll = () => {
    const saved = sessionStorage.getItem(storageId);

    if (saved) {
      let result = parseInt(saved, 10);
      if (isScrolledToBottom(el, result)) {
        result -= bottomOffset;
      }

      return result;
    } else {
      return 0;
    }
  };

  // apply scroll

  el.scrollTop = getScroll();

  el.addEventListener(
    'wheel',
    throttle(() => {
      saveScroll(el.scrollTop);
    }, 300),
  );

  // remove overlay
  onReady?.(el);

  window.addEventListener(
    'message',
    ({ data }: MessageEvent) => {
      const { type } = data as OnRestoredMessage;
      if (type !== MessageTypes.Restored) {
        return;
      }

      // apply scroll
      el.scrollTop = getScroll();
    },
    false,
  );
}

export { withScrollRestoration };
