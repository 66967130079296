import $ from 'jquery';

function glow(element: HTMLElement, ms: number) {
  const $element = $(element);
  $element.css(
    'animation',
    `didah-glow ${Math.ceil(ms / 2)}ms linear alternate infinite`
  );
  setTimeout(() => {
    $element.css('animation', '');
  }, ms);
}

export { glow };
