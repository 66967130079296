import { checkMobile } from '@/utils';
import { useMemo } from 'react';

const useMobileCheck = () => {
  const isMobile = useMemo(() => checkMobile(), []);

  return {
    isMobile,
  };
};

export { useMobileCheck };
