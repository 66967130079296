import type { SimpleGeolocation } from '@/types';
import { useMemo } from 'react';

const useGeolocationLink = (geolocation?: Nullable<SimpleGeolocation>) => {
  const { latitude, longitude } = geolocation ?? {};

  return useMemo(() => {
    if (latitude == null || longitude == null) return '';

    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  }, [latitude, longitude]);
};

export { useGeolocationLink };
