import { Uploader } from '@/react-app/shared/components';
import { cn } from '@/utils';
import {
  faCheckSquare,
  faSquare,
  faSquareMinus,
} from '@fortawesome/free-regular-svg-icons';
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  accept?: string;
  documentsSelected: number;
  documentsTotal: number;
  hasSelection?: boolean;
  hasUpload?: boolean;
  isDisabled: boolean;
  isMultiUpload?: boolean;
  onSelectAll: VoidFunction;
  onUpload: (formData: FormData) => void;
};

function MainButton({
  accept,
  documentsSelected,
  documentsTotal,
  hasSelection,
  hasUpload,
  isDisabled,
  isMultiUpload,
  onSelectAll,
  onUpload,
}: Props) {
  let selectIcon = faSquare;

  if (documentsTotal) {
    if (documentsTotal === documentsSelected) {
      selectIcon = faCheckSquare;
    } else if (documentsSelected) {
      selectIcon = faSquareMinus;
    }
  }
  return (
    <div className="tw-flex tw-h-20 tw-w-24 tw-overflow-hidden tw-rounded-md">
      {hasUpload && (
        <Uploader
          accept={accept}
          className="tw-flex-1"
          isDisabled={isDisabled}
          multiple={isMultiUpload}
          onUpload={onUpload}
        >
          <button
            className={cn(
              'btn btn-success tw-h-full tw-w-full !tw-rounded-none tw-transition-colors',
              {
                disabled: isDisabled,
              }
            )}
            type="button"
          >
            <FontAwesomeIcon className="tw-text-4xl" icon={faFileArrowUp} />
          </button>
        </Uploader>
      )}
      {hasSelection && (
        <button
          className={cn(
            'btn btn-primary tw-flex-1 !tw-rounded-none tw-transition-colors',
            {
              disabled: isDisabled,
            }
          )}
          type="button"
          onClick={() => onSelectAll()}
        >
          <FontAwesomeIcon className="tw-text-3xl" icon={selectIcon} />
        </button>
      )}
    </div>
  );
}

export { MainButton };
