import { useCallback, useRef, useState } from 'react';

import { createFilesFormData } from '@/utils';

import type { CanvasData, CanvasForwardedMethods } from './types';
import { DrawingTool, Mode } from './types';
import type { MediaDialogFile } from '../../types';
import { showNotification, translate } from '@/common';
import { NotificationType } from '@/common/widgets/notification';

type Params = {
  currentFile: Nullable<MediaDialogFile>;
  setMode: (mode: Mode) => void;
  uploadFiles?: (files: FormData) => Promise<boolean>;
};

const useDrawing = ({ currentFile, setMode, uploadFiles }: Params) => {
  const drawingCanvasRef = useRef<CanvasForwardedMethods>(null);

  const [tool, setTool] = useState(DrawingTool.Brush);

  const [canvasData, setCanvasData] = useState<Nullable<CanvasData>>(null);

  const resetDrawingState = useCallback(() => {
    setCanvasData(null);
  }, []);

  const onDraw = useCallback(() => setMode(Mode.Drawing), [setMode]);
  const onDrawCancel = useCallback(() => {
    resetDrawingState();
    setMode(Mode.Normal);
  }, [resetDrawingState, setMode]);

  const handleUploading = useCallback(async () => {
    if (!uploadFiles || !currentFile) {
      return;
    }
    const blob = await drawingCanvasRef.current?.exportBlob();
    if (!blob) {
      return;
    }

    const filesFormData = createFilesFormData(
      [new File([blob], currentFile.name)],
      'files[]'
    );

    if (!filesFormData) {
      return;
    }

    const isSuccess = await uploadFiles(filesFormData);

    if (isSuccess) {
      showNotification({
        title: translate('common.success'),
        type: NotificationType.Success,
      });
    } else {
      showNotification({
        title: translate('common.error'),
        type: NotificationType.Danger,
      });
    }
  }, [currentFile, uploadFiles]);

  const onDrawSave = useCallback(async () => {
    await handleUploading();
    resetDrawingState();
    setMode(Mode.Normal);
  }, [handleUploading, resetDrawingState, setMode]);

  return {
    canvasData,
    drawingCanvasRef,
    onDraw,
    onDrawCancel,
    onDrawSave,
    resetDrawingState,
    setCanvasData,
    setTool,
    tool,
  };
};

export { useDrawing };
