import { makeMovable } from '@/utils';
import { reportObjectCartCoordsKey } from '@/constants';

function movableCart(id: string) {
  const element = document.getElementById(id)
    ?.parentNode as Nullable<HTMLElement>;
  if (!element) {
    return;
  }

  document.body.appendChild(element);

  makeMovable({
    element,
    keepSize: true,
    onMoveEnd: (coords) => {
      localStorage.setItem(reportObjectCartCoordsKey, JSON.stringify(coords));
    },
  });

  try {
    const coords = JSON.parse(localStorage.getItem(reportObjectCartCoordsKey));
    if (coords) {
      const { bottom, left, right, top } = coords;
      element.style.left = `${left}px`;
      element.style.right = `${right}px`;
      element.style.bottom = `${bottom}px`;
      element.style.top = `${top}px`;
    }
  } catch (e) {
    // do nothing
  }
}

export { movableCart };
