import { cn } from '@/utils';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent } from 'react';
import { Input } from '../base';
import { PercentDropdown } from './PercentDropdown';
import type { PercentPickerProps } from './types';

const regexp = /^(100(\.0+)?|([1-9]?\d(\.\d+)?))$/;

function PercentPicker({
  disabled,
  hideIcon,
  ignoreSizes,
  inlineLabel,
  label,
  name,
  onInputChange,
  placeholder,
  value,
}: PercentPickerProps) {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const changedValue = e.target.value;
    if (changedValue) {
      const match = changedValue.match(regexp);
      if (!match) return;

      onInputChange(changedValue);
    } else {
      onInputChange('');
    }
  };

  const setPercent = (percent: number) => {
    onInputChange(percent + '');
  };

  return (
    <Input
      addon={{
        icon: (
          <PercentDropdown onSetPercent={setPercent}>
            <FontAwesomeIcon className="tw-cursor-pointer" icon={faPercent} />
          </PercentDropdown>
        ),
        position: 'after',
      }}
      className={cn({
        'hide-icon': hideIcon,
        'ignore-sizes': ignoreSizes,
      })}
      classNames={{
        formGroup: 'tw-max-w-[100px]',
      }}
      disabled={disabled}
      inlineLabel={inlineLabel}
      label={label}
      max="100"
      min="0"
      name={name}
      placeholder={placeholder}
      type="number"
      value={value}
      onChange={handleInputChange}
    />
  );
}

export { PercentPicker };
