import { cn } from '@/utils';
import { forwardRef, type InputHTMLAttributes, type ReactNode } from 'react';

import { Error } from './Error';
import { RequiredStar } from './RequiredStar';

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'value' | 'onChange'
> & {
  classNames?: {
    wrapper?: string;
  };
  errorMessage?: ReactNode;
  inlineLabel?: boolean | 'after';
  isChecked?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  onCheckedChange?: (
    value: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

const CheckboxComponent = (
  {
    className,
    classNames,
    errorMessage,
    inlineLabel,
    isChecked,
    isRequired,
    label,
    onCheckedChange,
    ...restProps
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const input = (
    <input
      ref={ref}
      checked={isChecked}
      className={cn(
        'tw-accent-primary dark:tw-accent-primary-dark',
        {
          '!tw-mt-0': inlineLabel,
        },
        className,
      )}
      type="checkbox"
      onChange={(e) => {
        onCheckedChange?.(e.target.checked, e);
      }}
      {...restProps}
    />
  );

  let content = input;

  if (label) {
    const labelNode = (
      <label
        className={cn(
          {
            checkbox: !inlineLabel,
            'checkbox-inline': inlineLabel,
            'tw-pl-0': inlineLabel === 'after',
          },
          'tw-font-bold',
        )}
        htmlFor={restProps.name}
      >
        {label}
        {isRequired && <RequiredStar />}
      </label>
    );
    content =
      inlineLabel === 'after' ? (
        <>
          {input}
          {labelNode}
        </>
      ) : (
        <>
          {labelNode}
          {input}
        </>
      );
  }

  return (
    <>
      <div
        className={cn(
          { 'tw-flex tw-items-center tw-gap-3': inlineLabel },
          classNames?.wrapper,
        )}
      >
        {content}
      </div>
      {errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};

const Checkbox = forwardRef<HTMLInputElement, Props>(CheckboxComponent);

export { Checkbox, Props as CheckboxProps };
