import { cn } from '@/utils';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onClick?: VoidFunction;
};

function FileLibraryButton({ onClick }: Props) {
  return (
    <div className="tw-flex tw-h-20 tw-w-24 tw-overflow-hidden tw-rounded-md">
      <button
        className={cn(
          'btn btn-default !tw-flex tw-h-20 tw-w-24 tw-items-center tw-justify-center !tw-rounded-md !tw-bg-foreground-violet tw-transition-colors hover:!tw-bg-foreground-violet-2 dark:!tw-bg-foreground-violet-dark dark:hover:!tw-bg-foreground-violet-2-dark',
        )}
        type="button"
        onClick={onClick}
      >
        <FontAwesomeIcon className="tw-text-4xl" icon={faFileAlt} />
      </button>
    </div>
  );
}

export { FileLibraryButton };
