import { checkboxListModal } from '@/common/widgets/checkbox-list-modal';

import './style.scss';

function setSelectizeItems(selectizeInput, items) {
  const selectizeAPI = selectizeInput.selectize;
  const currentItems = [...selectizeAPI.items];

  currentItems.forEach((item) => selectizeAPI.removeItem(item, true));

  items.forEach((item) => selectizeAPI.addItem(item));
}

function arrangeSelectizeItems(selectizeInput, $trigger) {
  const selectizeAPI = selectizeInput.selectize;
  const currentItems = () =>
    [...selectizeAPI.items].map((item) => ({
      label: item,
      value: item,
    }));

  const cblm = checkboxListModal(currentItems, {
    $trigger,
    isWithCheckboxes: false,
    onClose: () => {
      cblm.hideModal();
      cblm.setData(currentItems);
    },
    onReset: () => {
      cblm.setData(currentItems);
    },
    onSave: (data) => {
      setSelectizeItems(
        selectizeInput,
        data.map((item) => item.value)
      );
      cblm.hideModal();
    },
    staticFirst: false,
  });
}

function makeSelectizeItemsSortable(selectizeInput) {
  const selectizeAPI = selectizeInput.selectize;
  const $container = selectizeAPI.$control;
  const $trigger = $(`
        <btn class="button btn btn-primary sort-selectize-btn">
            <i class="fa fa-sort"></i>
        </btn>
    `);

  $container.prepend($trigger);

  arrangeSelectizeItems(selectizeInput, $trigger);
}

export { makeSelectizeItemsSortable };
