import { getPickerList } from '@/react-app/shared/utils/picker';
import { range } from 'lodash';
import { useState, type PropsWithChildren } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../dropdown';

const percentList = getPickerList(range(0, 105, 5));

type Props = PropsWithChildren<{
  onSetPercent: (value: number) => void;
}>;

function PercentDropdown({ children, onSetPercent }: Props) {
  const [dropdownShown, setDropdownShown] = useState(false);
  return (
    <DropdownMenu
      modal={false}
      open={dropdownShown}
      onOpenChange={setDropdownShown}
    >
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="tw-z-10">
        <div className="tw-grid-auto-rows-[31px] tw-grid-auto-columns-[59px] tw-grid tw-grid-cols-4">
          {percentList.map(({ label, value }) => (
            <div
              key={value}
              className="hover:tw-bg-brand-lighter-40 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-text-center tw-text-xs hover:tw-rounded-md hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => {
                onSetPercent(value);
                setDropdownShown(false);
              }}
            >
              <span className="tw-leading-[31px]">{label}</span>
            </div>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { PercentDropdown };
