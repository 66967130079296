import $ from 'jquery';
import moment from 'moment';

import { dt_number_format, extractHtmlText } from '@/utils';

import { datatableFormats } from '@/common/features/datatables';
import type { MediaExplorerModalParams } from '@/react-app/media';
import { mediaExplorerModal, mediaViewer } from '@/react-app/media';
import { getIsEllipsis } from './ellipsis';
import type { DocumentFile } from '@/react-app/media/types';

type RenderOptions = {
  a: string;
  doSendform266: boolean;
  docOk: string;
  enableCase004: boolean;
  fsessData: Dictionary;
  id: string;
  mediaFileLibrary?: MediaExplorerModalParams['fileLibrary'];
  mediaHasCamera?: boolean;
  menuId: string;
  signing?: MediaExplorerModalParams['signing'];
};

function initRender({
  a,
  doSendform266,
  docOk,
  enableCase004,
  fsessData,
  id,
  mediaFileLibrary,
  mediaHasCamera,
  menuId,
  signing,
}: RenderOptions) {
  function getMediaViewerHandler({
    documents: documentsUrls,
  }: {
    documents: string[];
  }) {
    const documents: DocumentFile[] = documentsUrls.map((url) => ({
      id: url,
      type: '',
      url,
    }));

    return () =>
      mediaViewer({
        documents,
      });
  }

  function getMediaExplorerHandler({
    datatable,
    documents,
    fieldName,
    fieldType,
    functionId,
    m_row,
    row,
  }: {
    datatable: any;
    documents: string[];
    fieldName: string;
    fieldType: 'file' | 'file_library';
    functionId: string;
    m_row: any;
    row: any;
  }) {
    const refetchRow = () => {
      $.ajax({
        cache: false,
        data: {
          a,
          fquery: row['s_0'],
          fsess: window.update_hash,
          id: 24,
          zsess: window.data_hash,
        },
        dataType: 'json',
        error(e) {
          alert('Error processing your request: ' + e.responseText);

          delete window.datatableHandlers[functionId];
          window.reloadDT(datatable, {
            fieldName,
            m_row,
            rowData: row,
            status: 'error',
          });
        },
        success(response) {
          delete window.datatableHandlers[functionId];
          window.reloadDT(datatable, {
            fieldName,
            m_row,
            response,
            rowData: row,
            status: 'success',
          });
        },
        type: 'POST',
        url: '/ajax_api.php',
      });
    };

    const commonMediaParams = {
      baseURL: '/ajax_api.php',
      onCountChange: refetchRow,
      requestsParams: {
        deleteOptions: {
          a,
          fquery: row['s_0'],
          fsess: fsessData['fd' + fieldName],
          id: 14,
        },
        initOptions: {
          a,
          fquery: row['s_0'],
          fsess: fsessData['fi' + fieldName],
          id: 14,
        },
        signOptions: {
          a,
          fquery: row['s_0'],
          fsess: fsessData['fs' + fieldName],
          id: 14,
        },
        uploadOptions: {
          a,
          fquery: row['s_0'],
          fsess: fsessData['fu' + fieldName],
          id: 14,
        },
      },
      signing,
    };

    return () =>
      mediaExplorerModal({
        ...commonMediaParams,
        ...(fieldType === 'file'
          ? {
              hasCamera: mediaHasCamera,
              hasUpload: row['s_7'] !== '1',
            }
          : {
              fileLibrary: mediaFileLibrary,
              hasUpload: false,
              initialDocuments: documents,
            }),
      });
  }

  function mediaField(params: {
    data: any;
    fieldName: string;
    fieldType: 'file' | 'file_library' | 'media_viewer';
    meta: { col: number; row: number };
    row: any;
  }) {
    const { data, fieldName, fieldType, meta, row } = params;
    const documents: string[] = (data || '').split(' ').filter(Boolean);
    const count = documents.length;

    let content =
      '<i class="glyphicon glyphicon-paperclip fa-fw" aria-hidden="true"></i>';
    if (count > 0) {
      content += ` (${count})`;
    } else if (!count) {
      content =
        '<i class="fa fa-plus-square-o fa-fw" aria-hidden="true" style="color:silver;"></i>';
    }

    const datatable = window[`table${id}`];
    const m_row = datatable.row(meta.row);
    const functionId = `table${id}_row_handler_${meta.row}_${fieldName || meta.col}`;

    const onClick =
      fieldType === 'media_viewer'
        ? getMediaViewerHandler({
            documents,
          })
        : getMediaExplorerHandler({
            datatable,
            documents,
            fieldName,
            fieldType,
            functionId,
            m_row,
            row,
          });

    window.datatableHandlers = window.datatableHandlers || {};
    window.datatableHandlers[functionId] = onClick;

    return `<a href="#" onclick="datatableHandlers['${functionId}']?.()" style="padding: 0 7px !important;">${content}</a>`;
  }

  $.fn.dataTable.render.didah = function (fieldType, deci, fsess, fieldName) {
    if (enableCase004 && fieldType === '_004') {
      return function (data, type, row) {
        if (type === 'display' || type === 'print') {
          return row['t_pth'];
        } else if (type === 'sort') {
          return row['t_srt'];
        } else {
          return '';
        }
      };
    } else if (fieldType === 'decimal') {
      return function (data, type, row) {
        if (type === 'print') {
          return dt_number_format(+data, deci);
        } else if (type === 'display') {
          if (fsess !== '')
            return `<div class="didah-edit didah-edit-decimal"><span data-field="${fieldName}" data-rowid="${
              row['s_0']
            }">${dt_number_format(+data, deci)}</span></div>`;
          else return dt_number_format(+data, deci);
        } else {
          return data;
        }
      };
    } else if (fieldType === 'enum') {
      return function (data, type, row) {
        if (type === 'filter') {
          return '';
        } else if (type === 'display') {
          if (fsess !== '') {
            return `<input class="didah-edit-checkbox" type="checkbox" name="${fieldName}" data-field="${fieldName}" data-rowid="${
              row['s_0']
            }" value="" ${
              (data != '1' ? '' : ' checked') +
              (fieldName == 's_6' || (row['s_6'] == '1' && row['s_7'] == '0')
                ? ''
                : ' disabled')
            }>`;
          } else {
            return data != '1'
              ? ''
              : '<i class="fa fa-check" aria-hidden="true" style="color:green"></i>';
          }
        } else if (type === 'print' || type === 'export') {
          return data != '1' ? '' : '*';
        } else {
          return data != '1' ? '' : '1';
        }
      };
    } else if (fieldType === 'date') {
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-date-datapicker"><span data-field="${fieldName}" data-rowid="${
            row['s_0']
          }">${
            data === 0
              ? ''
              : moment(data * 1000)
                  .utc()
                  .format(datatableFormats.date)
          }</span></div>`;
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0
            ? ''
            : moment.unix(data).utc().format(datatableFormats.date);
        } else {
          return data;
        }
      };
    } else if (fieldType === 'datetime') {
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-datetime-datapicker"><span data-field="${fieldName}" data-rowid="${
            row['s_0']
          }">${
            data === 0
              ? ''
              : moment(data * 1000)
                  .utc()
                  .format(datatableFormats.datetime)
          }</span></div>`;
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0
            ? ''
            : moment.unix(data).utc().format(datatableFormats.datetime);
        } else {
          return data;
        }
      };
    } else if (fieldType === 'varchar') {
      return function (data, type, row) {
        if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-input"><span data-field="${fieldName}" data-rowid="${row['s_0']}">${data}</span></div>`;
        } else {
          return data;
        }
      };
    } else if (fieldType === 'text') {
      return function (data, type, row) {
        if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-textfield"><span data-field="${fieldName}" data-rowid="${row['s_0']}">${data}</span></div>`;
        } else {
          return data;
        }
      };
    } else if (fieldType === 'password') {
      return function (data, type, row) {
        const text = data === '' ? '' : '******';
        if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-input"><span data-field="${fieldName}" data-rowid="${row['s_0']}">${text}</span></div>`;
        } else {
          return text;
        }
      };
    } else if (fieldType === 'simpleselect') {
      return function (data, type, row) {
        if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-select"><span data-init="${
            row['d' + fieldName]
          }" data-field="${fieldName}" data-rowid="${
            row['s_0']
          }">${data}</span></div>`;
        } else {
          return data;
        }
      };
    } else if (fieldType === 'simpleselect2') {
      return function (data, type, row) {
        if (type === 'display' && fsess !== '') {
          return `<div class="didah-edit didah-edit-search"><span data-init="${
            row['d' + fieldName]
          }" data-field="${fieldName}" data-rowid="${
            row['s_0']
          }">${data}</span></div>`;
        } else {
          return data;
        }
      };
    } else if (fieldType === 'links') {
      return function (data, type, row) {
        if (type === 'display' && row['dlink' + fieldName]) {
          const onClickStr = `Didah.Common.openDidahBox('/management.php?ac=${
            row['d' + fieldName]
          }${row['dlink' + fieldName]}', 'iframe', ''); return false;`;
          return `<div><a onclick="${onClickStr}">${data}</a></div>`;
        } else {
          return `<div>${data}</div>`;
        }
      };
    } else if (fieldType === 'signature') {
      return function (data, type, row) {
        if (type === 'sort') {
          return '';
        } else if (type === 'filter') {
          return '';
        } else if (type === 'display') {
          if (data != '' && row['sig' + fieldName]) {
            try {
              const signData = row['sig' + fieldName];
              const onClickStr = `Didah.Pages.bBd726.signaturesWidgetModal({fsess: '${signData}', docNr: '${row['s_0']}'})`;
              const i = JSON.parse(data).length;
              const signs =
                '<i class="fa fa-check-square-o" aria-hidden="true"></i>'.repeat(
                  Math.min(i, 3),
                );
              return `<a href="#" onclick="${onClickStr}">${signs}</a>`;
            } catch (e) {
              return '';
            }
          } else {
            return '';
          }
        } else {
          if (data != '') {
            try {
              const i = JSON.parse(data).length;
              return '<i class="fa fa-check-square-o" aria-hidden="true"></i>'.repeat(
                Math.min(i, 3),
              );
            } catch (e) {
              return '';
            }
          } else {
            return '';
          }
        }
      };
    } else if (fieldType === 'confirmationList') {
      return function (data, type, row) {
        if (type === 'sort') {
          return '';
        } else if (type === 'filter') {
          return '';
        } else if (type === 'display') {
          return `<div class="didah-edit didah-edit-confirmation-list"><span data-init="" data-field="${fieldName}" data-rowid="${row['s_0']}">${data}</span></div>`;
        } else {
          return data;
        }
      };
    } else if (
      fieldType === 'file_library' ||
      fieldType === 'file' ||
      fieldType === 'media_viewer'
    ) {
      return function (data, type, row, meta) {
        if (type === 'sort') {
          if (data != '') {
            return data.split(' ').length;
          } else {
            return '';
          }
        } else if (type === 'filter') {
          return '';
        } else {
          return mediaField({
            data,
            fieldName,
            fieldType,
            meta,
            row,
          });
        }
      };
    } else if (fieldType === '_00135') {
      return function (data, type) {
        if (type === 'sort') {
          return data;
        } else if (type === 'filter') {
          return '';
        } else {
          if (data != '') {
            const count = data.split(' ').filter((nn) => nn != '').length;
            if (!count) {
              return '';
            }

            const affix = count > 1 ? ` (${count})` : '';

            return `<a href="#"><i class="fa fa-file-o fa-fw" aria-hidden="true"></i>${affix}</a>`;
          } else {
            return '';
          }
        }
      };
    } else if (fieldType === 's_0') {
      return function (data, type, row) {
        let data_str = '';
        let second_data_str = '';
        if (type === 'display') {
          if (row['_02777'] && row['_02777'] != '') {
            $.each(row['_02777'].split(','), function (nr, img) {
              data_str += img;
            });
          }
          if (row['_02760'] && row['_02760'] != '0') {
            if (doSendform266) {
              //todo check with why where was a space after q=bd_05007
              const onClickStr = `Didah.Common.openDidahBox('/management.php?a=266&sn=&q=bd_05007 ${row['s_0']}&button_off=Back', 'iframe' , ''); return false;`;
              data_str += `<a href="#" onclick="${onClickStr}"><i class="fa fa-cloud-upload" aria-hidden="true" style="padding-left:2px; padding-right:2px;"></i></a>`;
            } else {
              data_str +=
                '<i class="fa fa-cloud-upload" aria-hidden="true" style="padding-left:2px; padding-right:2px;"></i>';
            }
          }
          if (docOk) {
            let onClickStr = '';
            if (
              row['_00396'] &&
              row['_00396'] != '0' /* && row['_00396'] != menuId*/
            ) {
              onClickStr = `Didah.Common.openDidahBox('/management.php?a=${row['_00396']}&sn=2&ac=${row['s_0']}&ah=${row['_02717']}&button_off=Back&snp=3&tmp=2', 'iframe', ''); return false;`;
            } else {
              onClickStr = `Didah.Common.openDidahBox('/management.php?a=${menuId}&sn=2&ac=${row['s_0']}&ah=${docOk}&button_off=Back&snp=3&tmp=3', 'iframe', ''); return false;`;
              //     const onClickStr = `didah.s.value='Print_doc'; didah.ac.value='${data}'; didah.ah.value='${docOk}'; didah.submit();`
              //     second_data_str = `<a href="#" onclick="${onClickStr}"><i class="fa fa-file-text-o fa-fw" aria-hidden="true" style="margin-left: 4px;"/></a>`
            }
            second_data_str = `<a href="#" onclick="${onClickStr}"><i class="fa fa-file-text-o fa-fw" aria-hidden="true" style="margin-left: 4px;"/></a>`;
          }
          const onClickStr = `Didah.Pages.bBd726.newGo({data: '${data}', id: '${id}', a: '${a}'})`;
          return (
            data_str +
            `<a href="#" onclick="${onClickStr}">${data}</a>` +
            second_data_str
          );
        } else {
          return data;
        }
      };
    } else {
      return function (data, type) {
        if (type === 'display') {
          const optionalTitle = getIsEllipsis()
            ? ''
            : ` title="${extractHtmlText(data)}"`;
          return `<div class="long-data"${optionalTitle}>${data}</div>`;
        } else {
          return data;
        }
      };
    }
  };
}

export { initRender };
