import $ from 'jquery';
import { saveAs } from 'file-saver';

import {
  displayError,
  removeLoadingOverlay,
  s2ab,
  setLoadingOverlay,
} from '@/utils';

import '@/common/plugins/jquery.ajaxTransport.ts';

export default async function exportToExcel(urlJSON: string) {
  const XLSX = window.XLSX;

  if (!XLSX) {
    displayError('No XLSX found!');
    return;
  }

  setLoadingOverlay();
  $.ajax({
    cache: false,
    dataType: 'json',
    type: 'GET',
    url: urlJSON,
  })
    .done(function (resp) {
      if (resp.data) {
        const o: { cellDates?: [] } = {};
        const ws = {};
        let cell = null;
        const range = { e: { c: 0, r: resp.data.length }, s: { c: 0, r: 0 } };
        const hdr = [];
        let C = 0;
        let R = 0;

        // eslint-disable-next-line no-inner-declarations
        function processData(data: Dictionary[]) {
          data.forEach(function (JS) {
            Object.keys(JS)
              .filter(function (x) {
                return Object.prototype.hasOwnProperty.call(JS, x);
              })
              .forEach(function (k) {
                if ((C = hdr.indexOf(k)) == -1) hdr[(C = hdr.length)] = k;
                const v = JS[k];
                let t = 'z';
                let z = '';
                if (typeof v == 'number') t = 'n';
                else if (typeof v == 'boolean') t = 'b';
                else if (typeof v == 'string') t = 's';
                else if (v instanceof Date) {
                  t = 'd';
                  if (!o.cellDates) {
                    t = 'n';
                  }
                  z = (XLSX.SSF as any)._table[14];
                }
                ws[XLSX.utils.encode_cell({ c: C, r: R + 1 })] = cell = {
                  t,
                  v,
                };
                if (z) cell.z = z;
              });
            R++;
          });
        }

        processData(resp.data);

        range.e.c = hdr.length - 1;
        if (resp.total) {
          processData(resp.total);
        }
        range.e.r = R;
        for (C = 0; C < hdr.length; ++C)
          ws[XLSX.utils.encode_col(C) + '1'] = { t: 's', v: hdr[C] };
        ws['!ref'] = XLSX.utils.encode_range(range);

        /* add to workbook */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, resp.title);

        /* write workbook (use type 'binary') */
        const wbout = XLSX.write(wb, { bookType: 'biff8', type: 'binary' });
        removeLoadingOverlay();
        saveAs(
          new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
          'sheetjs.xls',
        );
      }
    })
    .fail(function () {
      removeLoadingOverlay();
      alert('error');
    });
}
