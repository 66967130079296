import $ from 'jquery';

import AjaxSettings = JQuery.AjaxSettings;
import TextStatus = JQuery.Ajax.TextStatus;

$.ajaxTransport(
  '+binary',
  function (options: AjaxSettings, originalOptions, jqXHR) {
    // check for conditions and support for blob / arraybuffer response type
    if (
      (options.dataType && options.dataType == 'binary') ||
      (options.data &&
        (options.data instanceof ArrayBuffer || options.data instanceof Blob))
    ) {
      return {
        // create new XMLHttpRequest
        send: function (_, callback) {
          // setup all variables
          const xhr = new XMLHttpRequest();
          const url = options.url;
          const type = options.type;
          // blob or arraybuffer. Default is blob
          const dataType = (options as any).responseType || 'blob';
          const data = options.data || null;

          xhr.addEventListener('load', function () {
            const data = {};
            data[options.dataType] = xhr.response;
            // make callback and send data
            callback(
              xhr.status,
              xhr.statusText as TextStatus,
              data,
              xhr.getAllResponseHeaders()
            );
          });

          xhr.open(type, url, true);
          xhr.responseType = dataType;
          xhr.send(data as any);
        },
        abort: function () {
          jqXHR.abort();
        },
      };
    }
  }
);
