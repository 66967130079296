import kebabCase from 'lodash/kebabCase';

import {
  NotificationType,
  showNotification,
} from '@/common/widgets/notification';
import { translate } from '@/common/features/translations';

import { createCachedFunction } from '../general';
import confirmationModal, {
  ModalType,
} from '@/common/widgets/confirmation-modal';

interface ElementCoords {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

function getCoords(elem: Element): ElementCoords {
  const box = elem.getBoundingClientRect();
  return {
    bottom: box.bottom + pageYOffset,
    height: box.height,
    left: box.left + pageXOffset,
    right: box.right + pageXOffset,
    top: box.top + pageYOffset,
    width: box.width,
  };
}

enum MinMax {
  max = 'max',
  min = 'min',
}

type SetContainerHeightTillEndOfPageParams = {
  bottomMargin?: number | (() => number);
  container: HTMLElement;
  extraSpacePx?: number;
  minMax?: MinMax;
};

function setContainerHeightTillEndOfPage({
  bottomMargin = 0,
  container,
  extraSpacePx = 6,
  minMax,
}: SetContainerHeightTillEndOfPageParams) {
  const coords = getCoords(container);
  const _bottomMargin =
    typeof bottomMargin === 'function' ? bottomMargin() : bottomMargin;
  let prop = 'height';
  if (minMax === 'min' || minMax === 'max') {
    prop = `${minMax}Height`;
  }
  container.style[prop] = `calc(100vh - ${
    coords.top + _bottomMargin + extraSpacePx
  }px`;
}

function extractHtmlText(html: string): string {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const isHtml = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

function getScrollbarWidth(): number {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  // @ts-expect-error not standard
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

function hasOverflowX(element: HTMLElement) {
  return element.offsetWidth < element.scrollWidth;
}

function hasOverflowY(element: HTMLElement) {
  return element.offsetHeight < element.scrollHeight;
}

function hasOverflow(element: HTMLElement) {
  return hasOverflowX(element) || hasOverflowY(element);
}

function getPageScroll() {
  const scrollLeft =
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (
          (document.documentElement ||
            document.body.parentNode ||
            document.body) as HTMLElement
        ).scrollLeft;
  const scrollTop =
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (
          (document.documentElement ||
            document.body.parentNode ||
            document.body) as HTMLElement
        ).scrollTop;
  return {
    scrollLeft,
    scrollTop,
  };
}

// todo check if this is correct
function hasWindowScroll() {
  return {
    x: document.body.offsetWidth > window.outerWidth,
    y: document.body.offsetHeight > window.outerHeight,
  };
}

function isWindowScrollable() {
  return window.innerHeight < document.body.clientHeight;
}

function isPortrait() {
  try {
    return window.screen.orientation.type.includes('portrait');
  } catch (e) {
    // for safari iOS
    return window.innerHeight > window.innerWidth;
  }
}

function styleToString(styleObj: { [key: string]: string }) {
  return Object.entries(styleObj)
    .map(([key, value]) => `${kebabCase(key)}: ${value}`)
    .join('; ');
}

function getStyleRuleValue(style, selector, sheet?: StyleSheet) {
  const sheets = (sheet != null
    ? [sheet]
    : document.styleSheets) as unknown as Array<{ cssRules: CSSStyleRule[] }>;
  for (let i = 0, l = sheets.length; i < l; i++) {
    const sheet = sheets[i];
    if (!sheet.cssRules) {
      continue;
    }
    for (let j = 0, k = sheet.cssRules.length; j < k; j++) {
      const rule = sheet.cssRules[j] as CSSStyleRule;
      if (
        rule.selectorText &&
        rule.selectorText.split(',').indexOf(selector) !== -1
      ) {
        return rule.style[style];
      }
    }
  }
  return null;
}

function getStyleSheet(unique_title) {
  for (let i = 0; i < document.styleSheets.length; i++) {
    const sheet = document.styleSheets[i];
    if (sheet.href && sheet.href.includes(unique_title)) {
      return sheet;
    }
  }
}

function displayError(
  msg: string = translate('common.error'),
  type: NotificationType = NotificationType.Danger,
  title: string = translate('common.error'),
) {
  return showNotification({
    text: msg,
    title,
    type,
  });
}

function displayErrorWithConfirmation(msg: string = translate('common.error')) {
  return confirmationModal({
    cancelStr: null,
    confirmStr: translate('common.ok'),
    text: msg,
    title: translate('common.error'),
    type: ModalType.Danger,
  });
}

function getChildren(node) {
  if (node.nodes === undefined) {
    return [];
  }
  let childrenNodes = node.nodes;
  node.nodes.forEach(function (n) {
    childrenNodes = childrenNodes.concat(getChildren(n));
  });
  return childrenNodes;
}

function displayImageViaConsole(url: string, size = 50) {
  const image = new Image();
  image.onload = function () {
    const style = [
      'font-size: 1px;',
      'padding: ' +
        (image.height / 100) * size +
        'px ' +
        (image.width / 100) * size +
        'px;',
      'background: url(' + url + ') no-repeat;',
      'background-size: contain;',
    ].join(' ');
    // eslint-disable-next-line no-console
    console.log('%c ', style);
  };
  image.src = url;
}

function getQueryParameters(str) {
  return (str || document.location.search)
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      function (n) {
        return (n = n.split('=')), (this[n[0]] = n[1]), this;
      }.bind({}),
    )[0];
}

function isEllipsisActive(e: HTMLElement) {
  return e.offsetWidth < e.scrollWidth;
}

const isSmallSize = createCachedFunction(
  () => document.body.getAttribute('size') === 'small',
);
const isPrintMode = createCachedFunction(
  () => document.body.getAttribute('mode') === 'print',
);

function isTextHTML(text) {
  return /<[a-z][\s\S]*>/i.test(text);
}

// returns true if the element or one of its parents has the class classname
function hasSomeParentWithClass(element: Element, classname: string) {
  // baseVal is for SVGs. their className is an object and not string
  const className = (element.className as any)?.baseVal || element.className;
  if (className?.split?.(' ').includes(classname)) return true;
  return (
    element.parentNode &&
    hasSomeParentWithClass(element.parentNode as Element, classname)
  );
}

export {
  ElementCoords,
  MinMax,
  displayError,
  displayErrorWithConfirmation,
  displayImageViaConsole,
  escapeRegExp,
  extractHtmlText,
  getChildren,
  getCoords,
  getPageScroll,
  getQueryParameters,
  getScrollbarWidth,
  getStyleRuleValue,
  getStyleSheet,
  hasOverflow,
  hasOverflowX,
  hasOverflowY,
  hasSomeParentWithClass,
  hasWindowScroll,
  isEllipsisActive,
  isHtml,
  isPortrait,
  isPrintMode,
  isSmallSize,
  isTextHTML,
  isWindowScrollable,
  setContainerHeightTillEndOfPage,
  styleToString,
};
