import $ from 'jquery';

import { makeMovable } from '../fluid-ui';

function renderModal(selector, html, opts?: { move: boolean }) {
  const { move } = opts || {};
  const parent = 'body';
  let $this = $(parent).find(selector);

  if (!$this.length) {
    const selectorArr = selector.split('.');
    const $wrapper = $(
      `<div class="modal fade ${selectorArr[selectorArr.length - 1]}"></div>`
    ).append(html);
    $this = $wrapper.appendTo(parent);
    $this.modal();
  } else {
    $this.html(html).modal('show');
  }

  if (move) {
    makeModalWindowMovable($this);
  }
}

function makeModalWindowMovable($modal: JQuery) {
  const $dialog = $modal.find('.modal-dialog' as any);
  const $handle = $modal.find('.modal-header');
  makeMovable({
    element: $dialog.get(0),
    handle: $handle.get(0),
    ignoreHandleChildren: true,
    onMoveEnd: () => {
      $handle.removeClass('modal-header-moving');
    },
    onMoveStart: () => {
      $handle.addClass('modal-header-moving');
      setTimeout(() => $dialog.addClass('modal-movable'), 0);
    },
  });
}

function makeModalWindow(
  title?: string,
  body?:
    | JQuery.htmlString
    | JQuery.TypeOrArray<JQuery.Node | JQuery<JQuery.Node>>,
  close_str?: string,
  save_str?: string,
  opts: { closeSign?: string; move?: boolean; preserve?: boolean } = {}
) {
  const closeSign = opts.closeSign || '&times;';

  const $modal = $(
    `<div class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"></span>
                        </button>
                        <h4 class="modal-title"/>
                    </div>
                    <div class="modal-body"/>
                    <div class="modal-footer">
                        <button type="button" class="m-close btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" class="m-save btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>`
  );
  if (title) {
    $modal.find('.modal-title' as any).append(title);
    $modal.find('.modal-header button span' as any).html(closeSign);
  } else {
    $modal.find('.modal-header' as any).remove();
  }

  const $modalBody = $modal.find('.modal-body' as any);
  if (body) {
    $modalBody.append(body);
  } else {
    $modalBody.remove();
  }

  if (title && !body) {
    $modal.find('.modal-header').addClass('tw-border-0');
    $modal.find('.modal-footer').addClass('tw-border-0');
  }

  const $btnSave = $modal.find('button.m-save' as any);
  if (save_str) {
    $btnSave.html(save_str);
  } else {
    $btnSave.remove();
  }

  const $btnClose = $modal.find('button.m-close' as any);
  if (close_str) {
    $btnClose.html(close_str);
  } else {
    $btnClose.remove();
  }

  !save_str && !close_str && $modal.find('.modal-footer' as any).remove();

  if (!opts.preserve) {
    $modal.on('hidden.bs.modal', function () {
      $(this).remove();
    });
  }
  const $sbContainer = $('#sb-container');
  if ($sbContainer.length) {
    $sbContainer.before($modal);
  } else {
    $modal.appendTo('body');
  }

  if (opts.move) {
    makeModalWindowMovable($modal);
  }
  return $modal;
}

function makeCenteredModalWindow(
  title?: string,
  body?:
    | JQuery.htmlString
    | JQuery.TypeOrArray<JQuery.Node | JQuery<JQuery.Node>>,
  close_str?: string,
  save_str?: string,
  opts: { closeSign?: string; hideOnClick?: boolean } = {}
) {
  const hideOnClick = opts.hideOnClick ?? true;
  const $modal = makeModalWindow(title, body, close_str, save_str, opts);
  const $modalDialog = $modal.find('.modal-dialog' as any);
  $modalDialog
    .addClass('vertical-align-center')
    .wrap('<div class="vertical-alignment-helper"/>');
  if (hideOnClick) {
    $modal
      .find('.vertical-alignment-helper' as any)
      .on('click', () => $modal.modal('hide'));
  }

  $modalDialog.css('transform', 'translateY(-60px)');
  return $modal;
}

export {
  makeCenteredModalWindow,
  makeModalWindow,
  makeModalWindowMovable,
  renderModal,
};
