import { useEffect, useImperativeHandle, useRef } from 'react';

import { useMediaDialogContext } from '../../context';

import { DrawingControls } from './DrawingControls';
import { useCanvasDrawing } from './use-canvas-drawing';
import { usePreventTouch } from '@/react-app/shared/hooks';

function DrawingCanvas() {
  const { drawing, drawingCanvasRef: ref, image } = useMediaDialogContext();
  const { tool } = drawing;
  const { data: canvasData } = image;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  usePreventTouch({ target: canvasRef });

  const { mouseDown, mouseUp, onMouseMove, onTouchMove } = useCanvasDrawing({
    canvasRef,
    tool,
  });

  useImperativeHandle(
    ref,
    () => ({
      exportBlob() {
        return new Promise((resolve) => {
          canvasRef.current?.toBlob((blob) => resolve(blob), 'image/png');
        });
      },
    }),
    []
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container || !canvasData) return;

    canvas.width = canvasData.sizes.width;
    canvas.height = canvasData.sizes.height;

    const ctx = canvas.getContext('2d');
    ctx?.drawImage(
      canvasData.canvas,
      0,
      0,
      canvasData.sizes.width,
      canvasData.sizes.height
    );

    container.scrollTop = canvasData.containerScroll.scrollY;
    container.scrollLeft = canvasData.containerScroll.scrollX;
  }, [canvasData]);

  if (!canvasData) {
    return null;
  }

  return (
    <>
      <DrawingControls />
      <div
        ref={containerRef}
        className="tw-relative tw-max-h-[65vh] tw-max-w-full tw-overflow-auto tw-rounded-lg tw-shadow-none tw-shadow-black/5 md:tw-max-h-[80vh]"
      >
        <canvas
          ref={canvasRef}
          onMouseDown={mouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={mouseUp}
          onTouchEnd={mouseUp}
          onTouchMove={onTouchMove}
          onTouchStart={mouseDown}
        />
      </div>
    </>
  );
}

export { DrawingCanvas };
