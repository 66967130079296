import { cn } from '@/utils';
import type { BarGraphBar } from './types';

type Props = {
  bars: BarGraphBar[];
  className?: string;
  onMouseEnter?: (id: string | number) => void;
  onMouseLeave?: (id: string | number) => void;
};

function BarGraph({ bars, className, onMouseEnter, onMouseLeave }: Props) {
  return (
    <div
      className={cn(
        'tw-flex tw-h-[var(--form-controls-height)] tw-gap-0.5',
        className,
      )}
    >
      {bars.map((bar) => (
        <div
          key={bar.id}
          className="tw-relative tw-w-4 tw-flex-1 tw-cursor-pointer"
          title={bar.label}
          onMouseEnter={() => onMouseEnter?.(bar.id)}
          onMouseLeave={() => onMouseLeave?.(bar.id)}
        >
          <div className="tw-absolute tw-inset-0 tw-bg-[#337ab7] tw-opacity-[0.1]" />
          <div
            className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-[#337ab7]"
            style={{ top: `${100 - bar.percentage}%` }}
          />
        </div>
      ))}
    </div>
  );
}

export { BarGraph };
