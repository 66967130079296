import type { ResizeDelta } from '@/utils';
import type {
  ComponentProps,
  ElementType,
  PropsWithChildren,
  ReactNode,
} from 'react';

enum ModalSizes {
  Large = 'large',
  Normal = 'normal',
  Small = 'small',
}

enum ModalTypes {
  Blue = 'blue',
  Default = 'default',
  LightBlue = 'light-blue',
}

type CommonDialogProps<E extends ElementType = ElementType> =
  PropsWithChildren<{
    as?: E;
    asProps?: ComponentProps<E>;
    classNames?: {
      body?: string;
      container?: string;
      dialog?: string;
      footer?: string;
      header?: string;
      headerWrapper?: string;
    };
    footer?: ReactNode;
    header?: ReactNode;
    isShown?: boolean;
    onClose: VoidFunction;
  }>;

type MobileDialogProps = CommonDialogProps;

type DesktopDialogProps = CommonDialogProps & {
  className?: string;
  closeOnEsc?: boolean;
  flexBody?: boolean;
  isCloseShown?: boolean;
  isPortal?: boolean;
  movable?: boolean;
  onResize?: (params: ResizeDelta) => void;
  resizable?: boolean;
  size?: ModalSizes;
  type?: ModalTypes;
};

export type { DesktopDialogProps, MobileDialogProps };

export { ModalSizes, ModalTypes };
