import moment from 'moment';
import { dt_number_format } from '@/utils';
import { datatableFormats } from '../features/datatables';

/**
 * Externally trigger the display of DataTables' "processing" indicator.
 *
 * Please note that of DataTables 2.0.0 this functionality is now built into
 * DataTables core and this plug-in is no longer required.
 *
 *  @name processing()
 *  @summary Show / hide the processing indicator via the API
 *  @author [Allan Jardine](http://datatables.net)
 *  @requires DataTables 1.10+
 *  @param {boolean} show `true` to show the processing indicator, `false` to
 *    hide it.
 *
 * @returns {DataTables.Api} Unmodified API instance
 *
 *  @example
 *    // Show a processing indicator for two seconds on initialisation
 *    var table = $('#example').DataTable( {
 *      processing: true
 *    } );
 *
 *    table.processing( true );
 *
 *    setTimeout( function () {
 *      table.processing( false );
 *    }, 2000 );
 */
$.fn.dataTable.render.didah_sn8 = function (
  fieldType,
  deci,
  fieldName,
  fieldNameExt,
) {
  switch (fieldType) {
    default:
      return function (data, type, row) {
        return String(data);
      };
    case 'decimal':
      return function (data, type, row) {
        if (type === 'display' || type === 'print' || type === 'filter') {
          return dt_number_format(data, deci);
        } else {
          return data;
        }
      };
    case 'date':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0
            ? ''
            : moment.unix(data).utc().format(datatableFormats.date);
        } else {
          return data;
        }
      };
    case 'datetime':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0
            ? ''
            : moment.unix(data).utc().format(datatableFormats.datetime);
        } else {
          return data;
        }
      };
    case 'enum':
      return function (data, type, row) {
        if (type === 'filter') {
          return '';
        } else if (type === 'display') {
          if (row[fieldNameExt] !== '')
            return (
              '<input class="didah-edit-checkbox" type="checkbox" name="' +
              fieldName +
              '" data-field="' +
              fieldName +
              '" data-rowid="' +
              row[fieldNameExt] +
              '" data-id="' +
              fieldNameExt +
              '" value=""' +
              (data != '1' ? '' : ' checked') +
              '>'
            );
          else
            return (
              '<input class="didah-edit-checkbox" type="checkbox" name="' +
              fieldName +
              '" data-field="' +
              fieldName +
              '" data-rowid="' +
              row[fieldNameExt] +
              '" data-id="' +
              fieldNameExt +
              '"  value=""' +
              (data != '1' ? '' : ' checked') +
              ' disabled>'
            );
        } else if (type === 'print' || type === 'export') {
          return data != '1' ? '' : '*';
        } else {
          return data != '1' ? '' : '1';
        }
      };
    case 'datetime28':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-datetime-datapicker"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            (data === 0
              ? ''
              : moment(data * 1000)
                  .utc()
                  .format('DD.MM.YYYY HH:mm')) +
            '</span></div>'
          );
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('DD.MM.YYYY HH:mm');
        } else {
          return data;
        }
      };
    case 'date28':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-date-datapicker"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            (data === 0
              ? ''
              : moment(data * 1000)
                  .utc()
                  .format('DD.MM.YYYY')) +
            '</span></div>'
          );
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return data === 0 ? '' : moment.unix(data).utc().format('DD.MM.YYYY');
        } else {
          return data;
        }
      };
    case 'varchar28':
      return function (data, type, row) {
        if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-input"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            data +
            '</span></div>'
          );
        } else {
          return String(data);
        }
      };
    case 'decimal28':
      return function (data, type, row) {
        if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-decimal"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            dt_number_format(data, deci) +
            '</span></div>'
          );
        } else if (
          type === 'display' ||
          type === 'print' ||
          type === 'filter'
        ) {
          return dt_number_format(data, deci);
        } else {
          return data;
        }
      };
    case 'select28':
      return function (data, type, row) {
        if (type === 'display') {
          return `<div class="didah-edit didah-edit-select"><span data-init="${
            row['d' + fieldName]
          }" data-field="${fieldName}" data-rowid="${
            row[fieldNameExt]
          }">${data}</span></div>`;
        } else {
          return data;
        }
      };
    case 'selectAjax28':
      return function (data, type, row) {
        if (type === 'display') {
          return `<div class="didah-edit didah-edit-search"><span data-init="${
            row['d' + fieldName]
          }" data-field="${fieldName}" data-rowid="${
            row[fieldNameExt]
          }">${data}</span></div>`;
        } else {
          return data;
        }
      };
    case 'confirmationList': // TODO correct renderer attrs
      return function (data, type, row) {
        if (type === 'display') {
          return `<div class="didah-edit didah-edit-confirmation-list"><span data-init="${
            row['d' + fieldName]
          }" data-field="${fieldName}" data-rowid="${
            row[fieldNameExt]
          }">${data}</span></div>`;
        } else {
          return data;
        }
      };
  }
};

$.fn.dataTable.render.didah_sn28 = function (
  fieldType,
  deci,
  fieldName,
  fieldNameExt,
) {
  switch (fieldType) {
    default:
      return function (data, type, row) {
        return data;
      };
    case 'decimal':
      return function (data, type, row) {
        if (type === 'display' || type === 'print') {
          return dt_number_format(data, deci);
        } else {
          return data;
        }
      };
    case 'date':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' || type === 'print') {
          return data === 0 ? '' : moment.unix(data).utc().format('DD.MM.YYYY');
        } else {
          return data;
        }
      };
    case 'datetime':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' || type === 'print') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('DD.MM.YYYY HH:mm');
        } else {
          return data;
        }
      };
    case 'enum':
      return function (data, type, row) {
        if (type === 'filter') {
          return '';
        } else if (type === 'display') {
          if (row[fieldNameExt] !== '')
            return (
              '<input class="didah-edit-checkbox" type="checkbox" name="' +
              fieldName +
              '" data-field="' +
              fieldName +
              '" data-rowid="' +
              row[fieldNameExt] +
              '" data-id="' +
              fieldNameExt +
              '" value=""' +
              (data != '1' ? '' : ' checked') +
              '>'
            );
          else
            return (
              '<input class="didah-edit-checkbox" type="checkbox" name="' +
              fieldName +
              '" data-field="' +
              fieldName +
              '" data-rowid="' +
              row[fieldNameExt] +
              '" data-id="' +
              fieldNameExt +
              '"  value=""' +
              (data != '1' ? '' : ' checked') +
              ' disabled>'
            );
        } else if (type === 'print' || type === 'export') {
          return data != '1' ? '' : '*';
        } else {
          return data != '1' ? '' : '1';
        }
      };
    case 'date28':
      return function (data, type, row) {
        if (type === 'sort') {
          return data;
        } else if (type === 'export') {
          return data === 0
            ? ''
            : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
        } else if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-date-datapicker"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            (data === 0
              ? ''
              : moment(data * 1000)
                  .utc()
                  .format('DD.MM.YYYY')) +
            '</span></div>'
          );
        } else if (type === 'display' || type === 'print') {
          return data === 0 ? '' : moment.unix(data).utc().format('DD.MM.YYYY');
        } else {
          return data;
        }
      };
    case 'varchar28':
      return function (data, type, row) {
        if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-input"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            data +
            '</span></div>'
          );
        } else {
          return data;
        }
      };
    case 'confirmation-list':
      return function (data, type, row) {
        if (type === 'display' && row[fieldNameExt] !== '') {
          return (
            '<div class="didah-edit didah-edit-confirmation-list"><span data-field="' +
            fieldName +
            '" data-rowid="' +
            row[fieldNameExt] +
            '">' +
            data +
            '</span></div>'
          );
        } else {
          return data;
        }
      };
  }
};
