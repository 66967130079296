import { cn } from '@/utils';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ className?: string }>;

function OverlayControls({ children, className }: Props) {
  return (
    <div
      className={cn(
        'tw-flex tw-justify-center tw-gap-1 tw-bg-slate-400 tw-bg-opacity-10 tw-px-4 tw-py-2 md:tw-gap-3',
        className
      )}
    >
      {children}
    </div>
  );
}

export { OverlayControls };
