import { checkMobile, isTextHTML } from '@/utils';

function getLabelOnlyForMobile(label: string) {
  return checkMobile()
    ? `<div class="didah-lang-single-col-label">${label}</div>`
    : '';
}

function renderColumn(key: string, value: string, attrs?: string) {
  return `<div class="didah-lang-single-col" ${attrs}>${getLabelOnlyForMobile(
    key
  )}<div class="didah-lang-single-col-value">${value}</div></div>`;
}

function renderLangColumn(lang_values, lang) {
  const lang_value = lang_values[lang] || '';
  const tooltipAttrs =
    lang_value && !isTextHTML(lang_value)
      ? `data-toggle="tooltip" title="${lang_value}"`
      : '';
  return renderColumn(lang, lang_value, tooltipAttrs);
}

function renderHeaderColumn(lang) {
  return `<div class="didah-lang-single-col"><div class="didah-lang-single-col-header-label">${lang}</div></div>`;
}

export { renderColumn, renderHeaderColumn, renderLangColumn };
