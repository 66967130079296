import $ from 'jquery';
import { blinkingTitleManager, glow } from '@/utils';
import { showNotification } from '@/common';
import { NotificationType } from '@/common/widgets/notification';
import { translate } from '@/common/features/translations';

type CreateRefreshIncomingDataParams = {
  fetchParams: Dictionary;
};

type IncomingDataResponse = {
  messagesCount?: number;
  messagesImportant?: boolean;
  remindersCount?: number;
  remindersImportant?: boolean;
};

function processImportant({
  messagesImportant,
  remindersImportant,
}: Pick<IncomingDataResponse, 'messagesImportant' | 'remindersImportant'>) {
  if (messagesImportant) {
    showNotification({
      displayTime: 7000,
      title: translate('messages.alerts.important_messages_toast'),
      type: NotificationType.Warning,
    });
  }

  if (remindersImportant) {
    showNotification({
      displayTime: 7000,
      title: translate('messages.alerts.important_reminders_toast'),
      type: NotificationType.Warning,
    });
  }
}

function handleTitle({
  messagesCount,
  remindersCount,
}: Pick<IncomingDataResponse, 'messagesCount' | 'remindersCount'>) {
  const alerts: string[] = [];
  if (messagesCount) {
    alerts.push(translate('messages.alerts.new_messages', messagesCount + ''));
  }
  if (remindersCount) {
    alerts.push(
      translate('messages.alerts.new_reminders', remindersCount + ''),
    );
  }

  if (!alerts.length) {
    blinkingTitleManager.stop();
    return;
  }

  blinkingTitleManager.start(alerts);
}

function handleBadge({
  $button,
  count,
}: {
  $button: JQuery<HTMLElement>;
  count: number;
}) {
  const getBadge = (count: number) =>
    `
        <span class="tw-ml-1 tw-align-baseline fsxs badge">${count}</span>`.trim();

  const $badge = $button.find('span.badge');
  if (!count) {
    $badge.remove();
  } else if ($badge.length) {
    $badge.text(count);
  } else {
    $button.append(getBadge(count));
  }
}

function handlePopup({
  isMessagesChanged,
  isRemindersChanged,
}: {
  isMessagesChanged: boolean;
  isRemindersChanged: boolean;
}) {
  if (isMessagesChanged || isRemindersChanged) {
    const $refreshDataContainer = $('#refresh-data-container');
    let content = '';
    if (isMessagesChanged && isRemindersChanged) {
      content = translate('messages.alerts.popup_content_have_many');
    } else if (isMessagesChanged) {
      content = translate('messages.alerts.popup_content_have_messages');
    } else if (isRemindersChanged) {
      content = translate('messages.alerts.popup_content_have_reminders');
    }

    $refreshDataContainer.data('content', content);
    $refreshDataContainer.popover('show');

    setTimeout(function () {
      $refreshDataContainer.popover('hide');
    }, 3000);

    if (isMessagesChanged) {
      glow($('.message-btn').get(0), 2000);
    }

    if (isRemindersChanged) {
      glow($('.task-btn').get(0), 2000);
    }
  }
}

let prevMessagesCount = 0;
let prevRemindersCount = 0;

function processCount(
  {
    messagesCount,
    remindersCount,
  }: Pick<IncomingDataResponse, 'messagesCount' | 'remindersCount'>,
  isTitleChangeEnabled?: boolean,
) {
  const isMessagesChanged = messagesCount != prevMessagesCount;
  const isRemindersChanged = remindersCount != prevRemindersCount;

  prevMessagesCount = messagesCount;
  prevRemindersCount = remindersCount;

  if (isTitleChangeEnabled) {
    handleTitle({
      messagesCount,
      remindersCount,
    });
  }

  handleBadge({
    $button: $('.message-btn'),
    count: messagesCount,
  });

  handleBadge({
    $button: $('.task-btn'),
    count: remindersCount,
  });

  handlePopup({
    isMessagesChanged,
    isRemindersChanged,
  });
}

const createRefreshIncomingData =
  (
    { fetchParams }: CreateRefreshIncomingDataParams,
    isTitleChangeEnabled?: boolean,
  ) =>
  () => {
    $.ajax({
      async: true,
      cache: false,
      data: fetchParams,
      dataType: 'json',
      success(result: IncomingDataResponse, textStatus, XMLHttpRequest) {
        if (XMLHttpRequest.status !== 200) return;

        const {
          messagesCount = 0,
          messagesImportant = false,
          remindersCount = 0,
          remindersImportant = false,
        } = result;

        processImportant({
          messagesImportant,
          remindersImportant,
        });

        processCount(
          {
            messagesCount,
            remindersCount,
          },
          isTitleChangeEnabled,
        );
      },
      timeout: 15000,
      type: 'GET',
      url: '/api/v2/services/alert/count',
    });
  };

export type { CreateRefreshIncomingDataParams };

export { createRefreshIncomingData };
