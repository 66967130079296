import noop from 'lodash/noop';
import { createContext } from 'react';
import type { useStandaloneModalWindow } from './use-standalone-modal-window';

const DialogContext = createContext<
  ReturnType<typeof useStandaloneModalWindow>
>({
  closeWindow: () => Promise.resolve(),
  hide: noop,
  isClosing: false,
  isHiding: false,
  isShown: false,
  show: noop,
});

export { DialogContext };
