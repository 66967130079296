import { inIframe } from '@/utils';
import $ from 'jquery';

function addIFrameClass() {
  if (!inIframe()) {
    return;
  }

  $(document.body).addClass('inside-iframe !tw-static');
}

export { addIFrameClass };
