import type { PropsWithChildren } from 'react';

import { FixedPositionedControls } from './FixedPositionedControls';
import { OverlayControls } from '../../../shared';

type Props = PropsWithChildren;

function TopControls({ children }: Props) {
  return (
    <FixedPositionedControls className="tw-top-0">
      <OverlayControls className="tw-mx-auto tw-rounded-b-lg">
        {children}
      </OverlayControls>
    </FixedPositionedControls>
  );
}

export { TopControls };
