import $ from 'jquery';
import moment from 'moment';

function autoFormattable($input: JQuery<HTMLInputElement>, format) {
  const inputEl = $input.get(0);
  $input.on('keydown', (e) => {
    const value = $input.val() as string;
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl/cmd+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: Ctrl/cmd+C
      (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: Ctrl/cmd+X
      (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: Ctrl/cmd+V
      (e.keyCode === 86 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)) ||
      (value.length === format.length &&
        inputEl.selectionEnd - inputEl.selectionStart === 0)
    ) {
      e.preventDefault();
    } else {
      const currentCharNum = value.length;
      const formatCurrentChar = format[currentCharNum];
      if (formatCurrentChar && !formatCurrentChar.match(/[a-z]/i)) {
        $input.val(value + formatCurrentChar);
      }
    }
  });

  $input.on('input', (e) => {
    const currentVal = $input.val();
    const originalEvent = e.originalEvent as InputEvent;
    if (
      originalEvent.inputType === 'insertFromPaste' &&
      moment(currentVal, format).isValid()
    ) {
      $input.val(moment(currentVal, format).format(format));
    } else if (
      originalEvent.inputType === 'insertFromPaste' &&
      !moment(currentVal, format).isValid()
    ) {
      $input.val('');
    }
  });
}

export { autoFormattable };
