import $ from 'jquery';
import replace from 'lodash/replace';
import sum from 'lodash/sum';
import { makeModalWindow } from '@/utils';

function recalculateColumnSummary($table = $(document.body), precision) {
  const parseNumberText = (numberAsText) =>
    parseFloat(replace(numberAsText, ',', '.'));

  const $dynamicTdsInputs = $table.find('.calculate_value_2 input');
  const $dynamicSum = $table.find('.calculate_sum_2');

  $dynamicTdsInputs.on('input', function () {
    const $input = $(this);
    const num = parseNumberText($input.val());
    if (!isNaN(num)) {
      const nums = [];
      $dynamicTdsInputs.each(function () {
        const $i = $(this);
        const iNum = parseNumberText($i.val());
        if (!isNaN(iNum)) {
          nums.push(iNum);
        }
      });
      const numsSum = sum(nums).toFixed(precision);
      $dynamicSum.text(numsSum);
    }
  });
}

type Translations = {
  cancel?: string;
  label?: string;
  save?: string;
  title?: string;
};

function adjustColumnSummary(
  $table = $(document.body),
  precision,
  opts: { strings?: Translations } = {}
) {
  const parseNumberText = (numberAsText) =>
    parseFloat(replace(numberAsText, ',', '.'));

  const strings = opts.strings || {};
  const title = strings.title || 'Calculate new summary';
  const label = strings.label || 'New summary:';
  const cancel = strings.cancel || 'Cancel';
  const save = strings.save || 'Save';
  const $staticTds = $table.find('.calculate_value_1');
  const $staticSum = $table.find('.calculate_sum_1');
  const $dynamicSum = $table.find('.calculate_sum_2');
  const oldSum = parseNumberText($staticSum.text());

  function onSave(newSum) {
    const diffTimes = newSum / oldSum;
    let exactNewSum = 0;
    $staticTds.each(function () {
      const $sTd = $(this);
      const $dTd = $sTd.siblings('.calculate_value_2');
      const newVal = parseFloat(
        (parseNumberText($sTd.text()) * diffTimes).toFixed(precision)
      );
      $dTd.find('input').val(newVal);
      exactNewSum += newVal;
    });

    $dynamicSum.text(exactNewSum.toFixed(precision));
  }

  function makeModal() {
    const $modalContent = $(`
        <div class="form-group">
          <label for="new_summary">${label}</label>
          <input type="text" class="form-control" id="new_summary">
        </div>
    `);
    const $input = $modalContent.find('input');
    $input.val(oldSum.toFixed(precision));

    const $modal = makeModalWindow(title, $modalContent, cancel, save);
    $modal.find('.m-save').on('click', () => {
      const inputValue = parseNumberText($input.val());
      $modal.modal('hide');

      if (!isNaN(inputValue)) {
        onSave(inputValue);
      }
    });

    $modal.modal('show');
  }

  makeModal();
}

export { adjustColumnSummary, recalculateColumnSummary };
