const getParentNodes = (element: HTMLElement) => {
  let currentElement = element;
  const parents: HTMLElement[] = [];

  while (currentElement.tagName !== 'HTML') {
    const parent = currentElement.parentNode as HTMLElement;
    parents.push(parent);
    currentElement = parent;
  }

  return parents;
};

export { getParentNodes };
