import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { cn } from '@/utils';

import './style.scss';

type Props = PropsWithChildren<{
  className?: string;
  isShown?: boolean;
}>;

function ModalInner({ children, className, isShown }: Props) {
  const nodeRef = useRef<HTMLDivElement>();

  return (
    <CSSTransition
      appear={true}
      classNames="modal-wrapper"
      in={isShown}
      nodeRef={nodeRef}
      timeout={300}
    >
      <div
        ref={nodeRef}
        className={cn(
          'tw-fixed tw-inset-0 tw-z-[1050] tw-flex tw-items-center tw-justify-center tw-bg-black/50',
          className
        )}
      >
        {children}
      </div>
    </CSSTransition>
  );
}

export { ModalInner };
