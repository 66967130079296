function accordingReplacements(
  string: string,
  replaced: string[],
  replacedWith: string[],
) {
  let result = string;
  for (let i = 0; i < replaced.length; i++) {
    result = result.replaceAll(replaced[i], replacedWith[i]);
  }
  return result;
}

export { accordingReplacements };
