import $ from 'jquery';

import './style.scss';
import { styleToString } from '@/utils';

type ToggleOption = {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  style?: { [key: string]: string };
  value: string;
};

type Options = {
  $container: JQuery;
  $startBtn: JQuery;
  fastCycleDelay: number;
  height?: string;
  recalculateAjaxParams: Dictionary;
  slowCycleDelay: number;
  statusAjaxParams: Dictionary;
  timeout: number;
  toggles?: Array<ToggleOption>;
  url: string;
};

export default function setProgressTerminal({
  $container,
  $startBtn,
  fastCycleDelay = 3000,
  recalculateAjaxParams,
  slowCycleDelay = 10000,
  statusAjaxParams,
  timeout,
  toggles,
  url = '/ajax_api.php',
}: Options) {
  let buttonEnabled = false;
  let slowMode = false;
  let nextRequestFunc;
  let nextRequest;
  let nextRequestTimeoutId;

  function activateButton($terminal: JQuery) {
    $startBtn.removeClass('disabled');
    buttonEnabled = true;
    $startBtn.one('click', () => {
      $startBtn.addClass('disabled');
      buttonEnabled = false;

      sendRecalculateAjax($terminal);
      slowMode = false;

      clearTimeout(nextRequestTimeoutId);
      nextRequestFunc();

      nextRequestTimeoutId = undefined;
      nextRequestFunc = undefined;
      nextRequest = undefined;
    });
  }

  function setTitle(title) {
    $terminal.find('.terminal-header .terminal-header-text').text(title);
  }

  function startAjaxRequests($terminal) {
    const $spinner = $terminal.find('.fa.fa-spinner');
    const $dots = $terminal.find('.dots');

    function showLoadingVisualisation() {
      $spinner.show();
      $dots.show();
    }

    function hideLoadingVisualisation() {
      $spinner.hide();
      $dots.hide();
    }

    function clearTerminal() {
      $terminal.find('.terminal-line').remove();
    }

    function printMessage(data) {
      const $content = $terminal.find('.terminal-content');
      const content = $content.get(0);
      $dots.before(`<p class="terminal-line">${data}</p>`);
      content.scrollTop = content.scrollHeight;
    }

    function handleResponse(res, last_id, wasLastDone) {
      const resHasDone = res.done && res.done === 'done';

      function printNewMessages() {
        if (!(wasLastDone && resHasDone) && res.messages && res.messages.length)
          res.messages.forEach((message) => printMessage(message.message));
      }

      function setSpeedMode() {
        if (resHasDone) {
          slowMode = true;
        } else if (res.messages && res.messages.length) {
          slowMode = false;
        }
      }

      function manageVisualStuffAnimation() {
        if (resHasDone) {
          setTitle('Done');
          hideLoadingVisualisation();
        } else {
          setTitle('Connected');
          showLoadingVisualisation();
        }
      }

      function manageButton() {
        if (!buttonEnabled && resHasDone) {
          activateButton($terminal);
        } else if (buttonEnabled && !resHasDone) {
          $startBtn.off('click');
          $startBtn.addClass('disabled');
          buttonEnabled = false;
        }
      }

      function manageOldMessages() {
        if (wasLastDone && !resHasDone) clearTerminal();
      }

      function nextCycle() {
        const delay = () => (slowMode ? slowCycleDelay : fastCycleDelay);

        function getNewLastId() {
          return res.messages.map((m) => +m.id).pop();
        }

        function saveNextRequestFunc(id) {
          nextRequestFunc = () => sendMessagesAjax(id, resHasDone);
          nextRequest = () =>
            (nextRequestTimeoutId = setTimeout(nextRequestFunc, delay()));
        }

        if (!res.messages || !res.messages.length) {
          saveNextRequestFunc(last_id);
        } else if (res.messages) {
          saveNextRequestFunc(getNewLastId());
        } else alert('Incorrect response!');

        nextRequest();
      }

      manageVisualStuffAnimation();
      setSpeedMode();
      manageOldMessages();
      printNewMessages();
      manageButton();
      nextCycle();
    }

    function sendMessagesAjax(last_id = '0', wasLastDone = false) {
      if (last_id === '0') {
        setTitle('Connected');
      }
      const params = {
        ...statusAjaxParams,
        last_id,
      };
      $.ajax({
        cache: false,
        data: params,
        error: () => {
          setTimeout(
            () => sendMessagesAjax(last_id, wasLastDone),
            fastCycleDelay,
          );
        },
        success: (response, textStatus, XMLHttpRequest) => {
          if (XMLHttpRequest.status === 200)
            handleResponse(response, last_id, wasLastDone);
        },
        timeout,
        type: 'POST',
        url,
      });
    }

    sendMessagesAjax();
  }

  function getTogglesState($terminal) {
    const result = [];
    $terminal
      .find(
        '.progress-controls-option:not(.progress-controls-option-select-all) input',
      )
      .each(function () {
        const $checkbox: JQuery<HTMLInputElement> = $(this);
        const name = $checkbox.attr('name');
        const isChecked = $checkbox.is(':checked');
        if (isChecked) {
          result.push(name);
        }
      });

    return {
      command: result.join(','),
    };
  }

  function sendRecalculateAjax($terminal: JQuery) {
    const params = {
      ...recalculateAjaxParams,
      ...getTogglesState($terminal),
      recalculate: true,
    };
    $.ajax({
      cache: false,
      data: params,
      error: () => {
        setTitle('Error');
        activateButton($terminal);
      },
      success: () => {},
      timeout,
      type: 'POST',
      url,
    });
  }

  function createTerminalWindow() {
    const isToggles = toggles && toggles.length;
    const hasSelectAll = toggles && toggles.length > 1;

    const $controls = `
                <div class="progress-controls ${
                  !isToggles ? 'progress-controls-empty' : ''
                }">
                    ${
                      hasSelectAll
                        ? `
                         <div class="progress-controls-option progress-controls-option-select-all">
                            <input type="checkbox" ${
                              toggles.every((toggle) => toggle.checked)
                                ? 'checked'
                                : ''
                            }>
                            <label for="set">Select all</label>
                        </div>                      
                    `
                        : ''
                    }
                    ${
                      isToggles
                        ? toggles
                            .map((toggle) =>
                              `
                        <div 
                            class="${[
                              'progress-controls-option',
                              ...(toggle.className ? [toggle.className] : []),
                            ].join(' ')}"
                            ${
                              toggle.style
                                ? `style="${styleToString(toggle.style)}"`
                                : ''
                            }
                        >
                            <input 
                                type="checkbox" 
                                ${toggle.disabled ? 'disabled' : ''} 
                                name="${toggle.value}" 
                                ${toggle.checked ? 'checked' : ''}
                            >
                            <label for="set">${toggle.label}</label>
                        </div>                  
                    `.trim(),
                            )
                            .join('')
                        : ''
                    }
                </div>
            `.trim();

    const $terminal = $(
      `
                <div class="progress-terminal-widget">
                    <div class="progress-terminal ${
                      !isToggles ? 'progress-terminal-no-toggles' : ''
                    }">
                        <div class="terminal-header">
                            <span class="terminal-header-text">Connecting</span>
                            <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
                        </div>
                        <div class="terminal-content">
                            <p class="dots">
                                <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                            </p>
                        </div>
                    </div>
                    ${$controls}
                </div>`.trim(),
    );

    if (hasSelectAll) {
      $terminal.on(
        'change',
        '.progress-controls-option-select-all input',
        function () {
          const isChecked = this.checked;
          $terminal
            .find(
              '.progress-controls-option:not(.progress-controls-option-select-all) input:not(:disabled)',
            )
            .prop('checked', isChecked);
        },
      );
      $terminal.on(
        'change',
        '.progress-controls-option:not(.progress-controls-option-select-all) input',
        () => {
          const allChecked = $(
            '.progress-controls-option:not(.progress-controls-option-select-all) input',
          )
            .map(function () {
              return (this as HTMLInputElement).checked;
            })
            .get()
            .every((val) => val);
          $terminal
            .find('.progress-controls-option-select-all input')
            .prop('checked', allChecked);
        },
      );
    }

    $container.append($terminal);
    return $terminal;
  }

  const $terminal = createTerminalWindow();

  $(() => startAjaxRequests($terminal));
}
