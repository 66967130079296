import autoTimeBootstrapDateTimePicker from './datepickers/autotime-bsdtp';
import CameraReader from './camera-reader';
import { checkboxListModal } from './checkbox-list-modal';
import confirmationModal, { ModalType } from './confirmation-modal';
import datePickerModal from './datepickers/dp-modal';
import didahDatePicker from './datepickers/didah-datepicker';
import makeOuterSourceSelectize from './outer-source-selectize';
import selectToListGroup from './select-to-listgroup';
import setDpPair from './datepickers/dp-pair';
import setAsImagesModal from './images-container';
import setProgressTerminal from './progress-terminal';
import setTextAreaModal from './textarea-modal';
import { showNotification } from './notification';
import { launchBannerCarousel } from './banner-carousel';
import { setMediaButton } from './media-button';

function cameraReader(onRead, $targetInput, toggleOptions) {
  return new CameraReader(onRead, $targetInput, toggleOptions);
}

export * from './postponed-date-modal';
export * from './selectize';

export {
  ModalType,
  autoTimeBootstrapDateTimePicker,
  cameraReader,
  checkboxListModal,
  confirmationModal,
  datePickerModal,
  didahDatePicker,
  launchBannerCarousel,
  makeOuterSourceSelectize,
  selectToListGroup,
  setAsImagesModal,
  setDpPair,
  setMediaButton,
  setProgressTerminal,
  setTextAreaModal,
  showNotification,
};
