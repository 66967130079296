import $ from 'jquery';

export default function patchJqMultiselectStrings(strings = {}) {
  if (!$.ech?.multiselect) {
    throw new Error('Patching failed - $.ech.multiselect is not defined');
  }
  const keys = [
    'checkAllText', // Check all
    'noneSelectedText', // Select options
    'selectedText', // # selected
    'uncheckAllText', // Uncheck all
  ];

  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(strings, key)) {
      $.ech.multiselect.prototype.options[key] = strings[key];
    }
  }
}
