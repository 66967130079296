async function getFacingBackCameraStream() {
  const facingBackCameraStream = await navigator.mediaDevices.getUserMedia({
    video: { facingMode: 'environment' },
  });
  if (facingBackCameraStream) {
    return facingBackCameraStream;
  }

  const devices = await navigator.mediaDevices.enumerateDevices();
  const facingBackDevice = devices.find((camera) =>
    camera.label.includes('facing back')
  );
  if (!facingBackDevice) {
    return null;
  }

  return navigator.mediaDevices.getUserMedia({
    video: { deviceId: facingBackDevice.deviceId },
  });
}

export { getFacingBackCameraStream };
