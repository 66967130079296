import $ from 'jquery';
import { saveAs } from 'file-saver';

import { loadGoogleCharts } from './load-google-charts';
import { isDarkMode } from '@/utils';

declare let google;

type Options = {
  additionalChartOptions?: Dictionary;
  dData: Dictionary;
  dHeight: any;
  dTitle: any;
  dWidth: any;
  dhAxis: any;
  dvAxis: any;
  eId: any;
  resizeCls: string;
  type: any;
};

const darkBgStyles = {
  backgroundColor: {
    fill: '#262625',
    opacity: 100,
  },
};

const darkTextStyles = {
  textStyle: {
    color: 'white',
  },
  titleTextStyle: {
    color: 'white',
  },
};

function run(
  eId,
  type,
  dData,
  dWidth,
  dHeight,
  dTitle,
  dhAxis,
  dvAxis,
  additionalChartOptions,
) {
  const isDark = isDarkMode();

  const options = {
    fontName: 'tahoma, sans-serif',
    fontSize: '10',
    height: dHeight,
    width: dWidth,
    ...(isDark && {
      ...darkBgStyles,
      ...darkTextStyles,
      legend: {
        ...darkTextStyles,
      },
      titleTextStyle: {
        ...darkTextStyles.textStyle,
      },
    }),
  };
  let options2: Dictionary = {};
  let chart: any;

  const chart_div = document.getElementById(eId);

  if (dTitle) {
    options2 = {
      ...(isDark && {
        ...darkTextStyles,
      }),
      title: dTitle,
    };
    $.extend(options, options2);
  }
  if (dhAxis) {
    options2 = {
      hAxis: {
        ...(isDark && {
          ...darkTextStyles,
        }),
        title: dhAxis,
      },
    };
    $.extend(options, options2);
  }
  if (dvAxis) {
    options2 = {
      vAxis: {
        ...(isDark && {
          ...darkTextStyles,
        }),
        title: dvAxis,
      },
    };
    $.extend(options, options2);
  }

  switch (type) {
    case '1':
      chart = new google.visualization.PieChart(chart_div);
      options2 = { chartArea: { left: 10, width: '95%' }, is3D: true };
      $.extend(options, options2);
      break;
    case '2':
      chart = new google.visualization.LineChart(chart_div);
      $.extend(options, options2);
      break;
    case '3':
      chart = new google.visualization.AreaChart(chart_div);
      options2 = { isStacked: true };
      $.extend(options, options2);
      break;
    case '4':
      chart = new google.visualization.ComboChart(chart_div);
      options2 = { seriesType: 'bars' };
      $.extend(options, options2);
      break;
    case '5':
      chart = new google.visualization.ColumnChart(chart_div);
      options2 = {
        chartArea: { left: 60, top: 4, width: '95%' },
        isStacked: true,
      };
      $.extend(options, options2);
      break;
    case '6':
      chart = new google.visualization.BarChart(chart_div);
      options2 = { seriesType: 'bars' };
      $.extend(options, options2);
      break;
  }

  if (dData === '') {
    const urlData =
      '/img/chart/data.php?l1=' +
      eId +
      '&l2=' +
      type +
      '&l3=' +
      dWidth +
      '&l4=' +
      dHeight;
    const dataTxt = $.ajax({
      async: false,
      type: 'GET',
      url: urlData + '&date=' + new Date().getTime(),
    }).responseText;
    dData = eval(dataTxt);
  }

  const data = google.visualization.arrayToDataTable(dData);

  if (additionalChartOptions) {
    $.extend(options, additionalChartOptions);
  }

  google.visualization.events.addListener(chart, 'ready', function () {
    const $btn = $(
      '<button class="didah-download btn btn-primary btn-xs"><i class="fa fa-download" aria-hidden="true"/></button>',
    ).on('click', () => {
      saveAs(chart.getImageURI(), 'chartImage.png');
      return false;
    });
    $(chart_div).append($btn);
  });

  chart.draw(data, options);
}

async function chartCreateDidah({
  additionalChartOptions,
  dData,
  dHeight,
  dTitle,
  dWidth,
  dhAxis,
  dvAxis,
  eId,
  type,
}: Options) {
  await new Promise<void>((resolve) => {
    loadGoogleCharts(resolve);
  });

  await new Promise<void>((resolve) => {
    google.charts.setOnLoadCallback(() => {
      run(
        eId,
        type,
        dData,
        dWidth,
        dHeight,
        dTitle,
        dhAxis,
        dvAxis,
        additionalChartOptions,
      );

      resolve();
    });
  });
}

export { chartCreateDidah };
