import $ from 'jquery';
import ClickEvent = JQuery.ClickEvent;

import { makeModalWindow } from '@/utils';

enum ModalType {
  Danger = 'danger',
  Default = 'default',
  Info = 'info',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
}

type Options<T> = {
  autoFocus?: 'confirm' | 'cancel';
  cancelStr?: Nullable<string>;
  confirmStr?: Nullable<string>;
  getNegativeValue?: ($modal: JQuery) => T;
  getPositiveValue?: ($modal: JQuery) => T;
  text?: string;
  title: string;
  type?: ModalType;
};

function addButtonClass($btnSave: JQuery, modalType: ModalType) {
  let className = '';
  switch (modalType) {
    case ModalType.Danger:
      className = 'btn-danger';
      break;
    case ModalType.Warning:
      className = 'btn-warning';
      break;
    case ModalType.Info:
      className = 'btn-info';
      break;
    case ModalType.Success:
      className = 'btn-success';
      break;
    case ModalType.Default:
      className = 'btn-default';
      break;
    default:
      className = 'btn-primary';
  }

  $btnSave.addClass(className);
}

function confirmationModal<T = boolean>(
  {
    autoFocus,
    cancelStr = 'Cancel',
    confirmStr = 'Confirm',
    getNegativeValue = () => false as unknown as T,
    getPositiveValue = () => true as unknown as T,
    text,
    title,
    type = ModalType.Primary,
  }: Options<T>,
  resolve: (result: T) => void
) {
  let isResolved = false;
  let $modal = null;

  const doResolve = (result: T) => {
    if (isResolved) {
      return;
    }

    resolve(result);
    isResolved = true;
  };

  const onButtonClicked = (getValue: ($modal) => T) => (e: ClickEvent) => {
    e.stopPropagation();

    doResolve(getValue($modal));
    $modal.modal('hide');
  };

  const $body = text
    ? $(`
            <div>
                <div class="row">
                    <div class="col-xs-12">
                        ${text}
                    </div>
                </div>
            </div>
        `)
    : null;

  $modal = makeModalWindow(title, $body, cancelStr, confirmStr);
  $modal.find('.modal-dialog');

  const $btnClose = $modal.find('.m-close');
  $btnClose.on('click', onButtonClicked(getNegativeValue));

  const $btnSave = $modal.find('.m-save');
  $btnSave.removeClass('.btn-primary');
  addButtonClass($btnSave, type);
  $btnSave.on('click', onButtonClicked(getPositiveValue));

  $modal.modal('show');

  $modal.on('hidden.bs.modal', () => doResolve(getNegativeValue($modal)));
  $modal.on('shown.bs.modal', () => {
    if (autoFocus === 'cancel') {
      $btnClose.focus();
    }
    if (autoFocus === 'confirm') {
      $btnSave.focus();
    }
  });
}

export { ModalType };

export default function <T>(opts: Options<T>) {
  return new Promise((resolve) => confirmationModal(opts, resolve));
}
