import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title?: string;
  type?: string;
}>;

function FormPanel(props: Props) {
  const { children, title, type = 'info' } = props;

  return (
    <div className={`panel panel-${type}`}>
      {title && (
        <div className="panel-heading">
          <div className="panel-title">{title}</div>
        </div>
      )}
      <div className="panel-body !tw-pt-7">{children}</div>
    </div>
  );
}

export { FormPanel };
