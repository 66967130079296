import { useState, type PropsWithChildren, type ReactNode } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../dropdown';
import { cn } from '@/utils';
import range from 'lodash/range';
import { getPickerList } from '@/react-app/shared/utils/picker';

type Props = PropsWithChildren<{
  decrementHours: VoidFunction;
  decrementMinutes: VoidFunction;
  displayedHours: string;
  displayedMinutes: string;
  incrementHours: VoidFunction;
  incrementMinutes: VoidFunction;
  setHours: (hour: number) => void;
  setMinutes: (hour: number) => void;
}>;

const hoursList = getPickerList(range(24));
const minutesList = getPickerList(range(0, 60, 5));

function HoursDropdown({
  children,
  decrementHours,
  decrementMinutes,
  displayedHours,
  displayedMinutes,
  incrementHours,
  incrementMinutes,
  setHours,
  setMinutes,
}: Props) {
  const [mode, setMode] = useState<'hours' | 'minutes' | 'normal'>('normal');

  const onHoursSelected = (value: number) => {
    setHours(value);
    setMode('minutes');
  };

  const onMinutesSelected = (value: number) => {
    setMinutes(value);
    setMode('normal');
  };

  let content: ReactNode = null;

  if (mode === 'hours') {
    content = (
      <div className="tw-grid tw-auto-cols-[59px] tw-auto-rows-[31px] tw-grid-cols-4">
        {hoursList.map(({ label, value }) => (
          <div
            key={`hours-${value}`}
            className="tw-cursor-pointer tw-rounded tw-text-center tw-text-xs tw-leading-[31px] hover:tw-bg-foreground-default-2 hover:dark:tw-bg-foreground-default-2-dark"
            onClick={() => onHoursSelected(value)}
          >
            <span>{label}</span>
          </div>
        ))}
      </div>
    );
  } else if (mode === 'minutes') {
    content = (
      <div className="tw-grid tw-auto-cols-[50px] tw-auto-rows-[58px] tw-grid-cols-4">
        {minutesList.map(({ label, value }) => (
          <div
            key={`minutes-${value}`}
            className="tw-cursor-pointer tw-rounded tw-text-center tw-text-xs tw-leading-[58px] hover:tw-bg-foreground-default-2 hover:dark:tw-bg-foreground-default-2-dark"
            onClick={() => onMinutesSelected(value)}
          >
            <span>{label}</span>
          </div>
        ))}
      </div>
    );
  } else {
    content = (
      <div>
        <div className="tw-flex tw-h-14 tw-leading-[3.5rem]">
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center">
            <span
              className="btn link tw-px-7 tw-py-3.5 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => incrementHours()}
            >
              <span className="glyphicon glyphicon-chevron-up" />
            </span>
          </div>
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center">
            <span
              className="btn link tw-px-7 tw-py-3.5 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => incrementMinutes()}
            >
              <span className="glyphicon glyphicon-chevron-up" />
            </span>
          </div>
        </div>
        <div className="tw-flex tw-h-14 tw-leading-[3.5rem]">
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center tw-font-bold">
            <div
              className="tw-cursor-pointer tw-rounded tw-px-7 tw-py-1 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => setMode('hours')}
            >
              {displayedHours}
            </div>
          </div>
          <div className="separator">:</div>
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center tw-font-bold">
            <div
              className="tw-cursor-pointer tw-rounded tw-px-7 tw-py-1 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => setMode('minutes')}
            >
              {displayedMinutes}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-h-14 tw-leading-[3.5rem]">
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center">
            <span
              className="btn link tw-px-7 tw-py-3.5 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => decrementHours()}
            >
              <span className="glyphicon glyphicon-chevron-down" />
            </span>
          </div>
          <div className="tw-min-w-[3.5rem] tw-flex-1 tw-p-0.5 tw-text-center">
            <span
              className="btn link tw-px-7 tw-py-3.5 hover:tw-bg-foreground-default hover:dark:tw-bg-foreground-default-dark"
              onClick={() => decrementMinutes()}
            >
              <span className="glyphicon glyphicon-chevron-down" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DropdownMenu
      modal={false}
      onOpenChange={(isOpened) => {
        if (!isOpened) setMode('normal');
      }}
    >
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className={cn('tw-z-50 tw-max-h-96 tw-overflow-y-auto')}
      >
        {content}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { HoursDropdown };
