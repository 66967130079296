import type { ModalSizes } from '@/react-app/shared/components';

type IdType = {
  id: string;
  name: string;
};

enum ExportFormats {
  Png = 'png',
  Svg = 'svg',
}

type ModalValues = {
  idNr: string;
  idType: Nullable<string>;
  name: string;
};

type Signature = {
  geolocation: GeolocationCoordinates;
  id?: string;
  idNr: string;
  idTypeId: IdType['id'];
  img: string;
  name: string;
};

type SignatureWidgetProps = {
  docNr: string;
  exportFormat?: ExportFormats;
  geoPoint?: boolean;
  idTypeOptions?: IdType[];
  readOnly?: boolean;
};

type SignatureWidgetAppOpts = {
  defaultIdNr?: string | (() => string);
  defaultIdTypeId?: IdType['id'] | (() => IdType['id']);
  defaultName?: string | (() => string);
  dialogClass?: string;
  docNr: string;
  exportFormat: ExportFormats;
  geoPoint?: boolean;
  idTypeOptions: IdType[];
  readOnly?: boolean;
};

type SignatureAjaxAppOpts = SignatureWidgetAppOpts & {
  baseURL: string;
  deleteParams: Dictionary;
  fetchParams: Dictionary;
  modalSize: ModalSizes;
  submitParams: Dictionary;
  widgetId: string;
};

type SignatureAppOpts = SignatureWidgetAppOpts & {
  baseURL: string;
  fetchParams: Dictionary<string>;
  initialSignaturesIds?: string[];
  nameInForm: string;
  submitParams: Dictionary;
};

type SignatureModalAppOpts = SignatureWidgetAppOpts & {
  onSubmit: (res: any) => void;
  submitParams: Dictionary;
};

export {
  ExportFormats,
  IdType,
  ModalValues,
  Signature,
  SignatureAjaxAppOpts,
  SignatureAppOpts,
  SignatureModalAppOpts,
  SignatureWidgetAppOpts,
  SignatureWidgetProps,
};
