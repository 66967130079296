import type {
  AutoTableError,
  AutoTableHandle,
  Row,
} from '@/react-app/auto-table';

import type { GetDataRequest } from './request';

export type TableParams = {
  getDataRequest?: GetDataRequest;
  save?: Dictionary;
};

class AutoTablesController {
  private instances: Dictionary<AutoTableHandle> = {};
  private params: TableParams = {};

  private listeners: Dictionary<((...args: any[]) => void)[]> = {};

  add(id: string, instance: AutoTableHandle, params: TableParams) {
    this.instances[id] = instance;
    this.params[id] = params;

    instance.subscribe('errors', () => {
      this.listeners.errors?.forEach((cb) => cb(this.hasAnyErrors()));
    });

    instance.subscribe('readonly', () => {
      this.listeners.readonly?.forEach((cb) => cb(this.isReadOnlyAll()));
    });
  }

  remove(id: string) {
    const instance = this.instances[id];
    delete this.instances[id];

    return instance;
  }

  subscribe(event: string, cb: (...args: any[]) => void) {
    this.listeners[event] ||= [];
    this.listeners[event].push(cb);
  }

  unsubscribe(event: string, cb: (...args: any[]) => void) {
    if (!this.listeners[event]) {
      return;
    }
    this.listeners[event] = this.listeners[event].filter(
      (currentCb) => currentCb !== cb,
    );
  }

  setRows(id: string, rows: Row[]) {
    this.instances[id]?.setRows(rows);
  }

  setErrors(id: string, errors: AutoTableError[]) {
    this.instances[id]?.setErrors(errors);
  }

  hasAnyErrors() {
    return Object.values(this.instances).some((instance) =>
      instance.getHasErrors(),
    );
  }

  getAllRows() {
    return Object.fromEntries(
      Object.entries(this.instances).map(([id, instance]) => {
        return [id, instance.getRows()];
      }),
    );
  }

  getReadOnly(id: string) {
    return !!this.instances[id]?.isReadOnly();
  }

  setReadOnly(id: string, value: boolean) {
    this.instances[id]?.setReadOnly(value);
  }

  setReadOnlyAll(value: boolean) {
    Object.values(this.instances).forEach((instance) =>
      instance.setReadOnly(value),
    );
  }

  isReadOnlyAll() {
    return Object.values(this.instances).every((instance) =>
      instance.isReadOnly(),
    );
  }

  toggleReadOnlyAll() {
    const currentState = this.isReadOnlyAll();
    Object.values(this.instances).forEach((instance) =>
      instance.setReadOnly(!currentState),
    );
    return !currentState;
  }

  getIds() {
    return Object.keys(this.instances);
  }

  getParams(id: string) {
    return this.params[id];
  }
}

const controller = new AutoTablesController();

export { controller };
