const WINDOW_STACK_OPEN = 'window-stack-open' as const;
const WINDOW_STACK_CLOSE = 'window-stack-close' as const;
const WINDOW_STACK_CLOSED = 'window-stack-closed' as const;
const WINDOW_STACK_ESC_KEY = 'window-stack-esc-key' as const;

export {
  WINDOW_STACK_CLOSE,
  WINDOW_STACK_CLOSED,
  WINDOW_STACK_ESC_KEY,
  WINDOW_STACK_OPEN,
};
