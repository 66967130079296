import { createMountingPoint } from '@/utils';
import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

// использовать для дочерних модалок (не рутовой модалки)
function ReactPortal({ children }) {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    const element = createMountingPoint();
    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, []);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}

export { ReactPortal };
