import $ from 'jquery';
import MobileDetect from 'mobile-detect';

import { getCoords, removeLoadingOverlay, setLoadingOverlay } from '@/utils';
import { ToggleInLocalStorage } from '@/utils/local-storage';
import type { CameraReaderToggleOptions } from '@/common/widgets/camera-reader';
import CameraReader from '@/common/widgets/camera-reader';
import type { ContainerLocation } from '@/common/widgets/floating-buttons';
import { addButton } from '@/common/widgets/floating-buttons';

const HARDWARE_SCANNER_BUTTON_CODE = 0;
const md = new MobileDetect(window.navigator.userAgent);
const os = md.os() && md.os().toLowerCase();
const isIos = os === 'ios';
const isAndroid = os === 'androidos';
const TRY_SUBMIT_INTERVAL = 3000;
const AUTO_FOCUS_INTERVAL = 3000;
const intervalToggleStorage = new ToggleInLocalStorage(
  'SCANNER_FOCUS_INTERVAL_ON'
);
const typingToggleStorage = new ToggleInLocalStorage('SCANNER_FOCUS_TYPING_ON');

export type ScannerOptions = {
  cameraOptions?: CameraReaderToggleOptions & { enabled: boolean };
  options?: {
    autoFocusButtonLocation?: ContainerLocation;
    delayAfterSubmit?: number;
    enableIntervalAutoFocus?: boolean;
    enableTypingAutoFocus?: boolean;
  };
};

function extractNumberFromQuery(query: string) {
  const split = query
    .split('&')
    .find((str) => str.startsWith('q='))
    .split('=');
  return split[1] ? split[1] : '';
}

type Api = {
  intervalAutoFocus: {
    start: () => void;
    stop: () => void;
  };
  scanner: CameraReader | null;
  typingAutoFocus: {
    start: () => void;
    stop: () => void;
  };
};

const defaultAutoFocusButtonLocation = { right: '0', top: '215px' };

const scanner = ({ cameraOptions, options = {} }: ScannerOptions = {}) =>
  new Promise<Api | null>((resolve) => {
    const {
      autoFocusButtonLocation = defaultAutoFocusButtonLocation,
      delayAfterSubmit = 1000,
    } = options;
    if (cameraOptions?.location) {
      cameraOptions.location = autoFocusButtonLocation;
    }

    let isTypingAutoFocusEnabled = true;
    if (typingToggleStorage.get() != null) {
      isTypingAutoFocusEnabled = !!typingToggleStorage.get();
    } else if (options.enableTypingAutoFocus != null) {
      isTypingAutoFocusEnabled = options.enableTypingAutoFocus;
    }
    const updateTypingToggleInLs = () =>
      typingToggleStorage.set(isTypingAutoFocusEnabled);
    updateTypingToggleInLs();
    const startTypingAutofocus = () => {
      isTypingAutoFocusEnabled = true;
      updateTypingToggleInLs();
    };
    const stopTypingAutofocus = () => {
      isTypingAutoFocusEnabled = false;
      updateTypingToggleInLs();
    };
    const toggleTypingAutofocus = () => {
      isTypingAutoFocusEnabled = !isTypingAutoFocusEnabled;
      updateTypingToggleInLs();
    };

    let isIntervalAutoFocusEnabled = true;
    if (intervalToggleStorage.get() != null) {
      isIntervalAutoFocusEnabled = !!intervalToggleStorage.get();
    } else if (options.enableIntervalAutoFocus != null) {
      isIntervalAutoFocusEnabled = options.enableIntervalAutoFocus;
    }
    const updateIntervalToggleInLs = () =>
      intervalToggleStorage.set(isIntervalAutoFocusEnabled);
    updateIntervalToggleInLs();
    const startIntervalAutofocus = () => {
      isIntervalAutoFocusEnabled = true;
      updateIntervalToggleInLs();
    };
    const stopIntervalAutofocus = () => {
      isIntervalAutoFocusEnabled = false;
      updateIntervalToggleInLs();
    };
    const toggleIntervalAutofocus = () => {
      isIntervalAutoFocusEnabled = !isIntervalAutoFocusEnabled;
      updateIntervalToggleInLs();
    };

    $(function () {
      const $rForm = $('form[name="request"]');
      const $inputQ: JQuery<HTMLInputElement> = $rForm.find(
        'input[name="q"]'
      ) as JQuery<HTMLInputElement>;
      let onSubmitted = null;

      function onSubmit() {
        let savedData = [];
        const $saved = $('#saved');

        function resetSavedNumbers() {
          savedData = [];
          $saved.html('');

          $saved.hide();
          $('#content').css('padding-top', '');
        }

        function loadSavedNumbers(query) {
          return query.replace('q=', `q=${savedData.join('')}`);
        }

        function saveNumber(number) {
          if (!number.length) {
            return;
          }

          savedData.push(number);
          $saved.append(
            `<span class="label label-danger saved-label">${number}</span>`
          );

          $('#content').css('padding-top', '0px');
          $saved.show();
        }

        function doSubmit(data, callback) {
          const number = extractNumberFromQuery(data);
          data = loadSavedNumbers(data);
          if (!navigator.onLine) {
            saveNumber(number);
            removeLoadingOverlay();
            return;
          }
          $('#content').load(
            '/management.php',
            data,
            function (response, status) {
              if (status === 'error') {
                saveNumber(number);
                removeLoadingOverlay();
              } else {
                resetSavedNumbers();
                const $sFormFields = $('form[name="shadowForm"]').find(
                  'input[type="hidden"]'
                );
                if ($sFormFields.length > 0) {
                  $rForm.find('input[type="hidden"]').remove();
                  $sFormFields.each(function () {
                    $(this).clone().appendTo($rForm);
                  });
                  removeLoadingOverlay();
                }
              }

              if (callback) {
                callback();
                callback = null;
              }
            }
          );
        }

        function setLoadingNumber(number) {
          const $sc = $(document.body).find('#loading-overlay .sc');
          $sc.css('position', 'relative');

          const outerStyles = {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
          };
          const $outer = $('<div/>');
          $outer.css(outerStyles);

          const innerStyles = {
            backgroundColor: 'white',
            border: 'darkgrey solid 1px',
            borderRadius: '5px',
            color: 'black',
            fontSize: '26px',
            fontWeight: 'bold',
            margin: '15px',
            padding: '15px',
          };
          const $inner = $(`<div>${number}</div>`);
          $inner.css(innerStyles);

          $outer.append($inner);

          $sc.append($outer);

          $sc.find('.fa-spinner').css('font-size', '20em');
        }

        $rForm.on('submit', function (event) {
          event.preventDefault();

          const data = $(this).serialize();
          setLoadingOverlay();
          setLoadingNumber(savedData.join('') + $inputQ.val());

          $inputQ.val('');
          doSubmit(data, onSubmitted);
        });

        // tries to submit the form if case if there are queued numbers
        // this is for cases when devices goes offline for some period
        // we don't want to lose these scanned numbers
        setInterval(() => {
          if (savedData.length) {
            $rForm.trigger('submit');
          }
        }, TRY_SUBMIT_INTERVAL);
      }

      function onScannerRead(resultText: string, cameraReader: CameraReader) {
        if (resultText && cameraReader) {
          try {
            // на iOS не работает вибрация. Поэтому ловим ошибку
            navigator.vibrate([200]);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Vibration is not supported');
          }
          cameraReader.closeCamera();
          onSubmitted = () =>
            setTimeout(() => cameraReader.openCamera(), delayAfterSubmit);

          $inputQ.val(resultText);
          $rForm.submit();
        }
      }

      function autoFocus() {
        let scanner: CameraReader | null = null;

        function doFocus() {
          $inputQ.trigger('focus');

          const coords = getCoords($inputQ.get(0));
          const clickEv = document.createEvent('MouseEvent');
          clickEv.initMouseEvent(
            'click',
            true /* bubble */,
            true /* cancelable */,
            window,
            null,
            coords.top,
            coords.left,
            0,
            0 /* coordinates */,
            false,
            false,
            false,
            false /* modifier keys */,
            0 /*left*/,
            null
          );
          document.body.dispatchEvent(clickEv);
        }

        function addTypingAutoFocusToggleButton() {
          function setButtonClasses() {
            if (isTypingAutoFocusEnabled) {
              $button.addClass('btn-success');
              $button.removeClass('btn-default');
            } else {
              $button.removeClass('btn-success');
              $button.addClass('btn-default');
            }
          }
          const $button = $(`
                    <button class="floating-button btn btn-default" title="Toggle auto-focus when typing">
                        <i class="fa fa-keyboard-o" aria-hidden="true"/>
                    </button>
                `);

          addButton($button, autoFocusButtonLocation);
          setButtonClasses();

          $button.on('click', () => {
            toggleTypingAutofocus();
            setButtonClasses();
          });
        }

        function autoFocusOnTyping() {
          let activated = false;

          const ev = document.createEvent('MouseEvent');
          ev.initMouseEvent(
            'click',
            true /* bubble */,
            true /* cancelable */,
            window,
            null,
            0,
            0,
            0,
            0 /* coordinates */,
            false,
            false,
            false,
            false /* modifier keys */,
            0 /*left*/,
            null
          );
          $inputQ.on('click', () => (activated = true));
          $inputQ.on('focus', () => (activated = true));
          $inputQ.on('blur', () => (activated = false));
          document.body.dispatchEvent(ev);
          $(document.body).on('keydown', (e) => {
            if (!isTypingAutoFocusEnabled) {
              return;
            }

            if (
              e.keyCode === HARDWARE_SCANNER_BUTTON_CODE &&
              scanner?.isShowedOnce
            ) {
              return location.reload();
            }

            if (
              activated ||
              e.keyCode === 13 ||
              (document.activeElement &&
                document.activeElement.tagName === 'INPUT' &&
                document.activeElement !== $inputQ[0])
            ) {
              return;
            }

            if (isIos) {
              $inputQ.val(String.fromCharCode(e.keyCode));
            }
            $inputQ.trigger('focus');
          });
        }

        function autoFocusOnInterval() {
          setInterval(() => {
            if (!isIntervalAutoFocusEnabled) {
              return;
            }

            doFocus();
          }, AUTO_FOCUS_INTERVAL);
        }

        function addIntervalAutoFocusToggleButton() {
          function setButtonClasses() {
            if (isIntervalAutoFocusEnabled) {
              $button.addClass('btn-success');
              $button.removeClass('btn-default');
            } else {
              $button.removeClass('btn-success');
              $button.addClass('btn-default');
            }
          }
          const $button = $(`
                    <button class="floating-button btn btn-default" title="Toggle auto-focus by interval">
                        <i class="fa fa-repeat" aria-hidden="true"/>
                    </button>
                `);

          addButton($button, autoFocusButtonLocation);
          setButtonClasses();

          $button.on('click', () => {
            toggleIntervalAutofocus();
            setButtonClasses();
          });
        }

        function autoFocusOnceOnAndroid() {
          if (!isTypingAutoFocusEnabled || !isAndroid) {
            return;
          }

          doFocus();
        }

        function onUnblockFocus() {
          $(window).on('focus', () => {
            if (!isTypingAutoFocusEnabled) {
              return;
            }

            $inputQ.trigger('blur');
            $inputQ.trigger('focus');
          });
        }

        onUnblockFocus();
        autoFocusOnTyping();
        addTypingAutoFocusToggleButton();
        autoFocusOnInterval();
        addIntervalAutoFocusToggleButton();
        autoFocusOnceOnAndroid();

        if (cameraOptions?.enabled) {
          scanner = new CameraReader(onScannerRead, $inputQ, cameraOptions);
        }

        return {
          intervalAutoFocus: {
            start: startIntervalAutofocus,
            stop: stopIntervalAutofocus,
          },
          scanner,
          typingAutoFocus: {
            start: startTypingAutofocus,
            stop: stopTypingAutofocus,
          },
        };
      }

      onSubmit();
      resolve(autoFocus());
    });
  });

export default scanner;
