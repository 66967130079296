import { cn } from '@/utils';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faFile,
  faFileArchive,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileText,
  faFileWord,
  faFolder,
  faMusic,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MediaFileType } from '../../types';

type Props = {
  className?: string;
  text: string;
  type: MediaFileType | 'dir';
};

function PreviewWithIcon({ className, text, type }: Props) {
  let icon: Nullable<IconDefinition> = null;

  switch (type) {
    case 'pdf': {
      icon = faFilePdf;
      break;
    }
    case 'word': {
      icon = faFileWord;
      break;
    }
    case 'excel': {
      icon = faFileExcel;
      break;
    }
    case 'powerpoint': {
      icon = faFilePowerpoint;
      break;
    }
    case 'zip': {
      icon = faFileArchive;
      break;
    }
    case 'text': {
      icon = faFileText;
      break;
    }
    case 'audio': {
      icon = faMusic;
      break;
    }
    case 'dir': {
      icon = faFolder;
      break;
    }
    default: {
      icon = faFile;
    }
  }

  return (
    <div
      className={cn(
        'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center',
        className,
      )}
    >
      <div
        className={cn('tw-max-w-full tw-space-y-1.5 tw-text-center', {
          'tw-text-black': type === 'text',
          'tw-text-blue-600': type === 'word',
          'tw-text-green-600': type === 'excel',
          'tw-text-pink-600': type === 'pdf',
          'tw-text-purple-600': type === 'zip',
          'tw-text-red-600': type === 'powerpoint',
        })}
      >
        <FontAwesomeIcon className="tw-text-4xl" icon={icon} />
        <div className="tw-truncate tw-px-2 tw-text-sm">{text}</div>
      </div>
    </div>
  );
}

export { PreviewWithIcon };
