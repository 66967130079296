import { cn } from '@/utils';
import type { ReactNode } from 'react';

type ToggleOption<T extends string> = {
  icon?: ReactNode;
  id: T;
  label?: ReactNode;
};

type Props<T extends string> = {
  className?: string;
  disabled?: boolean;
  onSelect: (value: T) => void;
  options: ToggleOption<T>[];
  value: T;
};

function MultiToggle<T extends string>({
  className,
  disabled,
  onSelect,
  options,
  value,
}: Props<T>) {
  return (
    <div className={cn('btn-group', className)}>
      {options.map(({ icon, id, label }) => (
        <button
          key={id}
          className={cn('btn', {
            'btn-default': value !== id,
            'btn-primary': value === id,
          })}
          disabled={disabled}
          type="button"
          onClick={() => onSelect(id)}
        >
          {icon}
          {label}
        </button>
      ))}
    </div>
  );
}
export { MultiToggle, type ToggleOption };
