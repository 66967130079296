/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';
import _debounce from 'lodash/debounce';

import { management } from '@/common';
import type { Options as TableGroupsDragulaOptions } from '@/common/features/table-groups-dragula';
import tableGroupsDragula from '@/common/features/table-groups-dragula';
import { calcSubtotals, drowGroup } from '@/common/plugins';
import { checkboxListModal } from '@/common/widgets/checkbox-list-modal';
import {
  addOnZoomChangeListener,
  ajaxPopover,
  debounce,
  dt_number_format,
  setLoadingOverlay,
} from '@/utils';

import { type getGroupFields } from '../functions';
import { addToggleEllipsisButton } from './ellipsis';
import type {
  AddSaveSettingsButtonParams,
  TableSettings,
} from '@/common/features/datatables';
import {
  fillSearchOptionsStorage,
  addSaveSettingsButton,
} from '@/common/features/datatables';

type KanbanBtnOpts = {
  getUrl: () => Promise<string>;
};

type TableOptions = {
  columnsAndOther: Dictionary & {
    columns: any[];
    fixedColumns: {
      heightMatch: 'semiauto';
      leftColumns: number;
    };
  };
  dataLength: number;
  excelFileName: string;
  fieldPickerOptions: {
    fsess: string;
    originalData: any;
    resetData: any;
  };
  formSearchOptions: {
    selectValue: string;
  };
  getCurrentSettings: () => TableSettings;
  groupFields: ReturnType<typeof getGroupFields>;
  headerWidth: number;
  id: string;
  isConfigureSession: boolean;
  isEditable: boolean;
  isShowPeriodButton: boolean;
  kanbanOptions?: KanbanBtnOpts;
  lang_json: Dictionary;
  lsKey: string;
  persistOptions?: Pick<
    AddSaveSettingsButtonParams,
    | 'isSaveEnabled'
    | 'isSaveForUserEnabled'
    | 'saveForUserParams'
    | 'saveParams'
  >;
  postCreatedRow: PostCreatedRow;
  print: {
    footer: string;
    header: string;
  };
  requestOptions: {
    a: string;
  };
  resizeCls: string;
  savingOptions: {
    eInvoiceFsess: string;
    pdfFsess: string;
  };
  search?: {
    initialValue: string;
    onChange: (value: string) => void;
  };
  tableAssignIds: string[];
  tableGroupsDragulaOptions: Pick<
    TableGroupsDragulaOptions,
    | 'simpleMode'
    | 'initialState'
    | 'onGroupingStateUpdated'
    | 'colIdToFieldName'
  >;
  url: string;
};

function updateFixedColumnsOnZoomChange(dt: Datatable) {
  addOnZoomChangeListener(
    debounce(() => {
      dt.fixedColumns().update();
    }, 100),
  );
}

function showSelection(cell, table) {
  const jqCell = $(cell);
  if (
    table.rows({ selected: false }).any() &&
    table.rows({ selected: true }).any()
  ) {
    jqCell.addClass('select-checkbox-header-semi-selected');
  } else {
    jqCell.removeClass('select-checkbox-header-semi-selected');
    if (!table.rows({ selected: false }).any()) {
      jqCell.addClass('select-checkbox-header-selected');
    } else {
      jqCell.removeClass('select-checkbox-header-selected');
    }
  }
}

function footerCallback(api, oSettings, groupFields) {
  let dt;
  const columns = api.columns().dataSrc();
  if (api.rows({ search: 'applied', selected: true }).any()) {
    dt = api.rows({ selected: true }).data();
  } else {
    dt = api.rows({ search: 'applied' }).data();
  }
  groupFields.subtotalFieldsList.forEach(function (d) {
    const sPos = columns.indexOf(d);
    const txt = dt.reduce(function (a, b) {
      return a + (b['s_6'] == 1 ? +b[d] : 0);
    }, 0);
    $(api.column(sPos).footer()).html(
      dt_number_format(txt, oSettings.aoColumns[sPos].decimal),
    );
  });
}

function renderCalenderButton($buttonsPanel: JQuery) {
  $buttonsPanel.prepend(
    $(
      '<a href="#" class="custom-popover btn btn-default" id="calendarPopover"><span class="glyphicon glyphicon-calendar"></span></a>',
    ),
  );
  ajaxPopover({
    selector: '#calendarPopover',
    url: '/ajax_api.php?id=20&I_type=mainApply',
  });
}

async function renderKanbanButton(
  $buttonsPanel: JQuery,
  { getUrl }: KanbanBtnOpts,
) {
  const $button = $(`
        <button type="button" class="btn btn-default" disabled>
            <i class="fa fa-th" aria-hidden="true"/>
        </button>
    `);
  $buttonsPanel.prepend($button);

  const url = await getUrl();

  $button.replaceWith(
    $(`
        <a class="btn btn-default" href="${url}">
            <i class="fa fa-th" aria-hidden="true"/>
        </a>
    `),
  );
}

async function bd726Table({
  columnsAndOther,
  dataLength,
  excelFileName,
  fieldPickerOptions,
  formSearchOptions,
  getCurrentSettings,
  groupFields,
  id,
  isConfigureSession,
  isEditable,
  isShowPeriodButton,
  kanbanOptions,
  lang_json,
  lsKey,
  persistOptions,
  postCreatedRow,
  print,
  requestOptions,
  resizeCls,
  savingOptions,
  search,
  tableAssignIds,
  tableGroupsDragulaOptions,
  url,
}: TableOptions) {
  const headerWidth =
    ($('#logo').height() || 0) +
    ($('#menuTable1').height() || 0) +
    ($('.menu-main').height() || 0) +
    ($('#footer').height() || 0) +
    136 +
    (isConfigureSession ? 33 : 0);

  let topY = '';
  if (window.innerHeight - headerWidth - 30 < 300) {
    topY = '300px';
  } else {
    topY = 'calc(100vh - ' + headerWidth + 'px - 30px)';
  }

  function handleInitData(d) {
    d.q = $('input:text[name=q]').val();
    tableAssignIds.forEach((assignId) => {
      const val = $(`select[name="${assignId}"]`).val();
      if (val) {
        d[assignId] = val;
      }
    });
  }

  const table = $(`#new_table${id}`)
    .DataTable({
      ajax: {
        data: handleInitData,
        dataSrc: 'data',
        type: 'POST',
        url,
      },
      autoWidth: true,
      buttons: [
        {
          autoPrint: false,
          className: 'btn btn-default',
          customize(win) {
            $(win.document.body)
              .css('font-size', '8pt')
              .prepend(print.header)
              .append(print.footer);

            $(win.document.body)
              .find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          },
          exportOptions: {
            columns(idx, data, node) {
              return jQuery.inArray(idx, [0]) == -1;
            },
            orthogonal: 'print',
            stripHtml: true,
          },
          extend: 'print',
          footer: true,
          message: '',
          name: 'printall',
        },
        {
          autoPrint: false,
          className: 'btn btn-default',
          customize(win) {
            $(win.document.body)
              .css('font-size', '8pt')
              .prepend(print.header)
              .append(print.footer);

            $(win.document.body)
              .find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          },
          exportOptions: {
            columns(idx, data, node) {
              return jQuery.inArray(idx, [0]) == -1;
            },
            modifier: {
              selected: true,
            },
            orthogonal: 'print',
            stripHtml: true,
          },
          extend: 'print',
          footer: true,
          message: '',
          name: 'printselect',
        },
        {
          className: 'btn btn-default',
          customize(xlsx, btn, dtable) {
            const sheet = xlsx.xl.worksheets['sheet1.xml'];
            $('row[r=3] c', sheet).attr('s', '2');
          },
          customizeData(data) {
            const newRow: string[] = [];
            for (let i = 1; i < oSettings.aoColumns.length; i++) {
              newRow.push('[' + (oSettings.aoColumns[i].special ?? '') + ']');
            }
            data.body.unshift(newRow);
          },
          exportOptions: {
            columns(idx, data, node) {
              return jQuery.inArray(idx, [0]) == -1;
            },
            orthogonal: 'export',
          },
          extend: 'excelHtml5',
          footer: true,
          name: 'excelall',
          title: excelFileName,
        },
        {
          className: 'btn btn-default',
          customize(xlsx, btn, dtable) {
            const sheet = xlsx.xl.worksheets['sheet1.xml'];
            $('row[r=3] c', sheet).attr('s', '2');
          },
          customizeData(data) {
            const newRow: string[] = [];
            for (let i = 1; i < oSettings.aoColumns.length; i++) {
              newRow.push('[' + (oSettings.aoColumns[i].special ?? '') + ']');
            }
            data.body.unshift(newRow);
          },
          exportOptions: {
            columns(idx, data, node) {
              return jQuery.inArray(idx, [0]) == -1;
            },
            modifier: {
              selected: true,
            },
            orthogonal: 'export',
          },
          extend: 'excelHtml5',
          footer: true,
          name: 'excelselect',
          title: excelFileName,
        },
      ],
      createdRow(row, data, index) {
        postCreatedRow(row, data, index, $(this).DataTable());
      },
      deferRender: true,
      dom: '<<"top"<"dt-top-right"<"btn_block"B>lf>>tr<"bottom"ip>>',
      drawCallback() {
        drowGroup(this.api(), groupFields);
      },
      footerCallback(row, data, start, end, display) {
        const api = this.api();
        const oSettings = this.dataTable().fnSettings();
        footerCallback(api, oSettings, groupFields);
      },
      language: lang_json,
      lengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200],
      ],
      oClasses: {
        sFilterInput: 'form-control tal w100p dt-search',
        sLengthSelect: 'form-control tal',
      },
      order: [[1, 'desc']],
      processing: true,
      scrollX: true,
      scrollY: topY,
      search: {
        regex: true,
        search: '',
      },
      select: {
        selector: 'td:first-child',
        style: 'multi+shift',
      },
      stateLoadCallback(settings) {
        try {
          return JSON.parse(localStorage.getItem(lsKey) as string);
        } catch (e) {}
      },
      stateSave: true,
      stateSaveCallback(settings, data) {
        try {
          data.search.search = '';
          data.start = 0;
          $.each(data.columns, function (i, n) {
            n.visible = true;
            n.search.search = '';
          });
          data.length = dataLength;
          localStorage.setItem(lsKey, JSON.stringify(data));
        } catch (e) {}
      },
      ...columnsAndOther,
    })
    .on('init.dt', async function (e, settings, ajax) {
      const dt = $(this).DataTable();
      $(this).on('click', 'td.grp_header', function () {
        const me = $(this).parent();
        me.toggleClass('grp_pluss');
        if (me.hasClass('grp_pluss')) {
          $('tr.' + me.data('grp')).addClass('grp_hide');
        } else {
          $('tr.' + me.data('grp')).removeClass('grp_hide');
          $('tr.group.' + me.data('grp')).removeClass('grp_pluss');
        }
      });

      const $buttonsPanel = $(dt.table().container()).find(
        `div#new_table${id}_otherctrl`,
      );

      if (isShowPeriodButton) {
        renderCalenderButton($buttonsPanel);
      }
      if (kanbanOptions) {
        renderKanbanButton($buttonsPanel, kanbanOptions);
      }

      const $divTop = $(dt.table().container()).find('div.top');
      const $divTopRight = $divTop.find('.dt-top-right');

      const $block = $('<div class="dataTables_length flds_block btn-group"/>');
      $divTopRight.prepend($block);
      if (isEditable) {
        $block.append(
          $(
            `<a id="fields_edit${id}" href="#" class="tw-text-left btn btn-default ${
              window.isDatatableLocked ? '' : 'btn-success'
            }"><span class="fa fa-pencil-square-o" aria-hidden="true"/></a>`,
          ).on('click', function () {
            window.toggleDatatableEdit();
            $(this).toggleClass('btn-success');
            $(`#new_table${id}_wrapper`).toggleClass('is-locked2');
            table.draw(false);
            table.fixedColumns().update();
          }),
        );
      }

      $block.append(
        $(
          `<a id="fields${id}" href="#" class="tw-text-left btn btn-default"><span class="glyphicon glyphicon-cog" aria-hidden="true"/></a>`,
        ),
      );
      const cblm = checkboxListModal(fieldPickerOptions.originalData, {
        $trigger: $(`#fields${id}`),
        onClose: (data) => {
          cblm.hideModal();
          cblm.setData(fieldPickerOptions.originalData);
        },
        onReset: () => {
          cblm.setData(fieldPickerOptions.resetData);
        },
        onSave: (data) => {
          new Promise<void>((resolve) => {
            setLoadingOverlay();
            $.ajax({
              cache: false,
              data: {
                a: requestOptions.a,
                fsess: fieldPickerOptions.fsess,
                id: 24,
                val: data
                  .filter((name) => name.checked)
                  .map(({ name }) => name),
              },
              dataType: 'json',
              error(e) {
                alert('Error processing your request: ' + e.responseText);
                resolve();
              },
              success(response) {
                if (response['res'] === 'ok') {
                  window.didah.submit();
                } else {
                  resolve();
                }
              },
              type: 'POST',
              url: '/ajax_api.php',
            });
          });
        },
      });

      addToggleEllipsisButton({
        datatable: dt,
        renderBtn: (btn) => {
          $divTopRight.find('div.flds_block').append(btn);
        },
      });

      tableGroupsDragula({
        colIdToFieldName: tableGroupsDragulaOptions.colIdToFieldName,
        dt,
        groupFields,
        grpCallback(grpObj) {
          fieldPickerOptions.originalData.forEach(function (d) {
            if (d['b'] !== 0) {
              d['disabled'] = false;
            }
            if ($.inArray(d['name'], grpObj.groupColumnName) >= 0) {
              d['disabled'] = true;
            }
          });

          cblm.setData(fieldPickerOptions.originalData);
        },
        initialState: tableGroupsDragulaOptions.initialState,
        onGroupingStateUpdated:
          tableGroupsDragulaOptions.onGroupingStateUpdated,
        renderBtn: (btn) => $divTopRight.find('div.flds_block').append(btn),
        renderContainer: (container) => container.insertAfter($divTop),
        simpleMode: tableGroupsDragulaOptions.simpleMode,
      });

      addSaveSettingsButton({
        getCurrentSettings,
        renderBtn: (btn) => $divTopRight.find('div.flds_block').append(btn),
        ...persistOptions,
      });

      $('.dropdown-submenu a.didah-submenu').on('click', function (e) {
        if (!$(this).parent().hasClass('disabled')) $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
      $('a.show_as_select').parent('li').addClass('disabled');
      $('a.show_as_select').addClass('btn-disabled');
      $('a.show_as_select').attr('disabled', 'disabled');

      $(`a.SaveAsPdf${id}`).data('fsess', savingOptions.pdfFsess);
      $(`a.SaveAsEInvoice${id}`).data('fsess', savingOptions.eInvoiceFsess);

      $('form.search input:submit[value=Go]').on('click', function () {
        if ($(window.didah).find('input[name="q"]')) {
          $(window.didah)
            .find('input[name="q"]')
            .val($('input:text[name=q]').val() || '');
        }
        if ($('select[name=O1]').val()) {
          $('select[name=O1]').val(formSearchOptions.selectValue);
          dt.ajax.reload();
          return false;
        }
      });

      tableAssignIds.forEach((assignId) => {
        $(`select[name="${assignId}"]`)
          .prop('onchange', '')
          .change(function (e) {
            $(window.didah)
              .find(`input[name="${assignId}"]`)
              .val($(this).val() || '');
            dt.ajax.reload();
          });
      });

      if (!window.isDatatableLocked)
        $(dt.table().container()).addClass('is-locked2');
      if (
        typeof window.SendResizeMessage != null &&
        typeof window.SendResizeMessage === 'function'
      ) {
        window.SendResizeMessage(resizeCls);
      }

      updateFixedColumnsOnZoomChange(dt);

      // apply initial search value
      if (search?.initialValue) {
        dt.search(search?.initialValue).draw();
      }
    })
    .on('xhr.dt', function (e, settings, json, xhr) {
      if (json) {
        window.data_hash = json['hash'];
        groupFields.rowsData = {};
      }

      const dt = $(this).DataTable();
      fillSearchOptionsStorage(dt);
    })
    .on('select.dt', function (e, dt, type, indexes) {
      if (!dt) {
        return;
      }

      if (dt.rows({ selected: true }).any()) {
        $('a.show_as_select').parent('li').removeClass('disabled');
        $('a.show_as_select').removeClass('btn-disabled');
        $('a.show_as_select').removeAttr('disabled');
        $(`a.SaveAsPdf${id}`).data('reclst', management.QC(id)[0]);
        $(`a.SaveAsEInvoice${id}`).data('reclst', management.QC(id)[0]);
      }
      // const api = $(this).dataTable().api()
      // const oSettings = $(this).dataTable().fnSettings()
      // setTimeout(function () {
      //     showSelection($('div.select-checkbox-header'), table)
      //     calcSubtotals(dt, groupFields)
      //     footerCallback(api, oSettings, groupFields)
      // }, 1)
      selectRecalc();
      // dt.rows(indexes).draw(false)
      // dt.table().columns.adjust()
      dt.fixedColumns().update();
    })
    .on('deselect.dt', function (e, dt, type, indexes) {
      if (!dt.rows({ selected: true }).any()) {
        $('a.show_as_select').parent('li').addClass('disabled');
        $('a.show_as_select').addClass('btn-disabled');
        $('a.show_as_select').attr('disabled', 'disabled');
        $(`a.SaveAsPdf${id}`).data('reclst', '');
        $(`a.SaveAsEInvoice${id}`).data('reclst', '');
      } else {
        $(`a.SaveAsPdf${id}`).data('reclst', management.QC(id)[0]);
        $(`a.SaveAsEInvoice${id}`).data('reclst', management.QC(id)[0]);
      }

      // setTimeout(function () {
      //     showSelection($('div.select-checkbox-header'), table)
      //     calcSubtotals(dt, groupFields)
      //     footerCallback(api, oSettings, groupFields)
      // }, 1)
      selectRecalc();
      // dt.rows(indexes).draw(false)
      // dt.table().columns.adjust()
      dt.fixedColumns().update();
    })
    .on('length.dt', function (e, settings, len) {
      $(this).DataTable().processing(true);
    })
    .on('search.dt', function (e, settings) {
      const searchValue = $(this).DataTable().search();
      if (searchValue !== '') {
        groupFields.rowsData = {};
      }

      search?.onChange(searchValue);
    })
    .on('preDraw.dt', function (e, settings) {
      calcSubtotals($(this).DataTable(), groupFields);
    })
    .on('draw.dt', function (e, settings) {
      const dt = $(this).DataTable();

      setTimeout(function () {
        $('#' + $(this).attr('id') + '_wrapper [data-toggle="popover"][cl!=on]')
          .popover({
            container: '#' + $(this).attr('id') + ' tbody',
            placement: 'auto right',
            trigger: 'click',
          })
          .attr('cl', 'on');
      }, 1);

      $(this)
        .find('tr:not(.dis) td')
        .filter(function () {
          const td = $(this);
          return !!(
            td.find('.didah-edit-date-datapicker').length ||
            td.find('.didah-edit-datetime-datapicker').length ||
            td.find('.didah-edit-input').length ||
            td.find('.didah-edit-textfield').length ||
            td.find('.didah-edit-decimal').length ||
            td.find('.didah-edit-select').length ||
            td.find('.didah-edit-checkbox').length
          );
        })
        .addClass('highlighted');

      dt.table().columns.adjust();
    })
    .on('click', 'tr:not(.dis) td', function (e) {
      window.activateCell($(this), e);
    })
    .processing(true);

  const api = $(`#new_table${id}`).dataTable().api();
  const oSettings = $(`#new_table${id}`).dataTable().fnSettings();
  const selectRecalc = _debounce(function () {
    showSelection($('div.select-checkbox-header'), table);
    calcSubtotals(table, groupFields);
    footerCallback(api, oSettings, groupFields);
  }, 600);

  return table;
}

export * from './render';
export { bd726Table };
