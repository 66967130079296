/* eslint-disable @typescript-eslint/no-unused-vars */

import { translate } from '@/common/features/translations';
import confirmationModal, { ModalType } from '../confirmation-modal';
import { makeSelectizeItemsSortable } from './selectize';
import itemTwig from './templates/item.twig';
import optionCreateTwig from './templates/option-create.twig';

type GenericSelectizeParams = {
  canCreate?: boolean;
  id: string;
  ifEmptyDoOnChange?: boolean;
  isAjax?: boolean;
  isMultiple?: boolean;
  onChange?: (value: string) => void;
  requestParams?: {
    a: string;
    fsess: string;
    fsess2: string;
  };
  sortDirection?: string;
  type?: 'json-confirmation';
};

function genericSelectize({
  canCreate,
  id,
  ifEmptyDoOnChange,
  isAjax,
  isMultiple,
  onChange,
  requestParams,
  sortDirection,
  type,
}: GenericSelectizeParams) {
  const elementSelector = `#${id}`;

  const requestToServer = async (newValue: string) => {
    if (!requestParams) {
      return Promise.reject();
    }

    const { a, fsess2 } = requestParams;

    return new Promise<{ id: string }>((resolve, reject) => {
      $.ajax({
        data: {
          a,
          fquery: newValue,
          fsess2,
          id: 17,
        },
        dataType: 'json',
        error() {
          reject();
        },
        success(res) {
          resolve(res);
        },
        type: 'POST',
        url: '/ajax_api.php',
      });
    });
  };

  async function onOptionAdd(newOptionValueAndText, data) {
    const api = $(elementSelector)[0].selectize;

    const isLoadedOption = data.id !== data.label;
    if (isLoadedOption) {
      return;
    }
    const isConfirmed = await confirmationModal({
      autoFocus: 'cancel',
      cancelStr: translate('common.cancel'),
      confirmStr: translate('common.ok'),
      text: newOptionValueAndText,
      title: translate('common.selectize.add_title'),
      type: ModalType.Primary,
    });

    if (!isConfirmed) {
      api.clear();
      return;
    }

    const response = await requestToServer(newOptionValueAndText);

    const existingOption = api.getOption(newOptionValueAndText);
    if (existingOption) {
      api.updateOption(newOptionValueAndText, {
        ...existingOption,
        id: response.id,
        label: newOptionValueAndText,
      });
      api.refreshItems();
    }
  }

  $(elementSelector).selectize({
    closeAfterSelect: !isMultiple,
    hideSelected: isMultiple || isAjax,
    labelField: 'label',
    valueField: 'id',
    ...(!isAjax && {
      sortField: {
        direction: sortDirection,
        field: 'label',
      },
    }),
    create: canCreate,
    ...(canCreate && { onOptionAdd }),
    searchField: 'label',
    ...(isMultiple && { maxItems: null, plugins: ['remove_button'] }),
    ...(isAjax && {
      load(query, callback) {
        if (!query.length) {
          this.close();
          const me = this;
          $.each(me.options, function (n, i) {
            if ($.inArray(n, me.items) < 0) {
              me.removeOption(n);
            }
          });

          return callback();
        }

        $.ajax({
          data: {
            a: requestParams.a,
            fquery: query,
            fsess: requestParams.fsess,
            id: 17,
            page_limit: 20,
          },
          dataType: 'json',
          error() {
            callback();
          },
          success(res) {
            callback(res);
          },
          type: 'POST',
          url: '/ajax_api.php',
        });
      },
    }),
    onChange(value) {
      if (!ifEmptyDoOnChange && !value) {
        return;
      }
      if (isAjax) {
        value && this.clearOptions();
      }

      if (isMultiple) {
        $(elementSelector + '_count').html(
          this.items.length == 0 ? '' : '(' + this.items.length + ')',
        );
      }

      onChange?.(value);
    },
    onInitialize() {
      if (isMultiple) {
        $(elementSelector + '_count').html(
          this.items.length == 0 ? '' : '(' + this.items.length + ')',
        );
      }
    },
    onItemAdd(value, $item) {
      if (isAjax) {
        const me = this;
        $.each(me.options, function (n, i) {
          if ($.inArray(n, me.items) < 0) {
            me.removeOption(n);
          }
        });
      }

      if (isMultiple) {
        $(elementSelector + '_count').html(
          this.items.length == 0 ? '' : '(' + this.items.length + ')',
        );
      }
    },
    onItemRemove(value, $item) {
      if (isMultiple) {
        $(elementSelector + '_count').html(
          this.items.length == 0 ? '' : '(' + this.items.length + ')',
        );
      }
      this.close();

      if (isAjax) {
        const me = this;
        $.each(me.options, function (n, i) {
          if ($.inArray(n, me.items) < 0) {
            me.removeOption(n);
          }
        });
      }
    },
    onType(value) {
      if (isAjax) {
        !value && this.clearOptions();
      }
    },
    render: {
      item(data) {
        return itemTwig({
          label: data.label,
        });
      },
      option(data) {
        return '<div class="item">' + data.label + '</div>';
      },
      option_create(data) {
        return optionCreateTwig({
          addString: translate('common.add'),
          input: data.input,
        });
      },
    },
  });

  if (type === 'json-confirmation') {
    makeSelectizeItemsSortable($(elementSelector)[0]);
  }
}

export { genericSelectize };
