import { makeModalWindow } from '@/utils';
import { translate } from '@/common/features/translations';

const getField = (field: string) => `
    <div class="form-group">
        <label for="${field}">${field}:</label>
        <textarea class="form-control lang-field" id="${field}" rows="3"></textarea>
    </div>
`;

const getBody = (langList: string[]) => `
    <div class="row">
        <div class="col-xs-12">
        <form>
            ${langList.map(getField).join('')}
        </form>
        </div>
    </div>
`;

function createAddWordModal(langList: string[]) {
  const title = translate('language_editor_translations.add_word_title');
  const body = getBody(langList);
  const close_str = translate('language_editor_translations.close_btn');
  const save_str = translate('language_editor_translations.save_btn');
  return makeModalWindow(title, body, close_str, save_str);
}

export { createAddWordModal };
