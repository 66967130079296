import { asyncPause, getCoords, getParentNodes } from '@/utils';
import $ from 'jquery';
import sum from 'lodash/sum';

type PrepareParams = {
  isToAddSpacePlaceholder?: boolean;
};

function getOffset() {
  return window.scrollY;
}

$(async function () {
  // ожидание чтоб инициализировались другие виджеты
  // наверняка мы не сможем знать когда это произойдет, поэтому просто пауза
  await asyncPause(1000);

  const toShowScroll = 'toShowScroll';
  const fixedTop = 'fixedTop';
  const prepare = (function () {
    let prevHeightSum = 0;
    return function (element, { isToAddSpacePlaceholder }: PrepareParams = {}) {
      const $element = $(element);

      // верхние отступы всех родителей
      const parentList = getParentNodes(element);
      const spaceSum = sum(
        parentList.map((parent) => {
          const space = getComputedStyle(parent).paddingTop;
          return parseInt(space.replace('px', ''));
        }),
      );

      if (isToAddSpacePlaceholder && spaceSum) {
        const $placeHolder = $(
          `<div class="tw-w-full tw-bg-background dark:tw-bg-background-dark tw-fixed tw-top-0 tw-left-0 tw-z-10" style="height: ${spaceSum}px"></div>`,
        );
        $placeHolder.insertBefore($element);
      }

      function updateHeightSum(el) {
        prevHeightSum += $(el).outerHeight();
      }

      function moveWrapOnTableContainerScroll($wrap, $originalTable) {
        $originalTable.parent().on('scroll', function () {
          requestAnimationFrame(() =>
            $wrap.css('left', +$wrap.prop('initial-left') - this.scrollLeft),
          );
        });
      }

      function setProps(el, $target) {
        const top = prevHeightSum + spaceSum;
        $target.css('top', top);
        $target.css('left', getCoords(el).left);
        $target.prop('initial-left', getCoords(el).left);
        $target.prop(toShowScroll, getCoords(el).top - spaceSum);
        $target.prop(fixedTop, prevHeightSum);
      }

      function setWidth($target, $original) {
        const $parent = $original.parent();
        if (
          $parent.get(0).tagName === 'BODY' &&
          $parent.width() === $original.width()
        ) {
          $target.css('width', '100%');
        } else {
          $target.width($original.width());
        }
      }

      function getClonedWrap(el) {
        const $wrap = $('<div/>');
        $wrap.addClass('fixed-wrap');
        $wrap.addClass('fixed-clone');
        setProps(el, $wrap);
        $wrap.hide();
        $wrap.appendTo($(document.body));
        return $wrap;
      }

      function getFixedNonCloned(el) {
        const $el = $(el);
        $el.addClass('fixed-nonclone');
        const $placeholder = $('<div class="fixed-nonclone-placeholder">');
        $placeholder.insertAfter($el);
        setWidth($placeholder, $el);
        $placeholder.height($el.outerHeight());
        $placeholder.hide();
        setProps(el, $el);
      }

      if (element.tagName === 'DIV' || element.tagName === 'TABLE') {
        // не клонируется
        getFixedNonCloned(element);
        setWidth($element, $element);

        updateHeightSum(element);
      } else if (element.tagName === 'TH' || element.tagName === 'TR') {
        // клонируется
        const $elTable = $element.closest('table');
        const $rowClone = $element.clone();
        const $rowCloneTds = $rowClone.find('th');
        const $elementTds = $element.find('th');
        for (let i = 0; i < $elementTds.length; i++) {
          $rowCloneTds.eq(i).width($elementTds.eq(i).outerWidth());
        }
        const $cloneTable = $('<table/>');
        $cloneTable.attr('class', $elTable.attr('class'));
        setWidth($cloneTable, $elTable);
        $rowClone.appendTo($cloneTable);
        const $wrap = getClonedWrap(element);
        $cloneTable.appendTo($wrap);

        moveWrapOnTableContainerScroll($wrap, $elTable);

        updateHeightSum(element);
      }
    };
  })();

  function setup() {
    $('.fixed-on-scroll').each(function (i) {
      prepare(this, { isToAddSpacePlaceholder: !i });
    });
  }

  function moveFixedClones() {
    $('.fixed-clone').each(function () {
      const $fixedClone = $(this);
      const pageOffSetAndFixedTop = getOffset() + $fixedClone.prop(fixedTop);
      if ($fixedClone.prop(toShowScroll) <= pageOffSetAndFixedTop) {
        $fixedClone.show();
      } else {
        $fixedClone.hide();
      }
    });
  }

  function moveFixedNonClones() {
    $('.fixed-nonclone').each(function () {
      const $fixedNonClone = $(this);

      const pageOffSetAndFixedTop = getOffset() + $fixedNonClone.prop(fixedTop);

      if ($fixedNonClone.prop(toShowScroll) <= pageOffSetAndFixedTop) {
        $fixedNonClone.addClass('fixed-wrap');
        $fixedNonClone.next('.fixed-nonclone-placeholder').show();
      } else {
        $fixedNonClone.removeClass('fixed-wrap');
        $fixedNonClone.next('.fixed-nonclone-placeholder').hide();
      }
    });
  }

  const tick = () => {
    moveFixedNonClones();
    moveFixedClones();
  };

  $(window).on('scroll', function () {
    requestAnimationFrame(tick);
  });

  setup();
  tick();
});
