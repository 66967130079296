type OverlayOptions = {
  noSpinner?: boolean;
  type?: 'white' | 'black';
};

function setModalLoadingOverlay(
  $modal: JQuery,
  { type = 'black' }: OverlayOptions = {},
) {
  const clazzName =
    type === 'black' ? 'loading-overlay-black' : 'loading-overlay-white';
  const $modalContent = $modal.find('.modal-content' as any);
  if (!$modalContent.find('.loading-overlay-modal' as any).length) {
    $modalContent.append(
      $(`
                <div class="loading-overlay-modal ${clazzName}">
                    <div class="sc">
                        <i class="fa fa-spinner fa-pulse fa-4x fa-fw"/>
                    </div>
                </div>`),
    );
  }
}

function removeModalLoadingOverlay($modal: JQuery) {
  $modal.find('.loading-overlay-modal' as any).remove();
}

function setContainerLoadingOverlay(
  $container: JQuery,
  style: Dictionary = {},
  { type = 'white' }: OverlayOptions = {},
) {
  const clazzName =
    type === 'black' ? 'loading-overlay-black' : 'loading-overlay-white';
  if (!$container.find('.loading-overlay-for-container' as any).length) {
    const $overlay = $(`
                            <div class="loading-overlay-for-container ${clazzName}">
                                <div class="sc">
                                    <i class="fa fa-spinner fa-pulse fa-4x fa-fw"/>
                                </div>
                            </div>`);
    for (const key in style) {
      if (Object.prototype.hasOwnProperty.call(style, key)) {
        $overlay.css(key, style[key]);
      }
    }
    $container.append($overlay);
  }
}

function removeContainerLoadingOverlay($container) {
  $container.find('.loading-overlay-for-container').remove();
}

export * from './global';

export {
  removeContainerLoadingOverlay,
  removeModalLoadingOverlay,
  setContainerLoadingOverlay,
  setModalLoadingOverlay,
};
