import { ensureArray, inIframe, isAllowedToContinueNavigation } from '@/utils';

import type { HookAction } from '@/react-app/iframe/types';
import { Commands } from '@/react-app/iframe/types';

import type {
  CloseSelfInput,
  IFrameGlobalConfig,
  IFrameOpts,
  OpenIFrameInput,
} from './types';

import config from './config';
import {
  handleCloseSelfMessage,
  handleCloseTopMessage,
  handleForceCloseAllMessage,
  handleOnCloseActionsMessage,
  postCloseAllMessage,
  postCloseSelfMessage,
  postCloseTopMessage,
  postOnCloseActionsMessage,
} from './iframe-closing';
import { handleOpeningMessage, postOpeningMessage } from './iframe-opening';

/**
 * Handled by TOP window. Actions handlers can be called directly.
 * @param configParams
 */
function useIFrameWindows(configParams?: IFrameGlobalConfig) {
  if (inIframe()) {
    return;
  }

  configParams && config.set(configParams);

  window.addEventListener('message', handleOpeningMessage, false);
  window.addEventListener('message', handleCloseSelfMessage, false);
  window.addEventListener('message', handleOnCloseActionsMessage, false);
  window.addEventListener('message', handleCloseTopMessage, false);
  window.addEventListener('message', handleForceCloseAllMessage, false);
}

function openIFrame(options: IFrameOpts) {
  if (!isAllowedToContinueNavigation()) {
    return;
  }
  const payload: OpenIFrameInput = {
    url: options.url,
  };

  if (options.onClosed) {
    payload.onClosed = ensureArray(options.onClosed);
  }

  if (options.onOpened) {
    payload.onOpened = ensureArray(options.onOpened);
  }

  postOpeningMessage(payload);
}

function closeThisIFrame(action?: HookAction[] | HookAction) {
  if (!inIframe()) {
    // eslint-disable-next-line no-console
    console.warn('Attempt to close self from non-iframe');
    return;
  }

  if (!isAllowedToContinueNavigation()) {
    return;
  }

  const payload: CloseSelfInput = {};
  if (action) {
    payload.actions = ensureArray(action);
  }

  postCloseSelfMessage(payload);
}

/**
 * This should be used to close top iframe from within other iframe.
 * Either Didah box iframe, or just regular iframes
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function closeTopWindow() {
  if (!inIframe()) {
    // eslint-disable-next-line no-console
    console.warn('Attempt to close top from non-iframe');
    return;
  }

  postCloseTopMessage();
}

/**
 * This should be used to force close all windows from within other iframe.
 * Either Didah box iframe, or just regular iframes
 */
function closeAllWindows() {
  if (!inIframe()) {
    // eslint-disable-next-line no-console
    console.warn('Attempt to close top from non-iframe');
    return;
  }

  if (!isAllowedToContinueNavigation()) {
    return;
  }

  postCloseAllMessage();
}

function addOnCloseActions(action: HookAction[] | HookAction) {
  if (!inIframe()) {
    // eslint-disable-next-line no-console
    console.warn('addOnCloseActions called not from iframe window');
    return;
  }

  const actions = ensureArray(action);
  postOnCloseActionsMessage({ actions });
}

export * from './types';
export * from './utils';
export {
  Commands,
  addOnCloseActions,
  closeAllWindows,
  closeThisIFrame,
  openIFrame,
  useIFrameWindows,
};

export { isWindowOpen } from './storage';
