import { isObject } from '@/utils';

let initResolve: Optional<() => void> = null;
const initDone = new Promise<void>((resolve) => {
  initResolve = resolve;
});

function wrapWithInit(module: Dictionary) {
  return new Proxy(module, {
    get(target: Dictionary, prop: string) {
      const value = target[prop];
      if (isObject(value)) {
        return wrapWithInit(value);
      }

      if (typeof value !== 'function') {
        return value;
      }

      return async (...args) => {
        await initDone;
        return value(...args);
      };
    },
  });
}

export { initDone, initResolve, wrapWithInit };
