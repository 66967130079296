import type { SimpleGeolocation } from '@/types';
import { cn } from '@/utils';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGeolocationLink } from './use-geolocation-link';

type Props = {
  className?: string;
  geolocation: SimpleGeolocation;
};

function GeolocationLink({ className, geolocation }: Props) {
  const url = useGeolocationLink(geolocation);

  return (
    <>
      {url ? (
        <a className={className} href={url} rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={faGlobeEurope} />
        </a>
      ) : (
        <span
          className={cn(
            'tw-cursor-not-allowed tw-text-text-default-4',
            className
          )}
        >
          <FontAwesomeIcon icon={faGlobeEurope} />
        </span>
      )}
    </>
  );
}

export { GeolocationLink };
