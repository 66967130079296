import $ from 'jquery';

function sortable() {
  try {
    const $sortable = $('.sortable');
    if (!$sortable.length) {
      return;
    }

    $sortable.tablesorter({
      dateFormat: 'dd.mm.yyyy',
    });
  } catch (e) {}
}

export { sortable };
