import map from 'lodash/map';
import filter from 'lodash/filter';

import { openIFrame } from '../iframe-windows';
import { mediaViewer } from '@/react-app/media/apps/viewer';

type DidahBoxParams = {
  constant: Dictionary;
  dynamic: Dictionary;
  url: string;
};

function openDidahBox(content: string, player?: string) {
  if (player === 'img') {
    return mediaViewer({
      documents: [{ id: '0', type: 'img', url: content }],
    });
  } else if (player === 'pdf') {
    return mediaViewer({
      documents: [{ id: '0', type: 'pdf', url: content }],
    });
  } else {
    return openIFrame({ url: content });
  }
}

function makeDidahBoxUrl(obj: Dictionary, params: DidahBoxParams): string {
  const constantParams = map(
    params.constant,
    (value, key) => `${key}=${value}`,
  ).join('&');
  const dynamicParams = filter(
    map(params.dynamic, (value, key) => {
      return obj[value] ? `${key}=${obj[value]}` : '';
    }),
  ).join('&');

  return `${params.url}?${filter([dynamicParams, constantParams]).join('&')}`;
}

function openDidahBoxFromParams(obj: Dictionary, params: DidahBoxParams): void {
  const url = makeDidahBoxUrl(obj, params);
  openDidahBox(url, 'iframe');
}

export {
  DidahBoxParams,
  makeDidahBoxUrl,
  openDidahBox,
  openDidahBoxFromParams,
};
