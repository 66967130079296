// import $ from 'jquery';

// import { signatureAjax, ExportFormats } from '@/react-app/signature';
// import { checkMobile, makeModalWindow } from '@/utils';
// import { translate } from '@/common/features/translations';
// import { ModalSizes } from '@/react-app/shared/components';

// type Params = {
//   docNr: string;
//   fsess: string;
// };

// function init(el: HTMLElement, { docNr, fsess }: Params) {
//   return signatureAjax(el, {
//     baseURL: '/ajax_api.php',
//     deleteParams: {},
//     dialogClass: !checkMobile() && 'tw-translate-y-[30vh]',
//     docNr,
//     exportFormat: ExportFormats.Svg,
//     fetchParams: {
//       fsess,
//       id: 31,
//     },
//     geoPoint: false,
//     idTypeOptions: [
//       { id: '1', name: 'Passport' },
//       { id: '2', name: 'Id card' },
//     ],
//     modalSize: ModalSizes.Large,
//     readOnly: true,
//     submitParams: {},
//     widgetId: `signatures-${docNr}`,
//   });
// }

function signaturesWidgetModal(
  
) {
  // const $modalBody = $(`
  //               <div class="signatures-outer-modal">
  //                   <div id="in-modal-widget-placeholder"></div>
  //               </div>
  //           `);
  // const el = $modalBody.find('#in-modal-widget-placeholder').get(0);
  // const $modalWindow = makeModalWindow(
  //   translate('common.signature'),
  //   $modalBody,
  //   translate('common.close'),
  //   null,
  //   { move: true },
  // );
  // $modalWindow.on('show.bs.modal', function () {
  //   init(el, params);
  // });

  // $modalWindow.modal('show');
}

export { signaturesWidgetModal };
