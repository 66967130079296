// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';
import moment from 'moment';
import sum from 'lodash/sum';
import dragula from 'dragula';

import { translate } from '@/common/features/translations';
import {
  AbstractCargo,
  AbstractCargoView,
  CalenderAjaxSenderMixin,
  CalenderAppApi,
  CargoAppAjaxSender,
  CargoAppMixin,
  CargoAppViewMixin,
  cargoStatuses,
  CargoTabs,
  CargoTraits,
  Controller,
  DidahCalender,
  DidahCalenderApp,
  DidahCalenderAppView,
  DidahCalenderDay,
  DidahCalenderDayInfo,
  DidahCalenderDayView,
  DidahCalenderList,
  DidahCalenderListModel,
  DidahCalenderListView,
  DidahCalenderView,
  Equipment,
  EquipmentView,
  Material,
  MaterialView,
  MultiSelectableObjectView,
  Panel,
  PanelView,
  TabbedAppMixin,
  TabbedAppViewMixin,
  View,
} from '@/common/features/schedulers/shared_components';
import { displayError, extractHtmlText } from '@/utils';

function DeliverySchedulerConstrAjaxSender() {
  CargoAppAjaxSender.apply(this, arguments);
  const newKeys = {
    calenderData: 'calender-data',
    dayAction: 'day-action',
    daysData: 'days-data',
    poolData: 'pool-data',
  };
  Object.assign(this.keys, newKeys);
}

DeliverySchedulerConstrAjaxSender.prototype = Object.create(
  CargoAppAjaxSender.prototype,
);
CalenderAjaxSenderMixin.call(DeliverySchedulerConstrAjaxSender.prototype);

DeliverySchedulerConstrAjaxSender.prototype.sendCustom = function (
  action,
  actionData,
) {
  const app = this.application;
  const appModel = app.model();
  app.setLoading();

  const data: { [key: string]: any } = {};
  if (actionData) {
    data.appData = btoa(JSON.stringify(actionData));
  }
  this.assignActionData(data, action, app);
  data.action = action;
  return $.ajax({
    contentType: 'application/x-www-form-urlencoded',
    data,
    dataType: 'json',
    method: 'POST',
    timeout: 10000,
    url: appModel.dataUrl,
  })
    .done(() => {
      return app.refresh();
    })
    .fail(() => {
      displayError(
        `${translate('logictics_translations.ajax_error_message')} ${action}`,
      );
      app.removeLoading();
      app.reRender(true, true);
    });
};
/*******************************************************/

const { hasCargo, removesCargo } = CargoTraits;
const getDeliveryBuildingAjaxKey = function (cargo, ajaxSender, isMulti) {
  if (
    cargo.parent instanceof DeliveryBuildingDay &&
    this instanceof DeliveryBuildingDay
  ) {
    return ajaxSender.keys.moveCargo;
  } else if (
    cargo.parent instanceof DeliveryBuildingPool &&
    this instanceof DeliveryBuildingDay
  ) {
    return isMulti ? ajaxSender.keys.addCargoMulti : ajaxSender.keys.addCargo;
  }
};

/*******************************************************/
function DeliveryBuildingCargoMixin() {
  this.__parentIsPool = function () {
    return this.parent instanceof DeliveryBuildingPool;
  };

  this._isToRender = function () {
    if (!this.__parentIsPool()) {
      return true;
    }
    return this.getApp().getActiveTab() === this.tab;
  };

  this._isToRenderHidden = function () {
    if (!this.__parentIsPool()) {
      return false;
    }
    const { title } = this._data || this.model();
    const text = extractHtmlText(title);
    const filterVal = this.getApp().getActiveTab().filterVal;
    return (
      filterVal &&
      filterVal !== '' &&
      !text.toLowerCase().includes(filterVal.toLowerCase())
    );
  };

  this._tabBufferName = ''; //to implement

  this.bufferName = function () {
    const parentController = this.parent;
    if (parentController instanceof DeliveryBuildingPool) {
      return this._tabBufferName;
    } else if (parentController instanceof DeliveryBuildingDay) {
      return parentController.formattedDate();
    } else {
      throw new Error('Incorrect cargo parent');
    }
  };
}

/*******************************************************/
function DeliveryBuildingCargoViewMixin() {
  MultiSelectableObjectView.call(this);
  this._viewData = function () {
    this._setViewSelectable();
    this._addSelectedClass();
    this._setColor();
    this._setText();
    this._addBlockedStyle();
    if (this.getApp().model().didahBoxUrlParams) {
      this._setOnPanelClick();
      this._setOnZClick();
    }
  };

  this.bufferName = function () {
    return this.controller.bufferName();
  };

  this._addBlockedStyle = function () {
    const isBlocked = this.controller.isBlocked();
    if (!isBlocked) return;

    this._$view.addClass('blocked-dragged');
  };

  this._canSelectionHappen = function () {
    const app = this.getApp();
    if (this.controller.isBlocked()) return false;

    const checkOthersBuffersAreEmpty = () => {
      const bufferName = this.bufferName();
      return !Object.entries(app.selectBuffers).find(([key, value]) => {
        return key !== bufferName && value.length;
      });
    };
    return (
      !app.selectBuffers ||
      Object.keys(app.selectBuffers).length === 0 ||
      checkOthersBuffersAreEmpty()
    );
  };
}

/*******************************************************/
const genericCargo_tabBufferName = 'generic-tab';

function DBGenericCargo() {
  AbstractCargo.apply(this, arguments);
}

DBGenericCargo.prototype = Object.create(AbstractCargo.prototype);
DeliveryBuildingCargoMixin.call(DBGenericCargo.prototype);

DBGenericCargo.prototype._assignV = function () {
  this._view = new DBGenericCargoView(this);
};
DBGenericCargo.prototype._tabBufferName = genericCargo_tabBufferName;

DBGenericCargo.prototype._isToRender = function () {
  return this.getApp().getActiveTab().type === 'refresh';
};

function DBGenericCargoView() {
  AbstractCargoView.apply(this, arguments);
}

DBGenericCargoView.prototype = Object.create(AbstractCargoView.prototype);
DeliveryBuildingCargoViewMixin.call(DBGenericCargoView.prototype);

DBGenericCargoView.prototype._getViewClass = function () {
  return '';
};

/*******************************************************/
const Panel_tabBufferName = 'panel-tab';

function DBPanel() {
  Panel.apply(this, arguments);
}

DBPanel.prototype = Object.create(Panel.prototype);
DeliveryBuildingCargoMixin.call(DBPanel.prototype);

DBPanel.prototype._assignV = function () {
  this._view = new DBPanelView(this);
};

DBPanel.prototype._tabBufferName = Panel_tabBufferName;

function DBPanelView() {
  PanelView.apply(this, arguments);
}

DBPanelView.prototype = Object.create(PanelView.prototype);
DeliveryBuildingCargoViewMixin.call(DBPanelView.prototype);
/*******************************************************/
const Equipment_tabBufferName = 'equipment-tab';

function DBEquipment() {
  Equipment.apply(this, arguments);
}

DBEquipment.prototype = Object.create(Equipment.prototype);
DeliveryBuildingCargoMixin.call(DBEquipment.prototype);

DBEquipment.prototype._assignV = function () {
  this._view = new DBEquipmentView(this);
};

DBEquipment.prototype._tabBufferName = Equipment_tabBufferName;

function DBEquipmentView() {
  EquipmentView.apply(this, arguments);
}

DBEquipmentView.prototype = Object.create(EquipmentView.prototype);
DeliveryBuildingCargoViewMixin.call(DBEquipmentView.prototype);
/*******************************************************/
const Material_tabBufferName = 'material-tab';

function DBMaterial() {
  Material.apply(this, arguments);
}

DBMaterial.prototype = Object.create(Material.prototype);
DeliveryBuildingCargoMixin.call(DBMaterial.prototype);

DBMaterial.prototype._assignV = function () {
  this._view = new DBMaterialView(this);
};

DBMaterial.prototype._tabBufferName = Material_tabBufferName;

function DBMaterialView() {
  MaterialView.apply(this, arguments);
}

DBMaterialView.prototype = Object.create(MaterialView.prototype);
DeliveryBuildingCargoViewMixin.call(DBMaterialView.prototype);

/*******************************************************/
function deliveryBuildingCargoConstructor(cargoData, parent) {
  const app = parent.getApp();
  if (app.getActiveTab().type === 'refresh') {
    return new DBGenericCargo(cargoData, parent);
  } else {
    if (cargoData.cargoType === 'panel') {
      return new DBPanel(cargoData, parent);
    } else if (cargoData.cargoType === 'equipment') {
      return new DBEquipment(cargoData, parent);
    } else if (cargoData.cargoType === 'material') {
      return new DBMaterial(cargoData, parent);
    }
  }
}

/*******************************************************/
const getToday = function () {
  const date = moment();
  date.set({
    hour: 0,
    minute: 0,
    second: 0,
  });
  return date;
};

const getAfterToday = function (days) {
  const date = getToday();
  date.add(days, 'd');
  return date;
};

/*******************************************************/
const { equipTab, materialsTab, panelsTab } = CargoTabs;

const appModel = {
  tabs: [panelsTab, equipTab, materialsTab],
};

function App(data) {
  DidahCalenderApp.apply(this, arguments);
}

App.prototype = Object.create(DidahCalenderApp.prototype);
TabbedAppMixin.call(App.prototype);
CargoAppMixin.call(App.prototype);

App.prototype._assignMV = function () {
  this._model = appModel;
  this._view = new AppView(this);
};

App.prototype.processData = function (data) {
  this.childAdd(new DeliveryBuildingPool(data.pool, this), 'pool');
  this.childAdd(new DeliveryBuildingCalender(data.calender, this), 'calender');
};

App.prototype.getPoolViewContainer = function () {
  return this._view.getPoolViewContainer();
};

App.prototype.promptBatchAmount = function (total) {
  const app = this.getApp();
  return prompt(
    `${translate('logictics_translations.delivery_move_cargo_count_prompt')}`,
    total,
  );
};

App.prototype.emptyBuffer = function () {
  this.selectBuffers = {};
};

function AppView() {
  DidahCalenderAppView.apply(this, arguments);
}

AppView.prototype = Object.create(DidahCalenderAppView.prototype);
TabbedAppViewMixin.call(AppView.prototype);
CargoAppViewMixin.call(AppView.prototype);

AppView.prototype._doInit = function () {
  this.setupDragula();
};

AppView.prototype._viewData = function () {
  if (this.getApp().isAgendaView()) {
    this._addTabs(this._$view.find('.pool-container .nav.nav-tabs'));
    this._setupTabFilter();
    this.setupMultiselectReset();
  } else {
    this._hidePoolContainer();
  }
};

AppView.prototype._afterChildrenRendered = function () {
  this._modifyTabLabels();
};

AppView.prototype._modifyTabLabels = function () {
  const getCargosQuantitySum = (cargos) =>
    sum(cargos.map((c) => +c.model().quantity || 1));
  const self = this;
  this._$currentView.find('.pool-tab').each(function () {
    const $tab = $(this);
    const tabModel = $tab.data('tab');
    const cargo = self.controller.pool.cargo;
    if (!cargo) return;

    let tabCargoSize;
    let tabCargoHiddenSize;

    if (tabModel.type === 'refresh') {
      if (self.getApp().getActiveTab() !== tabModel) return;

      tabCargoSize = getCargosQuantitySum(cargo);
      tabCargoHiddenSize = getCargosQuantitySum(cargo.filter((c) => c.hidden));
    } else {
      const tabCargo = cargo.filter((c) => c.tab === tabModel);
      tabCargoSize = getCargosQuantitySum(tabCargo);
      tabCargoHiddenSize = getCargosQuantitySum(
        tabCargo.filter((c) => c.hidden),
      );
    }

    const size = !tabCargoHiddenSize
      ? tabCargoSize
      : `${tabCargoSize - tabCargoHiddenSize}/${tabCargoSize}`;
    $tab.find('a').text(`${tabModel.label} (${size})`);
  });
};

AppView.prototype._onTabFilterChange = function () {
  this.controller.pool.cargo.forEach((cargo) => cargo.reRender(true));
};

AppView.prototype.setupDragula = function () {
  const app = this.getApp();

  const onRequestDone = (promise) => {
    promise
      .then(() => {
        app.emptyBuffer();
        app.refresh();
      })
      .catch((e) => {
        app.emptyBuffer();
        app.reRender(true, true);
      });
  };

  const singleDragOnDrop = (cargo, target, orderNum) => {
    if (
      cargo.parent instanceof DeliveryBuildingDay &&
      target instanceof DeliveryBuildingPool
    ) {
      cargo.parent.removeCargos([cargo]);
    } else if (
      cargo.parent instanceof DeliveryBuildingPool &&
      target instanceof DeliveryBuildingPool
    ) {
      drake.cancel(true);
    } else {
      if (cargo.isBatchDragged()) {
        const existingQuantity = cargo.getQuantity();
        const transferredQuantity =
          +this.controller.promptBatchAmount(existingQuantity);

        if (
          transferredQuantity === 0 ||
          transferredQuantity > existingQuantity
        ) {
          drake.cancel(true);
        } else if (transferredQuantity === existingQuantity) {
          onRequestDone(target.addCargo([cargo], orderNum));
        } else {
          onRequestDone(
            target.addCargoOfQuantity(cargo, orderNum, transferredQuantity),
          );
        }
        cargo.cancelBatchDragged();
      } else {
        onRequestDone(target.addCargo([cargo], orderNum));
      }
    }
  };

  const multiDragOnDrop = (cargos, target, orderNum) => {
    const headCargo = cargos[0];
    if (
      headCargo.parent instanceof DeliveryBuildingDay &&
      target instanceof DeliveryBuildingPool
    ) {
      headCargo.parent.removeCargos(cargos);
    } else if (
      headCargo.parent instanceof DeliveryBuildingPool &&
      target instanceof DeliveryBuildingPool
    ) {
      drake.cancel(true);
    } else {
      onRequestDone(target.addCargo(cargos, orderNum));
    }
  };

  const drake = dragula({
    copy: (el, source, handle, sibling) => {
      const { draggedController } = this._getControllers($(el));
      return (
        draggedController.isBatchDragged() ||
        this._findMultiDragCargo(draggedController).length > 1
      );
    },
    isContainer(el) {
      return el.classList.contains('delivery-building-container');
    },
    moves: (el, source, handle, sibling) => {
      const $el = $(el);
      if (!$el.hasClass('didah-cargo-draggable')) {
        return false;
      }

      const $handle = $(handle);
      const { draggedController } = this._getControllers($el);
      const multiDragCargo = this._findMultiDragCargo(draggedController);

      if (draggedController.isBlocked && draggedController.isBlocked()) {
        return false;
      }

      if (multiDragCargo.length) {
        if (!multiDragCargo.includes(draggedController)) return false;
      } else {
        //handle pulling only for non-multi drag
        if ($handle.hasClass('cargo-handle-batch')) {
          $el.data('controller').setBatchDragged();
        }
      }

      return true;
    },
    revertOnSpill: true,
  })
    .on('cloned', (clone, original, type) => {
      if (type === 'copy') {
        const cargo = $(original).data('controller');
        const $clone = $(clone);
        $clone.data('original', original);
        if (cargo.isBatchDragged()) {
          $clone.addClass('batch-dragged');
        }
      } else {
        const cargo = $(original).data('controller');
        if (cargo.isBatchDragged()) {
          $(original).addClass('batch-dragged');
        }
      }
    })
    .on('drag', (el, source) => {
      const $el = $(el);
      const { draggedController } = this._getControllers($el);
      if (draggedController.isBatchDragged()) {
        $el.addClass('batch-dragged');
      }
    })
    .on('shadow', (el, container, source) => {
      const $el = $(el);
      const $container = $(container);
      const { draggedController, targetController } = this._getControllers(
        $el,
        $container,
      );

      if (!(targetController instanceof DeliveryBuildingDay)) return;

      const multiDragCargo = this._findMultiDragCargo(draggedController);
      if (multiDragCargo.length > 1) {
        this._multiDragOnShadow(
          draggedController,
          multiDragCargo,
          $el,
          $container,
        );
      } else {
        this._singleDragOnShadow(
          draggedController,
          targetController,
          $el,
          $container,
        );
      }
    })
    .on('out', (el, container, source) => {
      const $container = $(container);
      $container.find('.shadow-clone').remove();
    })
    .on('cancel', (el, container) => {
      const $el = $(el);
      const originalOpt = $el.data('original');
      originalOpt
        ? $(originalOpt).removeClass('batch-dragged')
        : $el.removeClass('batch-dragged');

      const { draggedController } = this._getControllers($el);
      draggedController.cancelBatchDragged();

      $el.removeClass('batch-dragged');
      $('.hidden-copies-in-source').removeClass('hidden-copies-in-source');
    })
    .on('drop', (el, target, source, sibling) => {
      const $el = $(el);
      const $target = $(target);
      const { draggedController, targetController } = this._getControllers(
        $el,
        $target,
      );

      let orderNum = $target.children('.didah-cargo').index($('.gu-transit'));
      if (
        draggedController.parent instanceof DeliveryBuildingPool &&
        targetController instanceof DeliveryBuildingDay
      ) {
        orderNum = $target.children('.didah-cargo').length;
      }

      const multiDragCargo = this._findMultiDragCargo(draggedController);
      if (multiDragCargo.length > 1) {
        multiDragOnDrop(multiDragCargo, targetController, orderNum);
      } else {
        singleDragOnDrop(draggedController, targetController, orderNum);
      }
    });
};

AppView.prototype.setupMultiselectReset = function () {
  if (this.getApp().enabled) {
    this._$view.click((e) => {
      const app = this.getApp();
      if (
        !app.selectBuffers ||
        !Object.values(app.selectBuffers).find((b) => b.length)
      )
        return;
      if (!($(e.target).hasClass('multi-selected') && e.ctrlKey)) {
        app.emptyBuffer();
        app.reRender(true, true);
      }
    });
  }
};

AppView.prototype.getPoolViewContainer = function () {
  return this._$view.find('.pool-container');
};

AppView.prototype._getTemplate = function () {
  const app = this.getApp();
  return `<div id="delivery-building" class="col-xs-12 didah-app-root">
                    <div id="delivery-building-calender-container" class="col-xs-12 calender-container"></div>
                    <div id="delivery-building-pool-container" class="col-xs-12 pool-container">
                        <ul class="nav nav-tabs">
                            <li class="pull-right">
                            <div class="form-inline">
                                <div class="form-group tab-filter">
                                  <label for="tab-filter">
                                        ${translate(
                                          'logictics_translations.pool_search_label',
                                        )}
                                    </label>
                                    <div class="input-group">
                                        <span class="input-group-addon">Go</span>
                                        <input type="text" class="form-control" name="msg">
                                    </div>
                                  <span class="remove-filter">×</span>
                                </div>
                            </div>        
                            </li>
                        </ul>
                    </div>
                </div>`;
};
/*******************************************************/
const deliveryPoolModel = {
  id: 'deliveryBuildingPool',
};

function DeliveryBuildingPool(data, parent) {
  Controller.apply(this, arguments);
}

DeliveryBuildingPool.prototype = Object.create(Controller.prototype);
hasCargo.call(DeliveryBuildingPool.prototype);

DeliveryBuildingPool.prototype._getKey = getDeliveryBuildingAjaxKey;
DeliveryBuildingPool.prototype._doInit = function () {
  this._view = new DeliveryBuildingPoolView(this);
  this._model = deliveryPoolModel;
  this._data &&
    this._data
      .sort((a, b) => +a.didah_pos - +b.didah_pos)
      .forEach((c, i) => {
        c.local_index = i + 1;
        const newCargo = deliveryBuildingCargoConstructor(c, this);
        this.toChildArrayAdd(newCargo, 'cargo');
      });

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryBuildingPool.prototype.getViewContainer = function () {
  return this.parent.getPoolViewContainer();
};

DeliveryBuildingPool.prototype.getInfo = function () {
  return {
    type: 'pool',
  };
};

function DeliveryBuildingPoolView() {
  View.apply(this, arguments);
}

DeliveryBuildingPoolView.prototype = Object.create(View.prototype);

DeliveryBuildingPoolView.prototype._viewData = function () {
  this._createTabsContents();
};

DeliveryBuildingPoolView.prototype._createTabsContents = function () {
  const application = this.getApp();
  const create$TabContent = (tabName) =>
    $(
      `<div class="didah-pool-tab cargo-tab-${tabName} delivery-building-container" style="display: none"></div>`,
    );

  const activeTab = application.getActiveTab();
  application.getTabs().forEach((tab) => {
    if (!(tab.type && tab.type === 'refresh')) {
      const $tabContent = create$TabContent(tab.name);
      if (tab === activeTab) {
        $tabContent.show();
      }
      $tabContent.data('controller', this.controller);

      $tabContent.appendTo(this._$view);
    } else if (tab.type && tab.type === 'refresh') {
      const $tabContent = this._$view.find('.cargo-tab-__generic');
      if (tab === activeTab) {
        $tabContent.show();
      }
      $tabContent.data('controller', this.controller);
    }
  });
};

DeliveryBuildingPoolView.prototype.getCargoViewContainer = function (cargo) {
  if (this.getApp().getActiveTab().type === 'refresh') {
    return this._$view.find('.cargo-tab-__generic');
  } else {
    return this._$view.find(`.cargo-tab-${cargo.tab.name}`);
  }
};

DeliveryBuildingPoolView.prototype._getTemplate = function () {
  return `<div id="delivery-building-pool" class="didah-pool">
                    <div class="didah-pool-tab cargo-tab-__generic delivery-building-container" style="display: none"></div>
                </div>`;
};
/*******************************************************/
const deliveryBuildingCalenderModel = {
  id: 'deliveryBuildingCalender',
};

function DeliveryBuildingCalender(data, parent) {
  DidahCalender.apply(this, arguments);
}

DeliveryBuildingCalender.prototype = Object.create(DidahCalender.prototype);

DeliveryBuildingCalender.prototype._doInit = function () {
  this._view = new DeliveryBuildingCalenderView(this);
  this._model = deliveryBuildingCalenderModel;

  const daysMomentsMapped = this._getDaysAccordingToPeriod();

  if (this._data) {
    this._data.days = this._remapData(this._data, daysMomentsMapped);
  } else {
    const days = Object.values(daysMomentsMapped);
    days.forEach((day) => {
      day.cargos = [];
      day.html = '';
    });
    this._data = {};
    this._data.days = days;
  }

  this.childAdd(
    new DeliveryBuildingCalenderList({ days: this._data.days }, this),
    'list',
  );
  this._data.days.forEach((dayData, i) => {
    dayData.orderNum = i;
    this.toChildArrayAdd(new DeliveryBuildingDay(dayData, this), 'days');
    this.toChildArrayAdd(new DidahCalenderDayInfo(dayData, this), 'dayinfos');
  });

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryBuildingCalender.prototype._remapData = function (
  calenderData,
  daysMomentsMapped,
) {
  calenderData.itemsData.forEach((cargo) => {
    const cargoDayMoment = moment(cargo.dt);
    const dayFormatted = cargoDayMoment.format('DD-MM-YYYY');
    if (daysMomentsMapped[dayFormatted]) {
      !daysMomentsMapped[dayFormatted].cargos &&
        (daysMomentsMapped[dayFormatted].cargos = []);
      daysMomentsMapped[dayFormatted].cargos.push(cargo);
    }
  });

  calenderData.daysInfo.forEach((dayInfo) => {
    const infoDayMoment = moment(dayInfo.dt);
    const dayFormatted = infoDayMoment.format('DD-MM-YYYY');
    if (daysMomentsMapped[dayFormatted]) {
      !daysMomentsMapped[dayFormatted].infoHtml &&
        (daysMomentsMapped[dayFormatted].infoHtml = dayInfo.html);
    }
  });
  return Object.values(daysMomentsMapped);
};

function DeliveryBuildingCalenderView() {
  DidahCalenderView.apply(this, arguments);
}

DeliveryBuildingCalenderView.prototype = Object.create(
  DidahCalenderView.prototype,
);

DeliveryBuildingCalenderView.prototype._getTemplate = function () {
  const app = this.getApp();
  return `<div id="delivery-building-calender" class="fc col-xs-12">
                    <div class="col-xs-12 calender-controls" id="delivery-building-calender-controls">
                        <div class="col-xs-12 col-sm-4 calender-controls-left">
                            <div class="fc-button-group">
                                <button type="button" class="fc-button fc-state-default fc-corner-left didah-app-scroll-back-btn">
                                    <span class="fc-icon fc-icon-left-single-arrow"></span>
                                </button>
                                <button type="button" class="fc-button fc-state-default fc-corner-right didah-app-scroll-forward-btn">
                                    <span class="fc-icon fc-icon-right-single-arrow"></span>
                                </button>
                            </div>
                            <div class="fc-button-group today-print-buttons">
                                <button 
                                    type="button" 
                                    class="fc-button fc-state-default fc-corner-left didah-app-today-btn">
                                        ${translate(
                                          'logictics_translations.today',
                                        )}
                                </button>
                                <div class="dropdown">
                                    <button 
                                        type="button"
                                        data-toggle="dropdown" 
                                        class="dropdown-toggle fc-button fc-state-default fc-corner-right didah-app-go-to-btn">
                                            ${translate(
                                              'logictics_translations.goto',
                                            )}
                                    </button>
                                    <div class="dropdown-menu">
                                        <div id="dddatepicker"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4 calender-controls-middle">
                            <h2 id="delivery-building-calender-header" class="calender-header"></h2>
                        </div>
                        <div class="col-xs-12 col-sm-4 calender-controls-right">
                            <div class="fc-button-group pull-right duration-buttons"></div>
                        </div>
                    </div>
                    <div class="col-xs-12 calender-days fc" id="delivery-building-calender-days">
                        <div class="col-xs-12 calender-days-info" id="delivery-building-calender-days-info"></div>
                        <div class="col-xs-12 calender-days-content" id="delivery-building-calender-days-content"></div>
                    </div>
                    <div class="col-xs-12 calender-list fc" id="delivery-building-calender-list"></div>
                </div>`;
};

/*******************************************************/
function DeliveryBuildingCalenderList(data, parent) {
  DidahCalenderList.apply(this, arguments);
}

DeliveryBuildingCalenderList.prototype = Object.create(
  DidahCalenderList.prototype,
);

DeliveryBuildingCalenderList.prototype._assignMV = function () {
  this._model = new DidahCalenderListModel(this._data);
  this._view = new DeliveryBuildingCalenderListView(this);
};

function DeliveryBuildingCalenderListView() {
  DidahCalenderListView.apply(this, arguments);
}

DeliveryBuildingCalenderListView.prototype = Object.create(
  DidahCalenderListView.prototype,
);

DeliveryBuildingCalenderListView.prototype._listData = function () {
  const mc = this.controller.model();
  return mc.days.filter((day) => day.cargos && day.cargos.length);
};

DeliveryBuildingCalenderListView.prototype._viewData = function () {
  const listData = this._listData();
  if (!listData.length) return;

  const $table = this._$view;
  listData.forEach((day) => {
    $(
      `<tr class="day-header-row"><td colspan="3">${day.moment.format(
        'dddd D.M',
      )}</td></tr>`,
    ).appendTo($table);
    day.cargos.forEach((cargoData) => {
      const color = cargoStatuses[cargoData.status];
      const styleStr = color ? `style="background-color: ${color}"` : '';
      $(`<tr class="day-entry-row">
                        <td><div class="entry-color-circle" ${styleStr}></div></td>
                        <td>${cargoData.title}</td>
                        <td class="table-td">${cargoData.desc}</td>
                    </tr>`).appendTo($table);
    });
  });
};

/*******************************************************/
function DeliveryBuildingDayModel(data) {
  this.day = data.day;
  this.month = data.month;
  this.year = data.year;
  this.moment = data.moment;
  this.orderNum = data.orderNum;
}

function DeliveryBuildingDay(data, parent) {
  DidahCalenderDay.apply(this, arguments);
}

DeliveryBuildingDay.prototype = Object.create(DidahCalenderDay.prototype);
hasCargo.call(DeliveryBuildingDay.prototype);
removesCargo.call(DeliveryBuildingDay.prototype);

DeliveryBuildingDay.prototype._getKey = getDeliveryBuildingAjaxKey;
DeliveryBuildingDay.prototype._doInit = function () {
  this._view = new DeliveryBuildingDayView(this);
  this._model = new DeliveryBuildingDayModel(this._data);
  this._data.cargos &&
    this._data.cargos
      .sort((a, b) => +a.didah_pos - +b.didah_pos)
      .forEach((c, i) => {
        const local_index = i + 1;
        c.local_index = local_index;
        if (local_index !== 1 && local_index === this._data.cargos.length) {
          c.local_last = true;
        }
        const newCargo = deliveryBuildingCargoConstructor(c, this);
        this.toChildArrayAdd(newCargo, 'cargo');
      });
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryBuildingDay.prototype._removeCargoMsgTemplate = function () {
  return translate('logictics_translations.delivery_remove_cargo_prompt');
};

function DeliveryBuildingDayView() {
  DidahCalenderDayView.apply(this, arguments);
}

DeliveryBuildingDayView.prototype = Object.create(
  DidahCalenderDayView.prototype,
);

DeliveryBuildingDayView.prototype.getCargoViewContainer = function () {
  return this._$view.find('.calender-day-content');
};

DeliveryBuildingDayView.prototype._getTemplate = function () {
  return `<div class="delivery-building-calender-day calender-day">
                    <div class="calender-day-header col-xs-12">
                        <span class="header-date"></span>
                        <div class="dropdown pull-right">
                            <span class="glyphicon glyphicon-option-vertical " data-toggle="dropdown"></span>
                            <ul class="dropdown-menu"></ul>
                        </div>
                    </div>
                    <div class="calender-day-content delivery-building-container col-xs-12"></div>
                </div>`;
};

DeliveryBuildingDayView.prototype._setHeader = function () {
  const application = this.getApp();
  const appMc = application.model();
  const c = this.controller;
  const mc = c.model();
  const $header = this._$view.find('.calender-day-header .header-date');

  const isMonthView = appMc.duration.type === 'month';

  if (!isMonthView) {
    $header.text(mc.moment.format('W | dd D.M'));
  } else if (isMonthView && mc.orderNum < 7) {
    $header.text(mc.moment.format('dd'));
  } else {
    this._$view.addClass('not-top');
    $header.remove();
  }

  if (application.enabled && appMc.dayActions && appMc.dayActions.length) {
    const $dropDownToggle = this._$view.find('.calender-day-header .glyphicon');
    $dropDownToggle.dropdown();

    const $dropDownMenu = this._$view.find(
      '.calender-day-header .dropdown-menu',
    );

    const iconExists = appMc.dayActions.find((action) => !!action.icon);
    appMc.dayActions.forEach((action) => {
      if (action.beforeBreak) {
        $dropDownMenu.append('<hr style="margin: 0">');
      }
      const blankIconHTML = iconExists
        ? '<span style="margin-right: 22px"></span>'
        : '';
      const iconHTML = action.icon
        ? `<i class="${action.icon} fa-fw"
                        aria-hidden="true"
                        ${
                          action.iconColor
                            ? `style="color: ${action.iconColor};"`
                            : ''
                        }
                     />`
        : blankIconHTML;
      const $menuItem = $(`
                    <li>
                        <a href="#">${iconHTML} ${action.label}</a>
                    </li>`);
      $dropDownMenu.append($menuItem);
      $menuItem.click(() => {
        const ajaxSender = this.getApp().getAjaxSender();
        const ajaxData = {
          ...action.params,
          ...this.controller.getInfo(),
        };
        ajaxSender.sendCustom(ajaxSender.keys.dayAction, ajaxData);
      });
    });
  } else {
    this._$view.find('.calender-day-header .dropdown').remove();
  }
};

/*******************************************************/
function DidahDeliverySchedulerBuilding(data) {
  CalenderAppApi.apply(this, arguments);
}

DidahDeliverySchedulerBuilding.prototype = Object.create(
  CalenderAppApi.prototype,
);

DidahDeliverySchedulerBuilding.prototype.init = function () {
  const app: any = (this._app = new App(this._data));
  app.setAjaxSender(new DeliverySchedulerConstrAjaxSender(app));

  ['setActiveTab', 'setTabFilterVal'].forEach((methodName) =>
    this._exposeMethod(methodName, true),
  );

  ['getTabs', 'getActiveTab', 'getTabFilterVal'].forEach((methodName) =>
    this._exposeMethod(methodName),
  );

  CalenderAppApi.prototype.init.apply(this, arguments);
};

export { DidahDeliverySchedulerBuilding };
