import { guid } from './general';

class ToggleInLocalStorage {
  constructor(private key: string) {}
  get(): Optional<boolean> {
    if (localStorage.getItem(this.key) === 'true') {
      return true;
    } else if (localStorage.getItem(this.key) === 'false') {
      return false;
    }
    return undefined;
  }
  set(value: boolean) {
    localStorage.setItem(this.key, value ? 'true' : 'false');
  }
}
function localStorageExists(): boolean {
  const test = guid();
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export { ToggleInLocalStorage, localStorageExists };
