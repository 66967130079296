import { useCallback } from 'react';
import { useMediaDialogContext } from '../context';

const useMediaNavigation = () => {
  const { allMedia, updateMedia } = useMediaDialogContext();

  const next = useCallback(() => {
    if (allMedia.files.length < 2) {
      return;
    }

    updateMedia({
      ...allMedia,
      activeFile: (allMedia.activeFile + 1) % allMedia.files.length,
    });
  }, [allMedia, updateMedia]);

  const prev = useCallback(() => {
    if (allMedia.files.length < 2) {
      return;
    }

    const minusOne = allMedia.activeFile - 1;

    updateMedia({
      ...allMedia,
      activeFile: minusOne < 0 ? allMedia.files.length - 1 : minusOne,
    });
  }, [allMedia, updateMedia]);

  const buttonsDisabled = allMedia.files.length < 2;
  const currentFile = allMedia.activeFile + 1;
  const totalFiles = allMedia.files.length;

  return {
    buttonsDisabled,
    currentFile,
    next,
    prev,
    totalFiles,
  };
};

export { useMediaNavigation };
