import { useFileLibraryContext } from '../context';
import { ContentFilesGrid } from './ContentFilesGrid';

function FileLibraryContent() {
  const { selectedPath } = useFileLibraryContext();
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-gap-1">
      <div className="tw-flex-none tw-px-2 tw-py-1">
        <div className="well well-sm tw-mb-0">{selectedPath}</div>
      </div>
      <div className="tw-flex-1 tw-overflow-auto">
        <ContentFilesGrid />
      </div>
    </div>
  );
}

export { FileLibraryContent };
