import type {
  CloseSelfMessage,
  CloseTopMessage,
  ForceCloseAllMessage,
  OnCloseActionsMessage,
} from '../types';
import { MessageTypes } from '../types';
import { windows, onCloseActions } from '../storage';
import { forceCloseAllWindows, closeTopChildFromRoot } from './actions';

/**
 * Called in the root window
 * @param data
 * @param source
 */
function handleCloseSelfMessage({ data, source }: MessageEvent) {
  const { payload, type } = data as CloseSelfMessage;
  if (type !== MessageTypes.CloseSelf) {
    return;
  }

  const childWindow = source as Window;
  const id = childWindow.name;

  const childInstance = windows.find((instance) => instance.id === id);
  if (!childInstance) {
    // eslint-disable-next-line no-console
    console.error('Child instance not found');
    return;
  }

  childInstance.close(payload?.actions);
}

/**
 * Stores actions in the root window
 * @param data
 * @param source
 */
function handleOnCloseActionsMessage({ data, source }: MessageEvent) {
  const { payload, type } = data as OnCloseActionsMessage;
  if (type !== MessageTypes.OnCloseActions) {
    return;
  }

  const childWindow = source as Window;

  // похоже оно иногда может быть null
  if (!childWindow) {
    return;
  }

  const id = childWindow.name;

  onCloseActions[id] = [...(onCloseActions[id] || []), ...payload.actions];
}

function handleCloseTopMessage({ data }: MessageEvent) {
  const { type } = data as CloseTopMessage;
  if (type !== MessageTypes.CloseTop) {
    return;
  }

  closeTopChildFromRoot();
}

function handleForceCloseAllMessage({ data }: MessageEvent) {
  const { type } = data as ForceCloseAllMessage;
  if (type !== MessageTypes.ForceCloseAll) {
    return;
  }

  forceCloseAllWindows();
}

export {
  handleCloseSelfMessage,
  handleCloseTopMessage,
  handleForceCloseAllMessage,
  handleOnCloseActionsMessage,
};
