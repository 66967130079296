import { cn } from 'utils';

import { useMediaDialogContext } from '../../context';

import { translate } from '@/common';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faFile,
  faFileArchive,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasicControls } from '../common';

function MinimalViewer() {
  const { currentFile, onDownload } = useMediaDialogContext();

  if (!currentFile) return null;

  const { type } = currentFile;

  let icon: Nullable<IconDefinition> = null;

  switch (type) {
    case 'pdf': {
      icon = faFilePdf;
      break;
    }
    case 'word': {
      icon = faFileWord;
      break;
    }
    case 'excel': {
      icon = faFileExcel;
      break;
    }
    case 'powerpoint': {
      icon = faFilePowerpoint;
      break;
    }
    case 'zip': {
      icon = faFileArchive;
      break;
    }
    default: {
      icon = faFile;
    }
  }

  return (
    <>
      <BasicControls />
      <div
        className={cn(
          'tw-relative tw-flex tw-h-full tw-w-full !tw-max-w-full tw-items-center tw-justify-center tw-overflow-auto tw-pt-12 tw-shadow-none tw-shadow-black/5'
        )}
      >
        <div className="tw-absolute tw-top-12 tw-flex tw-w-full tw-justify-center tw-p-7 tw-text-lg tw-text-text-default-contrast ">
          <div className="tw-text-center md:tw-max-w-xl">
            {translate('documents_viewer.download_hint')}
          </div>
        </div>
        <div
          className={cn(
            'tw-flex tw-min-w-96 tw-max-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-gap-6 tw-space-y-1.5 tw-rounded-lg tw-py-7 tw-transition-colors hover:tw-bg-white/10'
          )}
          title={translate('documents_viewer.download_title')}
          onClick={() => onDownload()}
        >
          <FontAwesomeIcon
            className={cn('tw-mb-4 tw-text-8xl tw-text-text-default-contrast', {
              'tw-text-black': type === 'text',
              'tw-text-blue-600': type === 'word',
              'tw-text-green-600': type === 'excel',
              'tw-text-pink-600': type === 'pdf',
              'tw-text-purple-600': type === 'zip',
              'tw-text-red-600': type === 'powerpoint',
            })}
            icon={icon}
          />
          <div
            className="tw-text-md tw-max-w-full tw-truncate tw-px-7 tw-text-text-default-contrast md:tw-text-lg lg:tw-max-w-3xl lg:tw-text-2xl"
            title={currentFile.name}
          >
            {currentFile.name}
          </div>
        </div>
      </div>
    </>
  );
}

export { MinimalViewer };
