import { asyncPause } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useRootContext } from '../../../hooks/use-root-context';

const useStandaloneModalWindow = () => {
  const { root } = useRootContext();
  const [isShown, setShown] = useState(false);
  const [isHiding, setHiding] = useState(false);
  const [isClosing, setClosing] = useState(false);

  const hide = useCallback((params?: { keepRendering?: boolean }) => {
    if (!params?.keepRendering) {
      setShown(false);
    }
    setHiding(true);
  }, []);
  const show = useCallback(() => {
    setShown(true);
    setHiding(false);
  }, []);

  useEffect(() => {
    show();
  }, [show]);

  const closeWindow = useCallback(async () => {
    setClosing(true);
    hide();

    await asyncPause(300);

    root.remove();
  }, [hide, root]);

  return {
    closeWindow,
    hide,
    isClosing,
    isHiding,
    isShown,
    show,
  };
};

export { useStandaloneModalWindow };
