import { checkMobile, cn } from '@/utils';
import { FileLibraryContent } from './content/FileLibraryContent';
import { FileLibraryTree } from './FileLibraryTree';

const isMobile = checkMobile();

function FileLibrary() {
  return (
    <div
      className={cn(
        'tw-flex tw-gap-1 tw-overflow-hidden tw-rounded-lg tw-bg-foreground-default dark:tw-bg-foreground-default-2-dark',
        {
          'tw-h-[calc(75vh-1.75rem)]': !isMobile,
          'tw-h-full tw-flex-col': isMobile,
        },
      )}
    >
      <div
        className={cn({
          'tw-h-1/4': isMobile,
          'tw-min-w-48 tw-flex-none': !isMobile,
        })}
      >
        <FileLibraryTree />
      </div>
      <div
        className={cn({
          'tw-flex-1': !isMobile,
          'tw-h-3/4': isMobile,
        })}
      >
        <FileLibraryContent />
      </div>
    </div>
  );
}

export { FileLibrary };
