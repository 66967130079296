import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import range from 'lodash/range';
import type { DocumentFile } from '../../types';

import { DocumentTile, GhostTile } from '@/react-app/shared/containers';
import { Recorder } from '../audio';
import { DocumentPreview } from './DocumentPreview';
import { MainButton } from './MainButton';
import { CameraButton } from './CameraButton';
import { FileLibraryButton } from './FileLibraryButton';
import { cn } from '@/utils';

type Props = {
  accept?: string;
  documents: DocumentFile[];
  ghosts?: number;
  hasCamera?: boolean;
  hasLibrary?: boolean;
  hasSelection?: boolean;
  hasUpload?: boolean;
  isLoading?: boolean;
  isMultiUpload?: boolean;
  isUploading?: boolean;
  isWithDeletion?: boolean;
  onDeleted?: (deletedDocument: DocumentFile) => void;
  onFileLibrary?: VoidFunction;
  onOpen: (url: string) => void;
  onPhotoCamera?: VoidFunction;
  onSelectAll: VoidFunction;
  onSelected: (id: string) => void;
  onUpload?: (formData: FormData) => void;
  selectedDocumentsIds: Nullable<string[]>;
};
function MediaFilesGrid({
  accept,
  documents,
  ghosts,
  hasCamera,
  hasLibrary,
  hasSelection,
  hasUpload,
  isLoading,
  isMultiUpload,
  isUploading,
  isWithDeletion,
  onDeleted,
  onFileLibrary,
  onOpen,
  onPhotoCamera,
  onSelectAll,
  onSelected,
  onUpload,
  selectedDocumentsIds,
}: Props) {
  return (
    //
    <div
      className={cn('tw-relative tw-flex tw-flex-wrap tw-gap-2 tw-p-2', {
        'tw-backdrop-blur-sm': isLoading,
      })}
    >
      {(hasUpload || hasSelection) && (
        <MainButton
          accept={accept}
          documentsSelected={selectedDocumentsIds?.length ?? 0}
          documentsTotal={documents?.length ?? 0}
          hasSelection={hasSelection}
          hasUpload={hasUpload}
          isDisabled={isUploading}
          isMultiUpload={isMultiUpload}
          onSelectAll={onSelectAll}
          onUpload={onUpload}
        />
      )}
      {hasUpload && <Recorder maxAudioLength={300} uploadFiles={onUpload} />}
      {hasUpload && hasCamera && <CameraButton onClick={onPhotoCamera} />}
      {hasLibrary && <FileLibraryButton onClick={onFileLibrary} />}
      {documents?.map((document) => (
        <DocumentTile
          key={document.id}
          hasSelection={hasSelection}
          isSelected={selectedDocumentsIds?.includes(document.id)}
          isWithDeletion={isWithDeletion}
          title={document.text}
          onClick={() => {
            onOpen(document.url);
          }}
          onDelete={() => {
            if (
              confirm(
                `Are you sure you want to delete document${
                  document.text ? ' ' + document.text : ''
                }?`,
              )
            ) {
              onDeleted?.(document);
            }
          }}
          onSelect={() => onSelected(document.id)}
        >
          <DocumentPreview document={document} />
        </DocumentTile>
      ))}
      {range(ghosts).map((i) => (
        <GhostTile
          key={`ghost-${i}`}
          className="tw-flex tw-items-center tw-justify-center"
        >
          <FontAwesomeIcon
            className="tw-animate-spin tw-text-4xl"
            icon={faSpinner}
          />
        </GhostTile>
      ))}

      {isLoading && (
        <div className="tw-absolute tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-py-4 tw-text-4xl">
          <FontAwesomeIcon className="tw-animate-spin" icon={faSpinner} />
        </div>
      )}
    </div>
  );
}

export { MediaFilesGrid };
