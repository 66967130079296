import { Card, DndUploaderZone } from '@/react-app/shared/components';
import { useMediaDialogContext } from '../context';
import { translate } from '@/common';

function MediaUploader() {
  const { uploadFiles } = useMediaDialogContext();

  return (
    <div className="tw-flex tw-size-full tw-items-center tw-justify-center tw-p-8">
      <Card className="tw-max-w-80 tw-p-4">
        <DndUploaderZone
          buttonText={translate('documents_viewer.upload_zone.button_text')}
          disabled={!uploadFiles}
          draggingHint={translate('documents_viewer.upload_zone.dragging_hint')}
          zoneHint={translate('documents_viewer.upload_zone.zone_hint')}
          onUpload={uploadFiles}
        />
      </Card>
    </div>
  );
}

export { MediaUploader };
