// @ts-nocheck
function lv(moment) {
  const units = {
    M: 'mēneša_mēnešiem_mēnesis_mēneši'.split('_'),
    MM: 'mēneša_mēnešiem_mēnesis_mēneši'.split('_'),
    d: 'dienas_dienām_diena_dienas'.split('_'),
    dd: 'dienas_dienām_diena_dienas'.split('_'),
    h: 'stundas_stundām_stunda_stundas'.split('_'),
    hh: 'stundas_stundām_stunda_stundas'.split('_'),
    m: 'minūtes_minūtēm_minūte_minūtes'.split('_'),
    mm: 'minūtes_minūtēm_minūte_minūtes'.split('_'),
    ss: 'sekundes_sekundēm_sekunde_sekundes'.split('_'),
    y: 'gada_gadiem_gads_gadi'.split('_'),
    yy: 'gada_gadiem_gads_gadi'.split('_'),
  };
  /**
   * @param withoutSuffix boolean true = a length of time; false = before/after a period of time.
   */
  function format(forms, number, withoutSuffix) {
    if (withoutSuffix) {
      // E.g. "21 minūte", "3 minūtes".
      return number % 10 === 1 && number % 100 !== 11 ? forms[2] : forms[3];
    } else {
      // E.g. "21 minūtes" as in "pēc 21 minūtes".
      // E.g. "3 minūtēm" as in "pēc 3 minūtēm".
      return number % 10 === 1 && number % 100 !== 11 ? forms[0] : forms[1];
    }
  }
  function relativeTimeWithPlural(number, withoutSuffix, key) {
    return number + ' ' + format(units[key], number, withoutSuffix);
  }
  function relativeTimeWithSingular(number, withoutSuffix, key) {
    return format(units[key], number, withoutSuffix);
  }
  function relativeSeconds(number, withoutSuffix) {
    return withoutSuffix ? 'dažas sekundes' : 'dažām sekundēm';
  }

  moment.defineLocale('lv', {
    calendar: {
      lastDay: '[Vakar pulksten] LT',
      lastWeek: '[Pagājušā] dddd [pulksten] LT',
      nextDay: '[Rīt pulksten] LT',
      nextWeek: 'dddd [pulksten] LT',
      sameDay: '[Šodien pulksten] LT',
      sameElse: 'L',
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    longDateFormat: {
      L: 'DD.MM.YYYY.',
      LL: 'YYYY. [gada] D. MMMM',
      LLL: 'YYYY. [gada] D. MMMM, HH:mm',
      LLLL: 'YYYY. [gada] D. MMMM, dddd, HH:mm',
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
    },
    months:
      'janvāris_februāris_marts_aprīlis_maijs_jūnijs_jūlijs_augusts_septembris_oktobris_novembris_decembris'.split(
        '_'
      ),
    monthsShort: 'jan_feb_mar_apr_mai_jūn_jūl_aug_sep_okt_nov_dec'.split('_'),
    ordinal: '%d.',
    relativeTime: {
      M: relativeTimeWithSingular,
      MM: relativeTimeWithPlural,
      d: relativeTimeWithSingular,
      dd: relativeTimeWithPlural,
      future: 'pēc %s',
      h: relativeTimeWithSingular,
      hh: relativeTimeWithPlural,
      m: relativeTimeWithSingular,
      mm: relativeTimeWithPlural,
      past: 'pirms %s',
      s: relativeSeconds,
      ss: relativeTimeWithPlural,
      y: relativeTimeWithSingular,
      yy: relativeTimeWithPlural,
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
    weekdays:
      'svētdiena_pirmdiena_otrdiena_trešdiena_ceturtdiena_piektdiena_sestdiena'.split(
        '_'
      ),
    weekdaysMin: 'Sv_P_O_T_C_Pk_S'.split('_'),
    weekdaysParseExact: true,
    weekdaysShort: 'Sv_P_O_T_C_Pk_S'.split('_'),
  });
}

export { lv };
