import $ from 'jquery';

export function ajaxPopover(params) {
  function runAjax() {
    $.ajax({
      async: true,
      cache: false,
      success(result, textStatus, XMLHttpRequest) {
        if (XMLHttpRequest.status === 200) {
          handleResult(result);
        }
      },
      timeout: 10000,
      type: 'GET',
      url,
    });
  }

  function handleResult(result) {
    if (onResultHandle) {
      result = onResultHandle(result);
    }
    btn.addClass('loaded');
    btn.data('bs.popover').options.content = result;
    btn.popover('show');
  }

  const btnSelector = params.selector;
  const insideClickExceptionsSelectors = params.exceptions;
  const url = params.url;
  const onResultHandle = params.handleResult;
  let placement = 'bottom';
  if (params.placement) {
    placement = params.placement;
  }

  const btn = $(btnSelector);
  const loadingImage = '<i class="fa fa-spinner fa-pulse fa-4x fa-fw"/>';

  if (btn.length) {
    btn.popover({
      content: loadingImage,
      html: true,
      placement,
    });

    //click event handling
    btn.on('click', function (e) {
      e.stopPropagation();
    });
    $(document.body).on('click', function (e) {
      function isClickOnException() {
        let clickOnException = false;
        if (
          insideClickExceptionsSelectors &&
          insideClickExceptionsSelectors.constructor === Array
        ) {
          for (let i = 0; i < insideClickExceptionsSelectors.length; i++) {
            const selector = insideClickExceptionsSelectors[i];
            if (
              popover.has(selector).length === 0 ||
              $(e.target).is(selector)
            ) {
              clickOnException = true;
              break;
            }
          }
        }
        return clickOnException;
      }

      const popover = $(btnSelector + ' + .popover');
      if (popover.has(e.target).length === 0 || isClickOnException()) {
        btn.popover('hide');
      }
    });
    /////////////////////////////////////////////////

    btn.on('show.bs.popover', function () {
      if (!btn.hasClass('loaded')) {
        runAjax();
      }
    });

    btn.on('hidden.bs.popover', function () {
      btn.removeClass('loaded');
      btn.data('bs.popover').options.content = loadingImage;
      btn.data('bs.popover').inState.click = false;
    });
  }
}

export function closeParentPopover($el: JQuery) {
  const $parentPopover = $el.closest('.popover');
  if (!$parentPopover.length) {
    return;
  }

  const id = $parentPopover.attr('id');
  if (!id) {
    return;
  }

  const $trigger = $(`[aria-describedby="${id}"]`);
  if (!$trigger.length) {
    return;
  }

  $trigger.popover('hide');
}
