import { useMediaDialogContext } from '../../context';

import { Player } from '../../../audio';
import { BasicControls } from '../common';

function AudioViewer() {
  const { currentFile } = useMediaDialogContext();

  return (
    <>
      <BasicControls />
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-px-2 tw-backdrop-blur-sm md:tw-px-24 lg:tw-px-36">
        <Player autoPlay url={currentFile.url} />
      </div>
    </>
  );
}

export { AudioViewer };
