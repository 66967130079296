import type { ClassValue } from 'clsx';
import clsx from 'clsx';

import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  prefix: 'tw-',
});

function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export { cn };
