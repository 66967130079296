import { useEffect, useRef, useState } from 'react';

import { cn } from 'utils';

import { useMediaDialogContext } from '../../context';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MediaDialogParams } from '../../../../types';
import { BasicControls } from '../common';

const cache: Dictionary<Blob> = {};

function IFrameViewer() {
  const { currentFile, options } = useMediaDialogContext();
  const url = currentFile?.url ?? '';
  const [iframeUrl, setIframeUrl] = useState('');

  const mode: MediaDialogParams['options']['mode'] = options?.mode ?? 'default';

  useEffect(() => {
    (async () => {
      setIframeUrl('');

      let blob = cache[url];
      if (!blob) {
        const res = await fetch(url);

        blob = await res.blob();

        cache[url] = blob;
      }

      setIframeUrl(URL.createObjectURL(blob));
    })();
  }, [url]);

  const containerRef = useRef<HTMLDivElement>(null);

  const settings = options?.iframe
    ? `#${Object.entries(options.iframe)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : '';

  return (
    <>
      <BasicControls />
      <div
        ref={containerRef}
        className={cn(
          'tw-relative tw-flex !tw-max-w-full tw-justify-stretch tw-overflow-auto tw-shadow-none tw-shadow-black/5',
          {
            'tw-h-[65vh] tw-w-[80vw] md:tw-h-[80vh]': mode === 'default',
            'tw-h-full tw-w-full tw-pt-12': mode === 'full',
          },
        )}
      >
        {iframeUrl ? (
          <iframe className="tw-flex-1" src={iframeUrl + settings} />
        ) : (
          <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-4xl">
            <FontAwesomeIcon
              className="tw-animate-spin tw-text-text-default-contrast"
              icon={faSpinner}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { IFrameViewer };
