const killExecutorsStorage = new Map<string, VoidFunction>();
const windowKillExecutor = {
  add(id: string, closeFunction: VoidFunction) {
    killExecutorsStorage.set(id, closeFunction);
  },
  checkAndExecute(id: string) {
    const executor = killExecutorsStorage.get(id);
    if (!executor) return;

    executor();
    windowKillExecutor.remove(id);
  },
  remove(id: string) {
    killExecutorsStorage.delete(id);
  },
};

export { windowKillExecutor };
