import AudioPlayer from 'react-h5-audio-player';

import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-h5-audio-player/lib/styles.css';
import './styles.scss';

type Props = {
  autoPlay?: boolean;
  url: string;
};

function Player({ autoPlay, url }: Props) {
  return (
    <AudioPlayer
      autoPlay={autoPlay}
      customAdditionalControls={[]}
      customIcons={{
        pause: <FontAwesomeIcon icon={faPause} />,
        play: <FontAwesomeIcon icon={faPlay} />,
      }}
      showDownloadProgress={false}
      showJumpControls={false}
      src={url}
    />
  );
}

export { Player };
