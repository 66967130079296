import { createContext, useContext } from 'react';
import type { FileLibraryParams } from './types';
import { noop } from 'lodash';

type FileLibraryContextType = {
  selectedFilesUrls: string[];
  selectedPath: string;
  setSelectedFilesUrls: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPath: React.Dispatch<React.SetStateAction<string>>;
} & FileLibraryParams;

const FileLibraryContext = createContext<FileLibraryContextType>({
  filesBaseURL: '',
  queryURL: '',
  selectedFilesUrls: [],
  selectedPath: '',
  setSelectedFilesUrls: noop,
  setSelectedPath: noop,
});

const useFileLibraryContext = () => useContext(FileLibraryContext);

export {
  FileLibraryContext,
  type FileLibraryContextType,
  useFileLibraryContext,
};
