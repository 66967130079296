// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-rest-params,@typescript-eslint/no-unused-vars */
import $ from 'jquery';
import moment from 'moment';
import dragula from 'dragula';

import {
  AjaxSender,
  CalenderAjaxSenderMixin,
  CalenderAppApi,
  Controller,
  DidahCalender,
  DidahCalenderApp,
  DidahCalenderAppView,
  DidahCalenderDay,
  DidahCalenderDayInfo,
  DidahCalenderDayView,
  DidahCalenderList,
  DidahCalenderListModel,
  DidahCalenderListView,
  DidahCalenderView,
  lineLockIcons,
  View,
} from '@/common/features/schedulers/shared_components';
import { groupBy } from '@/utils';
import { translate } from '@/common/features/translations';

/*******************************************************/
function DeliverySchedulerAjaxSender() {
  AjaxSender.apply(this, arguments);
  const newKeys = {
    addLine: 'add-line',
    addLineMulti: 'add-line-multi',
    calenderData: 'calender-data',
    daysData: 'days-data',
    moveLine: 'move-line',
    moveObject: 'move-object',
    poolData: 'pool-data',
    removeLine: 'remove-line',
  };
  Object.assign(this.keys, newKeys);
}

DeliverySchedulerAjaxSender.prototype = Object.create(AjaxSender.prototype);
CalenderAjaxSenderMixin.call(DeliverySchedulerAjaxSender.prototype);
/*******************************************************/

const _getsObjects = {
  _sendMoveObjectAjax(objects, orderNum, data: { [key: string]: any } = {}) {
    const ajaxSender = this.getApp().getAjaxSender();
    const targetInfo = this.getInfo();
    const objsInfo = objects.map((o) => o.getInfo());
    data.orderNum = orderNum;
    Object.assign(data, targetInfo);
    data.objs = objsInfo;

    return ajaxSender.send(ajaxSender.keys.moveObject, data);
  },
  _transferFailureMsg: 'unable to transfer object',
  moveObject(objects, orderNum) {
    return new Promise((resolve, reject) => {
      this._sendMoveObjectAjax(objects, orderNum).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(this._transferFailureMsg);
          }
        },
        (e) => {
          reject(e);
        },
      );
    });
  },
};

const getsObjects = function () {
  Object.assign(this, _getsObjects);
};

const _hasObjects = {
  getObjectViewContainer() {
    return this._view.getObjectViewContainer();
  },
};

const hasObjects = function () {
  Object.assign(this, _hasObjects);
};

/*******************************************************/
const _getsLines = {
  _sendMoveLinesAjax(lines, orderNum, data: { [key: string]: any } = {}) {
    const ajaxSender = this.getApp().getAjaxSender();
    const targetInfo = this.getInfo();
    delete targetInfo.linesIds;
    if (targetInfo.objectDt) {
      targetInfo.dayDt = targetInfo.objectDt;
      delete targetInfo.objectDt;
    }

    const linesInfo = lines.map((l) => l.getInfo());
    data.orderNum = orderNum;
    Object.assign(data, targetInfo);
    data.lines = linesInfo;

    return ajaxSender.send(ajaxSender.keys.moveLine, data);
  },
  _transferFailureMsg: 'unable to transfer line',
  moveLine(lines, orderNum) {
    return new Promise((resolve, reject) => {
      this._sendMoveLinesAjax(lines, orderNum).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(this._transferFailureMsg);
          }
        },
        (e) => {
          reject(e);
        },
      );
    });
  },
};

const getsLines = function () {
  Object.assign(this, _getsLines);
};

/*******************************************************/
const appModel = {
  filterVal: '',
};

function App(data) {
  DidahCalenderApp.apply(this, arguments);
}

App.prototype = Object.create(DidahCalenderApp.prototype);

App.prototype.outerCallbacks = DidahCalenderApp.prototype.outerCallbacks.concat(
  ['lineBlockContent'],
);

App.prototype._assignMV = function () {
  this._model = appModel;
  this._view = new AppView(this);
};

App.prototype.processData = function (data) {
  this.childAdd(new DeliveryPool(data.pool, this), 'pool');
  this.childAdd(new DeliveryCalender(data.calender, this), 'calender');
};

App.prototype.setFilterVal = function (val) {
  this._model.filterVal = val;
};

App.prototype.getPoolViewContainer = function () {
  return this._view.getPoolViewContainer();
};

function AppView() {
  DidahCalenderAppView.apply(this, arguments);
}

AppView.prototype = Object.create(DidahCalenderAppView.prototype);

AppView.prototype._doInit = function () {
  this.setupDragula();
};

AppView.prototype._viewData = function () {
  if (this.getApp().isAgendaView()) {
    this._setupFilter();
  } else {
    this._hidePoolContainer();
  }
};

AppView.prototype._hidePoolContainer = function () {
  this._$view.find('#delivery-pool').hide();
  this._$view
    .find('#delivery-calender-container')
    .removeClass('col-xs-6')
    .removeClass('col-sm-10')
    .addClass('col-xs-12');
};

AppView.prototype._setupFilter = function () {
  const controller = this.controller;
  const $view = this._$view;
  const $filter = $view.find('#delivery-filter');

  $filter.val(this.controller.model().filterVal);
  $filter.on('input', function (e) {
    const inputText = this.value;
    controller.setFilterVal(inputText);

    controller.pool.objects.forEach((poolObjs) => poolObjs.reRender(true));
  });
};

AppView.prototype.setupDragula = function () {
  const app = this.getApp();

  const onDrag = (el, source) => {
    const $el = $(el);
    const dragged = $el.data('controller');
    if (dragged.isBlocked && dragged.isBlocked()) {
      $el.addClass('blocked-dragged');
    }
  };

  const onRequestDone = (promise) => {
    promise
      .then(() => {
        app.refresh();
      })
      .catch((e) => {
        app.reRender(true, true);
      });
  };

  const drakeLines = dragula({
    accepts: (el, target, source, sibling) => {
      //revert if another object
      const { draggedController, targetController } = this._getControllers(
        $(el),
        $(target),
      );

      return !(
        targetController instanceof DeliveryCalenderObject &&
        draggedController.model().className !== targetController.model().id
      );
    },
    copy: (el, source, handle, sibling) => false,
    isContainer(el) {
      return (
        el.classList.contains('delivery-calender-obj-lines') ||
        el.classList.contains('calender-day-content')
      );
    },
    moves: (el, source, handle, sibling) => {
      const $el = $(el);
      return $el.hasClass('delivery-calender-obj-line');
    },
    revertOnSpill: true,
  })
    .on('drag', onDrag)
    .on('drop', (el, target, source, sibling) => {
      const $el = $(el);
      const $target = $(target);
      const { draggedController, targetController } = this._getControllers(
        $el,
        $target,
      );
      if (draggedController.isBlocked()) {
        drakeLines.cancel(true);
        $el.removeClass('blocked-dragged');
        return;
      }

      const orderNum = $target
        .children('.delivery-calender-obj-line')
        .index($('.gu-transit'));
      onRequestDone(targetController.moveLine([draggedController], orderNum));
    });

  const drakeObjects = dragula({
    copy: (el, source, handle, sibling) => false,
    isContainer(el) {
      return el.classList.contains('delivery-container');
    },
    moves: (el, source, handle, sibling) => {
      const $el = $(el);
      return (
        $el.hasClass('delivery-draggable-obj') &&
        !$(handle).closest('.delivery-calender-obj-line').length
      );
    },
    revertOnSpill: true,
  })
    .on('drag', onDrag)
    .on('drop', (el, target, source, sibling) => {
      const $el = $(el);
      const $target = $(target);
      const { draggedController, targetController } = this._getControllers(
        $(el),
        $(target),
      );
      if (draggedController.isBlocked && draggedController.isBlocked()) {
        $el.removeClass('blocked-dragged');
        drakeObjects.cancel(true);
        return;
      }

      const orderNum = $target
        .children('.delivery-draggable-obj')
        .index($('.gu-transit'));

      if (
        draggedController instanceof DeliveryPoolObject &&
        targetController instanceof DeliveryDay
      ) {
        onRequestDone(targetController.addLine([draggedController], orderNum));
      } else if (targetController instanceof DeliveryPool) {
        drakeObjects.cancel(true);
      } else if (draggedController instanceof DeliveryCalenderObject) {
        onRequestDone(
          targetController.moveObject([draggedController], orderNum),
        );
      }
    });
};

AppView.prototype.getPoolViewContainer = function () {
  return this._$view.find('.pool-container');
};
AppView.prototype._hideAllCalenderContent = function () {
  this._$view.find('.delivery-calender-obj').css('visibility', 'hidden');
  this._$view.find('.delivery-calender-obj-line').css('visibility', 'hidden');
};

AppView.prototype._revealAllCalenderContent = function () {
  this._$view.find('.delivery-calender-obj').css('visibility', 'visible');
  this._$view.find('.delivery-calender-obj-line').css('visibility', 'visible');
};

AppView.prototype._getTemplate = function () {
  return `<div class="col-xs-12 didah-app-root" id="delivery">
                <div class="col-xs-6 col-sm-2" id="delivery-pool">
                    <div id="delivery-pool-controls">
                        <div class="form-group">
                            <label for="delivery-filter">${translate(
                              'logictics_translations.delivery_filter_label',
                            )}</label>
                            <input type="text" class="form-control" id="delivery-filter">
                        </div>
                    </div>
                    <div id="delivery-pool-container" class="pool-container"></div>
                </div>
                <div class="col-xs-6 col-sm-10 calender-container" id="delivery-calender-container"></div>
            </div>`;
};
/*******************************************************/
const deliveryPoolModel = {
  id: 'deliveryPool',
};

function DeliveryPool(data, parent) {
  Controller.apply(this, arguments);
  if (!this.data) {
    this.data = [];
  }
}

DeliveryPool.prototype = Object.create(Controller.prototype);
hasObjects.call(DeliveryPool.prototype);
DeliveryPool.prototype._doInit = function () {
  this._view = new DeliveryPoolView(this);
  this._model = deliveryPoolModel;
  this._data &&
    this._data.forEach((objectData) => {
      this.toChildArrayAdd(new DeliveryPoolObject(objectData, this), 'objects');
    });

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryPool.prototype.getViewContainer = function () {
  return this.parent.getPoolViewContainer();
};

DeliveryPool.prototype.getInfo = function (): { [key: string]: any } {
  return {
    type: 'pool',
  };
};

function DeliveryPoolView() {
  View.apply(this, arguments);
}

DeliveryPoolView.prototype = Object.create(View.prototype);

DeliveryPoolView.prototype.getObjectViewContainer = function () {
  return this._$view;
};

DeliveryPoolView.prototype._getTemplate = function () {
  return '<div class="col-xs-12 delivery-container" id="delivery-pool-objects"></div>';
};
/*******************************************************/
const deliveryCalenderModel = {
  id: 'deliveryCalender',
};

function DeliveryCalender(data, parent) {
  DidahCalender.apply(this, arguments);
}

DeliveryCalender.prototype = Object.create(DidahCalender.prototype);

DeliveryCalender.prototype._doInit = function () {
  this._view = new DeliveryCalenderView(this);
  this._model = deliveryCalenderModel;

  const daysMomentsMapped = this._getDaysAccordingToPeriod();

  if (this._data) {
    this._data.days = this._remapData(this._data, daysMomentsMapped);
  } else {
    const days = Object.values(daysMomentsMapped);
    days.forEach((day) => {
      day.lines = [];
      day.html = '';
    });
    this._data = {};
    this._data.days = days;
  }

  this.childAdd(
    new DeliveryCalenderList(
      {
        days: this._data.days,
      },
      this,
    ),
    'list',
  );
  this._data.days.forEach((dayData, i) => {
    dayData.orderNum = i;
    this.toChildArrayAdd(new DeliveryDay(dayData, this), 'days');
    this.toChildArrayAdd(new DidahCalenderDayInfo(dayData, this), 'dayinfos');
  });

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryCalender.prototype._remapData = function (
  calenderData,
  daysMomentsMapped,
) {
  calenderData.itemsData.forEach((line) => {
    const lineDayMoment = moment(line.dt);
    const dayFormatted = lineDayMoment.format('DD-MM-YYYY');
    if (daysMomentsMapped[dayFormatted]) {
      if (!daysMomentsMapped[dayFormatted].lines)
        daysMomentsMapped[dayFormatted].lines = [];
      daysMomentsMapped[dayFormatted].lines.push(line);
    }
  });

  calenderData.daysInfo.forEach((dayInfo) => {
    const infoDayMoment = moment(dayInfo.dt);
    const dayFormatted = infoDayMoment.format('DD-MM-YYYY');
    if (daysMomentsMapped[dayFormatted]) {
      !daysMomentsMapped[dayFormatted].infoHtml &&
        (daysMomentsMapped[dayFormatted].infoHtml = dayInfo.html);
    }
  });
  return Object.values(daysMomentsMapped);
};

function DeliveryCalenderView() {
  DidahCalenderView.apply(this, arguments);
}

DeliveryCalenderView.prototype = Object.create(DidahCalenderView.prototype);

DeliveryCalenderView.prototype._getTemplate = function () {
  return `<div class="fc" id="delivery-calender">
                    <div class="col-xs-12 calender-controls" id="delivery-calender-controls">
                        <div class="col-xs-12 col-sm-4 calender-controls-left">
                            <div class="fc-button-group">
                                <button type="button" class="fc-button fc-state-default fc-corner-left didah-app-scroll-back-btn">
                                    <span class="fc-icon fc-icon-left-single-arrow"></span>
                                </button>
                                <button type="button" class="fc-button fc-state-default fc-corner-right didah-app-scroll-forward-btn">
                                    <span class="fc-icon fc-icon-right-single-arrow"></span>
                                </button>
                            </div>
                            <div class="fc-button-group today-print-buttons">
                                <button 
                                    type="button" 
                                    class="fc-button fc-state-default fc-corner-left didah-app-today-btn">
                                    ${translate('logictics_translations.today')}
                                </button>
                                <div class="dropdown">
                                    <button 
                                        type="button"
                                        data-toggle="dropdown" 
                                        class="dropdown-toggle fc-button fc-state-default fc-corner-right didah-app-go-to-btn">
                                        ${translate(
                                          'logictics_translations.goto',
                                        )}
                                    </button>
                                    <div class="dropdown-menu">
                                        <div id="dddatepicker"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4 calender-controls-middle">
                            <h2 id="delivery-calender-header" class="calender-header"></h2>
                        </div>
                        <div class="col-xs-12 col-sm-4 calender-controls-right">
                            <div class="fc-button-group pull-right duration-buttons"></div>
                        </div>
                    </div>
                    <div class="col-xs-12 calender-days fc" id="delivery-calender-days">
                        <div class="col-xs-12 calender-days-info" id="delivery-calender-days-info"></div>
                        <div class="col-xs-12 calender-days-content" id="delivery-calender-days-content"></div>
                    </div>
                    <div class="col-xs-12 calender-list fc" id="delivery-calender-list"></div>
                </div>`;
};
/*******************************************************/
const groupLinesByObject = function (lines) {
  if (!lines) {
    return [];
  }
  const grouped = groupBy(lines, 'className'); //todo check if _.groupBy can be used
  return Object.keys(grouped).map((key) => {
    const lines = grouped[key];
    return {
      dt: lines[0].dt,
      id: key,
      lines,
    };
  });
};

/*******************************************************/
function DeliveryCalenderList(data, parent) {
  DidahCalenderList.apply(this, arguments);
}

DeliveryCalenderList.prototype = Object.create(DidahCalenderList.prototype);

DeliveryCalenderList.prototype._doInit = function () {
  this._data.days.forEach((day) => {
    day.objects = groupLinesByObject(day.lines);
    day.objects.forEach((object) => {
      object.lines && object.lines.sort((a, b) => a.index > b.index);
    });
    delete day.lines;
  });
  this._assignMV();
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryCalenderList.prototype._assignMV = function () {
  this._model = new DidahCalenderListModel(this._data);
  this._view = new DeliveryCalenderListView(this);
};

function DeliveryCalenderListView() {
  DidahCalenderListView.apply(this, arguments);
}

DeliveryCalenderListView.prototype = Object.create(
  DidahCalenderListView.prototype,
);

DeliveryCalenderListView.prototype._listData = function () {
  const mc = this.controller.model();
  return mc.days.filter((day) => day.objects && day.objects.length);
};

DeliveryCalenderListView.prototype._viewData = function () {
  const listData = this._listData();
  if (!listData.length) return;

  const getAccordingPoolObjModel = (object) => {
    const refId = object.lines[0].className;
    const application = this.getApp();
    return application.pool.objects.find((o) => o.model().id === refId).model();
  };

  const $table = this._$view;
  const lineRowContent = this.getApp()._outerCallbacks.lineRowContent;
  listData.forEach((day) => {
    $(
      `<tr class="day-header-row"><td colspan="3">${day.moment.format(
        'dddd D.M',
      )}</td></tr>`,
    ).appendTo($table);
    day.objects.forEach((object) => {
      const accordingPoolModel = getAccordingPoolObjModel(object);
      const $dayRow = $(`<tr class="day-entry-row">
                        <td><div class="entry-color-circle" style="background-color: ${accordingPoolModel.color}"></div></td>
                        <td>${accordingPoolModel.title}</td>
                    </tr>`);
      const $dayLinesRow = $(`<tr class="day-lines-row">
                        <td colspan="2">
                            <table class="inner-table">
                                  <tbody></tbody>                            
                            </table>
                        </td>
                    </tr>`);
      const $lineTableBody = $dayLinesRow.find('table tbody');

      const $lines = object.lines.map((line) => {
        const $line = $('<tr><td class="table-td"></td></tr>');

        lineRowContent($line, line, 'delivery_scheduler_line');
        $line
          .find('.leftViewTime')
          .append(lineFormatStartEnd(line.start, line.end));
        return $line;
      });

      $lines.forEach(($line) => $line.appendTo($lineTableBody));
      $dayRow.appendTo($table);
      $dayLinesRow.appendTo($table);
    });
  });
};

/*******************************************************/
function DeliveryDayModel(data) {
  this.day = data.day;
  this.month = data.month;
  this.year = data.year;
  this.moment = data.moment;
  this.orderNum = data.orderNum;
}

function DeliveryDay(data, parent) {
  DidahCalenderDay.apply(this, arguments);
}

DeliveryDay.prototype = Object.create(DidahCalenderDay.prototype);
hasObjects.call(DeliveryDay.prototype);
getsObjects.call(DeliveryDay.prototype);
getsLines.call(DeliveryDay.prototype);

DeliveryDay.prototype._doInit = function () {
  this._view = new DeliveryDayView(this);
  this._model = new DeliveryDayModel(this._data);
  this._data.objects = groupLinesByObject(this._data.lines);
  this._data.objects.forEach((obj) =>
    this.toChildArrayAdd(new DeliveryCalenderObject(obj, this), 'objects'),
  );
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryDay.prototype.getObjectViewContainer = function () {
  return this._view.getObjectViewContainer();
};

DeliveryDay.prototype.addLine = function (sourceObjs, orderNum) {
  const application = this.getApp();
  const ajaxSender = application.getAjaxSender();
  const self = this;

  function sendAjax(count) {
    const data: { [key: string]: any } = {};
    let key;
    if (count > 1) {
      data.count = count;
      key = ajaxSender.keys.addLineMulti;
    } else {
      key = ajaxSender.keys.addLine;
    }
    const objsInfo = sourceObjs.map((so) => so.getInfo());
    data.objectDt = self.getInfo().dayDt;
    data.orderNum = orderNum;
    data.objs = objsInfo;

    return ajaxSender.send(key, data);
  }

  const count = +prompt(
    translate('logictics_translations.delivery_add_lines_count_prompt'),
    '1',
  );
  return new Promise((resolve, reject) => {
    if (count && count > 0) {
      sendAjax(count).then(
        (data) => {
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(this._transferFailureMsg);
          }
        },
        (e) => {
          reject(e);
        },
      );
    } else {
      reject();
    }
  });
};

function DeliveryDayView() {
  DidahCalenderDayView.apply(this, arguments);
}

DeliveryDayView.prototype = Object.create(DidahCalenderDayView.prototype);

DeliveryDayView.prototype.getObjectViewContainer = function () {
  return this._$view.find('.calender-day-content');
};

DeliveryDayView.prototype._getTemplate = function () {
  return `<div class="delivery-calender-day calender-day">
                    <div class="calender-day-header col-xs-12"></div>
                    <div class="calender-day-content delivery-container col-xs-12"></div>
                </div>`;
};

/*******************************************************/
function DeliveryPoolObjectModel(data) {
  this.id = data.id;
  this.descriptionHTML = data.desc;
  this.color = data.color;
  this.title = data.title;
}

function DeliveryPoolObject(data, parent) {
  Controller.apply(this, arguments);
}

DeliveryPoolObject.prototype = Object.create(Controller.prototype);

DeliveryPoolObject.prototype._doInit = function () {
  this._view = new DeliveryPoolObjectView(this);
  this._model = new DeliveryPoolObjectModel(this._data);
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryPoolObject.prototype.getViewContainer = function () {
  return this.parent.getObjectViewContainer();
};

DeliveryPoolObject.prototype.getInfo = function () {
  return {
    objectId: this.model().id,
  };
};

DeliveryPoolObject.prototype._isToRenderHidden = function () {
  const { title } = this._data || this.model();
  const filterVal = this.getApp().model().filterVal;
  return (
    filterVal !== '' && !title.toLowerCase().includes(filterVal.toLowerCase())
  );
};

function DeliveryPoolObjectView() {
  View.apply(this, arguments);
}

DeliveryPoolObjectView.prototype = Object.create(View.prototype);

DeliveryPoolObjectView.prototype._viewData = function () {
  const $view = this._$view;
  const modelCopy = this.controller.model();
  const title = modelCopy.title;

  $view.text(title);
  $view.css('background-color', modelCopy.color);
};

DeliveryPoolObjectView.prototype._getTemplate = function () {
  return '<div class="delivery-draggable-obj delivery-pool-obj"></div>';
};

/*******************************************************/
function DeliveryCalenderObjectModel(data) {
  this.id = data.id;
  this.dt = data.dt;
}

function DeliveryCalenderObject(data, parent) {
  Controller.apply(this, arguments);
}

DeliveryCalenderObject.prototype = Object.create(Controller.prototype);
getsLines.call(DeliveryCalenderObject.prototype);

DeliveryCalenderObject.prototype._doInit = function () {
  this._view = new DeliveryCalenderObjectView(this);
  this._model = new DeliveryCalenderObjectModel(this._data);
  this._data.lines &&
    this._data.lines
      .sort((a, b) => a.index > b.index)
      .forEach((ts, i) => {
        ts.local_index = i + 1;
        this.toChildArrayAdd(new DeliveryLine(ts, this), 'lines');
      });

  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryCalenderObject.prototype.getViewContainer = function () {
  return this.parent.getObjectViewContainer();
};

DeliveryCalenderObject.prototype.getInfo = function (): { [key: string]: any } {
  const mc = this.model();
  return {
    linesIds: this.lines.map((l) => l.model().id),
    objectDt: mc.dt,
    objectId: mc.id,
  };
};

DeliveryCalenderObject.prototype._sendAction = function (data, ajaxKey) {
  const application = this.getApp();
  const ajaxSender = application.getAjaxSender();
  new Promise((resolve, reject) => {
    ajaxSender.send(ajaxKey, data).then(
      (data) => {
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject();
        }
      },
      (e) => {
        reject(e);
      },
    );
  })
    .then(() => application.refresh())
    .catch((e) => application.reRender(true, true));
};

DeliveryCalenderObject.prototype.onLineAddBtn = function () {
  const data = this.getInfo();
  const application = this.getApp();
  const ajaxSender = application.getAjaxSender();
  this._sendAction(data, ajaxSender.keys.addLine);
};

DeliveryCalenderObject.prototype.isShowAddButtons = function () {
  const application = this.getApp();
  return application.model().isShowAddButtons;
};

DeliveryCalenderObject.prototype.onLineAddMultiBtn = function () {
  const application = this.getApp();
  const ajaxSender = application.getAjaxSender();
  const count = prompt(
    translate('logictics_translations.delivery_add_lines_count_prompt'),
    '1',
  );
  const data = this.getInfo();
  if (count !== '1') {
    data.count = count;
    this._sendAction(data, ajaxSender.keys.addLineMulti);
  } else {
    this._sendAction(data, ajaxSender.keys.addLine);
  }
};

DeliveryCalenderObject.prototype.getLineViewContainer = function () {
  return this._view.getLineViewContainer();
};

DeliveryCalenderObject.prototype._initDone = function (initPromise) {
  initPromise.then(() => this._view.renderPropsFromChildren());
};

DeliveryCalenderObject.prototype.reRender = function (
  isForcedSelf = false,
  isForcedChildren = false,
) {
  Controller.prototype.reRender.apply(this, arguments);
  this._view.renderPropsFromChildren();
};

DeliveryCalenderObject.prototype.getAccordingPoolObjectModel = function () {
  const refId = this.lines[0].model().className;
  const application = this.getApp();
  return application.pool.objects.find((o) => o.model().id === refId).model();
};

DeliveryCalenderObject.prototype.isBlocked = function () {
  return !!this.lines.find((l) => l.isBlocked());
};

function DeliveryCalenderObjectView() {
  View.apply(this, arguments);
}

DeliveryCalenderObjectView.prototype = Object.create(View.prototype);

DeliveryCalenderObjectView.prototype.getLineViewContainer = function () {
  return this._$view.find('.delivery-calender-obj-lines');
};

DeliveryCalenderObjectView.prototype._viewData = function () {
  this._setupButtons();
};

DeliveryCalenderObjectView.prototype.renderPropsFromChildren = function () {
  const poolObjModel = this.controller.getAccordingPoolObjectModel();
  this._$view.find('.delivery-calender-obj-title').html(poolObjModel.title);
  this._$view.css('background-color', poolObjModel.color);
};

DeliveryCalenderObjectView.prototype._setupButtons = function () {
  this._$view.find('.one-line-btn').click(() => this.controller.onLineAddBtn());
  this._$view
    .find('.multi-line-btn')
    .click(() => this.controller.onLineAddMultiBtn());
};

DeliveryCalenderObjectView.prototype._getTemplate = function () {
  return `<div class="delivery-draggable-obj delivery-calender-obj clearfix">
                    <div class="delivery-calender-obj-title"></div>
                    <div class="delivery-calender-obj-lines"></div>
                    ${
                      this.controller.isShowAddButtons()
                        ? `
                      <div class="btn-group add-line-btns">
                      <div class="fc-button-group ">
                          <div class="btn-group">
                            <button type="button" class="btn btn-default btn-sm one-line-btn">
                                <span class="glyphicon glyphicon-plus"></span>
                            </button>
                            <button type="button" class="btn btn-default btn-sm multi-line-btn">
                                <span class="glyphicon glyphicon-plus"></span>
                                <span class="glyphicon glyphicon-plus"></span>
                            </button>
                          </div>
                      </div>
                      </div>
                    `
                        : ''
                    }
                    
                </div>`;
};

/*******************************************************/
const lineFormatStartEnd = (start, end) => {
  const formatStartEnd = (isoTime) => {
    const formatted = isoTime
      ? moment(isoTime).format('HH:mm')
      : 'Invalid date';
    return formatted === 'Invalid date' ? '' : formatted;
  };
  const formattedStart = formatStartEnd(start);
  const formattedEnd = formatStartEnd(end);
  return `${formattedStart}`; //  - ${formattedEnd}
};

/*******************************************************/
function DeliveryLineModel(data) {
  this.id = data.id;
  this.lock = data.didah_lock;
  this.time = data.time;
  this.start = data.start;
  this.end = data.end;
  this.title = data.title;
  this.index = data.index;
  this.local_index = data.local_index;
  this.className = data.className;
  this.didah_a = data.didah_a;
  this.didah_ac = data.didah_ac;
  this.didah_ah = data.didah_ah;
  this.didah_pos = data.didah_pos;
}

function DeliveryLine(data, parent) {
  Controller.apply(this, arguments);
}

DeliveryLine.prototype = Object.create(Controller.prototype);

DeliveryLine.prototype._doInit = function () {
  this._view = new DeliveryLineView(this);
  this._model = new DeliveryLineModel(this._data);
  delete this._data;
  this._view.init();
  return this._initChildren();
};

DeliveryLine.prototype.getViewContainer = function () {
  return this.parent.getLineViewContainer();
};

DeliveryLine.prototype.getInfo = function () {
  return {
    lineId: this.model().id,
    objectId: this.parent.model().id,
  };
};

DeliveryLine.prototype.isBlocked = function () {
  const mc = this.model();
  return mc.lock[0] === '1' || mc.lock[2] === '3';
};

DeliveryLine.prototype._selfRemove = function () {
  const application = this.getApp();
  const ajaxSender = application.getAjaxSender();
  const data = this.getInfo();
  new Promise((resolve, reject) => {
    ajaxSender.send(ajaxSender.keys.removeLine, data).then(
      (data) => {
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject();
        }
      },
      (e) => {
        reject(e);
      },
    );
  })
    .then(() => application.refresh())
    .catch((e) => application.reRender(true, true));
};

DeliveryLine.prototype.onRemoveBtn = function () {
  const msgTemplate = translate(
    'logictics_translations.delivery_remove_line_prompt',
  );
  const message = msgTemplate.replace(/{lineId}/g, this.model().id);
  if (confirm(message)) {
    this._selfRemove();
  }
};

function DeliveryLineView() {
  View.apply(this, arguments);
}

DeliveryLineView.prototype = Object.create(View.prototype);

DeliveryLineView.prototype._viewData = function () {
  const model = this.controller.model();
  this._setHeader(model);
  this._setContent(model);
  this._setOnBoxClick(model);
  this._setupButtons();

  const outerCallbacks = this.getApp()._outerCallbacks;
  if (outerCallbacks.lineBlockContent) {
    outerCallbacks.lineBlockContent(this._$view, model, 'line_block_content');
  }
};

DeliveryLineView.prototype._setHeader = function (model) {
  this._$view.find('.line-index').text(model.local_index);
  this._$view.find('.global-index').text(model.didah_pos);
  this._$view
    .find('.line-time')
    .text(lineFormatStartEnd(model.start, model.end));

  const flagElement = this._$view.find('.line-flag');

  lineLockIcons(model.lock).forEach((el) => flagElement.append(el));
};

DeliveryLineView.prototype._setContent = function (model) {
  if (model.title) {
    const text = model.title;
    const lineText = this._$view.find('.line-text');
    lineText.html(text);
    lineText.find('z').wrap('<a class="z-wrapped">');
    lineText.on('click', 'z', () => this.getApp().openIframe(model, 'z'));
  }
};

DeliveryLineView.prototype._setOnBoxClick = function (model) {
  this._$view.off('click');
  this._$view.on(
    'click',
    ':not(.z-wrapped):not(.obj-remove-btn):not(z)',
    (e) => {
      if (
        $(e.target).hasClass('z-wrapped') ||
        $(e.target).hasClass('obj-remove-btn') ||
        e.target.tagName.toLowerCase() === 'z'
      ) {
        return;
      }

      e.stopPropagation();
      this.getApp().openIframe(model, 'def');
    },
  );
};

DeliveryLineView.prototype._setupButtons = function () {
  if (!this.controller.isBlocked()) {
    const $removeBtn = $(
      '<span class="glyphicon glyphicon-remove obj-remove-btn"></span>',
    );
    this._$view.find('.line-right').append($removeBtn);
    $removeBtn.click(() => this.controller.onRemoveBtn());
  }
};

DeliveryLineView.prototype._getTemplate = function () {
  return `<div class="delivery-calender-obj-line">
                    <div>
                        <span class="line-index"></span> | <b class="global-index"></b>
                        <span class="line-flag"></span>
                        <span class="line-time"></span>
                    </div>
                    <div class="delivery-calender-obj-line-lower">
                        <div class="form-inline line-left">
                            <span class="line-text"></span>
                        </div>
                        <div class="form-inline line-right"></div>
                    </div>
                </div>`;
};

/*******************************************************/
function DidahDeliveryScheduler(data) {
  CalenderAppApi.apply(this, arguments);
}

DidahDeliveryScheduler.prototype = Object.create(CalenderAppApi.prototype);

DidahDeliveryScheduler.prototype.init = function () {
  const app: any = (this._app = new App(this._data));
  app.setAjaxSender(new DeliverySchedulerAjaxSender(app));

  CalenderAppApi.prototype.init.apply(this, arguments);
};

export { DidahDeliveryScheduler };
