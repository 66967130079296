import throttle from 'lodash/throttle';
import $ from 'jquery';
import { getPxValue } from '../units';
import { makeAbsolute } from './utils';

export type ResizeDelta = { deltaHeight: number; deltaWidth: number };

type Args = {
  contain?: boolean;
  element: HTMLElement;
  onResizeChange?: (params: ResizeDelta) => void;
  onResizeEnd?: () => void;
  onResizeStart?: () => void;
};

const activeZonePx = 2;
const cornerMultiplier = 5;

enum Mode {
  ResizeBottom = 'ResizeBottom',
  ResizeBottomLeft = 'ResizeBottomLeft',
  ResizeBottomRight = 'ResizeBottomRight',
  ResizeLeft = 'ResizeLeft',
  ResizeRight = 'ResizeRight',
  ResizeTop = 'ResizeTop',
  ResizeTopLeft = 'ResizeTopLeft',
  ResizeTopRight = 'ResizeTopRight',
}

const cursors = {
  [Mode.ResizeBottomLeft]: 'sw-resize',
  [Mode.ResizeBottomRight]: 'se-resize',
  [Mode.ResizeLeft]: 'col-resize',
  [Mode.ResizeRight]: 'col-resize',
  [Mode.ResizeTopLeft]: 'nw-resize',
  [Mode.ResizeTopRight]: 'ne-resize',
  [Mode.ResizeTop]: 'row-resize',
  [Mode.ResizeBottom]: 'row-resize',
};

function getPotentialMode({
  bcr,
  x,
  y,
}: {
  bcr: DOMRect;
  x: number;
  y: number;
}) {
  const isOnLeftSide = x <= activeZonePx;
  const isOnRightSide = x > bcr.width - activeZonePx;
  const isOnTopSide = y <= activeZonePx;
  const isOnBottomSide = y > bcr.height - activeZonePx;

  const isOnLeftTopSide =
    x <= activeZonePx * cornerMultiplier &&
    y <= activeZonePx * cornerMultiplier;
  const isOnLeftBottomSide =
    x <= activeZonePx * cornerMultiplier &&
    y > bcr.height - activeZonePx * cornerMultiplier;

  const isOnRightTopSide =
    x > bcr.width - activeZonePx * cornerMultiplier &&
    y <= activeZonePx * cornerMultiplier;
  const isOnRightBottomSide =
    x > bcr.width - activeZonePx * cornerMultiplier &&
    y > bcr.height - activeZonePx * cornerMultiplier;

  if (isOnLeftTopSide) {
    return Mode.ResizeTopLeft;
  }
  if (isOnLeftBottomSide) {
    return Mode.ResizeBottomLeft;
  }
  if (isOnLeftSide) {
    return Mode.ResizeLeft;
  }

  if (isOnRightTopSide) {
    return Mode.ResizeTopRight;
  }
  if (isOnRightBottomSide) {
    return Mode.ResizeBottomRight;
  }
  if (isOnRightSide) {
    return Mode.ResizeRight;
  }

  if (isOnTopSide) {
    return Mode.ResizeTop;
  }
  if (isOnBottomSide) {
    return Mode.ResizeBottom;
  }

  return null;
}

function stretch({ element, mode, pageX, pageY }): ResizeDelta {
  let left = getPxValue(element.style.left);
  let top = getPxValue(element.style.top);
  let bottom = getPxValue(element.style.bottom);
  let right = getPxValue(element.style.right);

  const bcr = element.getBoundingClientRect();

  let deltaX = 0;
  let deltaY = 0;

  switch (mode) {
    case Mode.ResizeTop: {
      deltaY = pageY - bcr.top;

      top += deltaY;
      break;
    }
    case Mode.ResizeBottom: {
      deltaY = bcr.bottom - pageY;

      bottom += deltaY;
      break;
    }
    case Mode.ResizeLeft: {
      deltaX = pageX - bcr.left;

      left += deltaX;
      break;
    }
    case Mode.ResizeRight: {
      deltaX = bcr.right - pageX;

      right += deltaX;
      break;
    }
    case Mode.ResizeBottomRight: {
      deltaY = bcr.bottom - pageY;
      deltaX = bcr.right - pageX;

      bottom += deltaY;
      right += deltaX;
      break;
    }
    case Mode.ResizeBottomLeft: {
      deltaY = bcr.bottom - pageY;
      deltaX = pageX - bcr.left;

      bottom += deltaY;
      left += deltaX;
      break;
    }
    case Mode.ResizeTopRight: {
      deltaX = bcr.right - pageX;
      deltaY = pageY - bcr.top;

      right += deltaX;
      top += deltaY;
      break;
    }
    case Mode.ResizeTopLeft: {
      deltaX = pageX - bcr.left;
      deltaY = pageY - bcr.top;

      top += deltaY;
      left += deltaX;
      break;
    }
  }

  element.style.left = `${left}px`;
  element.style.top = `${top}px`;
  element.style.bottom = `${bottom}px`;
  element.style.right = `${right}px`;

  return {
    deltaHeight: -deltaY,
    deltaWidth: -deltaX,
  };
}

function makeResizable({
  element,
  onResizeChange,
  onResizeEnd,
  onResizeStart,
}: Args) {
  const $element = $(element);

  let mode: Nullable<Mode> = null;
  let isPointerDown = false;
  let initialCursor = null;

  $element.data('resizable', true);
  $element.on(
    'pointermove',
    throttle(function (event) {
      if (isPointerDown) {
        return;
      }

      const bcr = element.getBoundingClientRect();
      const shiftX = event.clientX - bcr.left;
      const shiftY = event.clientY - bcr.top;

      mode = getPotentialMode({ bcr, x: shiftX, y: shiftY });

      if (mode) {
        applyCursorStyle(element, mode);
        $element.data('resizing', true);
      } else {
        removeModeCursor(element);
        $element.removeData('resizing');
      }
    }, 10)
  );

  $element.on('pointerleave', () => {
    if (isPointerDown) {
      return;
    }

    mode = null;
    $element.removeData('resizing');
  });

  $element.on('pointerdown', function () {
    if (!mode) {
      return;
    }

    isPointerDown = true;

    onResizeStart?.();

    makeAbsolute(element);

    function onPointerMove(moveEvent) {
      const { deltaHeight, deltaWidth } = stretch({
        element,
        mode,
        pageX: moveEvent.pageX - window.scrollX,
        pageY: moveEvent.pageY - window.scrollY,
      });

      onResizeChange?.({ deltaHeight, deltaWidth });
    }

    function onPointerUp() {
      document.body.removeEventListener('pointermove', onPointerMove);
      document.body.removeEventListener('pointerup', onPointerUp);

      isPointerDown = false;
      onResizeEnd?.();
    }

    document.body.addEventListener('pointermove', onPointerMove);
    document.body.addEventListener('pointerup', onPointerUp);
  });

  element.ondragstart = function () {
    return false;
  };

  function applyCursorStyle(element: HTMLElement, mode: Mode) {
    const modeCursor = cursors[mode];

    if (element.style.cursor !== modeCursor) {
      if (!initialCursor) {
        const computedStyle = getComputedStyle(element);
        initialCursor = computedStyle.cursor;
      }

      element.style.cursor = modeCursor;
    }
  }

  function removeModeCursor(element: HTMLElement) {
    if (!initialCursor) {
      return;
    }

    element.style.cursor = initialCursor;
    initialCursor = null;
  }
}

export { makeResizable };
