import $ from 'jquery';
import type { Moment } from 'moment';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import ClickEvent = JQuery.ClickEvent;

import { simpleDateFormat } from '@/constants';
import { translate } from '@/common/features/translations';
import { makeCenteredModalWindow } from '@/utils';

import type { DatePickerModalResult } from '@/common/widgets/datepickers/dp-modal';
import datePickerModal from '@/common/widgets/datepickers/dp-modal';
import { Period } from '@/common/widgets/datepickers/dp-modal/types';

import './style.scss';

type Options = {
  prevSelection?: string;
  showPeriod?: boolean;
  title: string;
};

type TimeOption = {
  dateStr: string;
  label: string;
};

const renderPostponedDateModal = (
  { prevSelection, showPeriod, title }: Options,
  resolve: (result: Nullable<DatePickerModalResult>) => void,
) => {
  const now = moment();
  const isTomorrowWeekend = now.day() === 0 || now.day() === 6;
  const prevSelectionStr = translate(
    'postpone_date_translations.prev_selection',
  );
  const selectDateStr = translate('postpone_date_translations.select_time');
  const morningStr = translate('postpone_date_translations.date_label_morning');
  const afternoonStr = translate(
    'postpone_date_translations.date_label_afternoon',
  );
  const eveningStr = translate('postpone_date_translations.date_label_evening');
  const todayStr = translate('postpone_date_translations.date_label_today');

  const renderOption = ({ dateStr, format = 'D MMM, HH:mm', label }) => `
        <div class="postponed-date-modal-option" data-time="${dateStr}">
            <span class="postponed-date-modal-option-label">${label}</span>
            <span class="postponed-date-modal-option-time">${moment(
              dateStr,
              simpleDateFormat,
            ).format(format)}</span>        
        </div>
    `;

  const moveIfWeekend = (time: Moment) => {
    if (time.day() === 0) {
      time.add(1, 'd');
    } else if (time.day() === 6) {
      time.add(2, 'd');
    }
  };

  const getTimeLabel = (time: Moment) => {
    let dayDescription = todayStr;
    let timeDescription = '';

    switch (time.hours()) {
      case 8:
        timeDescription = morningStr;
        break;
      case 13:
        timeDescription = afternoonStr;
        break;
      case 18:
        timeDescription = eveningStr;
        break;
    }

    if (!now.isSame(time, 'day')) {
      dayDescription = time.format('dddd');
    }

    return [capitalize(dayDescription), timeDescription].join(' ');
  };

  const getTimeOption: (time: Moment) => TimeOption = (time) => {
    return {
      dateStr: time.format(simpleDateFormat),
      label: getTimeLabel(time),
    };
  };

  const getNextTimes: () => TimeOption[] = () => {
    const hours = now.hours();

    let time1;
    let time2;
    let time3;

    if (hours < 8) {
      time1 = moment().set({ hours: 8, minutes: 0, seconds: 0 });

      if (isTomorrowWeekend) {
        time2 = moment().set({ hours: 8, minutes: 0, seconds: 0 });
        time3 = moment().set({ hours: 13, minutes: 0, seconds: 0 });

        moveIfWeekend(time2);
        moveIfWeekend(time3);
      } else {
        time2 = moment().set({ hours: 13, minutes: 0, seconds: 0 });
        time3 = moment().add(1, 'd').set({ hours: 8, minutes: 0, seconds: 0 });
      }
    } else if (hours >= 8 && hours < 13) {
      time1 = moment().set({ hours: 13, minutes: 0, seconds: 0 });
      time2 = moment().set({ hours: 18, minutes: 0, seconds: 0 });
      time3 = moment().add(1, 'd').set({ hours: 8, minutes: 0, seconds: 0 });

      moveIfWeekend(time3);
    } else if (hours >= 13 && hours < 18) {
      time1 = moment().set({ hours: 18, minutes: 0, seconds: 0 });
      time2 = moment().add(1, 'd').set({ hours: 8, minutes: 0, seconds: 0 });

      if (isTomorrowWeekend) {
        time3 = moment().set({ hours: 8, minutes: 0, seconds: 0 });
        moveIfWeekend(time3);
      } else {
        time3 = moment().add(1, 'd').set({ hours: 13, minutes: 0, seconds: 0 });
      }
    } else if (hours >= 18) {
      time1 = moment().add(1, 'd').set({ hours: 8, minutes: 0, seconds: 0 });
      time2 = moment().add(1, 'd').set({ hours: 13, minutes: 0, seconds: 0 });
      time3 = moment().add(1, 'd').set({ hours: 18, minutes: 0, seconds: 0 });

      if (isTomorrowWeekend) {
        time3 = moment().set({ hours: 8, minutes: 0, seconds: 0 });

        moveIfWeekend(time3);
      } else {
        time3 = moment().add(1, 'd').set({ hours: 18, minutes: 0, seconds: 0 });
      }
    } else {
      time1 = moment().set({ hours: 18, minutes: 0, seconds: 0 });
      time2 = moment().add(1, 'd').set({ hours: 8, minutes: 0, seconds: 0 });
      time3 = moment().add(1, 'd').set({ hours: 13, minutes: 0, seconds: 0 });

      moveIfWeekend(time2);
      moveIfWeekend(time3);
    }

    return [getTimeOption(time1), getTimeOption(time2), getTimeOption(time3)];
  };

  let isResolved = false;
  let isShowingDatepicker = false;
  let $modal = null;

  const doResolve = (result: Nullable<DatePickerModalResult>) => {
    if (isResolved) {
      return;
    }

    resolve(result);
    isResolved = true;
  };

  function onOptionClicked(event: ClickEvent) {
    event.stopPropagation();

    const $option = $(this);
    doResolve({
      selectedDate: $option.data('time'),
      selectedPeriod: Period.Never,
    });
    $modal.modal('hide');
  }

  async function onSelectDateTimeClicked(event: ClickEvent) {
    event.stopPropagation();

    let result: Nullable<DatePickerModalResult> = null;
    isShowingDatepicker = true;
    new Promise<void>((resolve) => {
      $modal.on('hidden.bs.modal', async () => {
        result = await datePickerModal({
          minDate: moment().add(1, 'm'),
          showPeriod,
        });
        resolve();
      });
    }).then(() => doResolve(result));

    $modal.modal('hide');
  }

  const $prevSelection = prevSelection
    ? renderOption({
        dateStr: prevSelection,
        format: 'dd, D MMM, HH:mm',
        label: prevSelectionStr,
      })
    : '';

  const $body = $(`
            <div>
                <div class="postponed-date-modal-options">
                    ${$prevSelection}
                    ${getNextTimes()
                      .map(({ dateStr, label }) =>
                        renderOption({ dateStr, label }),
                      )
                      .join('')}
                </div> 
                <div class="postponed-date-modal-select">
                   <i class="fa fa-calendar-o" aria-hidden="true"></i> ${selectDateStr}     
                </div>
            </div>
        `);
  $modal = makeCenteredModalWindow(title, $body);
  $modal.find('.modal-dialog').addClass('postponed-date-modal');

  $modal.find('.postponed-date-modal-option').on('click', onOptionClicked);
  $modal
    .find('.postponed-date-modal-select')
    .on('click', onSelectDateTimeClicked);

  $modal.modal('show');

  $modal.on('hidden.bs.modal', () => {
    if (isShowingDatepicker) {
      return;
    }

    doResolve(null);
  });
};

function postponedDateModal(opts: Options) {
  return new Promise<Nullable<DatePickerModalResult>>((resolve) =>
    renderPostponedDateModal(opts, resolve),
  );
}

export { postponedDateModal };
