import $ from 'jquery';
import { localStorageExists } from '@/utils';

const LS_KEY = 'elementFolding';

class LsUtil {
  constructor(private elementId: string) {
    this.prepareLocalStorage();
  }

  private static addToLs(data) {
    localStorage.setItem(LS_KEY, JSON.stringify(data));
  }

  private static getFromLS() {
    return JSON.parse(localStorage.getItem(LS_KEY));
  }

  private prepareLocalStorage() {
    const savedData = localStorage.getItem(LS_KEY);
    if (!savedData) {
      LsUtil.addToLs({});
    }
    const currentData = LsUtil.getFromLS();
    if (!currentData[this.elementId]) {
      currentData[this.elementId] = false;
    }
    LsUtil.addToLs(currentData);
  }

  get() {
    return LsUtil.getFromLS()[this.elementId];
  }

  set(newStatus) {
    const currentData = LsUtil.getFromLS();
    currentData[this.elementId] = newStatus;
    LsUtil.addToLs(currentData);
  }
}

function setElementFolding(elementId) {
  const $element = $(`#${elementId}`);
  if (!localStorageExists() || !$element.length) return;
  const slideTimeMS = 500;
  const lsUtil = new LsUtil(elementId);

  const $outerWrapper = $element.wrap('<div/>').parent();
  const $innerWrapper = $element.wrap('<div style="display: none"/>').parent();
  const $button = $(
    '<button class="btn btn-primary btn-sm" style="float: right; margin-bottom: 10px" type="button"></button>'
  );
  const $buttonWrapper = $('<div class="clearfix"/>');
  $buttonWrapper.append($button);
  $outerWrapper.prepend($buttonWrapper);

  function reDrawElementWrapper(isWOAnimation = false) {
    if (lsUtil.get()) {
      isWOAnimation ? $innerWrapper.hide() : $innerWrapper.slideUp(slideTimeMS);
    } else {
      isWOAnimation
        ? $innerWrapper.show()
        : $innerWrapper.slideDown(slideTimeMS);
    }
  }

  function reDrawBtn() {
    if (lsUtil.get()) {
      $button.html('<span class="glyphicon glyphicon-menu-down"></span>');
    } else {
      $button.html('<span class="glyphicon glyphicon-menu-up"></span>');
    }
  }

  $button.on('click', () => {
    lsUtil.set(!lsUtil.get());
    reDrawElementWrapper();
    reDrawBtn();
  });

  $element.show(); //to override stylesheet style
  reDrawElementWrapper(true);
  reDrawBtn();
}

export { setElementFolding };
