import type { PasswordRecoveryAuthenticationFormOpts } from './types';

function passwordRecoveryAuthentication(
  selector: string,
  params: PasswordRecoveryAuthenticationFormOpts,
) {
  return import('./init').then(({ passwordRecoveryAuthentication: fn }) =>
    fn(selector, params),
  );
}

export { passwordRecoveryAuthentication };
