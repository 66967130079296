import { asyncNoop } from '@/utils';
import { createContext, useContext } from 'react';
import type { DocumentFile } from '../../../types';

type MediaServiceContextType = {
  deleteDocument: (deletedDocument: DocumentFile) => Promise<void>;
  documents: DocumentFile[];
  extraDocuments: DocumentFile[];
  getDocumentId: (document: DocumentFile) => string;
  isDocumentsLoading: boolean;
  isDocumentsUploading: boolean;
  mainDocuments: DocumentFile[];
  updateDocumentsViaLinks: (links: string[]) => Promise<void>;
  uploadDocuments: (formData: FormData) => Promise<DocumentFile[]>;
  uploadViaButton: (formData: FormData) => Promise<DocumentFile[]>;
};

const MediaServiceContext = createContext<MediaServiceContextType>({
  deleteDocument: asyncNoop,
  documents: [],
  extraDocuments: [],
  getDocumentId: () => '',
  isDocumentsLoading: false,
  isDocumentsUploading: false,
  mainDocuments: [],
  updateDocumentsViaLinks: asyncNoop,
  uploadDocuments: () => Promise.resolve([]),
  uploadViaButton: () => Promise.resolve([]),
});

const useMediaServiceContext = () => useContext(MediaServiceContext);

export {
  MediaServiceContext,
  type MediaServiceContextType,
  useMediaServiceContext,
};
