import type { DocumentFile } from '../../../../types';

const fetchDocumentsRequest = async (baseUrl: string, urls: string[] = []) => {
  const response = await fetch(baseUrl, {
    body: JSON.stringify({ urls }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
  });

  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }

  return (await response.json()) as DocumentFile[];
};

export { fetchDocumentsRequest };
