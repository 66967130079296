import $ from 'jquery';
import '../common/plugins/dtable';
import '../common/plugins/font-awesome';
import '../common/plugins/jquery-ui';

import '../common/style/style-init';

import 'dragula/dist/dragula.css';

import '../common/plugins/bootstrap-treeview';
import '../common/plugins/jquery-multiselect';
import '../common/plugins/jquery-qtip';
import '../common/plugins/jquery-simplecolopicker';
import '../common/plugins/jquery-tablesorter';
import '../common/plugins/scriptbreaker-multiple-accordion';

import type { AppTheme } from '@/common';
import {
  desktopMenu,
  email,
  logo,
  patchJqMultiselectStrings,
  resizeMessages,
  siteFeatures,
  warnAboutBrowser,
} from '@/common';
import type { Params as TimeoutParams } from '@/common/features/timeout';
import { timeout } from '@/common/features/timeout';
import { inIframe, Timer } from '@/utils';
import {
  isLoadingOverlayShown,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils/dom/loading';
import { initResolve, initDone as waitForInit, wrapWithInit } from './proxy';
import { getLang, setLang } from './utils';
import { logger } from '@/utils/logger';

type Params = {
  globalLoaderImg: string;
  isConfigureSession?: boolean;
  isLoggedIn?: boolean;
  isManagement?: boolean;
  isMobileAgent?: boolean;
  isMobileMode?: boolean;
  isPrintMode?: boolean;
  lang: string;
  multiSelectTranslations: Dictionary;
  theme: AppTheme;
  timeoutParams?: TimeoutParams;
};

async function init({
  isConfigureSession,
  isLoggedIn,
  isManagement,
  isMobileAgent,
  isPrintMode,
  lang,
  multiSelectTranslations,
  theme,
  timeoutParams,
}: Params) {
  const root = document.documentElement;
  root.dataset.theme = theme;

  if (isMobileAgent) {
    root.dataset.agent = 'mobile';
  }

  if (inIframe()) {
    logger.info('Iframe init');
    // в iframe
    if (isLoadingOverlayShown()) {
      logger.info('Loading overlay is shown');
      removeLoadingOverlay();
    } else {
      logger.info('Loading overlay not shown');
      const timer = new Timer(() => {
        logger.info('Calling setLoadingOverlay');
        setLoadingOverlay();
      }, 250);

      $(() => {
        const isTimerCompleted = timer.cancel();
        logger.info(
          'Page loaded',
          `Timer to start loading completed: ${isTimerCompleted}`,
        );
        if (isTimerCompleted) {
          logger.info('Calling removeLoadingOverlay');
          removeLoadingOverlay();
        }
      });
    }
  } else {
    // не в iframe
    logger.info('non-iframe init');
    removeLoadingOverlay();
  }

  resizeMessages();

  if (!isPrintMode) {
    email();
    siteFeatures({
      iFrameConfig: { backArrow: true, skippableCommands: true },
      isLoggedIn,
      lang,
    });

    if (!isConfigureSession) {
      logo();
    }

    if (!isManagement) {
      desktopMenu();
    }

    patchJqMultiselectStrings(multiSelectTranslations);

    if (isLoggedIn) {
      timeoutParams && timeout(timeoutParams);
      warnAboutBrowser();
    }
  }

  setLang(lang);

  initResolve?.();
}

export { getLang, init, waitForInit, wrapWithInit };
