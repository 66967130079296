import $ from 'jquery';

const fixPopover = () => {
  $(document.body).on('click', function (e) {
    $('[data-toggle="popover"],[data-original-title]').each(function () {
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $('.popover').has(e.target).length === 0
      ) {
        (
          ($(this).popover('hide').data('bs.popover') || {}).inState || {}
        ).click = false; // fix for BS 3.3.6
      }
    });
  });
};

const setupModal = (id) => {
  $(`#md${id}`).on('hidden.bs.modal', function () {
    $(`#md${id} div.modal-body`).html(
      '<i class="fa fa-spinner fa-pulse fa-4x fa-fw"/>'
    );
  });
};

export { fixPopover, setupModal };
