import { useMobileCheck } from '../../hooks';
import { DesktopDialog } from './desktop';
import { MobilePage } from './mobile/MobilePage';
import type { DesktopDialogProps } from './types';

type Props = DesktopDialogProps;

const Dialog = (props: Props) => {
  const { isMobile } = useMobileCheck();

  if (isMobile) {
    return <MobilePage {...props} />;
  }

  return <DesktopDialog {...props} />;
};

export { Dialog };
