import throttle from 'lodash/throttle';
import { getCoords } from './general';

function makeBsDropdownFixed(
  $dropdown: JQuery,
  $extraScrollContainers: JQuery
) {
  const getDropdownCoords = () => getCoords($dropdown.get(0));
  const $menu = $dropdown.find('.dropdown-menu');
  $menu.css('position', 'fixed');

  const updateCoords = () => {
    const coords = getDropdownCoords();

    $menu.css('left', coords.left);
    $menu.css('top', coords.bottom);
  };
  const updateCoordsDebounced = throttle(updateCoords, 50);

  $dropdown.on('show.bs.dropdown', function () {
    updateCoords();

    window.addEventListener('resize', updateCoordsDebounced);
    window.addEventListener('scroll', updateCoordsDebounced);

    if ($extraScrollContainers?.length) {
      $extraScrollContainers?.on('resize', updateCoordsDebounced);
      $extraScrollContainers?.on('scroll', updateCoordsDebounced);
    }
  });

  $dropdown.on('hidden.bs.dropdown', function () {
    window.removeEventListener('resize', updateCoordsDebounced);
    window.removeEventListener('scroll', updateCoordsDebounced);

    if ($extraScrollContainers?.length) {
      $extraScrollContainers.off('resize', updateCoordsDebounced);
      $extraScrollContainers.off('scroll', updateCoordsDebounced);
    }
  });
}

export { makeBsDropdownFixed };
