import asFormData from 'json-form-data';
import type { DocumentFile, RequestsParams } from '../../../../types';
import {
  checkFormDataFilesCountLimit,
  checkFormDataSizeLimit,
  displayError,
} from '@/utils';
import { translate } from '@/common';

type RequestMeta = {
  baseURL: string;
  initialDocuments?: (DocumentFile | string)[];
  requestsParams: RequestsParams;
};

const fetchDocumentsRequest = async (
  { baseURL, requestsParams }: RequestMeta,
  data?: { fileLinkList: string },
) => {
  const response = await fetch(baseURL, {
    body: asFormData({
      ...data,
      ...requestsParams.initOptions,
    }),
    method: 'post',
  });

  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }

  return (await response.json()) as DocumentFile[];
};

const uploadDocumentsRequest = async (
  { baseURL, requestsParams }: RequestMeta,
  formData: FormData,
) => {
  const belowSizeLimit = checkFormDataSizeLimit(formData);
  if (!belowSizeLimit) {
    displayError(translate('common.upload.above_size_limit'));
    return;
  }

  const belowCountLimit = checkFormDataFilesCountLimit(formData);
  if (!belowCountLimit) {
    displayError(translate('common.upload.above_count_limit'));
    return;
  }

  const response = await fetch(baseURL, {
    body: asFormData(requestsParams.uploadOptions || {}, {
      initialFormData: formData,
    }),
    method: 'post',
  });

  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }

  return (await response.json()) as DocumentFile[];
};

const deleteDocumentRequest = async (
  { baseURL, requestsParams }: RequestMeta,
  data: { fileid: string },
) => {
  const response = await fetch(baseURL, {
    body: asFormData({
      ...data,
      ...requestsParams.deleteOptions,
    }),
    method: 'post',
  });

  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }

  return (await response.json()) as any[] | { error: string };
};

export { deleteDocumentRequest, fetchDocumentsRequest, uploadDocumentsRequest };
