import $ from 'jquery';
import { MinMax, setContainerHeightTillEndOfPage } from '@/utils';

function topnav() {
  const $topnav = $('.topnav');
  if ($topnav.length > 0) {
    $topnav.accordion({
      accordion: true,
      closedSign: '<i class="fa fa-chevron-down"></i>',
      openedSign: '<i class="fa fa-chevron-up"></i>',
      speed: 500,
    });

    $topnav.css('overflow-y', 'auto');
    setContainerHeightTillEndOfPage({
      bottomMargin: () => $('#footer').height(),
      container: $topnav.get(0),
      minMax: MinMax.max,
    });
  }
}

export { topnav };
