import type { Moment } from 'moment';

type MessageOpts = {
  a?: string;
  attachments?: string[];
  attachmentsDeleteOptions?: Dictionary;
  attachmentsFetchOptions?: Dictionary;
  attachmentsUploadOptions?: Dictionary;
  date?: Moment;
  message?: string;
  subject?: string;
};

type CommonMessagesConfig = {
  accept?: string;
  attachmentsBaseURL?: string;
  defaultSender?: string;
  disabled?: boolean;
  hasCamera?: boolean;
  senderOptions?: PersonOption[];
  templateOptions?: Template[];
  templatesChangeUrl?: string;
  templatesCreateUrl?: string;
  url: string;
};

type DocData = {
  a: string;
  ac: string;
  ah: string;
  docId: string;
  docIdName: string;
};

type PersonOption = {
  name: string;
  value: string;
};

type ExternalRecipient = {
  email: string;
  name: string;
};

type Template = {
  body: string;
  id: string;
  subj: string;
};

type MessageAttachment = {
  label: string;
  url: string;
};

type Message = {
  fileLinks?: string[];
  mes_att?: boolean;
  mes_attachments?: MessageAttachment[];
  mes_body: string;
  mes_canEdit?: boolean;
  mes_channel: MessageSource;
  mes_datetime: number | false;
  mes_id: string;
  mes_noread: boolean | string;
  mes_reciever: string;
  mes_self: boolean;
  mes_sendResult?: Nullable<string>;
  mes_sendStatus?: string;
  mes_sender: string;
  mes_sender_id?: string;
  mes_text?: string;
  mes_title?: string;
};

enum MessageDialogClosedStatus {
  Closed,
  Submitted,
  Deleted,
}

enum TemplateStandardOptions {
  Change = 'change',
  Create = 'create',
}

enum PostponeOptions {
  Postpone = 'Postpone',
}

enum MessageSource {
  ExternalIncoming = '5',
  ExternalOutgoing = '4',
  InternalIncoming = '2',
  InternalOutgoing = '3',
  SmsIncoming = '6',
  SmsOutgoing = '7',
  TelegramIncoming = '8',
  TelegramOutgoing = '9',
  WhatsAppIncoming = '10',
  WhatsAppOutgoing = '11',
}

type OutgoingMessage =
  | MessageSource.ExternalOutgoing
  | MessageSource.SmsOutgoing
  | MessageSource.InternalOutgoing
  | MessageSource.TelegramOutgoing
  | MessageSource.WhatsAppOutgoing;

export {
  CommonMessagesConfig,
  DocData,
  ExternalRecipient,
  Message,
  MessageAttachment,
  MessageDialogClosedStatus,
  MessageOpts,
  MessageSource,
  OutgoingMessage,
  PersonOption,
  PostponeOptions,
  Template,
  TemplateStandardOptions,
};
