import moment from 'moment';

import { autoFormattable } from '@/utils';

type DpOpts = {
  allowInputToggle?: boolean;
  calendarWeeks?: boolean;
  format?: string;
  keyBinds?: boolean;
  locale?: string;
  useCurrent?: boolean;
  useStrict?: boolean;
};

type OtherOpts = {
  autoFormat?: boolean;
  weekCell?: boolean;
  weekSelect?: boolean;
};

export default function ($dp, dpOpts: DpOpts = {}, otherOpts: OtherOpts = {}) {
  $dp.datetimepicker(dpOpts);
  const $dp_api = $dp.data('DateTimePicker');

  const $inp = $dp.find('input');

  function autoFormat(format) {
    autoFormattable($inp, format);

    $inp.on('input', function () {
      if (moment($(this).val(), format, true).isValid()) {
        $dp_api.hide();
        $dp_api.show();
      }
    });
  }

  function addWeekCell() {
    function createElement() {
      $dp.wrap('<div class="dp-with-weeks"/>');
      const $wrapper = $dp.parent();

      const $weekCell = $(
        '<input type="text" class="dp-with-weeks__week" disabled>',
      );
      $wrapper.prepend($weekCell);
      return $weekCell;
    }

    function setWeekcellValue($weekCell) {
      if ($dp_api.date()) {
        $weekCell.val($dp_api.date().week());
      }
    }

    const $weekCell = createElement();
    const $dpWeekCellUpdate = () => setWeekcellValue($weekCell);

    $dpWeekCellUpdate();
    $dp.on('dp.change', $dpWeekCellUpdate);
  }

  function onWeekSelect() {
    $dp.on(
      'click',
      '.datepicker .datepicker-days .table-condensed tbody .cw',
      function () {
        $(this).siblings().get(0).click();
      },
    );
  }

  if (otherOpts.autoFormat && dpOpts.format) {
    autoFormat(dpOpts.format);
  }

  if (otherOpts.weekCell) {
    addWeekCell();
  }

  if (otherOpts.weekSelect) {
    onWeekSelect();
  }
}
