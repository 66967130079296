import type { Commands, HookAction } from '@/react-app/iframe/types';

type IFrameGlobalConfig = {
  backArrow?: boolean;
  skippableCommands: Commands[] | true;
};

enum MessageTypes {
  CloseSelf = 'CloseSelf',
  CloseTop = 'CloseTop',
  ForceCloseAll = 'ForceCloseAll',
  NotifyMobileDialogShown = 'NotifyMobileDialogShown',
  OnCloseActions = 'OnCloseActions',
  OpenIframe = 'OpenIframe',
  Restored = 'Restored',
}

type IFrameOpts = {
  onClosed?: HookAction[] | HookAction;
  onOpened?: HookAction[] | HookAction;
  url: string;
};

type OpenIFrameInput = {
  onClosed?: HookAction[];
  onOpened?: HookAction[];
  url: string;
};

type OpenIFrameMessage = {
  payload: OpenIFrameInput;
  type: MessageTypes.OpenIframe;
};

type CloseSelfInput = Optional<{ actions?: HookAction[] }>;
type CloseSelfMessage = {
  payload: CloseSelfInput;
  type: MessageTypes.CloseSelf;
};

type OnCloseActionsInput = { actions: HookAction[] };
type OnCloseActionsMessage = {
  payload: OnCloseActionsInput;
  type: MessageTypes.OnCloseActions;
};

type CloseTopMessage = {
  type: MessageTypes.CloseTop;
};

type ForceCloseAllMessage = {
  type: MessageTypes.ForceCloseAll;
};

type CustomFormUpdateInput = {
  an: any;
  snr: any;
};

type OnRestoredMessage = {
  type: MessageTypes.Restored;
};

export {
  CloseSelfInput,
  CloseSelfMessage,
  CloseTopMessage,
  CustomFormUpdateInput,
  ForceCloseAllMessage,
  IFrameGlobalConfig,
  IFrameOpts,
  MessageTypes,
  OnCloseActionsInput,
  OnCloseActionsMessage,
  OnRestoredMessage,
  OpenIFrameInput,
  OpenIFrameMessage,
};
