import {
  makeModalWindow,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils';

function getHistory(dd: string, langObject: Dictionary) {
  setLoadingOverlay();
  $.ajax({
    data: {
      fsess: dd,
      id: 24,
    },
    dataType: 'json',
    error(e) {
      removeLoadingOverlay();
      alert(langObject.errorTitle + ': ' + e.responseText);
    },
    success(response) {
      removeLoadingOverlay();
      if (response.res == 'ok') {
        const table = $(
          '<table class="display compact" cellspacing="0" data-page-length="20" width="100%"><thead><tr><th>' +
            langObject.header1 +
            '</th><th>' +
            langObject.header2 +
            '</th><th>' +
            langObject.header3 +
            '</th><th>' +
            langObject.header4 +
            '</th><th>' +
            langObject.header5 +
            '</th><th>' +
            langObject.header6 +
            '</th></tr></thead></table>'
        );
        const mWin = makeModalWindow(
          langObject.title,
          table,
          langObject.closeButton,
          ''
        );
        table.DataTable({
          autoWidth: true,
          columns: [
            { data: '_00643' },
            { data: '_00223' },
            { data: '_00201' },
            { data: '_00386' },
            { data: '_02707' },
            { data: '_02708' },
          ],
          data: response.data,
          lengthChange: false,
          order: [[0, 'desc']],
          processing: true,
        });
        mWin.modal('show');
      }
    },
    type: 'POST',
    url: '/ajax_api.php',
  });
}

export { getHistory };
