import { useEffect, useState } from 'react';

import { useMediaDialogContext } from '../context';
import type { Scroll, Sizes } from '../types';
import { Mode } from '../types';
import { usePrevious } from '@/react-app/shared/hooks';

type Params = {
  containerScroll: Scroll;
  getCanvas: (sizes: Sizes) => Nullable<HTMLCanvasElement>;
  getSizes: () => Sizes;
};

const useExtractCanvas = ({ containerScroll, getCanvas, getSizes }: Params) => {
  const { image, mode } = useMediaDialogContext();
  const { setCanvasImage } = image;
  const [display, setDisplay] = useState(mode === Mode.Normal);
  const prevMode = usePrevious(mode);

  useEffect(() => {
    (async () => {
      if (prevMode === Mode.Normal && mode !== Mode.Normal) {
        const sizes = getSizes();
        const canvas = getCanvas(sizes);

        if (canvas) {
          setCanvasImage({
            canvas,
            containerScroll,
            sizes,
          });
        }

        setDisplay(false);
      } else if (prevMode !== Mode.Normal && mode === Mode.Normal) {
        setDisplay(true);
      }
    })();
  }, [getCanvas, mode, prevMode, getSizes, setCanvasImage, containerScroll]);

  return {
    display,
  };
};

export { useExtractCanvas };
