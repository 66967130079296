import $ from 'jquery';

import { openDidahBox } from '../../didah-viewer';
import saveSettingsButtonTwig from './save-settings-button.twig';
import type { TableSettings } from './storage';
import { setupSaveSettings } from './storage';

type AddSaveSettingsButtonParams = {
  getCurrentSettings?: () => TableSettings;
  isSaveEnabled?: boolean;
  isSaveForUserEnabled?: boolean;
  renderBtn: (btn: JQuery) => void;
  saveForUserParams?: Dictionary;
  saveParams?: Dictionary;
};
const addSaveSettingsButton = ({
  getCurrentSettings,
  isSaveEnabled,
  isSaveForUserEnabled,
  renderBtn,
  saveForUserParams,
  saveParams,
}: AddSaveSettingsButtonParams) => {
  if (!isSaveEnabled && !isSaveForUserEnabled) {
    return;
  }

  const $btn = $(
    saveSettingsButtonTwig({
      actions: [
        isSaveEnabled && {
          id: 'save',
          label: saveParams.label,
        },
        isSaveForUserEnabled && {
          id: 'save-for-user',
          label: saveForUserParams.label,
        },
      ].filter(Boolean),
    }),
  );

  $btn.find('[data-action="save"').on('click', async function () {
    const dtSettings =
      '%26amp;dtSettings=' + encodeURI(JSON.stringify(getCurrentSettings?.()));
    openDidahBox(saveParams.base + dtSettings, 'iframe');
  });
  $btn.find('[data-action="save-for-user"').on('click', async function () {
    const dtSettings =
      '%26amp;dtSettings=' + encodeURI(JSON.stringify(getCurrentSettings?.()));
    openDidahBox(saveForUserParams.base + dtSettings, 'iframe');
  });

  renderBtn($btn);
};

export {
  AddSaveSettingsButtonParams,
  addSaveSettingsButton,
  setupSaveSettings,
};
