enum KeyboardEventKey {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Enter = 'Enter',
  Escape = 'Escape',
}

const ArrowEventKeys = [
  KeyboardEventKey.ArrowLeft,
  KeyboardEventKey.ArrowRight,
  KeyboardEventKey.ArrowUp,
  KeyboardEventKey.ArrowDown,
];

export { ArrowEventKeys, KeyboardEventKey };
