import $ from 'jquery';

function tooltips() {
  $('[data-toggle="tooltip"]').tooltip();

  setTimeout(function () {
    $('[rel="tooltip"]').tooltip({
      container: 'body',
      delay: { hide: 2000 },
      trigger: 'hover',
    });
  }, 500);
  setTimeout(function () {
    $('[rel="tooltip"]').tooltip({
      container: 'body',
      delay: { hide: 2000 },
      trigger: 'hover',
    });
  }, 1500);
  $('[data-toggle="popover"]:not(.custom-popover)').popover({
    content: 'Popover content',
    html: true,
    placement: 'bottom',
    trigger: 'manual',
  });
}

export { tooltips };
