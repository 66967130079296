import { Modal } from '@/react-app/shared/components';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PhotoImage } from './PhotoImage';
import type { PhotoCameraParams } from './types';
import { OverlayControls } from '../shared';

type Props = PhotoCameraParams;

function PhotoCamera({ closeActiveDialog, isActive, onCaptured }: Props) {
  return (
    <Modal className="tw-bg-black/75" isShown={isActive}>
      <div className="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
        <div className="tw-relative tw-h-[95vh] tw-w-[90vw] tw-overflow-hidden tw-rounded-lg">
          <PhotoImage onCaptured={onCaptured} />
        </div>
        <div
          className="tw-absolute tw-left-1/2 tw-top-0 -tw-translate-x-1/2"
          onClick={closeActiveDialog}
        >
          <OverlayControls className="tw-rounded-b-lg">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => closeActiveDialog()}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </OverlayControls>
        </div>
      </div>
    </Modal>
  );
}

export { PhotoCamera };
