import { translate } from '@/common';
import { cn } from '@/utils';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { MiniItems } from './MiniItems';
import type { PipelineItemTileProps } from './PipelineItemTile';
import { PipelineItemTile } from './PipelineItemTile';
import type { PipelineOptions, PipelineStage } from './types';
import { reactNodeToText } from '../../utils';

type Props<I extends Dictionary, S extends Dictionary> = {
  isDisabled?: boolean;
  isHighlightedByDnd?: boolean;
  isLoading?: boolean;
  onCreate: (stageId: UniqueIdentifier) => void;
  onToggleMinimize?: (stageId: UniqueIdentifier) => void;
  options: PipelineOptions<I, S>;
  renderLabel?: ({ items, stage }: { items: I[]; stage: S }) => ReactNode;
  stage: PipelineStage<I, S>;
} & Pick<PipelineItemTileProps<I>, 'itemActions' | 'renderItem'>;

const PipelineStageColumn = <I extends Dictionary, S extends Dictionary>({
  isDisabled,
  isHighlightedByDnd,
  isLoading,
  itemActions,
  onCreate,
  onToggleMinimize,
  options,
  renderItem,
  renderLabel,
  stage,
}: Props<I, S>) => {
  const isMinimized = useMemo(() => {
    return options.stage.minimizedIds?.includes(stage.id);
  }, [stage.id, options.stage.minimizedIds]);

  const { setNodeRef } = useDroppable({
    disabled: isDisabled || isMinimized,
    id: stage.id,
  });

  const isColumnHighlightedExternally = useMemo(() => {
    return options.stage.highlightedIds?.includes(stage.id);
  }, [stage.id, options.stage.highlightedIds]);

  const onHeaderClick = () => {
    if (!options.stage.isMinimizable) {
      return;
    }

    onToggleMinimize?.(stage.id);
  };

  const stageLabel = renderLabel
    ? renderLabel({
        items: stage.items.map((item) => item.data),
        stage: stage.data,
      })
    : stage.label || stage.id;

  return (
    <div
      className={cn(
        'tw-flex tw-flex-col tw-overflow-hidden tw-px-2.5 tw-transition-colors tw-duration-200',
        {
          'tw-bg-[#eeeeee]': isColumnHighlightedExternally,
          'tw-bg-foreground-primary/10 dark:tw-bg-foreground-primary-dark/10':
            isHighlightedByDnd,
          'tw-flex-1': !isMinimized,
          'tw-w-20 tw-flex-none': isMinimized,
        },
      )}
    >
      <div className="tw-text-nowrap tw-py-4">
        <span
          className="link tw-inline-flex tw-max-w-full tw-items-center tw-gap-1 tw-font-bold"
          onClick={() => onHeaderClick()}
        >
          {options.stage.isMinimizable && (
            <FontAwesomeIcon icon={isMinimized ? faArrowRight : faArrowLeft} />
          )}
          <span className="tw-truncate" title={reactNodeToText(stageLabel)}>
            {stageLabel}
          </span>
        </span>
      </div>
      <div className="tw-flex tw-flex-col tw-divide-y tw-overflow-hidden">
        <div
          ref={setNodeRef}
          className="tw-relative tw-flex tw-min-h-12 tw-max-w-full tw-flex-1 tw-flex-col tw-gap-2 tw-overflow-y-auto tw-overflow-x-visible tw-p-1"
        >
          {isMinimized ? (
            <MiniItems count={stage.items.length} />
          ) : (
            <SortableContext
              id={stage.id + ''}
              items={stage.items.map((item) => item.id)}
            >
              {stage.items.map((item) => (
                <PipelineItemTile
                  key={item.id}
                  isDragItem
                  isLoading={isLoading}
                  itemActions={itemActions}
                  itemData={item.data}
                  itemId={item.id}
                  itemLabel={item.label}
                  renderItem={renderItem}
                />
              ))}
            </SortableContext>
          )}
        </div>
        <div className="tw-flex-none tw-py-4 tw-font-semibold">
          <div
            className="link tw-truncate"
            onClick={() => {
              onCreate?.(stage.id);
            }}
          >
            + {translate('common.create')}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PipelineStageColumn };

export type { Props as PipelineStageColumnProps };
