/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';
import dragula from 'dragula';

import { translate } from '@/common/features/translations';

type GroupFields = any;

type GroupingStateUpdatedHandler = (
  currentGroups: string[],
  minimizedGroups: string[]
) => void;

type Options = {
  colIdToFieldName?: Dictionary<string>;
  dt: any;
  groupFields: GroupFields;
  grpCallback: (groupFields: GroupFields) => void;
  initialState?: GroupingState;
  onGroupingStateUpdated?: GroupingStateUpdatedHandler;
  renderBtn: (btn: JQuery) => void;
  renderContainer: (btn: JQuery) => void;
  simpleMode?: boolean;
};

type GroupingState = {
  currentGroups: string[];
  minimizedGroups: string[];
};

type State = GroupingState & {
  draggedId: Nullable<string>;
  shown: boolean;
};

/**
 *
 * @param renderBtn - callback which receives button JQ object
 * @param renderContainer - callback which receives drop container JQ object
 * @param dt - DataTable API object
 * @param groupFields
 * @param grpCallback
 */
function tableGroupsDragula({
  colIdToFieldName,
  dt,
  groupFields = {
    alltotal: false,
    groupColumn: [],
    groupColumnName: [],
    hideLevelGroup: null,
    linkFieldsList: {},
    rowsData: {},
    rowsDataCount: [],
    subtotalFieldsList: [],
  },
  grpCallback,
  initialState,
  onGroupingStateUpdated,
  renderBtn,
  renderContainer,
  simpleMode = false,
}: Options) {
  const groupsStr = translate('table_groups_dragula.title');
  const clearStr = translate('table_groups_dragula.cancel');
  const allTotalStr = translate('table_groups_dragula.allTotal');

  const fieldNameToColId = colIdToFieldName
    ? Object.fromEntries(
        Object.entries(colIdToFieldName).map(([key, value]) => [value, key])
      )
    : null;

  const convertToColId = (fieldName: string) => {
    if (!fieldNameToColId) {
      return fieldName;
    }

    return fieldNameToColId[fieldName] ?? fieldName;
  };

  const convertToFieldName = (colId: string) => {
    if (!colIdToFieldName) {
      return colId;
    }

    return colIdToFieldName[colId] ?? colId;
  };

  const state: State = {
    currentGroups: (initialState?.currentGroups ?? []).map(convertToColId),
    draggedId: null,
    minimizedGroups: (initialState?.minimizedGroups ?? []).map(convertToColId),
    shown: false,
  };

  const notifyGroupingStateUpdated = () => {
    onGroupingStateUpdated(
      state.currentGroups.map(convertToFieldName),
      state.minimizedGroups.map(convertToFieldName)
    );
  };

  let $dropBar,
    $buttons,
    $buttonClear,
    $buttonAllTotal,
    $buttonAllTotalCheckbox,
    $dst,
    $src,
    $toggleButtonGroup,
    $toggleButton;

  function getValidGroups() {
    const cols = dt.columns();
    return Array.from(cols.dataSrc()).filter((group) => !!group);
  }

  function getGroupName(group) {
    const cols = dt.columns();
    const allGroups = cols.dataSrc();
    const header = cols.header();
    const index = allGroups.indexOf(group);
    return header[index].innerText;
  }

  function updateTable() {
    const fieldName = [...state.currentGroups];

    dt.processing(true);
    groupFields.rowsData = {};

    groupFields.groupColumn = fieldName
      .map(function (d) {
        return dt.columns().dataSrc().indexOf(d);
      })
      .filter(function (d) {
        return d >= 0;
      });
    groupFields.groupColumnName = groupFields.groupColumn.map(function (d) {
      return dt.columns().dataSrc()[d];
    });

    setTimeout(function () {
      dt.columns().visible(true, false);
      if (groupFields.groupColumn.length > 0) {
        dt.columns(groupFields.groupColumn).visible(false);
        dt.order.fixed({
          pre: groupFields.groupColumn.map(function (d) {
            return [d, 'asc'];
          }),
        });
        dt.fixedColumns().hide();
        dt.draw(false);
      } else {
        dt.order.fixed({});
        dt.rows()
          .nodes()
          .each(function (r) {
            $(r).removeClass('grp_hide');
          });
        dt.draw(false);
        dt.fixedColumns().show();
        dt.fixedColumns().update();
      }
    }, 0);

    if (grpCallback) {
      setTimeout(function () {
        grpCallback(groupFields);
      }, 0);
    }
  }

  function updateChips() {
    if (simpleMode) return;

    $dst.find('.dragula-group-chip').remove();
    state.currentGroups.forEach((group) => $dst.append(createChip(group)));
  }

  function createChip(group) {
    const text = getGroupName(group);
    const $newView = $(
      `<button class="btn btn-primary dragula-group-chip btn-xs" type="button">
                ${
                  state.minimizedGroups.includes(group)
                    ? '<i class="fa fa-plus"/>'
                    : '<i class="fa fa-minus"/>'
                }
                ${text}
            </button>`
    );
    $newView.data('group', group);
    $newView.click(() => toggleMinimization(group));

    return $newView;
  }

  function updateCheckboxAllTotal() {
    $buttonAllTotalCheckbox?.prop('checked', groupFields.alltotal);
  }

  function updateAll(doUpdateChips: boolean = true) {
    if (doUpdateChips) {
      updateChips();
    }
    updateCheckboxAllTotal();
    updateTable();
  }

  function removeFromGrouping(group, doUpdateChips) {
    state.currentGroups.splice(state.currentGroups.indexOf(group), 1);
    cleanMinimizedGroups();
    updateAll(doUpdateChips);
  }

  function setGrouping(
    groups,
    doUpdateChips: boolean = false,
    silent: boolean = false
  ) {
    state.currentGroups = [...groups];
    notifyGroupingStateUpdated();

    if (silent) {
      return;
    }
    updateAll(doUpdateChips);
  }

  function cleanMinimizedGroups() {
    state.minimizedGroups = state.minimizedGroups.filter((group) =>
      state.currentGroups.includes(group)
    );
  }

  function toggleMinimization(group) {
    if (state.minimizedGroups.includes(group)) {
      maximizeGroup(group);
    } else {
      minimizeGroup(group);
    }
  }

  function minimizeGroup(group) {
    if (!state.currentGroups.includes(group)) return;

    state.minimizedGroups.push(group);
    updateChips();
    hideGroupLevel(state.currentGroups.indexOf(group));

    notifyGroupingStateUpdated();
  }

  function maximizeGroup(group) {
    if (!state.currentGroups.includes(group)) return;

    const index = state.currentGroups.indexOf(group);
    state.minimizedGroups = state.minimizedGroups.filter(
      (group) => state.currentGroups.indexOf(group) < index
    );
    updateChips();
    hideGroupLevel(null);

    notifyGroupingStateUpdated();
  }

  function clear() {
    state.currentGroups = [];
    state.minimizedGroups = [];
    groupFields.hideLevelGroup = null;
    updateAll();

    notifyGroupingStateUpdated();
  }

  function basicSetup() {
    function getGroupIdFromSrcElement($el) {
      const visIdx = $el.index();
      return dt.columns().dataSrc()[dt.column.index('fromVisible', visIdx)];
    }

    function getGroupIdFromDstElement($el) {
      return $el.data('group');
    }

    $dropBar = $('<div class="dragula-group-dropbar" style="display: none"/>');
    $buttons = $('<div class="dragula-group-dropbar-buttons"/>');
    $buttonClear = $(
      `<div class="btn btn-sm btn-default dragula-group-dropbar-button dragula-group-dropbar-button-clear"><span>${clearStr}</span></div>`
    );
    $buttonAllTotal = $(`
            <div class="btn btn-sm btn-default dragula-group-dropbar-button dragula-group-dropbar-button-clear">
                <span>${allTotalStr}</span><input id="checkbox0005" style="margin: 0 0 0 5px;" type="checkbox">
            </div>`);
    $buttonAllTotalCheckbox = $buttonAllTotal.find('input');

    $dst = $('<div class="dragula-group-container dragula-group-dst"/>');
    $src = $('.dataTables_scrollHead thead tr:last-child');
    $toggleButtonGroup = $(`
            <div class="btn-group">
              <button type="button" class="btn btn-default dragula-group-toggle">${groupsStr}</button>
            </div>     
        `);
    $toggleButton = $toggleButtonGroup.find('.dragula-group-toggle');

    $buttons.append($buttonClear);
    $buttons.append($buttonAllTotal);
    $dropBar.append($buttons);
    $dropBar.append($dst);

    $src.addClass('dragula-group-container').addClass('dragula-group-src');

    $toggleButton.click(() => {
      $dropBar.toggle();
      state.shown = !state.shown;
    });

    $buttonAllTotal.prop('checked', !!groupFields.alltotal);
    $buttonAllTotalCheckbox.click(function () {
      groupFields.alltotal = !!$(this).prop('checked');
      dt.processing(true);
      setTimeout(function () {
        dt.draw(false);
      }, 0);
    });

    renderBtn($toggleButtonGroup);
    renderContainer($dropBar);

    const drake = dragula({
      accepts(el, target, source) {
        return $(target).hasClass('dragula-group-dst');
      },
      copy(el, source) {
        return $(source).hasClass('dragula-group-src');
      },
      isContainer(el) {
        return $(el).hasClass('dragula-group-container');
      },
      moves(el, source, handle, sibling) {
        if (!state.shown) return false;

        const $source = $(source);
        if ($source.hasClass('dragula-group-src')) {
          return !!getGroupIdFromSrcElement($(el));
        } else return !!$source.hasClass('dragula-group-dst');
      },
      removeOnSpill: true,
    });

    drake.on('drag', function (el, source) {
      const $source = $(source);
      if ($source.hasClass('dragula-group-src')) {
        state.draggedId = getGroupIdFromSrcElement($(el));
      } else if ($source.hasClass('dragula-group-dst')) {
        state.draggedId = getGroupIdFromDstElement($(el));
      }
    });

    drake.on('drop', function (el, target, source) {
      const $el = $(el);
      const group = state.draggedId;

      const $chip = createChip(group);
      $el.replaceWith($chip);

      const newGroups = [];
      $chip
        .parent()
        .find('.dragula-group-chip')
        .each(function () {
          newGroups.push(getGroupIdFromDstElement($(this)));
        });

      setGrouping(newGroups, false);
    });

    drake.on('remove', function (el, container, source) {
      if ($(source).hasClass('dragula-group-dst')) {
        removeFromGrouping(getGroupIdFromDstElement($(el)), false);
      }
    });

    $buttonClear.on('click', clear);
  }

  function hideGroupLevel(lvl) {
    groupFields.hideLevelGroup = lvl;
    dt.processing(true);
    setTimeout(function () {
      dt.draw(false);
    }, 0);
  }

  function setPreselectedValues() {
    if (state.currentGroups.length) {
      const validGroups = getValidGroups();
      const groups = state.currentGroups.filter((group) =>
        validGroups.includes(group)
      );
      setGrouping(groups, false, true);

      const firstMinimizedGroup = groups.find((group) =>
        state.minimizedGroups.includes(group)
      );
      if (firstMinimizedGroup) {
        hideGroupLevel(groups.indexOf(firstMinimizedGroup));
      }
    }

    updateAll();
  }

  if (!simpleMode) {
    basicSetup();
  }

  setPreselectedValues();

  return {
    toggleMinimization,
  };
}

export { GroupingState, GroupingStateUpdatedHandler, Options };

export default tableGroupsDragula;
