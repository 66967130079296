let lang = '';

function setLang(value: string) {
  lang = value;
}

function getLang() {
  return lang;
}

export { getLang, setLang };
