import { cn } from '@/utils';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ className?: string }>;

function FixedPositionedControls({ children, className }: Props) {
  return (
    <div
      className={cn(
        'tw-fixed tw-left-0 tw-right-0 tw-z-[51] tw-flex tw-w-screen',
        className
      )}
    >
      {children}
    </div>
  );
}

export { FixedPositionedControls };
