import type { InitialErrors } from '@/react-app/shared/types';
import { cn } from '@/utils';
import { useEffect, useMemo } from 'react';

type Props = {
  errors: InitialErrors[];
};
const MainErrors = ({ errors }: Props) => {
  const CRITICAL_ERROR = 'error_critical';

  const renderedErrors = useMemo(() => {
    return errors.filter((error) => error.style !== CRITICAL_ERROR);
  }, [errors]);

  const criticalErrors = useMemo(() => {
    return errors.filter((error) => error.style === CRITICAL_ERROR);
  }, [errors]);

  const getStyle = (error) => {
    if (!error.color) return;
    return { backgroundColor: error.color };
  };

  useEffect(() => {
    criticalErrors.length &&
      criticalErrors.forEach((error) => {
        alert(error.error);
      });
  }, [criticalErrors]);

  if (!renderedErrors.length) return null;

  return (
    <>
      <div>
        {renderedErrors.map((item, index) => (
          <div
            key={index}
            className={cn('panel', {
              'panel-danger': !item.color,
            })}
            style={getStyle(item)}
          >
            <div className="panel-heading">{item.error}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export { MainErrors };
