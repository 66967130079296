import { cn } from '@/utils';
import type { PipelineStageColumnProps } from './PipelineStageColumn';
import { PipelineStageColumn } from './PipelineStageColumn';

import type {
  Active,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  Over,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PipelineItemTile } from './PipelineItemTile';
import type {
  PipelineDragChangeParams,
  PipelineItem,
  PipelineStage,
} from './types';

type Props<I extends Dictionary, S extends Dictionary> = {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  items: I[];
  onDragChange?: (params: PipelineDragChangeParams) => void;
  stages: S[];
} & Pick<
  PipelineStageColumnProps<I, S>,
  | 'renderItem'
  | 'renderLabel'
  | 'onToggleMinimize'
  | 'options'
  | 'onCreate'
  | 'itemActions'
>;

const Pipeline = <I extends Dictionary, S extends Dictionary>({
  className,
  isDisabled,
  isLoading,
  itemActions,
  items: initialItems,
  onCreate,
  onDragChange,
  onToggleMinimize,
  options,
  renderItem,
  renderLabel,
  stages: initialStages,
}: Props<I, S>) => {
  const createPipelineStages = useCallback(
    () => () => {
      const getItems = (stageId: string) => {
        return initialItems.filter(
          (item) => item[options.item.stageIdField] === stageId,
        );
      };

      return initialStages.map((stage) => {
        const stageId = stage[options.stage.idField];
        const stageItems = getItems(stageId).map(
          (item) =>
            ({
              data: item,
              id: item[options.item.idField],
              label: item[options.item.labelField],
              stageId,
            }) satisfies PipelineItem<I>,
        );

        return {
          data: stage,
          id: stageId,
          items: stageItems,
          label: stage[options.stage.labelField] || stageId,
        };
      });
    },
    [
      initialItems,
      initialStages,
      options.item.idField,
      options.item.labelField,
      options.item.stageIdField,
      options.stage.idField,
      options.stage.labelField,
    ],
  );

  const [pipelineStages, setPipelineStages] = useState<PipelineStage<I, S>[]>(
    [],
  );

  useEffect(() => {
    setPipelineStages(createPipelineStages());
  }, [createPipelineStages]);

  const [draggedItem, setDraggedItem] =
    useState<Nullable<PipelineItem<I>>>(null);
  const [dndHoveredStageId, setDndHoveredStageId] =
    useState<Nullable<UniqueIdentifier>>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemsById = useMemo(() => {
    const allItemsFromStages = pipelineStages.flatMap((stage) => stage.items);
    return Object.fromEntries(
      allItemsFromStages.map((item) => [item.id, item]),
    ) as StrictDictionary<UniqueIdentifier, PipelineItem<I>>;
  }, [pipelineStages]);

  const stagesById = useMemo(() => {
    return Object.fromEntries(
      pipelineStages.map((stage) => [stage.id, stage]),
    ) as StrictDictionary<UniqueIdentifier, PipelineStage<I, S>>;
  }, [pipelineStages]);

  const stageIds = useMemo(
    () => Object.keys(stagesById) as UniqueIdentifier[],
    [stagesById],
  );

  function findItemAnywhere(id: UniqueIdentifier) {
    return itemsById[id];
  }

  function idIsContainer(id: UniqueIdentifier) {
    return stageIds.includes(id);
  }

  function findContainerIdOfItem(id: UniqueIdentifier) {
    const item = findItemAnywhere(id);
    return item?.stageId || null;
  }

  function findItemsOfContainer(id: UniqueIdentifier) {
    return stagesById[id].items;
  }

  function setItemsOfContainer(id: UniqueIdentifier, items: PipelineItem<I>[]) {
    setPipelineStages((prevStages) => {
      const stage = prevStages.find((stage) => stage.id === id);
      if (!stage) return prevStages;

      return prevStages.map((prevStage) => {
        if (prevStage.id !== id) return prevStage;

        return {
          ...prevStage,
          items: items.map((item) => ({
            ...item,
            data: {
              ...item.data,
              [options.item.stageIdField]: id,
            },
            stageId: id,
          })),
        };
      });
    });
  }

  function moveLogic(active: Active, over: Nullable<Over>) {
    const activeItem = findItemAnywhere(active.id);
    const possibleOverItem = findItemAnywhere(over.id);

    if (activeItem && possibleOverItem && active.id !== over.id) {
      // Handle Items Sorting
      // Find the active container and over container
      const activeContainer = findContainerIdOfItem(active.id);
      const overContainer = findContainerIdOfItem(over.id);

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) {
        return;
      }

      setDndHoveredStageId(overContainer);

      const activeContainerItems = findItemsOfContainer(activeContainer);
      const overContainerItems = findItemsOfContainer(overContainer);

      // Find the index of the active and over item
      const activeItemIndex = activeContainerItems.findIndex(
        (item) => item.id === active.id,
      );
      const overItemIndex = overContainerItems.findIndex(
        (item) => item.id === over.id,
      );
      // In the same container
      if (activeContainer === overContainer) {
        let newItems = [...activeContainerItems];
        newItems = arrayMove(newItems, activeItemIndex, overItemIndex);

        setItemsOfContainer(activeContainer, newItems);
      } else {
        // In different containers
        const newOverItems = [...overContainerItems];
        const newActiveItems = [...activeContainerItems];
        const [removedItem] = newActiveItems.splice(activeItemIndex, 1);
        newOverItems.splice(overItemIndex, 0, removedItem);

        setItemsOfContainer(activeContainer, newActiveItems);
        setItemsOfContainer(overContainer, newOverItems);
      }
    } else if (activeItem && idIsContainer(over.id)) {
      // Handling Item Drop Into a Container
      // Find the active and over container
      const activeContainer = findContainerIdOfItem(active.id);
      const overContainer = over.id;

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) return;

      setDndHoveredStageId(overContainer);

      const activeContainerItems = findItemsOfContainer(activeContainer);
      const overContainerItems = findItemsOfContainer(overContainer);

      // Find the index of the active and over item
      const activeItemIndex = activeContainerItems.findIndex(
        (item) => item.id === active.id,
      );

      // Remove the active item from the active container and add it to the over container
      const newOverItems = [...overContainerItems];
      const newActiveItems = [...activeContainerItems];

      const [removedItem] = newActiveItems.splice(activeItemIndex, 1);

      setItemsOfContainer(activeContainer, newActiveItems);

      if (!newOverItems.map((item) => item.id).includes(removedItem.id)) {
        newOverItems.push(removedItem);
      }
      setItemsOfContainer(overContainer, newOverItems);
    } else if (active.id === activeItem?.id && idIsContainer(over.id)) {
      const overContainer = over.id;

      setDndHoveredStageId(overContainer);

      const overContainerItems = findItemsOfContainer(overContainer);
      const newOverItems = [...overContainerItems];

      newOverItems.push(activeItem);

      setItemsOfContainer(overContainer, newOverItems);
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    const item = findItemAnywhere(event.active.id);
    if (!item) return;

    setDraggedItem(item);
    setDndHoveredStageId(findContainerIdOfItem(item.id));
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const { active, over } = event;

    moveLogic(active, over);
  };

  // This is the function that handles the sorting of the containers and items when the user is done dragging.
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    moveLogic(active, over);

    const activeContainer = findContainerIdOfItem(active.id);
    const stage = stagesById[activeContainer];
    if (stage) {
      onDragChange?.({
        itemId: active.id,
        newIndex: stage.items.findIndex((item) => item.id === active.id),
        stageId: activeContainer,
      });
    }

    setDraggedItem(null);
    setDndHoveredStageId(null);
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
    >
      <div
        className={cn(
          'tw-flex tw-items-stretch tw-justify-start tw-divide-x',
          className,
        )}
      >
        {pipelineStages.map((stage) => (
          <PipelineStageColumn
            key={stage.id}
            isDisabled={isDisabled}
            isHighlightedByDnd={dndHoveredStageId === stage.id}
            isLoading={isLoading}
            itemActions={itemActions}
            options={options}
            renderItem={renderItem}
            renderLabel={renderLabel}
            stage={stage}
            onCreate={onCreate}
            onToggleMinimize={onToggleMinimize}
          />
        ))}
      </div>
      <DragOverlay>
        {draggedItem ? (
          <PipelineItemTile
            className="tw-scale-105"
            itemData={draggedItem.data}
            itemId={draggedItem.id}
            itemLabel={draggedItem.label}
            renderItem={renderItem}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export { Pipeline };
