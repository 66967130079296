import $ from 'jquery';

function closeSubMenus() {
  const $panelCollapse = $('.panel-collapse');
  $panelCollapse.attr('aria-expanded', 'false');
  $panelCollapse.removeClass('in');
  $panelCollapse.css('height', '0px');
}

function submenu() {
  $('#menu-submenu-line').on('mouseover', function () {
    if ($('.menu-submenu').css('left') == '-400px') {
      $('.menu-submenu').animate(
        {
          left: '0px',
        },
        400
      );
    }
  });
  $('#menu-conf-line').on('mouseover', function () {
    if ($('.menu-conf').css('right') == '-400px') {
      $('.menu-conf').animate(
        {
          right: '0px',
        },
        400
      );
    }
  });

  $('#menu-submenu-but').on('click', function (e) {
    e.preventDefault();
    if ($('.js-menu-submenu').css('left') == '-400px') {
      $('.js-menu-submenu').animate(
        {
          left: '0px',
        },
        400
      );
    }
  });
  $('#menu-conf-but').on('click', function (e) {
    e.preventDefault();
    const $menuConf = $('.menu-conf');
    if ($menuConf.css('right') == '-400px') {
      $menuConf.animate(
        {
          right: '0px',
        },
        400
      );
    }
  });

  $(document.body).on('click', function (e) {
    const $menuSubmenu = $('.menu-submenu');
    if ($menuSubmenu.css('left') == '0px') {
      if (
        !$menuSubmenu.is(e.target) &&
        $menuSubmenu.has(e.target).length === 0
      ) {
        $('.menu-submenu').animate(
          {
            left: '-400px',
          },
          400
        );
      }
    }
    const $menuConf = $('.menu-conf');
    if ($menuConf.css('right') == '0px') {
      if (!$menuConf.is(e.target) && $menuConf.has(e.target).length === 0) {
        $('.menu-conf').animate(
          {
            right: '-400px',
          },
          400
        );
        closeSubMenus();
      }
    }

    const $transMessage = $('#transMessage');
    if ($transMessage.css('display') == 'block') {
      if (
        !$transMessage.is(e.target) &&
        $transMessage.has(e.target).length === 0
      ) {
        $transMessage.hide();
        $('#background').hide();
      }
    }

    const $butModuleOther = $('#but-module-other');
    if ($butModuleOther.next('ul').css('display') == 'block') {
      const $container = $butModuleOther.next('ul');

      if (!$container.is(e.target) && $container.has(e.target).length === 0) {
        $butModuleOther.dropdown('toggle');
      }
    }

    const $topDropdown = $('.js-top-dropdown');
    for (let i = 0; i < $topDropdown.length; i++) {
      const $container = $topDropdown.eq(i);
      if ($container.css('display') == 'block') {
        if (!$container.is(e.target) && $container.has(e.target).length === 0) {
          $container.hide(150);
        }
      }
    }
  });

  //close sub-menu when other is open
  $('a[data-toggle="collapse"]').click(function () {
    closeSubMenus();
  });

  $('.dropdown-submenu a.didah-submenu').on('click', function (e) {
    if (!$(this).parent().hasClass('disabled')) {
      $(this).next('ul').toggle();
    }
    e.stopPropagation();
    e.preventDefault();
  });
}

export { submenu };
