import $ from 'jquery';
import type { Moment, MomentInput } from 'moment';
import moment from 'moment';
import ClickEvent = JQuery.ClickEvent;

import { simpleDateFormat, simpleDateOnlyFormat } from '@/constants';
import { makeCenteredModalWindow } from '@/utils';
import { translate } from '@/common/features/translations';
import { neverOption, options, Period } from './types';

import './style.scss';
const setupPeriodPicker = (
  $body: JQuery,
  onChange: (period: Period) => void,
) => {
  const $periodSelect = $body.find('.dp-modal-period-select');
  $periodSelect.selectize({
    create: false,
    items: [neverOption.id],
    labelField: 'label',
    maxItems: 1,
    onChange(value) {
      if (!value) {
        selectizeAPI.setValue(neverOption.id);
      }
      onChange(value);
    },
    options,
    valueField: 'id',
  });
  const selectizeAPI = ($periodSelect[0] as any).selectize;
};

export type DatePickerModalResult = {
  selectedDate: string | null;
  selectedPeriod: Period | null;
};

function datePickerModal(
  resolve: (result: DatePickerModalResult | null) => void,
  options: Options = {},
) {
  const { initialDate, minDate, showPeriod, timePicker = true } = options;

  const titleStr = translate(
    timePicker
      ? 'datepicker_modal_translations.title'
      : 'datepicker_modal_translations.title_date_only',
  );
  const closeStr = translate('common.modal.cancel');
  const saveStr = translate('common.modal.ok');

  let selectedDate: string = null;
  let isResolved = false;
  let $modal = null;
  let selectedPeriod = neverOption.id;

  const setDate = (date: Moment) => {
    selectedDate = date.set({ seconds: 0 }).format(simpleDateFormat);
  };

  const doResolve = (date: string | null) => {
    if (isResolved) {
      return;
    }

    if (date) {
      resolve({
        selectedDate: date,
        selectedPeriod: selectedPeriod !== Period.Never ? selectedPeriod : null,
      });
    } else {
      resolve(null);
    }
    isResolved = true;
  };

  const onSubmit = (e: ClickEvent) => {
    e.stopPropagation();

    doResolve(selectedDate);
    $modal.modal('hide');
  };
  const onCancel = (e: ClickEvent) => {
    e.stopPropagation();

    doResolve(null);
    $modal.modal('hide');
  };

  const $body = $(`
            <div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-xs-12">
                            <div id="dp-modal-datepicker"/>
                            ${
                              showPeriod
                                ? `
                            <hr>
                            <div class="control-group dp-modal-period-select-container">
                                <label for="select-tools">Repeat:</label>
                                <select class="form-control dp-modal-period-select"/>
                            </div>
                            `
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        `);

  const $datePicker = $body.find('#dp-modal-datepicker');

  const initialMoment = initialDate
    ? moment(initialDate)
    : moment().add(1, 'm');
  setDate(initialMoment);

  $datePicker.datetimepicker({
    format: timePicker ? simpleDateFormat : simpleDateOnlyFormat,
    inline: true,
    minDate,
    sideBySide: !!timePicker,
  });
  const dpApi = $datePicker.data('DateTimePicker');

  if (showPeriod) {
    selectedPeriod = neverOption.id;
    setupPeriodPicker($body, (period) => (selectedPeriod = period));
  }

  dpApi.date(initialMoment);

  $datePicker.on('dp.change', (e) => {
    setDate((e as unknown as { date: Moment }).date);
  });
  $modal = makeCenteredModalWindow(titleStr, $body, closeStr, saveStr, {
    hideOnClick: false,
  });
  $modal.find('.modal-dialog').addClass('dp-modal');

  $modal.find('.m-close').on('click', onCancel);
  $modal.find('.m-save').on('click', onSubmit);

  $modal.modal('show');

  $modal.on('hidden.bs.modal', () => doResolve(null));
}

type Options = {
  initialDate?: MomentInput;
  minDate?: MomentInput;
  showPeriod?: boolean;
  timePicker?: boolean;
};

export default function (options?: Options) {
  return new Promise<DatePickerModalResult | null>((resolve) =>
    datePickerModal(resolve, options),
  );
}
