import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import dragula from 'dragula';

import { makeModalWindow, moveInArrayMutable } from '@/utils';
import { translate } from '@/common/features/translations';

import './style.scss';

type Options = {
  $trigger: JQuery;
  isWithCheckboxes?: boolean;
  onClose: (data: any[]) => void;
  onReset: () => void;
  onSave: (data: any[]) => void;
  staticFirst?: boolean;
};

export function checkboxListModal(
  originalData,
  {
    $trigger,
    isWithCheckboxes = true,
    onClose,
    onReset,
    onSave,
    staticFirst = true,
  }: Options
) {
  const title = translate('checkbox_list_modal.title');
  const selectAll = translate('checkbox_list_modal.selectAll');
  const cancel = translate('checkbox_list_modal.cancel');
  const save = translate('checkbox_list_modal.save');
  const reset = translate('checkbox_list_modal.reset');

  const ID = Symbol('id');

  let data;
  let $modal;

  if (!$trigger) {
    throw new Error(`checkboxListModal ${title} trigger not set!`);
  }

  const copyNewData = (newData) => {
    const d = typeof newData === 'function' ? newData() : newData;
    data = cloneDeep(d);
    data.forEach((entry, i) => (entry[ID] = 'id' + i));
  };

  const exportData = () => {
    const exportedData = [...data];
    return exportedData.map((entry) => {
      delete entry[ID];
      return entry;
    });
  };

  const setupDragula = ($listGroup) => {
    let $dragContainer = $('.checkbox-modal-drag-container');
    if (!$dragContainer.length) {
      $dragContainer = $('<div class="checkbox-modal-drag-container"/>');
      $(document.body).append($dragContainer);
    }

    const drake = dragula([$listGroup.get(0)], {
      direction: 'vertical',
      mirrorContainer: $dragContainer.get(0),
      moves(el, source, handle) {
        const $handle = $(handle);
        const $el = $(el);
        const index = $el.index();
        if (staticFirst && index === 0) {
          return false;
        }

        return (
          $el.hasClass('checkbox-modal-item') &&
          !$el.hasClass('disabled') &&
          ($handle.hasClass('checkbox-modal-item-handle') ||
            $handle.parent().hasClass('checkbox-modal-item-handle'))
        );
      },
    });

    drake.on('drop', function (el) {
      const $el = $(el);
      const id = $el.data('id');
      const toIndex = $el.index();

      if (staticFirst && toIndex === 0) {
        drake.cancel(true);
        return;
      }

      const entry = data.find((entry) => entry[ID] === id);
      const fromIndex = data.indexOf(entry);
      moveInArrayMutable(data, fromIndex, toIndex);
    });
  };

  const get$List = (listData) => {
    const $headerListGroup = $('<ul class="list-group header-list-group"/>');
    const $contentListGroup = $('<ul class="list-group content-list-group"/>');

    let checkSelectAll = null;

    if (isWithCheckboxes) {
      const $header = $(`<li class="list-group-item header-item">
                <div class="form-group select-all">
                    <label for="modal-list-select-all-checkmark">${selectAll} </label>
                    <input class="form-control" type="checkbox" id="modal-list-select-all-checkmark">
                </div>
            </li>`);

      $header.appendTo($headerListGroup);

      const $headerCheckMark = $header.find('#modal-list-select-all-checkmark');
      const selectableListData = listData.filter((entry) => !entry.disabled);
      checkSelectAll = () => {
        $headerCheckMark.prop(
          'checked',
          !selectableListData.find((entry) => !entry.checked)
        );
      };
      checkSelectAll();

      $headerCheckMark.change(() => {
        const isChecked = $headerCheckMark.is(':checked');
        selectableListData.forEach((entry) => (entry.checked = isChecked));
        $contentListGroup
          .find(
            '.list-group-item:not(.header-item):not(.disabled) input[type="checkbox"]'
          )
          .prop('checked', isChecked);
      });
    }

    listData.forEach((entry, i) => {
      const $listItem = $(`<li class="list-group-item checkbox-modal-item">
                       <div class="form-group">
                            <div class="checkbox-modal-item-handle">
                                <i class="fa fa-ellipsis-v" aria-hidden="true"/>
                            </div>
                           <label>${entry.label}</label>${
        isWithCheckboxes ? '<input type="checkbox" class="form-control">' : ''
      }
                       </div>
                   </li>`);

      if (staticFirst && i === 0) {
        $listItem
          .find('.checkbox-modal-item-handle')
          .css('visibility', 'hidden');
      }

      if (isWithCheckboxes) {
        const $cb = $listItem.find('input');
        if (entry.checked) {
          $cb.prop('checked', true);
        }

        if (entry.disabled) {
          $listItem.addClass('disabled');
          $cb.prop('disabled', 'disabled');
        }

        $cb.change(() => {
          entry.checked = $cb.is(':checked');
          checkSelectAll?.();
        });
      }

      $listItem.data('id', entry[ID]);

      $listItem.appendTo($contentListGroup);
    });

    const $listGroups = $('<div class="list-groups"/>');
    $listGroups.append($headerListGroup);
    $listGroups.append($contentListGroup);
    return $listGroups;
  };

  const setData = (newData) => {
    copyNewData(newData);

    if (!$modal) return;

    const $newList = get$List(data);
    $modal.find('.list-groups').replaceWith($newList);

    setupDragula($newList.find('.content-list-group'));
  };

  const hideModal = () => {
    if (!$modal) return;

    $modal.modal('hide');
    $modal = null;
  };

  $trigger.on('click', (e) => {
    e.stopPropagation();

    copyNewData(originalData);

    const $list = get$List(data);
    $modal = makeModalWindow(title, $list, cancel, save, { move: true });
    setupDragula($list.find('.content-list-group'));
    $modal.addClass('modal-list');

    if (onReset) {
      const $resetBtn = $(
        `<button type="button" class="m-reset btn btn-warning" style="float: left">${reset}</button>`
      );
      $modal.find('.modal-footer').append($resetBtn);
      $resetBtn.on('click', onReset);
    }

    const $closeBtn = $modal.find('.m-close');
    $closeBtn.removeAttr('data-dismiss');
    $closeBtn.on('click', () => {
      if (onClose) {
        onClose(exportData());
      }
    });

    $modal.find('.m-save').on('click', () => {
      if (onSave) {
        onSave(exportData());
      }
    });

    const $closeSign = $modal.find('.modal-header button');
    $closeSign.removeAttr('data-dismiss');
    $closeSign.on('click', () => {
      if (onClose) {
        onClose(exportData());
      }
    });

    $modal.modal('show');
  });

  return {
    get $modal() {
      return $modal;
    },
    hideModal,
    setData,
  };
}
