import { cn } from '@/utils';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const Card = forwardRef<HTMLDivElement, Props>(
  ({ children, className, onClick }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-w-full tw-overflow-hidden tw-rounded-xl tw-border tw-border-solid tw-shadow-xl',
          'tw-border-border-default tw-bg-white',
          'dark:tw-text-default-dark dark:tw-border-border-default-dark dark:tw-bg-foreground-default-3-dark',
          className,
        )}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);
Card.displayName = 'Card';

export { Card };
