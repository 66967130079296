import { cn } from '@/utils';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  className?: string;
  size?:
    | 'small'
    | 'medium'
    | 'large'
    | 'extra-large'
    | 'mega-large'
    | 'giga-large';
};

const Spinner = ({ className, size = 'medium' }: Props) => (
  <FontAwesomeIcon
    className={cn(
      'tw-animate-spin',
      {
        'tw-text-3xl': size === 'extra-large',
        'tw-text-4xl': size === 'mega-large',
        'tw-text-5xl': size === 'giga-large',
        'tw-text-lg': size === 'large',
        'tw-text-md': size === 'medium',
        'tw-text-sm': size === 'small',
      },
      className,
    )}
    icon={faSpinner}
    spin
  />
);

export { Spinner };
