import { useMediaDialogContext } from '../../../context';
import {
  TopControls,
  Close,
  Download,
  StartDrawing,
  ZoomSwitcher,
  BottomControls,
  NextPrevButtons,
  GeolocationButton,
} from '../../../controls';
import { Mode } from '../../../types';

function ImageControls() {
  const { mode, options } = useMediaDialogContext();

  if (mode !== Mode.Normal) {
    return null;
  }

  return (
    <>
      <TopControls>
        <ZoomSwitcher />
        <StartDrawing />
        {options?.mode === 'full' && <NextPrevButtons />}
        <Download />
        <GeolocationButton />
        {!options?.noClose && <Close />}
      </TopControls>
      <BottomControls>
        <NextPrevButtons className="tw-mx-auto" />
      </BottomControls>
    </>
  );
}

export { ImageControls };
