import $ from 'jquery';

import type { FileUploaderOptions } from '@/utils';
import {
  downloadURI,
  guid,
  makeModalWindow,
  removeLoadingOverlay,
  removeModalLoadingOverlay,
  setAsFileUploader,
  setLoadingOverlay,
  setModalLoadingOverlay,
} from '@/utils';
import { translate } from '@/common/features/translations';

import './style.scss';

type SubOptions = {
  deleteOptions: { params?: Dictionary; url: string };
  initOptions: { params?: Dictionary; url: string };
  uploadOptions: FileUploaderOptions;
};

type Options = {
  $el: JQuery;
  $hiddenInput: JQuery;
  ajaxCallback: () => void;
  defaultMode: 'visual' | 'manual';
  limitHeight?: boolean;
  noAjaxInitialFiles?: any[];
  options: SubOptions;
  readonly?: boolean;
  type: 'modal' | 'static';
};

export default function setAsImagesModal({
  $el,
  $hiddenInput,
  ajaxCallback,
  defaultMode = 'visual',
  limitHeight = true,
  noAjaxInitialFiles,
  options,
  readonly,
  type,
}: Options) {
  const WAIT_BEFORE_OVERLAY_MS = 200;
  const modalUUID = guid();

  let isVisualMode = defaultMode === 'visual';

  function showErrorMessage(text) {
    alert(text);
  }

  function getHeightLimitClass() {
    if (limitHeight) return ' didah-oldmode-files-limitheight';
    else return '';
  }

  function getFilesWidgetHTML() {
    function optionallyGetPlusButton() {
      if (!readonly) {
        return `<div class="didah-form-file didah-form-files-plus" title="Add new image">
                            <span class="glyphicon glyphicon-plus"></span>
                        </div>`;
      } else return '';
    }

    return `<div class="didah-oldmode-files-container didah-form-files-container clearfix ${getHeightLimitClass()}">${optionallyGetPlusButton()}</div>`;
  }

  function getFilesTextareaViewHTML() {
    return `<textarea class="didah-oldmode-files-textarea form-control ${getHeightLimitClass()}"></textarea>`;
  }

  function getSwitchButtonHTML() {
    return '<div class="didah-oldmode-files-switch"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>';
  }

  function renderFiles(result, $widget, toRemoveAllOnRender) {
    function getSingleFile$(file) {
      function setTooltipFromData($file) {
        function setTooltip(tooltip) {
          $file.prop('title', tooltip);
        }

        if (file.tooltip) setTooltip(file.tooltip);
        else if (file.text) setTooltip(file.text);
      }

      function optionallyGetXicon() {
        if (!readonly) {
          return '<span class="didah-form-file-x">&times;</span>';
        } else return '';
      }

      function getRegularFile() {
        function getRegularFileFAClass() {
          //https://www.w3schools.com/icons/fontawesome_icons_filetype.asp
          if (file.type) return `fa-file-${file.type}-o`;
          else return 'fa-file';
        }

        function getHrefAttrs() {
          if (file.type === 'pdf') {
            return `rel="shadowbox[${modalUUID}];player=iframe" href="${file.url}"`;
          } else {
            return 'href="javascript:void(0);"';
          }
        }

        return $(`<div class="didah-form-file didah-form-regular-file" data-fileid="${
          file.id
        }">
                            <a ${getHrefAttrs()}>
                                <i class="fa ${getRegularFileFAClass()} fa-fw fsxxl icon"></i>
                                <span class="name">${file.text}</span>
                            </a>
                            ${optionallyGetXicon()}
                        </div>`);
      }

      function getImageFile() {
        const $file =
          $(`<div class="didah-form-file didah-form-image" data-fileid="${
            file.id
          }">
                        <a rel="shadowbox[${modalUUID}];player=img" href="${
                          file.url
                        }"></a>
                        ${optionallyGetXicon()}
                       </div>`);
        $file.css('background-image', `url(${file.smallImgUrl})`);
        return $file;
      }

      function get$DependingOnType() {
        return file.type === 'img' ? getImageFile() : getRegularFile();
      }

      function setOnClick($file) {
        if (file.opentype === 'download') {
          $file.on('click', (e) => {
            if ($(e.target).hasClass('didah-form-file-x')) return;
            downloadURI(file.url);
          });
        }
      }

      const $file = get$DependingOnType();
      setTooltipFromData($file);
      setOnClick($file);
      return $file;
    }

    if (toRemoveAllOnRender)
      $widget.find('.didah-form-file:not(.didah-form-files-plus)').remove();
    result &&
      result.forEach((file) => {
        $widget.append(getSingleFile$(file));
      });
  }

  function fillTextArea(result, $textarea) {
    if (result) {
      const finalString = result.map((file) => file.id).join('\n');
      $textarea.val(finalString);
    }
  }

  function getFilesAndRunCallback({ extraData, onSuccess, overlay }) {
    let overlayOn = false;

    const overlayTimeoutId = setTimeout(() => {
      overlay.set();
      overlayOn = true;
    }, WAIT_BEFORE_OVERLAY_MS);

    const finishOverlay = () => {
      if (overlayOn) {
        overlay.remove();
      } else {
        clearTimeout(overlayTimeoutId);
      }
    };

    const data =
      options.initOptions && options.initOptions.params
        ? options.initOptions.params
        : {};
    Object.assign(data, extraData);

    $.ajax({
      cache: false,
      data,
      error: (xhr) => {
        finishOverlay();
        showErrorMessage(xhr.responseText);
      },
      success: (resultAsObjectsArray, textStatus, XMLHttpRequest) => {
        if (XMLHttpRequest.status === 200) {
          onSuccess(resultAsObjectsArray);
          finishOverlay();
        }
      },
      type: 'POST',
      url: options.initOptions.url,
    });
  }

  function getFilesForWidget({
    $widget,
    extraActions,
    extraData = {},
    overlay,
    toRemoveAllOnRender = false,
  }) {
    const onSuccess = (resultAsObjectsArray) =>
      renderFilesFromJsonAndExtraActions(
        resultAsObjectsArray,
        $widget,
        extraActions,
        toRemoveAllOnRender,
      );

    getFilesAndRunCallback({ extraData, onSuccess, overlay });
  }

  function getFilesForTextArea({
    $textarea,
    extraActions,
    extraData = {},
    overlay,
  }) {
    const onSuccess = (resultAsObjectsArray) =>
      fillTextAreaFromJsonAndExtraActions(
        resultAsObjectsArray,
        $textarea,
        extraActions,
      );

    getFilesAndRunCallback({ extraData, onSuccess, overlay });
  }

  function renderFilesFromJsonAndExtraActions(
    result,
    $widget,
    extraActions,
    toRemoveAllOnRender?: boolean,
  ) {
    renderFiles(result, $widget, toRemoveAllOnRender);
    extraActions && extraActions(result, $el);
  }

  function fillTextAreaFromJsonAndExtraActions(
    result,
    $textarea,
    extraActions,
  ) {
    fillTextArea(result, $textarea);
    extraActions && extraActions(result, $el);
  }

  function setUploadButton({ $widget, extraActions, overlay }) {
    if (!readonly) {
      setAsFileUploader({
        $el: $widget.find('.didah-form-files-plus'),
        onError: showErrorMessage,
        onSuccess: (resultAsObjectsArray) => {
          const removeAllOnRender = type === 'modal';
          renderFiles(resultAsObjectsArray, $widget, removeAllOnRender);

          extraActions && extraActions(resultAsObjectsArray, $el);
        },
        options: options.uploadOptions,
        overlayOff: overlay.remove,
        overlayOn: overlay.set,
      });
    }
  }

  function setFileRemoveOnClick({ $widget, extraActions, overlay }) {
    if (!readonly) {
      $widget.on('click', '.didah-form-file-x', function () {
        if (confirm('Do you really want to delete this file?')) {
          const $x = $(this);
          const $image = $x.closest('.didah-form-file');
          const id = $image.data('fileid');
          const data = {
            ...options.deleteOptions.params,
            fileid: id,
          };
          overlay.set();
          $.ajax({
            cache: false,
            data,
            error: (xhr) => {
              overlay.remove();
              showErrorMessage(xhr.responseText);
            },
            success: (resultNotUsed, textStatus, XMLHttpRequest) => {
              if (XMLHttpRequest.status === 200) {
                $image.remove();
                extraActions && extraActions([id], $el);
                overlay.remove();
              }
            },
            type: 'POST',
            url: options.deleteOptions.url,
          });
        }
      });
    }
  }

  function setSwitch({ $switch, $textarea, $widget, extraActions, overlay }) {
    const showAndHide = ($a, $b) => {
      $a.show();
      $b.hide();
    };
    $switch.click(() => {
      if (isVisualMode) {
        showAndHide($textarea, $widget);
        const ids = [];
        $widget
          .find('.didah-form-file:not(.didah-form-files-plus)')
          .each(function () {
            ids.push($(this).data('fileid'));
          });
        $textarea.val(ids.join('\n'));
      } else {
        showAndHide($widget, $textarea);
        const extraData = getTextAreaExtraData($textarea);
        getFilesForWidget({
          $widget,
          extraActions,
          extraData,
          overlay,
          toRemoveAllOnRender: true,
        });
      }

      isVisualMode = !isVisualMode;
    });
  }

  function getTextAreaExtraData($textarea) {
    return {
      fileLinkList: $textarea.val().split('\n').join(' '),
    };
  }

  function getGlobalTextModeExitCallback($textarea, overlay, extraActions) {
    return (cb) => () => {
      if (!isVisualMode) {
        const extraData = getTextAreaExtraData($textarea);
        getFilesAndRunCallback({
          extraData,
          onSuccess: (res) => {
            extraActions(res);
            cb();
          },
          overlay,
        });
      } else {
        cb();
      }
    };
  }

  function getOverlay($modal?: JQuery) {
    return $modal
      ? {
          remove: () => removeModalLoadingOverlay($modal),
          set: () => setModalLoadingOverlay($modal),
        }
      : {
          remove: () => removeLoadingOverlay(),
          set: () => setLoadingOverlay(),
        };
  }

  function showModal() {
    const $outerContainer = $(
      '<div class="didah-files-outer-container"></div>',
    );
    const $widget = $(getFilesWidgetHTML());
    // const $textarea = $(getFilesTextareaViewHTML());//manual mode turned off for modal view
    // const $switch = $(getSwitchButtonHTML());//manual mode turned off for modal view

    $outerContainer.append($widget);
    // $outerContainer.append($textarea);//manual mode turned off for modal view
    // $outerContainer.append($switch);//manual mode turned off for modal view

    const $modal = makeModalWindow(
      translate('files_widget_translations.files'),
      $outerContainer,
      translate('files_widget_translations.close_btn'),
    );
    const overlay = getOverlay($modal);

    setUploadButton({
      $widget,
      extraActions: ajaxCallback,
      overlay,
    });
    setFileRemoveOnClick({
      $widget,
      extraActions: ajaxCallback,
      overlay,
    });

    $modal.on('show.bs.modal', function () {
      getFilesForWidget({
        $widget,
        extraActions: ajaxCallback,
        overlay,
        toRemoveAllOnRender: true,
      });
    });

    //manual mode turned off for modal view
    // setSwitch({
    //         $widget,
    //         $textarea,
    //         $switch,
    //         overlay,
    //         extraActions: ajaxCallback
    //     }
    // );

    $modal.modal('show');
  }

  function createStaticContainer() {
    function setAsHiddenInputValue(ids) {
      $hiddenInput.val(ids.join(' '));
    }

    function getCurrentIds() {
      const currentVal = $hiddenInput.val().toString();
      return currentVal ? currentVal.split(' ') : [];
    }

    function addIdsToHiddenInputValue(idsToAdd) {
      const currentIds = getCurrentIds();
      const result = currentIds.concat(idsToAdd);
      setAsHiddenInputValue(result);
    }

    function removeIdsFromHiddenInputValue(idsToRemove) {
      const currentIds = getCurrentIds();
      const result = currentIds.filter((id) => idsToRemove.indexOf(id) === -1);
      setAsHiddenInputValue(result);
    }

    const $outerContainer = $('<div class="didah-files-outer-container"/>');
    const $widget = $(getFilesWidgetHTML());
    const $textarea = $(getFilesTextareaViewHTML());
    const $switch = $(getSwitchButtonHTML());
    const overlay = getOverlay();

    if (isVisualMode) {
      $textarea.hide();
      if (noAjaxInitialFiles) {
        renderFilesFromJsonAndExtraActions(
          noAjaxInitialFiles,
          $widget,
          (result) => addIdsToHiddenInputValue(result.map((file) => file.id)),
        );
      } else {
        getFilesForWidget({
          $widget,
          extraActions: (result) =>
            addIdsToHiddenInputValue(result.map((file) => file.id)),
          overlay,
        });
      }
    } else {
      $widget.hide();
      if (noAjaxInitialFiles) {
        fillTextAreaFromJsonAndExtraActions(
          noAjaxInitialFiles,
          $textarea,
          (result) => addIdsToHiddenInputValue(result.map((file) => file.id)),
        );
      } else {
        getFilesForTextArea({
          $textarea,
          extraActions: (result) =>
            addIdsToHiddenInputValue(result.map((file) => file.id)),
          overlay,
        });
      }
    }

    setUploadButton({
      $widget,
      extraActions: (result) =>
        addIdsToHiddenInputValue(result.map((file) => file.id)),
      overlay,
    });
    setFileRemoveOnClick({
      $widget,
      extraActions: (result) =>
        removeIdsFromHiddenInputValue(result.map((id) => id + '')),
      overlay,
    });
    const exAct = (result) =>
      setAsHiddenInputValue(result.map((file) => file.id));
    setSwitch({
      $switch,
      $textarea,
      $widget,
      extraActions: exAct,
      overlay,
    });

    const globalCallback = getGlobalTextModeExitCallback(
      $textarea,
      overlay,
      exAct,
    );

    $outerContainer.append($widget);
    $outerContainer.append($textarea);
    $outerContainer.append($switch);
    $el.append($outerContainer);

    return globalCallback;
  }

  if (type === 'modal') {
    $el.on('click', () => showModal());
  } else if (type === 'static') {
    return createStaticContainer();
  }
}
