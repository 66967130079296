import sum from 'lodash/sum';

import $ from 'jquery';

import { translate } from '@/common/features/translations';
import { displayError, removeLoadingOverlay, setLoadingOverlay } from './dom';

function getFormDataSize(fd: FormData) {
  return sum(
    Array.from(fd.entries(), ([, prop]) =>
      typeof prop === 'string' ? prop.length : prop.size,
    ),
  );
}

function checkFormDataSizeLimit(fd: FormData) {
  return getFormDataSize(fd) <= window.maxUploadFilesSizeTotalInBytes;
}

function checkFormDataFilesCountLimit(fd: FormData) {
  return Array.from(fd.entries()).length <= window.maxUploadFilesCount;
}

type FileUploaderOptions = {
  params: Dictionary;
  url: string;
};

type SetAsFileUploaderOptions = {
  $el: JQuery;
  onError?: (error: string) => void;
  onSuccess?: (result: any) => void;
  options: FileUploaderOptions;
  overlayOff?: () => void;
  overlayOn?: () => void;
};

function setAsFileUploader({
  $el,
  onError,
  onSuccess,
  options,
  overlayOff = () => removeLoadingOverlay(),
  overlayOn = () => setLoadingOverlay(),
}: SetAsFileUploaderOptions) {
  const $form = $(
    '<form><input type="file" style="display:none" multiple/></form>',
  );
  const $input: JQuery<HTMLInputElement> = $form.find(
    'input',
  ) as JQuery<HTMLInputElement>;

  $form.on('submit', function () {
    return false;
  });

  function resetForm() {
    $input.get(0).form.reset();
  }

  function uploadFiles() {
    overlayOn();
    const formData = new FormData();
    const files = $input.get(0).files;
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    const params = options.params;
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        formData.append(key, params[key]);
      }
    }

    const belowSizeLimit = checkFormDataSizeLimit(formData);
    if (!belowSizeLimit) {
      displayError(translate('common.upload.above_size_limit'));
      overlayOff();
      return;
    }

    const belowCountLimit = checkFormDataFilesCountLimit(formData);
    if (!belowCountLimit) {
      displayError(translate('common.upload.above_count_limit'));
      overlayOff();
      return;
    }

    return $.ajax({
      contentType: false,
      data: formData,
      error(xhr) {
        if (onError) onError(xhr.responseText);
        resetForm();
        overlayOff();
      },
      processData: false,
      success(result, textStatus, XMLHttpRequest) {
        if (XMLHttpRequest.status === 200) {
          if (onSuccess) onSuccess(result);
          resetForm();
          overlayOff();
        }
      },
      type: 'POST',
      url: options.url,
    } as any);
  }

  $el.after($form);

  $el.on('click', function () {
    resetForm();
    $input.trigger('click');
  });

  $input.on('change', function () {
    if ($input.get(0).files.length) {
      uploadFiles();
    }
  });
}

function createFilesFormData(
  fileList: FileList | File[],
  name?: string,
): Nullable<FormData> {
  if (!fileList.length) return null;

  const formData = new FormData();

  Array.from(Array(fileList.length).keys()).forEach((x) =>
    formData.append(name || fileList[x].name, fileList[x], fileList[x].name),
  );
  return formData;
}

function checkFilesMaxSize(fileList: FileList, maxSize?: number) {
  if (!maxSize) {
    return true;
  }

  let valid = true;

  Array.from(Array(fileList.length).keys()).forEach((x) => {
    const file = fileList[x];
    if (file.size > maxSize) {
      valid = false;
    }
  });
  return valid;
}

export {
  FileUploaderOptions,
  checkFilesMaxSize,
  checkFormDataFilesCountLimit,
  checkFormDataSizeLimit,
  createFilesFormData,
  setAsFileUploader,
};
