import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { RegistrationForm } from './RegistrationForm';
import type { RegistrationParams } from './types';

// TODO сделать тоже lazy load
// сейчас крашится в рантайме. наверно из-за циклических зависимостей
function registrationForm(selector: string, params: RegistrationParams) {
  const root = document.querySelector(selector);
  if (!root) {
    throw new Error(`${selector} not found`);
  }

  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Suspense fallback="Loading">
        <RegistrationForm {...params} />
      </Suspense>
    </React.StrictMode>,
  );
}

export { registrationForm };
