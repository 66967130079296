import $ from 'jquery';
import throttle from 'lodash/throttle';

import { getCoords, hasOverflowY } from '@/utils';

function positionSideMenus() {
  const nav = $('.js-menu-navigation ul')[0];
  if (nav) {
    const rect = nav.getBoundingClientRect();
    const top = rect.bottom;
    $('.menu-submenu').css('top', top + 'px');
    $('.menu-conf').css('top', top + 'px');
  }
}

function positionContextSubmenu($submenu, $menuParent) {
  const { bottom, left, right, top } = getCoords($menuParent.get(0));
  const isCloseToRightEdge = window.innerWidth < right + $submenu.width();
  const isCloseToBottomEdge = window.innerHeight < top + $submenu.height();
  let extraShift = 0;
  if (isCloseToRightEdge) {
    const isPageScroll = window.innerHeight < document.body.offsetHeight;
    if (isPageScroll) {
      extraShift = 9;
    }
    $submenu.css('left', 'initial');
    $submenu.css('right', window.innerWidth - left - extraShift);
  } else {
    const isTopMenuOverflow = hasOverflowY(
      $menuParent.closest('.js-top-dropdown').get(0)
    );

    if (isTopMenuOverflow) {
      extraShift = 9;
    }
    $submenu.css('left', `${right + extraShift}px`);
  }

  if (isCloseToBottomEdge) {
    $submenu.css('top', `${bottom - $submenu.height()}px`);
  } else {
    $submenu.css('top', `${top}px`);
  }
}

function activateSideMenus() {
  positionSideMenus();

  $(window).on('scroll', function () {
    positionSideMenus();
  });
}

function activateSubMenus() {
  $('.menu-submenu-toggle').on('click', function (e) {
    const $menuParent = $(this).closest('li');
    const $submenu = $menuParent.find('.menu-submenu-dropdown-menu');
    const wasVisible = $submenu.is(':visible');
    $(this)
      .closest('.js-dropdown-menu')
      .find('.menu-submenu-dropdown-menu')
      .hide();

    if (!wasVisible) {
      $submenu.show();
      positionContextSubmenu($submenu, $menuParent);
    }

    e.stopPropagation();
    e.preventDefault();
  });
}

function activateNavMenusHiding() {
  $('.js-dropdown-toggle').on('click', function (e) {
    $(this).parent().siblings().children('.js-dropdown-menu').hide(150);
    $(this).next('ul').toggle(150);
    e.stopPropagation();
    e.preventDefault();
  });
  $('.js-menu-dropdown-toggle').on('click', function (e) {
    $(this).parent().siblings().find('.js-dropdown-menu').hide();
    $(this).siblings('.js-dropdown-menu').toggle();
    e.stopPropagation();
    e.preventDefault();
  });
}

function activateSubMenusHiding() {
  $(document.body).on('click', function (e) {
    const $el = $(this);
    if ($el.closest('.menu-submenu-dropdown-menu').length) {
      e.stopPropagation();
      return;
    }

    $('.menu-submenu-dropdown-menu').hide();
  });

  $('.js-top-dropdown').on('scroll', function () {
    $('.menu-submenu-dropdown-menu').hide();
  });
}

const closeAllMenus = throttle(function () {
  $('.menu-submenu-dropdown-menu').hide();
  $('.js-top-dropdown').hide();
}, 25);

function activateAllMenusHidingOnScrollAndResize() {
  $(window).on('scroll', closeAllMenus);
  $(window).on('resize', closeAllMenus);
}

function positionNavMenus() {
  const $navTabs = $(
    '.js-menu-navigation .nav.nav-tabs > li:not(#menu-submenu-but)'
  );
  $navTabs.each(function () {
    const $tab = $(this);
    const $toggle = $tab.children('.js-menu-dropdown-toggle');
    const $dropdown = $tab.children('.js-dropdown-menu');

    if (!$dropdown.length || !$toggle.length) {
      return;
    }

    const { left } = getCoords($toggle.get(0));
    const isCloseToRightEdge = window.innerWidth < left + $dropdown.width();

    if (isCloseToRightEdge) {
      $dropdown.addClass('dropdown-menu-right');
    } else {
      $dropdown.removeClass('dropdown-menu-right');
    }
  });
}

function activateNavMenusPositioning() {
  positionNavMenus();
  $(window).on('resize', positionNavMenus);
}

export default function () {
  $(function () {
    activateNavMenusHiding();
    activateNavMenusPositioning();
    activateSideMenus();
    activateSubMenus();
    activateSubMenusHiding();
    activateAllMenusHidingOnScrollAndResize();
  });
}
