type ImageCompressionOptions = {
  alwaysKeepResolution?: boolean;
  initialQuality?: number;
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
};

async function compressFile(file: File, options?: ImageCompressionOptions) {
  const { maxSizeMB = 1, ...imageCompressionOptions } = options || {};

  if (
    (file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpg') ||
    file.size < maxSizeMB * 1024 * 1024
  ) {
    return file;
  }

  const compressedBlob = await import('browser-image-compression').then(
    ({ default: imageCompression }) =>
      imageCompression(file, {
        alwaysKeepResolution: true,
        maxSizeMB,
        useWebWorker: true,
        ...imageCompressionOptions,
      }),
  );

  // Преобразование Blob обратно в File
  const compressedFile = new File([compressedBlob], file.name, {
    lastModified: file.lastModified,
    type: file.type,
  });

  return compressedFile;
}

export { compressFile };
