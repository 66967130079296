import type { PropsWithChildren } from 'react';

import { FixedPositionedControls } from './FixedPositionedControls';
import { checkMobile } from '@/utils';
import { OverlayControls } from '../../../shared';

type Props = PropsWithChildren;

const isMobile = checkMobile();

function BottomControls({ children }: Props) {
  if (!isMobile) {
    return null;
  }

  return (
    <FixedPositionedControls className="tw-bottom-0">
      <OverlayControls className="tw-mx-auto tw-rounded-t-lg">
        {children}
      </OverlayControls>
    </FixedPositionedControls>
  );
}

export { BottomControls };
