import type { DocumentFile } from '../../../types';
import { DocumentPreview, PreviewWithIcon } from '../../grid';
import type { FileTreeDocumentFile } from '../types';
import { documentPathToName } from '../utils';

type Props = {
  document: FileTreeDocumentFile;
  documentPath: string;
};
function ContentFilePreview({ document, documentPath }: Props) {
  if (document.type === 'dir') {
    return (
      <PreviewWithIcon
        className="tw-bg-foreground-default-0 dark:tw-bg-transparent"
        text={documentPathToName(documentPath)}
        type="dir"
      />
    );
  }

  return (
    <DocumentPreview
      classNames={{
        withIcon: 'tw-bg-foreground-default-0 dark:tw-bg-transparent',
      }}
      document={document as DocumentFile}
    />
  );
}

export { ContentFilePreview };
