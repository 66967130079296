import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { DocumentTile } from '@/react-app/shared/containers';

import { translate } from '@/common';
import { MediaDialog, useMediaDialog } from '../../dialog';
import { useFileLibraryContext } from '../context';
import { getDirChildren } from '../service';
import { documentPathToName, isNonDirFile } from '../utils';
import { ContentFilePreview } from './ContentFilePreview';
import { FILE_LIBRARY_TREE_QUERY_KEY } from '../constants';
import { checkMobile, cn } from '@/utils';

const isMobile = checkMobile();

function ContentFilesGrid() {
  const {
    filesBaseURL,
    selectedFilesUrls,
    selectedPath,
    setSelectedFilesUrls,
    setSelectedPath,
  } = useFileLibraryContext();

  const { data, isError, isLoading } = useQuery({
    queryFn: async () => getDirChildren(selectedPath, filesBaseURL),
    queryKey: [FILE_LIBRARY_TREE_QUERY_KEY, selectedPath],
  });

  const mediaDialogDocuments = useMemo(() => {
    const currentPathFiles = data?.filter(isNonDirFile);

    return currentPathFiles?.map((document) => {
      const path = document.id;
      const documentName = documentPathToName(path);
      return {
        ...document,
        name: documentName,
      };
    });
  }, [data]);

  const {
    closeMediaDialog,
    isMediaDialogActive,
    media,
    setActiveFileByUrl,
    setMediaDialogActive,
    updateMedia,
  } = useMediaDialog({
    documents: mediaDialogDocuments,
  });

  if (isError && !data) {
    return (
      <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <FontAwesomeIcon className="tw-text-4xl" icon={faWarning} />
        <span className="tw-text-lg">{translate('common.error')}</span>
      </div>
    );
  }

  if (isLoading || !data) {
    return (
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
        <FontAwesomeIcon className="tw-text-4xl" icon={faSpinner} spin />
      </div>
    );
  }

  return (
    <>
      <div
        className={cn('tw-gap-2 tw-p-2', {
          'tw-flex tw-flex-wrap': !isMobile,
          'tw-grid tw-grid-cols-2': isMobile,
        })}
      >
        {data.map((document) => {
          const documentPath = document.id;
          return (
            <DocumentTile
              key={document.id}
              className={cn({
                'tw-w-auto': isMobile,
              })}
              hasSelection={document.type !== 'dir'}
              isSelected={selectedFilesUrls.includes(document.url)}
              title={document.text}
              onClick={() => {
                if (document.type === 'dir') {
                  setSelectedPath(documentPath);
                } else {
                  setActiveFileByUrl(document.url);
                  setMediaDialogActive(true);
                }
              }}
              onSelect={(wasSelected) => {
                if (wasSelected) {
                  setSelectedFilesUrls((prevSelectedFilesUrls) =>
                    prevSelectedFilesUrls.filter((url) => url !== document.url),
                  );
                } else {
                  setSelectedFilesUrls((prevSelectedFilesUrls) => [
                    ...prevSelectedFilesUrls,
                    document.url,
                  ]);
                }
              }}
            >
              <ContentFilePreview
                document={document}
                documentPath={documentPath}
              />
            </DocumentTile>
          );
        })}
      </div>
      <MediaDialog
        closeActiveDialog={closeMediaDialog}
        isActive={isMediaDialogActive}
        media={media}
        updateMedia={updateMedia}
      />
    </>
  );
}

export { ContentFilesGrid };
