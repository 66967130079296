import { cn } from '@/utils';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

const DocumentTile = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    hasSelection?: boolean;
    isSelected?: boolean;
    isWithDeletion?: boolean;
    onDelete?: VoidFunction;
    onSelect?: (wasSelected: boolean) => void;
  }
>(
  (
    {
      children,
      className,
      hasSelection,
      isSelected,
      isWithDeletion,
      onDelete,
      onSelect,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-relative tw-h-20 tw-w-24 tw-cursor-pointer tw-overflow-hidden tw-rounded-md tw-border tw-border-solid tw-border-border-default tw-no-underline tw-transition-all hover:tw-border-border-primary dark:tw-border-border-default-dark hover:dark:tw-border-border-primary-dark hover:dark:tw-bg-white/5',
          className,
        )}
        {...props}
      >
        {children}
        {hasSelection && (
          <div
            className={cn(
              'tw-absolute -tw-top-0.5 tw-left-0 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-1 tw-text-xl tw-text-white tw-transition-all',
              'before:tw-absolute before:tw-left-4 before:tw-top-4 before:tw-shadow-[0_0_30px_18px_#000000bf]',
            )}
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(isSelected);
            }}
          >
            <FontAwesomeIcon
              className="tw-z-10"
              icon={isSelected ? faCheckSquare : faSquare}
            />
          </div>
        )}
        {isWithDeletion && (
          <div
            className="text-danger tw-absolute -tw-top-0.5 tw-right-0 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-1 tw-text-xl tw-transition-all hover:tw-text-red-600 dark:tw-text-text-danger-dark hover:dark:tw-text-red-600"
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>
    );
  },
);

DocumentTile.displayName = 'DocumentTile';

export { DocumentTile };
