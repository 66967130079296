import asFormData from 'json-form-data';
import type { Column, Row } from '@/react-app/auto-table';

type GetDataResponse = [Column[], Row[], string];

const createGetDataRequest =
  (permanentParams: Dictionary) => async (additionalParams: Dictionary) => {
    const res = await fetch('/management.php', {
      body: asFormData({
        ...permanentParams,
        ...additionalParams,
      }),
      method: 'POST',
    });

    if (!res.ok) throw new Error();
    return (await res.json()) as GetDataResponse;
  };

type GetDataRequest = ReturnType<typeof createGetDataRequest>;

export { GetDataRequest, createGetDataRequest };
