/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';
import moment from 'moment';

import {
  checkMobile,
  number_format,
  removeLoadingOverlay,
  setLoadingOverlay,
} from '@/utils';
import { checkboxListModal } from '@/common/widgets/checkbox-list-modal';
import { datatableFormats } from '@/common/features/datatables';

type RenderOptions = {
  dec_point: string;
  thousands_sep: string;
};

function initRender({ dec_point, thousands_sep }: RenderOptions) {
  $.fn.dataTable.render.didah = function (fieldType, deci, link) {
    switch (fieldType) {
      default:
        return function (data, type, row) {
          return data;
        };
        break;
      case 'decimal':
        return function (data, type, row) {
          if (type === 'display' || type === 'print') {
            return number_format(data, deci, dec_point, thousands_sep);
          } else {
            return data;
          }
        };
        break;
      case 'date':
        return function (data, type, row) {
          if (type === 'sort') {
            return data;
          } else if (type === 'export') {
            return data === 0
              ? ''
              : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
          } else if (
            type === 'display' ||
            type === 'print' ||
            type === 'filter'
          ) {
            return data === 0
              ? ''
              : moment.unix(data).utc().format(datatableFormats.date);
          } else {
            return data;
          }
        };
        break;
      case 'datetime':
        return function (data, type, row) {
          if (type === 'sort') {
            return data;
          } else if (type === 'export') {
            return data === 0
              ? ''
              : moment.unix(data).utc().format('YYYY-MM-DD HH:mm:ss');
          } else if (
            type === 'display' ||
            type === 'print' ||
            type === 'filter'
          ) {
            return data === 0
              ? ''
              : moment.unix(data).utc().format(datatableFormats.datetime);
          } else {
            return data;
          }
        };
        break;
    }
  };
}

type TableOptions = {
  columnsAndCb: {
    columns: Dictionary<Dictionary>;
    footerCallback: (row, data, start, end, display) => void;
  };
  dataLength: number;
  data_json: Dictionary;
  fieldPickerOptions: {
    fsess: string;
    originalData: any;
    resetData: any;
  };
  headerWidth: number;
  id: string;
  lang_json: Dictionary;
  lockPrefix: string;
  lsKey: string;
  print: {
    footer: string;
    header: string;
  };
  requestOptions: {
    a: string;
  };
};
function showSelection(cell, table) {
  const jqCell = $(cell);
  if (
    table.rows({ selected: false }).any() &&
    table.rows({ selected: true }).any()
  ) {
    jqCell.addClass('select-checkbox-header-semi-selected');
  } else {
    jqCell.removeClass('select-checkbox-header-semi-selected');
    if (!table.rows({ selected: false }).any()) {
      jqCell.addClass('select-checkbox-header-selected');
    } else {
      jqCell.removeClass('select-checkbox-header-selected');
    }
  }
}

function editorProductionTable({
  columnsAndCb,
  data_json,
  dataLength,
  fieldPickerOptions,
  headerWidth,
  id,
  lang_json,
  lockPrefix,
  lsKey,
  print,
  requestOptions,
}: TableOptions) {
  return new Promise((resolve) => {
    $(async function () {
      let topY = '';
      if (window.innerHeight - headerWidth - 60 < 300) {
        topY = '300px';
      } else {
        topY = 'calc(100vh - ' + headerWidth + 'px - 60px)';
      }

      const table = $(`#new_table${id}`).DataTable({
        autoWidth: true,
        buttons: [
          {
            autoPrint: false,
            className: 'btn btn-default',
            customize(win) {
              $(win.document.body)
                .css('font-size', '8pt')
                .prepend(print.header)
                .append(print.footer);

              $(win.document.body)
                .find('table')
                .addClass('compact')
                .css('font-size', 'inherit');
            },
            exportOptions: {
              columns(idx, data, node) {
                return jQuery.inArray(idx, [0]) == -1;
              },
              orthogonal: 'print',
              stripHtml: true,
            },
            extend: 'print',
            footer: true,
            message: '',
            name: 'printall',
          },
          {
            autoPrint: false,
            className: 'btn btn-default',
            customize(win) {
              $(win.document.body)
                .css('font-size', '8pt')
                .prepend(print.header)
                .append(print.footer);

              $(win.document.body)
                .find('table')
                .addClass('compact')
                .css('font-size', 'inherit');
            },
            exportOptions: {
              columns(idx, data, node) {
                return jQuery.inArray(idx, [0]) == -1;
              },
              modifier: {
                selected: true,
              },
              orthogonal: 'print',
              stripHtml: true,
            },
            extend: 'print',
            footer: true,
            message: '',
            name: 'printselect',
          },
        ],
        createdRow(row, data, index) {
          const image_sec = {
            '1': '<i class="fa fa-exclamation fa-fw didah-dt-lock" aria-hidden="true"/>',
            '2': '<i class="fa fa-unlock-alt fa-fw didah-dt-lock" aria-hidden="true"/>',
            '4': '<i class="fa fa-lock fa-fw didah-dt-lock" aria-hidden="true"/>',
          };
          const rowAn =
            data['s_6'] === '0' ? '1 ' : data['s_7'] != '1' ? '2' : '4';
          const dt = $(this).DataTable();

          if (data['lock'] != '-1') {
            $(row)
              .addClass('KKN')
              .addClass('dis')
              .attr('title', lockPrefix + data['lock']);
          } else {
            $(row).removeClass('KKN').removeClass('dis').attr('title', '');

            if (data['title'] && data['title'] != '') {
              $(row).attr('title', data['title']);
            }

            if (data['s_6'] === '0') {
              $(row).addClass('del');
            } else {
              $(row).removeClass('del');
            }

            if (data['s_6'] === '0' || data['s_7'] === '1') {
              $(row).addClass('dis');
            }

            if (data['_02790']) {
              const statusClass = window.statusToClass[data['_02790']];
              $(row).addClass(statusClass);
            }
          }
          $('td', row)
            .eq(0)
            .append(image_sec[rowAn])
            .attr('style', 'white-space: nowrap');
        },
        data: data_json,
        deferRender: true,
        fixedColumns: {
          heightMatch: 'semiauto',
          leftColumns: 2,
        },
        language: lang_json,
        oClasses: {
          sFilterInput: 'form-control btn-xs tal w100p dt-search',
          sLengthSelect: 'form-control btn-xs tal',
        },
        order: [[1, 'desc']],
        processing: true,
        select: {
          selector: 'td:first-child',
          style: 'multi+shift',
        },
        stateLoadCallback(settings) {
          try {
            return JSON.parse(localStorage.getItem(lsKey));
          } catch (_) {}
        },
        stateSave: true,
        stateSaveCallback(settings, data) {
          try {
            data.search.search = '';
            data.start = 0;
            $.each(data.columns, function (i, n) {
              n.search.search = '';
            });
            data.length = dataLength;
            localStorage.setItem(lsKey, JSON.stringify(data));
          } catch (_) {}
        },
        ...columnsAndCb,
        dom: '<<"top"<"btn_block"B>lf>tr<"bottom"ip>>',
        lengthMenu: [
          [10, 25, 50, 100, 200],
          [10, 25, 50, 100, 200],
        ],
        scrollX: true,
        scrollY: topY,
      });

      table
        .on('draw.dt', function (e, settings, json, xhr) {
          table.fixedColumns().update();
        })
        .on('select.dt', function (e, dt, type, indexes) {
          if (!dt) {
            return;
          }

          if (dt.rows({ selected: true }).any()) {
            $(`#Action1${id}`).removeClass('disabled');
            $(`#Action2${id}`).removeClass('disabled');
            $(`#Action3${id}`).removeClass('disabled');
          }
          showSelection($('div.select-checkbox-header'), table);
          setTimeout(function () {
            dt.draw(false);
          }, 5);
        })
        .on('deselect.dt', function (e, dt, type, indexes) {
          if (!dt.rows({ selected: true }).any()) {
            $(`#Action1${id}`).addClass('disabled');
            $(`#Action2${id}`).addClass('disabled');
            $(`#Action3${id}`).addClass('disabled');
          }
          showSelection($('div.select-checkbox-header'), table);
          setTimeout(function () {
            dt.draw(false);
          }, 5);
        });

      const $divTop = $(table.table().container()).find('div.top');
      if (!$divTop.find('.dataTables_otherctrl').length && !checkMobile()) {
        $divTop.css('justify-content', 'flex-end');
      }

      $(document.body).on(
        'click',
        'div.didah-select-menu ul.dropdown-menu>li>a',
        function (e) {
          const dt = table;
          dt.rows({ search: 'applied' }).deselect();
          switch ($(this).data('id')) {
            case 1:
              dt.rows({ search: 'applied' }).select();

              break;
            case 2:
              dt.rows({ search: 'applied' }).deselect();
              break;
            case 3:
              dt.rows(
                function (idx, data, node) {
                  return data['_00608'] == 2;
                },
                { search: 'applied' },
              ).select();
              break;
            case 4:
              dt.rows(
                function (idx, data, node) {
                  return data['_00608'] == 3;
                },
                { search: 'applied' },
              ).select();
              break;
            case 5:
              dt.rows(
                function (idx, data, node) {
                  return data.s_6 != 0 && data.s_7 == 0;
                },
                { search: 'applied' },
              ).select();
              break;
            case 6:
              dt.rows(
                function (idx, data, node) {
                  return data.s_6 != 0 && data.s_7 != 0;
                },
                { search: 'applied' },
              ).select();
              break;
          }
        },
      );

      $(`#new_table${id}_wrapper div#new_table${id}_length`).append(
        $(
          `<a id="fields${id}" href="#" class="tw-text-left btn btn-default"><span class="glyphicon glyphicon-cog" aria-hidden="true"></span></a>`,
        ),
      );
      const cblm = checkboxListModal(fieldPickerOptions.originalData, {
        $trigger: $(`#fields${id}`),
        onClose: (data) => {
          cblm.hideModal();
          cblm.setData(fieldPickerOptions.originalData);
        },
        onReset: () => {
          cblm.setData(fieldPickerOptions.resetData);
        },
        onSave: (data) => {
          new Promise<void>((resolve) => {
            setLoadingOverlay();
            $.ajax({
              cache: false,
              data: {
                a: requestOptions.a,
                fsess: fieldPickerOptions.fsess,
                id: 24,
                val: data
                  .filter(function (name) {
                    return name.checked;
                  })
                  .map(function (name) {
                    return name.name;
                  }),
              },
              dataType: 'json',
              error(e) {
                alert('Error processing your request: ' + e.responseText);
                resolve();
              },
              success(response) {
                if (response['res'] === 'ok') {
                  window.didah.submit();
                } else {
                  resolve();
                }
              },
              type: 'POST',
              url: '/ajax_api.php',
            });
          }).then(() => {
            cblm.setData(fieldPickerOptions.originalData);
            removeLoadingOverlay();
          });
        },
      });

      resolve(table);
      const event = new Event('table-resolved');
      window.dispatchEvent(event);
    });
  });
}

export { editorProductionTable, initRender };
