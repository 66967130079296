import { cn } from '@/utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentProps } from '@tw-classed/react';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown';

type Props = PropsWithChildren<{
  align?: ComponentProps<typeof DropdownMenuContent>['align'];
  buttonSlot?: ReactNode;
  className?: string;
  classNames?: {
    dropdown?: string;
    item?: string;
  };
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSelect?: (option: string) => void;
  options?: (
    | string
    | {
        id: string;
        text: ReactNode;
      }
  )[];
  selectTab?: () => void;
  type?:
    | 'primary'
    | 'default'
    | 'danger'
    | 'warning'
    | 'info'
    | 'success'
    | 'basic';
  wholeButtonTrigger?: boolean;
}>;

const DropdownButton = ({
  align = 'start',
  children,
  className,
  classNames,
  disabled = false,
  onClick,
  onSelect,
  options = [],
  type = 'primary',
  wholeButtonTrigger = false,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleSelect = useCallback(
    (option: string) => {
      if (onSelect) onSelect(option);
      setOpen(false);
    },
    [onSelect],
  );

  const btnClass = type === 'basic' ? '' : `btn-${type}`;

  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      {wholeButtonTrigger ? (
        <DropdownMenuTrigger asChild disabled={disabled}>
          <div className="btn-group tw-flex">
            <button
              className={cn('btn', btnClass, className, {
                disabled,
              })}
              disabled={disabled}
              type="button"
            >
              {children}
            </button>
          </div>
        </DropdownMenuTrigger>
      ) : (
        <div className="btn-group tw-flex">
          <button
            className={cn('btn', btnClass, className, {
              disabled,
            })}
            disabled={disabled}
            type="button"
            onClick={onClick}
          >
            {children}
          </button>
          <DropdownMenuTrigger asChild disabled={disabled}>
            {options.length > 0 && (
              <button
                className={cn('btn', btnClass, className, {
                  disabled,
                })}
                disabled={disabled}
                type="button"
              >
                <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
              </button>
            )}
          </DropdownMenuTrigger>
        </div>
      )}

      <DropdownMenuContent
        align={align}
        className={cn(
          'tw-z-[1051] tw-w-40 tw-rounded-sm tw-py-2',
          classNames?.dropdown,
        )}
        sideOffset={4}
      >
        {options.length > 0 && (
          <>
            {options.map((option, index) => (
              <>
                {(typeof option === 'string' && option === '-') ||
                (typeof option === 'object' && option.text === '-') ? (
                  <DropdownMenuSeparator />
                ) : (
                  <DropdownMenuItem
                    key={index}
                    className={cn(
                      'tw-cursor-pointer tw-p-2 tw-text-xs hover:tw-bg-slate-200 dark:hover:tw-bg-foreground-default-dark',
                      classNames?.item,
                    )}
                    onClick={() =>
                      typeof option === 'string'
                        ? handleSelect(option)
                        : handleSelect(option.id)
                    }
                  >
                    {typeof option === 'string' ? option : option.text}
                  </DropdownMenuItem>
                )}
              </>
            ))}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { DropdownButton };

export type { Props as DropdownButtonProps };
