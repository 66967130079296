import {
  createOpenMessage,
  windowKillExecutor,
  createClosedMessage,
} from '@/common/features/window-stack-manager';
import { useEffect, useRef } from 'react';

type Params = {
  isEnabled?: boolean;
  onClose: VoidFunction;
};

const useEscManager = ({ isEnabled = true, onClose }: Params) => {
  const id = useRef(crypto.randomUUID());
  const isConsideredOpen = useRef(false);

  useEffect(() => {
    if (isEnabled) {
      createOpenMessage(id.current);
      isConsideredOpen.current = true;
    } else if (isConsideredOpen.current) {
      createClosedMessage(id.current);
      isConsideredOpen.current = false;
    }
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      windowKillExecutor.add(id.current, onClose);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => windowKillExecutor.remove(id.current);
  }, [onClose, isEnabled]);

  useEffect(() => {
    // подчищаем на случай закрытия не клавишей Esc
    return () => {
      createClosedMessage(id.current);
      isConsideredOpen.current = false;
    };
  }, []);
};

export { useEscManager };
