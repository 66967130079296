type ExternalUsageHandler = {
  beforeUnloadHandler: (e: BeforeUnloadEvent) => void;
  condition: () => boolean;
  message: string;
};

const externalUsageHandlers: ExternalUsageHandler[] = [];

function isUnloadPrevented() {
  const activeHandler = externalUsageHandlers.find((handler) =>
    handler.condition(),
  );
  if (activeHandler) {
    return {
      message: activeHandler.message,
      removeBeforeUnloadHandler: () => {
        window.removeEventListener(
          'beforeunload',
          activeHandler.beforeUnloadHandler,
        );
        externalUsageHandlers.splice(
          externalUsageHandlers.indexOf(activeHandler),
          1,
        );
      },
    };
  }
  return null;
}

function addOnPageLeaveWarning(msg: string, condition: () => boolean) {
  const handler = (e: BeforeUnloadEvent) => {
    if (!condition()) {
      return;
    }

    e.preventDefault();
    (e || window.event).returnValue = msg; //Gecko + IE
    window.beforeUnloadCancelled = true;
    return msg; //Gecko + Webkit, Safari, Chrome etc.
  };
  window.addEventListener('beforeunload', handler);

  const externalUsageHandler: ExternalUsageHandler = {
    beforeUnloadHandler: handler,
    condition,
    message: msg,
  };
  externalUsageHandlers.push(externalUsageHandler);

  return () => {
    window.removeEventListener('beforeunload', handler);
    externalUsageHandlers.splice(
      externalUsageHandlers.indexOf(externalUsageHandler),
      1,
    );
  };
}

function isAllowedToContinueNavigation() {
  const unloadPrevented = isUnloadPrevented();
  let canContinue = true;
  if (unloadPrevented) {
    canContinue = confirm(unloadPrevented.message);
    if (canContinue) {
      unloadPrevented.removeBeforeUnloadHandler();
    }
  }
  return canContinue;
}

export { addOnPageLeaveWarning, isAllowedToContinueNavigation };
