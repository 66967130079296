import $ from 'jquery';
import { asyncPause } from '../timer';

async function preventDoubleClick(el: HTMLElement) {
  const element = $(el);
  element.css('pointer-events', 'none');
  await asyncPause(100);
  element.css('pointer-events', 'auto');
}

export { preventDoubleClick };
