function createMountingPoint() {
  const className = `temp-mounting-point-${crypto.randomUUID()}`;
  let el = document.querySelector(`.${className}`) as HTMLElement;
  if (!el) {
    const fakeParent = document.createElement('div');
    fakeParent.className = className;
    const sbContainer = document.getElementById('sb-container');
    document.body.insertBefore(fakeParent, sbContainer);
    el = fakeParent;
  }
  return el;
}

export { createMountingPoint };
