import { nonEmptyOrUndefined } from '@/utils';
import { messagesConfig } from './config';
import type { MessagesConfig } from './send-message/types';

const setMessagesConfig = (config: MessagesConfig) => {
  return messagesConfig.set({
    ...config,
    external: {
      ...config.external,
      senderOptions: nonEmptyOrUndefined(config.external.senderOptions),
      templateOptions: config.external.templateOptions || [],
    },
  });
};

export * from './message-box';
export * from './messages-center';
export * from './send-message';

export type {
  ExternalMessageConfigType,
  InternalMessageConfigType,
  UniqueExternalMessageOpts,
  UniqueInternalMessageOpts,
} from './send-message/message-types';

export { MessageSource } from './types';
export { messagesConfig, setMessagesConfig };
