/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';

import { asyncPause, dt_number_format } from '@/utils';
import { debounce } from 'lodash';

type RenderParams = {
  a_link: string;
  f_name_link: string;
  mcid: string;
  q: string;
};

type Params = {
  O4New: string;
  columnDefs: any[];
  countY: number;
  data: any;
  echo_table2head: string;
  extraParams: Dictionary;
  printFooter: string;
  printHeader: string;
  scrollY?: string;
  selector: string;
  tableHead: string;
  tables: any[];
  tbl_nr: number;
};

function DIDAHgetHeaders(dt) {
  const thRows = $(dt.table().header()).find('tr:not(.min)');
  const numRows = thRows.length;
  const matrix = [];

  // Iterate over each row of the header and add information to matrix.
  for (let rowIdx = 0; rowIdx < numRows; rowIdx++) {
    const $row = $(thRows[rowIdx]);

    // Iterate over actual columns specified in this row.
    const $ths = $row.children('th');
    for (let colIdx = 0; colIdx < $ths.length; colIdx++) {
      const $th = $($ths.get(colIdx));
      const colspan = parseInt($th.attr('colspan')) || 1;
      const rowspan = parseInt($th.attr('rowspan')) || 1;
      let colCount = 0;

      // ----- add this cell's title to the matrix
      if (matrix[rowIdx] === undefined) {
        matrix[rowIdx] = []; // create array for this row
      }
      // find 1st empty cell
      for (let j = 0; j < matrix[rowIdx].length; j++, colCount++) {
        if (matrix[rowIdx][j] === 'PLACEHOLDER') {
          break;
        }
      }
      const myColCount = colCount;
      matrix[rowIdx][colCount++] = $th.text();

      // ----- If title cell has colspan, add empty titles for extra cell width.
      for (let j = 1; j < colspan; j++) {
        matrix[rowIdx][colCount++] = '';
      }

      // ----- If title cell has rowspan, add empty titles for extra cell height.
      for (let i = 1; i < rowspan; i++) {
        const thisRow = rowIdx + i;
        if (matrix[thisRow] === undefined) {
          matrix[thisRow] = [];
        }
        // First add placeholder text for any previous columns.
        for (let j = matrix[thisRow].length; j < myColCount; j++) {
          matrix[thisRow][j] = 'PLACEHOLDER';
        }
        for (let j = 0; j < colspan; j++) {
          // and empty for my columns
          matrix[thisRow][myColCount + j] = '';
        }
      }
    }
  }

  return matrix;
}
function colName(n) {
  const ordA = 'A'.charCodeAt(0);
  const ordZ = 'Z'.charCodeAt(0);
  const len = ordZ - ordA + 1;
  let s = '';

  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }

  return s;
}
function _createNode(doc, nodeName, opts) {
  const tempNode = doc.createElement(nodeName);

  if (opts) {
    if (opts.attr) {
      $(tempNode).attr(opts.attr);
    }

    if (opts.children) {
      $.each(opts.children, function (key, value) {
        tempNode.appendChild(value);
      });
    }

    if (opts.text !== null && opts.text !== undefined) {
      tempNode.appendChild(doc.createTextNode(opts.text));
    }
  }

  return tempNode;
}

function initRender({ a_link, f_name_link, mcid, q }: RenderParams) {
  $.fn.dataTable.render.didah = function (fieldType, deci, link, link2) {
    switch (fieldType) {
      case 'cell':
        return function (td, cellData, rowData, row, col) {
          $(td).addClass(
            'hm ' +
              link.reduce(function (cc, item) {
                return cellData <= item.gt ? cc : item.color;
              }, link[0].color),
          );
        };
        break;
      case 'data':
      case 'data_s':
        return function (data, type, row) {
          if (type === 'display' || type === 'print') {
            let ret = '&nbsp;';
            if (data) {
              if (typeof data === 'string') {
                ret = data;
              } else {
                ret = dt_number_format(data, deci);
              }
              let qParLinr2 = '';
              if (row['ls_' + link2]) {
                qParLinr2 = '&amp;dtSettings2=' + row['ls_' + link2];
              }
              if (row[link]) {
                ret =
                  `<a href='${a_link}&amp;q=${q}&amp;snp=5&amp;filter=` +
                  row[link] +
                  qParLinr2 +
                  `&amp;mcid=${mcid}${f_name_link}' rel='shadowbox'>${ret}</a>`;
              }
            }
            return ret;
          } else if (type === 'filter' || type === 'search') {
            return '';
          } else {
            return data ? data : 0;
          }
        };
        break;
      case 'header':
        return function (data, type, row) {
          if (type === 'display' || type === 'print') {
            if (data) {
              if (row[link]) {
                return row[link].toString().replace('%THIS%', data);
              }
            }
            return data == null ? '' : data;
          } else if (type === 'filter' || type === 'search') {
            return data == null ? '' : data;
          } else {
            return data == null ? '' : data;
          }
        };
        break;
      default:
        return function (data, type, row) {
          const data_str = data;
          return data_str;
        };
        break;
    }
  };
}

function handleRedrawAfterResizeMessage(table) {
  const redraw = async () => {
    await asyncPause(300);
    table.draw();
  };

  let handler;

  handler = (e: MessageEvent) => {
    const { data } = e;
    try {
      const obj = JSON.parse(data);
      if (obj?.type === 'resize-done') {
        redraw();

        handler?.removeEventListener('message', handler);
        handler = null;
      }
    } catch (e) {}
  };

  window.addEventListener('message', handler);
}

function handleRedrawAfterWindowResize(table) {
  const redraw = debounce(async () => {
    table.draw();
  }, 300);

  window.addEventListener('resize', redraw);
}

function selectQuery18Table({
  O4New,
  columnDefs,
  countY,
  data,
  echo_table2head,
  extraParams,
  printFooter,
  printHeader,
  scrollY,
  selector,
  tableHead,
  tables,
  tbl_nr,
}: Params) {
  let promiseResolve;
  const promise = new Promise((resolve) => {
    promiseResolve = resolve;
  });

  const table = $(selector).DataTable({
    columnDefs,
    data,
    ...extraParams,
    autoWidth: false,
    buttons: [
      {
        autoPrint: false,
        customize(win) {
          if (tbl_nr === 0) {
            let html = '';
            const addRow = function (d, tag) {
              let str = '<tr>';

              for (let i = 0, ien = d.length; i < ien; i++) {
                str += '<' + tag + '>' + d[i] + '</' + tag + '>';
              }

              return str + '</tr>';
            };
            const addRow2 = function (d, tag, z, dt) {
              let str = '<tr>';
              let color = '';
              for (let i = 0, ien = d.length; i < ien; i++) {
                color = $(dt.cell(z, i).nodes()).hasClass('hm')
                  ? $(dt.cell(z, i).nodes()).css('background-color')
                  : '';
                str +=
                  '<' +
                  tag +
                  (color !== ''
                    ? ' style="background-color:' + color + '"'
                    : '') +
                  '>' +
                  d[i].toString().replace(/<[^>]*>/g, '') +
                  '</' +
                  tag +
                  '>';
              }

              return str + '</tr>';
            };

            if (tables.length > 0) {
              const dataCur = tables[0].buttons.exportData({
                orthogonal: 'print',
                stripHtml: false,
              });
              let htmlCur = '';

              for (let i = 0, ien = dataCur.body.length; i < ien; i++) {
                htmlCur += addRow2(dataCur.body[i], 'td', i, tables[0]);
              }

              $(win.document.body).find('table tbody').html(htmlCur);

              for (let tb = 1, tben = tables.length; tb < tben; tb++) {
                const data = tables[tb].buttons.exportData({
                  orthogonal: 'print',
                  stripHtml: false,
                });
                html +=
                  '<br><br><table class="' +
                  tables[tb].table().node().className +
                  '">';

                html += '<thead>' + addRow(data.header, 'th') + '</thead>';

                html += '<tbody>';
                for (let i = 0, ien = data.body.length; i < ien; i++) {
                  html += addRow2(data.body[i], 'td', i, tables[tb]);
                }
                html += '</tbody>';

                if (data.footer) {
                  html += '<tfoot>' + addRow(data.footer, 'th') + '</tfoot>';
                }
              }
            }

            $(win.document.body).find('table thead').html(tableHead);
            $(win.document.body).append(html);
          }
          $(win.document.body)
            .css('font-size', '8pt')
            .prepend(printHeader)
            .append(printFooter);
          $(win.document.body)
            .find('table')
            .addClass('compact')
            .css('font-size', 'inherit');

          if (O4New == '2' && countY > 0) {
            $(win.document.body)
              .find('table.dataTable')
              .before($('div.didah-graf').clone());
          }
          $(win.document.body)
            .find(`table.compact tbody td:nth-child(n+${countY + 1})`)
            .css('text-align', 'right');
          $(win.document.body)
            .find(`table.compact tbody td:nth-child(-n+${countY})`)
            .css('text-align', 'left');
          $(win.document.body)
            .find('table.compact tfoot th')
            .css('text-align', 'right')
            .css('padding', '2px');
          $(win.document.body)
            .find('table.compact thead th')
            .css('padding', '2px')
            .css('border', ' 1px solid #111');
          $(win.document.body)
            .find('table.compact thead tr.min')
            .css('visibility', 'hidden');
        },
        exportOptions: {
          orthogonal: 'print',
          stripHtml: true,
        },
        extend: 'print',
        footer: true,

        message: echo_table2head,
        name: 'printall',
      },
      {
        exportOptions: {
          orthogonal: 'export',
        },
        extend: 'csvHtml5',
        name: 'csvall',
      },
      {
        customize(xlsx, config, dt) {
          const heads = DIDAHgetHeaders(tables[tbl_nr]);
          const sheet = xlsx.xl.worksheets['sheet1.xml'];
          const merges = [];
          let mgCnt = 0;

          for (let i = 0; i < heads.length - 1; i++) {
            //for each column (cell) in the row
            for (let j = 0; j < heads[i].length; j++) {
              //look for a non-colspan/rowspan cell
              if (heads[i][j] != '' || j == 0) {
                let startRow = i + (config.title ? 1 : 0);
                let startCol = j;
                let endRow = i;
                let endCol = j;

                //lookahead
                if (j + 1 < heads[i].length)
                  if (heads[i][j + 1] == '') {
                    //is the cell to the right a colspan?
                    startCol = j;
                    endCol = j + 1;

                    //get to the last column in the colspan
                    while (endCol < heads[i].length && heads[i][endCol] == '') {
                      endCol++;
                    }
                    endCol--;
                  }

                if (startCol == endCol && i + 1 < heads.length)
                  if (heads[i + 1][j] == '') {
                    //is the cell below a rowspan?
                    startRow = i;
                    endRow = i + 1;
                    //get to the last row in the rowspan
                    while (endRow < heads.length && heads[endRow][j] == '') {
                      //heads[endRow][j] = ""; //Use if rowspan is a special char/sequence
                      heads[endRow][j] = ' ';
                      endRow++;
                    }
                  }

                //create and store merge ranges
                //if endCol or endRow show movement
                if (startRow != endRow || startCol != endCol) {
                  const sC = colName(startCol); //convert startCol to excel column letter
                  const sR = startRow + 1;
                  const eC = colName(endCol); //conver endCol to excel column letter
                  const eR = endRow;

                  merges[mgCnt] = sC + '' + sR; //start of range

                  if (endCol > startCol)
                    //end column
                    merges[mgCnt] = merges[mgCnt] + ':' + eC;
                  else merges[mgCnt] = merges[mgCnt] + ':' + sC;

                  if (endRow > startRow)
                    //end row
                    merges[mgCnt] = merges[mgCnt] + eR;
                  else merges[mgCnt] = merges[mgCnt] + sR;

                  mgCnt++; //increment number of merge ranges
                }
              }
            }
          }
          const mergeCells = function (rels, merg) {
            const mergeCells = $('mergeCells', rels);

            mergeCells[0].appendChild(
              _createNode(rels, 'mergeCell', {
                attr: {
                  ref: merg,
                },
              }),
            );
            mergeCells.attr('count', parseFloat(mergeCells.attr('count')) + 1);
          };
          if (mgCnt > 0) {
            for (let i = 0; i < mgCnt; i++) {
              mergeCells(sheet, merges[i]);
            }
          }
        },
        customizeData(data) {
          const heads = DIDAHgetHeaders(tables[tbl_nr]);

          for (let i = heads.length; i > 0; i--) {
            data.body.unshift(heads[i - 1]);
          }
        },
        exportOptions: {
          format: {
            footer(data, row, column) {
              return data != ''
                ? tables[tbl_nr]
                    .column(row)
                    .data()
                    .reduce(function (a, b) {
                      return a + b;
                    })
                : '';
            },
          },
          orthogonal: 'export',
        },
        extend: 'excelHtml5',
        footer: true,
        header: false,
        name: 'excelall',
        title: '',
      },
    ],
    dom: '<<"top"<"btn_block"B>>tr>',
    async drawCallback(settings) {
      const api = this.api();
      const currentData = api.rows({ page: 'current' }).data();
      if (currentData.length > 0) {
        const rows = api.rows({ page: 'current' }).nodes();
        const headLength = $('td.head', $(rows[0])).length;
        const colIndex = [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25,
        ].splice(0, headLength);
        let last = new Array(headLength),
          firstAct = false;
        let curH = new Array(headLength);
        const headerList = api.columns(colIndex).dataSrc();

        currentData.each(function (dt, i) {
          curH = $.makeArray(
            headerList.map(function (item) {
              return dt[item];
            }),
          );
          for (let j = 0; j < headLength; j++) {
            if (
              firstAct &&
              curH.slice(0, j + 1).join('|') == last.slice(0, j + 1).join('|')
            ) {
              $($('.head', rows[i])[j]).addClass('empty');
            } else {
              $($('.head', rows[i])[j]).removeClass('empty');
            }
          }
          last = curH;
          firstAct = true;
        });

        await asyncPause(300);
      }
      promiseResolve();
    },
    info: false,
    initComplete(settings, json) {
      $('#dbStyleWidth').css('visibility', 'visible');
      $('.loaderBackground').css('display', 'none');
    },
    language: {
      processing:
        "<i class='fa fa-spinner fa-pulse fa-4x fa-fw'></i><span class='sr-only'>Loading...</span>",
    },
    ordering: true,
    paging: false,
    processing: true,
    scrollCollapse: true,
    scrollX: true,
    scrollY,
  });

  const dd_debounce = function (fn, delay) {
    let timeout;
    return function (...args) {
      const self = this;
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function () {
        fn.apply(self, args);
      }, delay);
    };
  };

  $('#wh_search').on(
    'keyup',
    dd_debounce(function () {
      tables[tbl_nr].search(this.value).draw(); // нужно продумать как передовать массив полей для поиска в columns()
    }, 300),
  );

  handleRedrawAfterResizeMessage(table);
  handleRedrawAfterWindowResize(table);

  return [table, promise];
}

export { initRender, selectQuery18Table };
