import { logger } from '@/utils/logger';
import { inIframe } from '../iframe';

type LoadingOverlayParams = {
  onlyIfFirstInvoke?: boolean;
  timeout?: number;
};

const SHOW_CLOSE_ICON_TIMEOUT = 10000;

let overlayCalledCount = 0;
let showCloseIconTimeout: number | null = null;

function showCloseIcon() {
  logger.info('showCloseIcon');
  $('#global-loading').attr('data-show-close', 'true');
}

function hideCloseIcon() {
  logger.info('hideCloseIcon');
  $('#global-loading').attr('data-show-close', 'false');
}

function startShowCloseIconTimeout() {
  showCloseIconTimeout = setTimeout(() => {
    showCloseIcon();
    showCloseIconTimeout = null;
  }, SHOW_CLOSE_ICON_TIMEOUT) as unknown as number;
}

function cancelShowIconTimeout() {
  if (showCloseIconTimeout != null) {
    clearTimeout(showCloseIconTimeout);
    showCloseIconTimeout = null;
  }
}

function isLoadingOverlayShown() {
  if (inIframe()) {
    return window.top.Didah.Utils.isLoadingOverlayShown();
  }

  return overlayCalledCount > 0;
}

function setLoadingOverlay(params: LoadingOverlayParams = {}) {
  const { onlyIfFirstInvoke, timeout } = params;
  logger.info(
    'Inside setLoadingOverlay',
    `Params: ${JSON.stringify(params)}`,
    `Iframe: ${inIframe()}`,
  );

  if (inIframe()) {
    // вызываем эту же функцию в верхнем окне
    return window.top.Didah.Utils.setLoadingOverlay(params);
  }

  logger.info(`Current overlayCalledCount: ${overlayCalledCount}`);

  if (onlyIfFirstInvoke && overlayCalledCount > 0) {
    return false;
  }

  $('#global-loading').css({ display: 'flex' });
  overlayCalledCount++;

  logger.info(`New overlayCalledCount: ${overlayCalledCount}`);

  if (overlayCalledCount === 1) {
    startShowCloseIconTimeout();
  }

  if (timeout != null) {
    setTimeout(() => removeLoadingOverlay(), timeout);
  }

  return true;
}

function removeLoadingOverlay(params: { force?: boolean } = {}) {
  logger.info(
    'Inside removeLoadingOverlay',
    `Params: ${JSON.stringify(params)}`,
    `Iframe: ${inIframe()}`,
  );
  if (inIframe()) {
    // вызываем эту же функцию в верхнем окне
    window.top.Didah.Utils.removeLoadingOverlay(params);
    return;
  }
  const { force } = params;

  logger.info(`Current overlayCalledCount: ${overlayCalledCount}`);

  if (overlayCalledCount > 0) {
    overlayCalledCount--;
  }

  logger.info(`New overlayCalledCount: ${overlayCalledCount}`);

  if (overlayCalledCount === 0 || force) {
    $('#global-loading').css({ display: 'none' });
    cancelShowIconTimeout();
    hideCloseIcon();
  }

  if (force) {
    overlayCalledCount = 0;
  }

  logger.info(`Final overlayCalledCount: ${overlayCalledCount}`);
}

export { isLoadingOverlayShown, removeLoadingOverlay, setLoadingOverlay };
