import type { HookAction, IFrameWindowHandle } from '@/react-app/iframe/types';

function getAndClearStoredActions(id: string) {
  const storedActions = onCloseActions[id];
  if (storedActions) {
    delete onCloseActions[id];
  }

  return storedActions;
}
const onCloseActions: Dictionary<HookAction[]> = {};

const windows: IFrameWindowHandle[] = [];
const isWindowOpen = () => !!windows.length;

export { getAndClearStoredActions, isWindowOpen, onCloseActions, windows };
