import { KeyboardEventKey, inIframe } from '@/utils';
import { windowKillExecutor } from './executors';
import { createEscMessage } from './message-creators';
import { windowStackManager } from './stack';
import type {
  CloseWindowMessage,
  ClosedWindowMessage,
  EscKeyMessage,
  OpenWindowMessage,
} from './types';
import {
  WINDOW_STACK_CLOSE,
  WINDOW_STACK_CLOSED,
  WINDOW_STACK_ESC_KEY,
  WINDOW_STACK_OPEN,
} from './constants';

// для всех окон
const listenCloseMessages = () => {
  window.addEventListener(
    'message',
    ({ data }) => {
      const { payload, type } = data as CloseWindowMessage;
      if (type !== WINDOW_STACK_CLOSE) {
        return;
      }

      // во всех окнах каждый проверяет есть ли функция закрытия с таким ид
      windowKillExecutor.checkAndExecute(payload);
    },
    false,
  );
};

// только для топового окна
const listenOpenMessages = () => {
  window.addEventListener(
    'message',
    ({ data }) => {
      const { payload, type } = data as OpenWindowMessage;
      if (type !== WINDOW_STACK_OPEN) {
        return;
      }

      if (inIframe()) return;

      windowStackManager.push(payload);
    },
    false,
  );
};

// только для топового окна
const listenClosedMessages = () => {
  window.addEventListener(
    'message',
    ({ data }) => {
      const { payload, type } = data as ClosedWindowMessage;
      if (type !== WINDOW_STACK_CLOSED) {
        return;
      }

      if (inIframe()) return;

      if (payload.removeAllFollowing) {
        windowStackManager.removeStartingAt(payload.id);
      } else {
        windowStackManager.remove(payload.id);
      }
    },
    false,
  );
};

// только для топового окна
const listenEscMessage = () => {
  window.addEventListener(
    'message',
    ({ data }) => {
      const { type } = data as EscKeyMessage;
      if (type !== WINDOW_STACK_ESC_KEY) {
        return;
      }

      if (inIframe()) return;

      windowStackManager.pop();
    },
    false,
  );
};

const listenMessages = () => {
  listenCloseMessages();

  if (!inIframe()) {
    listenClosedMessages();
    listenOpenMessages();
    listenEscMessage();
  }
};

const listenKeyboard = (referenceWindow: Window = window) => {
  referenceWindow.addEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      switch (e.key) {
        case KeyboardEventKey.Escape: {
          createEscMessage();
        }
      }
    },
    false,
  );
};

export { listenKeyboard, listenMessages };
