import $ from 'jquery';
import debounce from 'lodash/debounce';

import { setCssVariable } from '@/common/style';

const PARENT_LEFT_PADDING = 16;
const PARENT_RIGHT_PADDING = 12;
const EMPTY_INPUT_WIDTH = 4;

function adjustSize() {
  const $itemParents = $(
    '.legacy-table-form .form-control.selectize-control.single .selectize-input'
  );
  const width =
    ($itemParents.width() || 0) -
    PARENT_LEFT_PADDING -
    PARENT_RIGHT_PADDING -
    EMPTY_INPUT_WIDTH;
  setCssVariable('--max-legacy-table-item-width', `${width}px`);
}

$(() => {
  adjustSize();
  window.addEventListener('resize', debounce(adjustSize, 25));
});
