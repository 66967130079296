import { cn } from '@/utils';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onClick?: VoidFunction;
};

function CameraButton({ onClick }: Props) {
  return (
    <div className="tw-flex tw-h-20 tw-w-24 tw-overflow-hidden tw-rounded-md">
      <button
        className={cn(
          'btn btn-default !tw-bg-foreground-violet hover:!tw-bg-foreground-violet-2 dark:!tw-bg-foreground-violet-dark dark:hover:!tw-bg-foreground-violet-2-dark !tw-flex tw-h-20 tw-w-24 tw-items-center tw-justify-center !tw-rounded-md tw-transition-colors'
        )}
        type="button"
        onClick={onClick}
      >
        <FontAwesomeIcon className="tw-text-4xl" icon={faCamera} />
      </button>
    </div>
  );
}

export { CameraButton };
