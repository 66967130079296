import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/styles';

import type { ComponentRef } from 'react';
import {
  forwardRef,
  useRef,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react';

import { cn } from '@/utils';
import { RequiredStar } from '../base';
import { Error } from '../base/Error';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  classNames?: {
    formGroup?: string;
  };
  errorMessage?: ReactNode;
  initCountryCode?: string;
  initialValue?: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onChangeValidity?: (value: boolean) => void;
  onValueChange?: (value: string) => void;
  value?: string;
};

const PhoneInput = forwardRef<ComponentRef<typeof IntlTelInput>, Props>(
  (
    {
      classNames,
      errorMessage,
      initCountryCode = 'ee',
      isRequired,
      label,
      name,
      onChangeValidity,
      onValueChange,
      value,
    },
    ref,
  ) => {
    const initValueRef = useRef(value);

    return (
      <div
        className={cn(
          'form-group tw-flex tw-flex-col',
          '[&_input]:tw-w-full',
          '[&_input]:tw-py-0.5',
          '[&_input]:tw-px-1',
          '[&_input]:tw-px-1',
          '[&_input]:tw-border',
          '[&_input]:tw-border-border-default',
          '[&_input]:tw-rounded-md',
          '[&_input:focus-visible]:tw-outline-none',
          '[&_input:focus]:tw-outline-none',
          '[&_input:focus]:tw-border-[#66afe9]',
          '[&_input:focus]:tw-shadow-[inset_0_1px_1px_#00000013,0_0_8px_#66afe999]',
          classNames?.formGroup,
        )}
      >
        {label && (
          <label htmlFor={name}>
            {label}
            {isRequired && <RequiredStar />}
          </label>
        )}
        <IntlTelInput
          ref={ref}
          initialValue={initValueRef.current}
          initOptions={{
            fixDropdownWidth: true,
            initialCountry: initCountryCode,
            loadUtilsOnInit:
              'https://cdn.jsdelivr.net/npm/intl-tel-input@24.7.0/build/js/utils.js',
          }}
          onChangeNumber={(newValue) => {
            if (newValue !== undefined && newValue === value) {
              return;
            }

            onValueChange(newValue);
          }}
          onChangeValidity={onChangeValidity}
        />
        {errorMessage && <Error>{errorMessage}</Error>}
      </div>
    );
  },
);
PhoneInput.displayName = 'PhoneInput';

export { PhoneInput };
