import $ from 'jquery';

type Params = {
  keepSize?: boolean;
};

function makeAbsolute(element: HTMLElement, { keepSize }: Params = {}) {
  const $element = $(element);
  const key = 'forced-absolute';
  const isAbsolute = $(element).data(key);
  if (isAbsolute) {
    return;
  }

  const { bottom, height, left, right, top, width } =
    element.getBoundingClientRect();
  const style = getComputedStyle(element);
  if (keepSize) {
    element.style.left = `${left}px`;
    element.style.top = `${top}px`;
  } else {
    element.style.left = `${left}px`;
    element.style.bottom = `${bottom - height}px`;
    element.style.right = `${right - width}px`;
    element.style.top = `${top}px`;
    element.style.width = 'unset';
    element.style.height = 'unset';
  }
  if (!['fixed', 'absolute'].includes(style.position)) {
    element.style.position = 'absolute';
  }

  $element.data(key, true);
}

export { makeAbsolute };
