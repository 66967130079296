import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DocumentFile } from '../../../../types';
import type { FileLibraryParams } from '../../../file-library/types';
import { MediaServiceContext } from '../context';
import { fetchDocumentsRequest } from './service';

type Params = PropsWithChildren<{
  fileLibrary: FileLibraryParams;
  initialDocuments?: (DocumentFile | string)[];
}>;

function FileLibraryServiceProvider({
  children,
  fileLibrary,
  initialDocuments,
}: Params) {
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [isDocumentsLoading, setDocumentsLoading] = useState(true);
  const [isDocumentsUploading, setDocumentsUploading] = useState(false);

  const extraDocuments = useMemo(() => [], []);

  const fetchInitialDocuments = useCallback(async () => {
    try {
      const initialUrls = initialDocuments.map((doc) => {
        if (typeof doc === 'string') {
          return doc;
        }
        return doc.url;
      });
      const data = await fetchDocumentsRequest(
        fileLibrary.queryURL,
        initialUrls,
      );

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [fileLibrary.queryURL, initialDocuments]);

  useEffect(() => {
    (async () => {
      try {
        setDocumentsLoading(true);
        if (initialDocuments) {
          const data = await fetchInitialDocuments();
          setDocuments(data);
        }
      } finally {
        setDocumentsLoading(false);
      }
    })();
  }, [fetchInitialDocuments, initialDocuments]);

  const updateDocumentsViaLinks = useCallback(
    async (links: string[]) => {
      try {
        setDocumentsUploading(true);

        const data = await fetchDocumentsRequest(fileLibrary.queryURL, links);

        setDocuments(data);
      } finally {
        setDocumentsUploading(false);
      }
    },
    [fileLibrary.queryURL],
  );

  const deleteDocument = async (deletedDocument: DocumentFile) => {
    try {
      const documentsWithoutDeleted = documents.filter(
        (doc) => doc.id !== deletedDocument.id,
      );

      setDocuments(documentsWithoutDeleted);

      const data = await fetchDocumentsRequest(
        fileLibrary.queryURL,
        documentsWithoutDeleted.map((doc) => doc.url),
      );

      setDocuments(data);
    } catch (e) {}
  };

  const getDocumentId = useCallback((document: DocumentFile) => {
    return document.url;
  }, []);

  return (
    <MediaServiceContext.Provider
      value={{
        deleteDocument,
        documents,
        extraDocuments,
        getDocumentId,
        isDocumentsLoading,
        isDocumentsUploading,
        mainDocuments: documents,
        updateDocumentsViaLinks,
        uploadDocuments: () => Promise.resolve([]),
        uploadViaButton: () => Promise.resolve([]),
      }}
    >
      {children}
    </MediaServiceContext.Provider>
  );
}

export { FileLibraryServiceProvider };
