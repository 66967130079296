import type {
  BaseSyntheticEvent,
  MouseEventHandler,
  RefObject,
  TouchEvent,
  TouchEventHandler,
} from 'react';
import { useRef } from 'react';

import pinUrl from 'assets/red-pin.svg';

import { DrawingTool } from '../../types';

type Params = {
  canvasRef: RefObject<HTMLCanvasElement>;
  tool: DrawingTool;
};

type Coords = { x: number; y: number };

const BRUSH_COLOR = 'red';
const PIN_SIZE = 25;

const useCanvasDrawing = ({ canvasRef, tool }: Params) => {
  const isBrushMode = tool === DrawingTool.Brush;
  const brushDrawingStateRef = useRef({
    cp1x: 0,
    cp1y: 0,
    cp2x: 0,
    cp2y: 0,
    drawing: false,
    inProgress: false,
    skip1: false,
    skip2: false,
  });

  const drawPin = ({ x, y }: Coords) => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    // eslint-disable-next-line func-names
    img.onload = function () {
      ctx?.drawImage(img, x, y - PIN_SIZE, PIN_SIZE, PIN_SIZE);
    };
    img.src = pinUrl;
  };

  const drawViaBrush = ({ x, y }: Coords) => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    ctx.shadowColor = BRUSH_COLOR;
    ctx.shadowBlur = 2;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = 2;
    ctx.strokeStyle = BRUSH_COLOR;
    if (!brushDrawingStateRef.current.inProgress) {
      ctx.beginPath();
      ctx.moveTo(x, y);
      brushDrawingStateRef.current.inProgress = true;
      brushDrawingStateRef.current.skip1 = true;
      brushDrawingStateRef.current.skip2 = true;
    } else {
      if (brushDrawingStateRef.current.skip1) {
        brushDrawingStateRef.current.cp1x = x;
        brushDrawingStateRef.current.cp1y = y;
        brushDrawingStateRef.current.skip1 = false;
        brushDrawingStateRef.current.skip2 = true;
      }
      if (brushDrawingStateRef.current.skip2) {
        brushDrawingStateRef.current.cp2x = x;
        brushDrawingStateRef.current.cp2y = y;
        brushDrawingStateRef.current.skip1 = false;
        brushDrawingStateRef.current.skip2 = false;
      } else {
        ctx.bezierCurveTo(
          brushDrawingStateRef.current.cp1x,
          brushDrawingStateRef.current.cp1y,
          brushDrawingStateRef.current.cp2x,
          brushDrawingStateRef.current.cp2y,
          x,
          y,
        );
        brushDrawingStateRef.current.skip1 = true;
        brushDrawingStateRef.current.skip2 = false;
      }
    }
    ctx.stroke();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mouseDown = (e: BaseSyntheticEvent<any>) => {
    if (isBrushMode) {
      brushDrawingStateRef.current.drawing = true;
    } else {
      drawPin({
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      });
    }
  };
  const mouseUp = () => {
    if (isBrushMode) {
      brushDrawingStateRef.current.drawing = false;
      brushDrawingStateRef.current.inProgress = false;
    }
  };

  const getTouchPos = (e: TouchEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    return {
      x: e.touches[0].clientX - (rect?.left ?? 0),
      y: e.touches[0].clientY - (rect?.top ?? 0),
    };
  };

  const onMouseMove: MouseEventHandler<HTMLCanvasElement> = (e) => {
    if (isBrushMode) {
      if (brushDrawingStateRef.current.drawing) {
        drawViaBrush({
          x: e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
        });
      }
    }
  };

  const onTouchMove: TouchEventHandler<HTMLCanvasElement> = (e) => {
    if (isBrushMode) {
      if (brushDrawingStateRef.current.drawing) {
        drawViaBrush(getTouchPos(e));
      }
    }
  };

  return {
    mouseDown,
    mouseUp,
    onMouseMove,
    onTouchMove,
  };
};

export { useCanvasDrawing };
