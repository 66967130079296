import { splitNameAndExtension } from '@/utils';

function createNotesDocumentFormData({
  blob,
  initialUrl,
}: {
  blob: File;
  initialUrl: string;
}) {

  const initialFileName = initialUrl.split('/').reverse()[0];
  const [name] = splitNameAndExtension(initialFileName);

  const formData = new FormData();
  const newType = blob.type || 'image/png';
  const newExtension = newType.split('/')[1];

  const newName = `${name}-copy-${Date.now()}.${newExtension}`;
  const file = new File([blob], newName, { type: newType });
  formData.append('files[]', file, newName);

  return formData;
}

export { createNotesDocumentFormData };
