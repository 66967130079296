import { translate } from '@/common';
import { Dialog, ModalTypes } from '@/react-app/shared/components';
import { cn } from '@/utils';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import type { MediaExplorerParams } from '../../types';
import { MediaExplorer } from './MediaExplorer';

type Props = MediaExplorerParams & {
  closeWindow: VoidFunction;
  isShown: boolean;
};

function MediaExplorerModalEmbedable({
  closeWindow,
  isShown,
  ...restProps
}: Props) {
  const [isTemporaryHiding, setTemporaryHiding] = useState(false);

  const hideCurrentDialog = useCallback(() => setTemporaryHiding(true), []);
  const restoreCurrentDialog = useCallback(() => setTemporaryHiding(false), []);

  return (
    <Dialog
      className={cn({
        'tw-hidden': isTemporaryHiding,
      })}
      footer={
        <button
          className="btn btn-default"
          type="button"
          onClick={() => closeWindow()}
        >
          {translate('common.close')}
        </button>
      }
      header={
        <div className="tw-flex tw-items-center tw-gap-2">
          <FontAwesomeIcon icon={faFile} />
          <h4 className="tw-m-0">{translate('documents_viewer.files')}</h4>
        </div>
      }
      isPortal={true}
      isShown={isShown}
      movable={true}
      type={ModalTypes.Default}
      onClose={closeWindow}
    >
      <MediaExplorer
        {...restProps}
        hideCurrentDialog={hideCurrentDialog}
        restoreCurrentDialog={restoreCurrentDialog}
      />
    </Dialog>
  );
}

export { MediaExplorerModalEmbedable };
