import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMediaDialogContext } from '../../../context';

function ZoomSwitcher() {
  const { zoom } = useMediaDialogContext();

  const { isZoomOutDisabled, onZoomIn, onZoomOut } = zoom;

  return (
    <div className="btn-group tw-inline-flex">
      <button
        className="btn btn-default"
        type="button"
        onClick={() => onZoomIn()}
      >
        <FontAwesomeIcon icon={faSearchPlus} />
      </button>
      <button
        className="btn btn-default"
        disabled={isZoomOutDisabled}
        type="button"
        onClick={() => onZoomOut()}
      >
        <FontAwesomeIcon icon={faSearchMinus} />
      </button>
    </div>
  );
}

export { ZoomSwitcher };
