import { useGeolocationLink } from '@/react-app/shared/containers/geolocation-link';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaDialogContext } from '../../../context';

function GeolocationButton() {
  const { currentFile } = useMediaDialogContext();
  const { geolocation } = currentFile;
  const url = useGeolocationLink(geolocation);

  if (!url) {
    return null;
  }

  return (
    <a className="btn btn-default" href={url} rel="noreferrer" target="_blank">
      <FontAwesomeIcon icon={faGlobeEurope} />
    </a>
  );
}

export { GeolocationButton };
