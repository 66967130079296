import { Card, TextArea } from '@/react-app/shared/components';
import { usePrevious } from '@/react-app/shared/hooks';
import {
  asyncPause,
  buttonDefaultRadiusStyle,
  cn,
  createFilesFormData,
} from '@/utils';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import type Nanobus from 'nanobus';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MediaExplorerEvents, MediaExplorerParams } from '../../types';
import { MediaDialog, useMediaDialog } from '../dialog';
import { FileLibraryDialog } from '../file-library';
import { MediaFilesGrid } from '../grid';
import { PhotoCamera } from '../photo';
import {
  FileLibraryServiceProvider,
  MediaServiceProvider,
  useMediaServiceContext,
} from './service';
import { createNotesDocumentFormData } from './utils';

type Props = MediaExplorerParams & {
  cardsClass?: string;
  eventBus?: Nanobus<MediaExplorerEvents>;
  hasTextMode?: boolean;
  hideCurrentDialog?: VoidFunction;
  restoreCurrentDialog?: VoidFunction;
};

function MediaExplorerBase({
  accept,
  cardsClass,
  dialogOptions,
  eventBus,
  fileLibrary,
  hasCamera,
  hasSelection,
  hasTextMode,
  hasUpload = true,
  hiddenInput,
  hideCurrentDialog,
  initialSelectedDocumentsIds,
  limitHeight,
  onCountChange,
  onFilesChange,
  onSelectionChanged,
  restoreCurrentDialog,
  showGroupDocuments,
}: Props) {
  const [isPhotoCameraActive, setPhotoCameraActive] = useState(false);
  const closePhotoCamera = useCallback(() => setPhotoCameraActive(false), []);

  const [isFileLibraryActive, setFileLibraryActive] = useState(false);
  const wasFileLibraryActive = usePrevious(isFileLibraryActive);
  const closeFileLibrary = useCallback(() => setFileLibraryActive(false), []);

  useEffect(() => {
    if (!wasFileLibraryActive && isFileLibraryActive) {
      hideCurrentDialog?.();
    } else if (wasFileLibraryActive && !isFileLibraryActive) {
      restoreCurrentDialog?.();
    }
  }, [
    hideCurrentDialog,
    isFileLibraryActive,
    restoreCurrentDialog,
    wasFileLibraryActive,
  ]);

  const [isTextMode, setTextMode] = useState(false);
  const [textModeContent, setTextModeContent] = useState('');
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>(
    initialSelectedDocumentsIds || [],
  );
  const [ghosts, setGhosts] = useState(0);

  const {
    deleteDocument,
    documents,
    extraDocuments,
    getDocumentId,
    isDocumentsLoading,
    isDocumentsUploading,
    mainDocuments,
    updateDocumentsViaLinks,
    uploadDocuments,
    uploadViaButton,
  } = useMediaServiceContext();

  const mainDocumentsSelectedIds = intersection(
    mainDocuments?.map((doc) => doc.id),
    selectedDocumentsIds,
  );

  const extraDocumentsSelectedIds = intersection(
    extraDocuments?.map((doc) => doc.id),
    selectedDocumentsIds,
  );

  const mediaDialogDocuments = useMemo(
    () =>
      documents?.map((document) => {
        const documentName = document.url.split('/').reverse()[0];
        return {
          ...document,
          name: documentName,
        };
      }),
    [documents],
  );

  const {
    closeMediaDialog,
    isMediaDialogActive,
    media,
    setActiveFileByIndex,
    setActiveFileByUrl,
    setMediaDialogActive,
    updateMedia,
  } = useMediaDialog({
    documents: mediaDialogDocuments,
  });

  const count = showGroupDocuments ? documents.length : mainDocuments.length;
  const prevCount = usePrevious(count);
  const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
  const prevIsDocumentsLoading = usePrevious(isDocumentsLoading);

  useEffect(() => {
    if (
      !isInitialLoadingDone &&
      prevIsDocumentsLoading &&
      !isDocumentsLoading
    ) {
      setIsInitialLoadingDone(true);
    }
  }, [isDocumentsLoading, isInitialLoadingDone, prevIsDocumentsLoading]);

  useEffect(() => {
    if (!isInitialLoadingDone) return;
    if (prevCount === count) return;

    onCountChange?.(count);
  }, [count, isInitialLoadingDone, onCountChange, prevCount]);

  useEffect(() => {
    if (isDocumentsLoading) return;

    const docsIds = documents.map(getDocumentId);

    const strValue = docsIds.join(' ');
    onFilesChange?.(docsIds);
    hiddenInput?.val(strValue);
  }, [
    documents,
    onFilesChange,
    hiddenInput,
    getDocumentId,
    isDocumentsLoading,
  ]);

  useEffect(() => {
    onSelectionChanged?.(selectedDocumentsIds);
  }, [onSelectionChanged, selectedDocumentsIds]);

  const updateTextModeContent = () => {
    setTextModeContent(mainDocuments.map(getDocumentId).join('\n'));
  };

  const submitLinkList = useCallback(
    async (linkList: string[]) => {
      const lengthDiff = linkList.length - mainDocuments.length;
      const ghostsCount = lengthDiff > 0 ? lengthDiff : 0;

      try {
        setGhosts((prev) => prev + ghostsCount);

        await updateDocumentsViaLinks(linkList);
      } finally {
        setGhosts((prev) => prev - ghostsCount);
      }
    },
    [mainDocuments?.length, updateDocumentsViaLinks],
  );

  const submitTextModeContent = useCallback(
    async (cb?: VoidFunction) => {
      const linkList = textModeContent.split('\n').filter(Boolean);
      await submitLinkList(linkList);

      // без этой задержки что-то там на сервере не успевает произойти при отправке формы
      await asyncPause(1000);
      cb?.();
    },
    [submitLinkList, textModeContent],
  );

  // subscribe to event bus
  useEffect(() => {
    const cb = () => eventBus?.emit('submit-text-done');

    const listener = async () => {
      if (isTextMode) {
        await submitTextModeContent(cb);
      } else {
        cb();
      }
    };

    eventBus?.on('submit-text-content', listener);

    return () => {
      eventBus?.removeListener('submit-text-content', listener);
    };
  }, [eventBus, isTextMode, submitTextModeContent]);

  const handleUploadButton = useCallback(
    async (formData: FormData) => {
      const ghostsCount = Array.from(formData.entries()).length;
      setGhosts((prev) => prev + ghostsCount);

      await uploadViaButton(formData);
      setGhosts((prev) => prev - ghostsCount);
    },
    [uploadViaButton],
  );

  const onSave = async (payload: FormData) => {
    try {
      const formData = createNotesDocumentFormData({
        blob: payload.get('files[]') as File,
        initialUrl: media.files[media.activeFile].url,
      });
      await uploadDocuments(formData);
      setActiveFileByIndex(documents.length);
      return true;
    } catch (e) {
      return false;
    }
  };

  const toggleMode = () => {
    if (isTextMode) {
      setTextMode(false);
      submitTextModeContent();
    } else {
      setTextMode(true);
      updateTextModeContent();
    }
  };

  const selectDocument = (documentId: string) => {
    if (!hasSelection) {
      return;
    }

    if (selectedDocumentsIds.includes(documentId)) {
      setSelectedDocumentsIds(
        selectedDocumentsIds.filter((id) => id !== documentId),
      );
    } else {
      setSelectedDocumentsIds((prev) => [...prev, documentId]);
    }
  };

  const toggleSelectAllMain = () => {
    if (!hasSelection || !mainDocuments.length) {
      return;
    }

    if (mainDocumentsSelectedIds.length === mainDocuments.length) {
      setSelectedDocumentsIds(
        selectedDocumentsIds.filter(
          (id) => !mainDocumentsSelectedIds.includes(id),
        ),
      );
    } else {
      setSelectedDocumentsIds(
        uniq([...selectedDocumentsIds, ...mainDocuments.map((doc) => doc.id)]),
      );
    }
  };

  const toggleSelectAllExtra = () => {
    if (!hasSelection || !extraDocuments.length) {
      return;
    }

    if (extraDocumentsSelectedIds.length === extraDocuments.length) {
      setSelectedDocumentsIds(
        selectedDocumentsIds.filter(
          (id) => !extraDocumentsSelectedIds.includes(id),
        ),
      );
    } else {
      setSelectedDocumentsIds(
        uniq([...selectedDocumentsIds, ...extraDocuments.map((doc) => doc.id)]),
      );
    }
  };

  const onOpen = (url: string) => {
    setActiveFileByUrl(url);
    setMediaDialogActive(true);
  };

  const onPhotoCaptured = useCallback(
    (blob: Blob) => {
      // blob to file
      const file = new File([blob], `photo-${Date.now()}.png`, {
        type: 'image/png',
      });

      const formData = createFilesFormData([file]);
      handleUploadButton(formData);
    },
    [handleUploadButton],
  );

  const mainContent = isTextMode ? (
    <TextArea
      className="tw-min-h-[12.5rem] tw-resize-none !tw-rounded !tw-rounded-r-none !tw-border-none focus:!tw-shadow-none"
      value={textModeContent}
      onChange={(e) => setTextModeContent(e.target.value)}
    />
  ) : (
    <MediaFilesGrid
      accept={accept}
      documents={mainDocuments}
      ghosts={ghosts}
      hasCamera={hasCamera}
      hasLibrary={!!fileLibrary}
      hasSelection={hasSelection}
      hasUpload={hasUpload}
      isLoading={isDocumentsLoading}
      isMultiUpload={true}
      isUploading={isDocumentsUploading}
      isWithDeletion={hasUpload}
      selectedDocumentsIds={hasSelection ? mainDocumentsSelectedIds : null}
      onDeleted={deleteDocument}
      onFileLibrary={() => setFileLibraryActive(true)}
      onOpen={onOpen}
      onPhotoCamera={() => setPhotoCameraActive(true)}
      onSelectAll={toggleSelectAllMain}
      onSelected={selectDocument}
      onUpload={handleUploadButton}
    />
  );

  return (
    <>
      <Card
        className={cn(
          'tw-rounded tw-shadow-none',
          {
            'tw-max-h-24 tw-overflow-auto': limitHeight,
            'tw-pr-9': hasTextMode,
          },
          cardsClass,
        )}
      >
        {mainContent}
        {hasTextMode && (
          <button
            className="btn btn-primary tw-absolute tw-right-0 tw-top-0 !tw-rounded-br-none !tw-rounded-tl-none tw-px-2 tw-text-center tw-text-xl"
            style={buttonDefaultRadiusStyle}
            type="button"
            onClick={toggleMode}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        )}
      </Card>
      {showGroupDocuments && !!extraDocuments.length && (
        <Card
          className={cn(
            'tw-mt-2',
            {
              'tw-max-h-24 tw-overflow-auto': limitHeight,
            },
            cardsClass,
          )}
        >
          <MediaFilesGrid
            documents={extraDocuments}
            hasSelection={hasSelection}
            isLoading={isDocumentsLoading}
            selectedDocumentsIds={
              hasSelection ? extraDocumentsSelectedIds : null
            }
            onOpen={onOpen}
            onSelectAll={toggleSelectAllExtra}
            onSelected={selectDocument}
          />
        </Card>
      )}
      <MediaDialog
        closeActiveDialog={closeMediaDialog}
        isActive={isMediaDialogActive}
        media={media}
        options={dialogOptions}
        updateMedia={updateMedia}
        uploadFiles={hasUpload ? onSave : undefined}
      />
      <PhotoCamera
        closeActiveDialog={closePhotoCamera}
        isActive={isPhotoCameraActive}
        onCaptured={onPhotoCaptured}
      />
      <FileLibraryDialog
        closeWindow={closeFileLibrary}
        initialSelectedFiles={documents.map((document) => document.url)}
        isShown={isFileLibraryActive}
        params={fileLibrary}
        onSubmit={submitLinkList}
      />
    </>
  );
}

function MediaExplorer(props: Props) {
  if (props.fileLibrary) {
    return (
      <FileLibraryServiceProvider
        fileLibrary={props.fileLibrary}
        initialDocuments={props.initialDocuments}
      >
        <MediaExplorerBase {...props} />
      </FileLibraryServiceProvider>
    );
  } else {
    return (
      <MediaServiceProvider
        baseURL={props.baseURL}
        initialDocuments={props.initialDocuments}
        requestsParams={props.requestsParams}
      >
        <MediaExplorerBase {...props} />
      </MediaServiceProvider>
    );
  }
}

export { MediaExplorer };
