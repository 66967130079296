import { dt_number_format, extractHtmlText, isHtml } from '@/utils';

import type { DatatableFilterParams, Option } from './types';
import moment from 'moment';
import { datatableFormats } from '@/common/features/datatables';

function evaluateOptionsHaveHtml(options: Option[]) {
  return options.some((option) => typeof option === 'string' && isHtml(option));
}

type GetOptionDataParams = {
  decimals: DatatableFilterParams['decimals'];
  hasHtmlOptions: boolean;
  type: DatatableFilterParams['type'];
};

function getOptionData(
  option: Option,
  { decimals, hasHtmlOptions, type }: GetOptionDataParams,
) {
  if (typeof option === 'string') {
    return {
      formattedValue: option,
      searchValue: hasHtmlOptions
        ? extractHtmlText(option).toLowerCase()
        : option.toLowerCase(),
    };
  }

  if (type === 'text') {
    return {
      formattedValue: option + '',
      searchValue: hasHtmlOptions
        ? extractHtmlText(option + '').toLowerCase()
        : (option + '').toLowerCase(),
    };
  }

  if (type === 'number') {
    return {
      formattedValue: dt_number_format(option, decimals),
      searchValue: option + '',
    };
  }

  if (type === 'date') {
    const lDate =
      option === 0
        ? ''
        : moment(option * 1000)
            .utc()
            .format(datatableFormats.date);
    return {
      formattedValue: lDate,
      searchValue: lDate,
    };
  }

  if (type === 'datetime') {
    const lDate =
      option === 0
        ? ''
        : moment(option * 1000)
            .utc()
            .format(datatableFormats.datetime);
    return {
      formattedValue: lDate,
      searchValue: lDate,
    };
  }

  return {
    formattedValue: '',
    searchValue: '',
  };
}

export { evaluateOptionsHaveHtml, getOptionData };
