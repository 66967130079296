/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';
import moment from 'moment';

import { simpleDateFormat, simpleDateOnlyFormat } from '@/constants';

import type { ConfirmationListModalSubmitResult } from '@/react-app';
import { confirmationListModal } from '@/react-app';
import { reasonModal } from '@/react-app/reason-modal';
import { autoFormattable } from '@/utils';

let isLockedForSubmitting = false;

function getTargetDate(rowidData: string) {
  try {
    const dataString = rowidData.split('::').reverse()[0];
    const date = moment(dataString, simpleDateOnlyFormat, true);
    return date.isValid() ? date : null;
  } catch (_) {
    return null;
  }
}

function getDatatableLockState(userid: string, defaultLock: boolean) {
  const stored = localStorage.getItem(userid);
  if (stored == null) {
    return !!defaultLock;
  }
  return localStorage.getItem(userid) === 'true';
}

function getCalendarHorizontalPosition(fieldWrapperBoundingClientRect: {
  left: number;
}): 'left' | 'right' {
  const calendarWidthPx = 209;
  const { left } = fieldWrapperBoundingClientRect;
  const isCloseToRightEdge = window.innerWidth < left + calendarWidthPx;
  return isCloseToRightEdge ? 'right' : 'left';
}

function datatableEditableFields(
  datatable,
  TABLE_EDIT_ONLINE,
  flag_str,
  selectData,
  fsessData,
  $a,
  lang,
  reloadDT,
  defaultLocked = false,
  postCreatedRow: PostCreatedRow,
  userid,
) {
  const getOptions = (field) => selectData[field];

  function disableTooltip() {
    $('.didah-js-tooltip').tooltip('disable');

    $('.didah_tooltip').hide();
  }

  function enableTooltip() {
    $('.didah-js-tooltip').tooltip('enable');
  }

  function setTooltip() {
    if (window.isDatatableLocked) {
      enableTooltip();
    } else {
      disableTooltip();
    }
  }

  async function activateCell(td, event, toSelectText = false) {
    const dataPickerOpt = td.find('.didah-edit-date-datapicker');
    const datetimePickerOpt = td.find('.didah-edit-datetime-datapicker');
    const inputFieldOpt = td.find('.didah-edit-input');
    const textAreaFieldOpt = td.find('.didah-edit-textfield');
    const decimalFieldOpt = td.find('.didah-edit-decimal');
    const selectFieldOpt = td.find('.didah-edit-select');
    const searchFieldOpt = td.find('.didah-edit-search');
    const checkBoxFieldOpt = td.find('.didah-edit-checkbox');
    const confirmationListFieldOpt = td.find('.didah-edit-confirmation-list');
    const isLocked = isLockedForSubmitting || window.isDatatableLocked;

    if (
      !isLocked &&
      dataPickerOpt.length &&
      !td.find('.didah-dt-datepicker').length
    ) {
      disableTooltip();
      onDataPicker(dataPickerOpt, simpleDateOnlyFormat, toSelectText);
      return true;
    } else if (
      !isLocked &&
      datetimePickerOpt.length &&
      !td.find('.didah-dt-datepicker').length
    ) {
      disableTooltip();
      onDataPicker(datetimePickerOpt, simpleDateFormat, toSelectText);
      return true;
    } else if (
      !isLocked &&
      inputFieldOpt.length &&
      !td.find('.didah-editable-input').length
    ) {
      disableTooltip();
      onInputField(inputFieldOpt, true);
      return true;
    } else if (
      !isLocked &&
      textAreaFieldOpt.length &&
      !td.find('.didah-editable-textfield').length
    ) {
      disableTooltip();
      onTextAreaField(textAreaFieldOpt);
      return true;
    } else if (
      !isLocked &&
      decimalFieldOpt.length &&
      !td.find('.didah-editable-input').length
    ) {
      disableTooltip();
      onInputField(decimalFieldOpt, true, true);
      return true;
    } else if (
      !isLocked &&
      selectFieldOpt.length &&
      !td.find('.didah-editable-select').length
    ) {
      disableTooltip();
      onSelectField(selectFieldOpt);
      return true;
    } else if (
      !isLocked &&
      searchFieldOpt.length &&
      !td.find('.didah-editable-search').length
    ) {
      disableTooltip();
      onSearchField(searchFieldOpt);
      return true;
    } else if (!isLocked && confirmationListFieldOpt.length) {
      disableTooltip();
      await onConfirmationListField(confirmationListFieldOpt);
      return true;
    } else if (checkBoxFieldOpt.length) {
      const isCheckBoxClicked = event.target === checkBoxFieldOpt.get(0);
      if (!isLocked) {
        disableTooltip();
        await onCheckField(checkBoxFieldOpt, isCheckBoxClicked);
        return true;
      } else {
        revertCheckField(checkBoxFieldOpt, isCheckBoxClicked);
        return false;
      }
    }
    return false;
  }

  function setTabKeyToNextInRow(jqInput, submitValue) {
    jqInput.on('keydown', async function (e) {
      if (e.keyCode === 9) {
        e.preventDefault();
        const closestTd = jqInput.closest('td');
        const closestTr = jqInput.closest('tr');
        const closestTbody = jqInput.closest('tbody');
        const trIndex = closestTbody.children('tr').index(closestTr) + 1;
        const tdIndex = closestTr.children('td').index(closestTd) + 1;
        //upon submit, activate next cell
        await submitValue(() => {
          setTimeout(() => {
            const currentTd = closestTbody
              .children('tr:nth-child(' + trIndex + ')')
              .children('td:nth-child(' + tdIndex + ')');
            if (!e.shiftKey) {
              activateNextTd(currentTd);
            } else {
              activatePrevTd(currentTd);
            }
          }, 300);
        });
      }
    });

    async function activateNextTd(currentTd) {
      const nextTd = currentTd.next('td');
      if (nextTd.length && !(await activateCell(nextTd, true))) {
        await activateNextTd(nextTd);
      } else if (!nextTd.length) {
        const nextTr = currentTd.closest('tr').next('tr');
        if (nextTr.length) activateFirstInRow(nextTr);
      }
    }

    async function activatePrevTd(currentTd) {
      const prevTd = currentTd.prev('td');
      if (prevTd.length && !(await activateCell(prevTd, true))) {
        await activatePrevTd(prevTd);
      } else if (!prevTd.length) {
        const prevTr = currentTd.closest('tr').prev('tr');
        if (prevTr.length) await activateLastInRow(prevTr);
      }
    }

    async function activateFirstInRow(newTr) {
      const firstTd = newTr.children('td').first();
      if (firstTd.length && !(await activateCell(firstTd, true)))
        await activateNextTd(firstTd);
    }

    async function activateLastInRow(newTr) {
      const lastTd = newTr.children('td').last();
      if (lastTd.length && !(await activateCell(lastTd, true)))
        await activatePrevTd(lastTd);
    }
  }

  function setEnterKeyToNextInCol(jqInput, submitValue, withCtrl?: boolean) {
    jqInput.on('keyup', async function (e) {
      if (
        (e.keyCode === 13 && !withCtrl) ||
        (e.keyCode === 13 &&
          withCtrl &&
          (e.ctrlKey === true || e.metaKey === true))
      ) {
        e.preventDefault();
        const closestTd = jqInput.closest('td');
        const closestTr = jqInput.closest('tr');
        const closestTbody = jqInput.closest('tbody');
        const trIndex = closestTbody.children('tr').index(closestTr) + 1;
        const tdIndex = closestTr.children('td').index(closestTd) + 1;
        //upon submit, activate next cell
        await submitValue(() => {
          setTimeout(() => {
            activateBelowTd(
              closestTbody
                .children('tr:nth-child(' + trIndex + ')')
                .children('td:nth-child(' + tdIndex + ')'),
            );
          }, 300);
        });
      }
    });

    async function activateBelowTd(currentTd) {
      const closestTr = currentTd.closest('tr');
      const closestTbody = currentTd.closest('tbody');
      const nextTrIndex = closestTbody.children('tr').index(closestTr) + 1 + 1;
      const tdIndex = closestTr.children('td').index(currentTd) + 1;
      const belowTd = closestTbody
        .children('tr:nth-child(' + nextTrIndex + ')')
        .children('td:nth-child(' + tdIndex + ')');
      if (belowTd.length && !(await activateCell(belowTd, true))) {
        await activateBelowTd(belowTd);
      } else if (!belowTd.length) {
        const firstTr = closestTbody.children('tr').first();
        const nextTdIndex = tdIndex + 1;
        const nextTd = firstTr.children('td:nth-child(' + nextTdIndex + ')');
        if (nextTd.length) {
          await activateFirstRowNextTd(nextTd);
        } else {
          const firstTd = firstTr.children('td').first();
          await activateFirstRowNextTd(firstTd);
        }
      }
    }

    async function activateFirstRowNextTd(nextTd) {
      if (nextTd.length && !(await activateCell(nextTd, true)))
        await activateBelowTd(nextTd);
    }
  }

  async function submitValue({
    editableField,
    fieldWrapper,
    getCurrentVal,
    initialVal,
    m_row,
    onCancel,
    onSubmitEnd,
    row,
    rowData,
    textEl,
  }: Dictionary & { onCancel?: () => void; onSubmitEnd?: () => void }) {
    const fieldName = textEl.data('field');
    const isReasonRequired = fsessData['r' + fieldName] === 'true';
    const newVal = getCurrentVal();
    if (typeof editableField === 'function') {
      editableField = editableField();
    }
    const $spinner = getSpinner();
    editableField.replaceWith($spinner);

    const isChanged = initialVal !== newVal;

    let reason = null;
    if (isReasonRequired && isChanged) {
      reason = await reasonModal();
    }
    const isReasonRequirementFulfilled = !isReasonRequired || reason;

    if (isChanged && isReasonRequirementFulfilled) {
      isLockedForSubmitting = true;
      $.ajax({
        cache: false,
        data: {
          a: $a,
          fquery: textEl.data('rowid'),
          fsess: fsessData[fieldName],
          id: 24,
          val: newVal,
          zsess: window.data_hash,
          ...(reason && { reason }),
        },
        dataType: 'json',
        async error(xhr, textStatus, thrownError) {
          if (textEl !== editableField) {
            $spinner.remove();
            fieldWrapper?.show();
          } else {
            $spinner.replaceWith(editableField);
          }
          blinkRow(row, 'erroneous');
          await reloadDT(datatable, {
            fieldName,
            m_row,
            rowData,
            status: 'error',
          });

          dataTableFixedColumnsUpdate(datatable);
          isLockedForSubmitting = false;
          onSubmitEnd?.();
        },
        async success(response, textStatus, XMLHttpRequest) {
          $spinner.remove();
          if (response.res === 'ok') {
            blinkRow(row, 'updated');
          } else {
            blinkRow(row, 'erroneous');
          }
          await reloadDT(datatable, {
            fieldName,
            m_row,
            response,
            rowData,
            status: 'success',
          });
          if (response.res === 'ok') {
            dataTableFixedColumnsUpdate(datatable);
          }

          isLockedForSubmitting = false;
          onSubmitEnd?.();
        },
        type: 'POST',
        url: '/ajax_api.php',
      });
    } else {
      if (textEl !== editableField) {
        $spinner.remove();
        fieldWrapper?.show();
      } else {
        $spinner.replaceWith(editableField);
      }

      onCancel?.();

      dataTableFixedColumnsUpdate(datatable);
      onSubmitEnd?.();
    }
  }

  const getSpinner = () =>
    $(
      '<div style="text-align: center"><i class="fa fa-spinner fa-pulse fa-1x fa-fw" style=""/></div>',
    );

  function onDataPicker(fieldWrapper, format: string, toSelectText) {
    let fakeTargetedDateShown = false;
    let isSubmitting = false;

    const textEl = fieldWrapper.find('span');
    const fieldWrapperBoundingClientRect = fieldWrapper
      .get(0)
      .getBoundingClientRect();

    const $spinner = getSpinner();
    fieldWrapper.hide();
    fieldWrapper.parent().append($spinner);

    const dp = $(
      '<input type="text" class="form-control"/>',
    ) as JQuery<HTMLInputElement>;
    const dpWrapper = $('<div class="didah-dt-datepicker">');
    dpWrapper.append(dp);
    const input = dp;

    const initialVal = textEl.text();
    input.val(initialVal);
    dataTableFixedColumnsUpdate(datatable);
    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();

    const widgetParent = fieldWrapper.closest('.dataTables_wrapper');
    const alignment = getCalendarHorizontalPosition(
      fieldWrapperBoundingClientRect,
    );
    dp.datetimepicker({
      allowInputToggle: true,
      calendarWeeks: true,
      format,
      keyBinds: false,
      locale: lang,
      useCurrent: false,
      useStrict: true,
      widgetParent,
      widgetPositioning: {
        horizontal: alignment,
        vertical: 'bottom',
      },
    });
    const dpApi = dp.data('DateTimePicker');
    const getCurrentVal = () => {
      if (fakeTargetedDateShown) {
        return '';
      }

      return dpApi.date() ? dpApi.date().format(format) : '';
    };
    const onCancel = () => dpWrapper.remove();
    const doSubmit = async () => {
      if (isSubmitting) {
        return;
      }

      isSubmitting = true;

      await submitValue({
        editableField: dpWrapper,
        fieldWrapper,
        getCurrentVal,
        initialVal,
        m_row,
        onCancel,
        onSubmitEnd: () => {
          isSubmitting = false;
        },
        row,
        rowData,
        textEl,
      });
    };

    const setDpValue = (value: string) => {
      if (!value && !fakeTargetedDateShown) {
        dpApi.date(null);
        return;
      }

      const date = moment(value, format, true);
      if (date.isValid()) {
        dpApi.date(date);
      }
    };

    const setDateFromInput = () => {
      setTimeout(() => {
        setDpValue(input.val() as string);
        fakeTargetedDateShown = false;
      }, 100);
    };

    const setDateOnceOnCurrentDayClicked = () => {
      $('.bootstrap-datetimepicker-widget .day.active').one(
        'click',
        setDateFromInput,
      );
    };

    const onHide = async () => {
      await doSubmit();
      enableTooltip();
      $('.bootstrap-datetimepicker-widget .day.active').off(
        'click',
        setDateFromInput,
      );
    };

    dp.on('dp.show', function () {
      const selectedDate = dpApi.date();
      if (!selectedDate) {
        const targetDate = getTargetDate(textEl.data('rowid'));
        if (targetDate) {
          dpApi.date(targetDate);
          fakeTargetedDateShown = true;
          input.val('');
          setDateOnceOnCurrentDayClicked();
        }
      }
    });

    dp.on('dp.hide', () => onHide());

    dp.on('dp.change', function () {
      fakeTargetedDateShown = false;
    });

    input.on('input', function () {
      setDpValue($(this).val() as string);
    });

    autoFormattable(input, format);

    const submitViaKey = async (afterSubmitFn: () => void) => {
      await onHide();
      dpApi.hide();
      afterSubmitFn();
    };

    setEnterKeyToNextInCol(input, submitViaKey);
    setTabKeyToNextInRow(input, submitViaKey);

    $spinner.remove();
    fieldWrapper.parent().append(dpWrapper);

    const widgetParentBoundingClientRect = widgetParent
      .get(0)
      .getBoundingClientRect();

    dpApi.show();

    const $widget = $('.bootstrap-datetimepicker-widget');
    $widget.css(
      'top',
      fieldWrapperBoundingClientRect.top -
        widgetParentBoundingClientRect.top +
        20 +
        'px',
    );
    if (alignment === 'right') {
      $widget.css(
        'right',
        widgetParentBoundingClientRect.right -
          fieldWrapperBoundingClientRect.right +
          'px',
      );
    } else {
      $widget.css(
        'left',
        fieldWrapperBoundingClientRect.left -
          widgetParentBoundingClientRect.left +
          'px',
      );
    }

    if (toSelectText) input.select();
  }

  function onInputField(fieldWrapper, toSelectText, decimal = false) {
    const textEl = fieldWrapper.find('span');

    const $spinner = getSpinner();
    fieldWrapper.hide();
    fieldWrapper.parent().append($spinner);

    const initialVal = textEl.text();

    const input = $(
      '<input type="text" class="form-control didah-editable-input"/>',
    );
    input.css('text-align', textEl.css('text-align'));
    input.val(initialVal);

    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();

    const formatVal = (val: string) =>
      !decimal
        ? val
        : val
            .replace(/,/gi, '.')
            .replace(/ /gi, '')
            .replace(/€/gi, '')
            .replace(/\$/gi, '')
            .replace(/Ю/gi, '.')
            .replace(/ю/gi, '.')
            .replace(/Б/gi, '.')
            .replace(/б/gi, '.'); // преобразование

    const getCurrentVal = () => formatVal(input.val() as string);
    const onCancel = () => input.remove();
    const validateCurrentVal = () => {
      const tcv = getCurrentVal();
      return !decimal || tcv === '' || !isNaN(+tcv);
    };
    const doSubmit = async (onSubmitEnd?: () => void) => {
      if (!validateCurrentVal()) {
        blinkRow(row, 'erroneous');
        datatable
          .cell(m_row, textEl.data('field') + ':name')
          .data(rowData[textEl.data('field')])
          .draw(false);
        return;
      }
      await submitValue({
        editableField: input,
        fieldWrapper,
        getCurrentVal,
        initialVal: formatVal(initialVal),
        m_row,
        onCancel,
        onSubmitEnd,
        row,
        rowData,
        textEl,
      });
    };

    input.on('blur', async function (e) {
      await doSubmit();
      enableTooltip();
    });
    setEnterKeyToNextInCol(input, doSubmit);
    setTabKeyToNextInRow(input, doSubmit);

    $spinner.remove();
    fieldWrapper.parent().append(input);
    dataTableFixedColumnsUpdate(datatable);

    input.trigger('focus');
    if (toSelectText) input.select();
  }

  function onTextAreaField(fieldWrapper) {
    const textEl = fieldWrapper.find('span');

    const $spinner = getSpinner();
    fieldWrapper.hide();
    fieldWrapper.parent().append($spinner);

    const initialVal = textEl.text();
    const td = fieldWrapper.closest('td');
    td.attr('style', 'padding: 0 2px !important');
    const initialWidth = td.width();
    const initialHeight = td.height() - 3;

    const input = $(
      '<textarea class="form-control didah-editable-textfield"/>',
    );
    input.height(initialHeight);
    input.width(initialWidth);

    input.val(initialVal);

    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();
    const onCancel = () => {
      input.remove();
      td.attr('style', '');
    };
    const getCurrentVal = () => input.val();

    const doSubmit = async (onSubmitEnd?: () => void) => {
      await submitValue({
        editableField: input,
        fieldWrapper,
        getCurrentVal,
        initialVal,
        m_row,
        onCancel,
        onSubmitEnd,
        row,
        rowData,
        textEl,
      });
    };

    input.on('blur', async function (e) {
      await doSubmit();
      enableTooltip();
    });
    setTabKeyToNextInRow(input, doSubmit);
    setEnterKeyToNextInCol(input, doSubmit, true);

    $spinner.remove();
    fieldWrapper.parent().append(input);
    dataTableFixedColumnsUpdate(datatable);

    input.trigger('focus');
  }

  function onSearchField(fieldWrapper) {
    let isLoadedOnceOrCleared = false;
    const textEl = fieldWrapper.find('span');
    const initialWidth = fieldWrapper.width();

    const $spinner = getSpinner();
    fieldWrapper.hide();
    fieldWrapper.parent().append($spinner);

    const initialVal = textEl.data('init') ? textEl.data('init') + '' : '';
    const initialText = textEl.text();

    const select = $('<select class="form-control didah-editable-search"/>');
    select.css('text-align', textEl.css('text-align'));

    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();
    const getPostCreatedRowData = () => rowData;
    const onCancel = () =>
      datatable
        .cell(m_row, textEl.data('field') + ':name')
        .data(rowData[textEl.data('field')])
        .draw(false);
    const getCurrentVal = () =>
      isLoadedOnceOrCleared ? selectizeAPI.getValue() : initialVal;
    const doSubmit = async (onSubmitEnd?: () => void) => {
      await submitValue({
        editableField: () => select.next('.selectize-control'),
        fieldWrapper,
        getCurrentVal,
        getPostCreatedRowData,
        initialVal,
        m_row,
        onCancel,
        onSubmitEnd,
        row,
        rowData,
        textEl,
      });
      td.css({
        position: '',
      });
    };

    $spinner.remove();
    const td = fieldWrapper.parent();
    td.css({
      position: 'relative',
    });

    td.append(select);
    const selectized = select.selectize({
      closeAfterSelect: true,
      create: false,
      dropdownParent: 'body',
      hideSelected: true,
      items: [initialVal],
      labelField: 'label',
      load(query, callback) {
        if (!query.length) {
          this.close();

          // var me = this;

          // Array.from(me.options).forEach(option => {
          //     if (!me.items.includes(option)) {
          //         me.removeOption(option)
          //     }
          // })
          this.clearOptions();

          return callback();
        }
        $.ajax({
          data: {
            a: $a,
            fquery: query,
            fsess: selectData[textEl.data('field')],
            id: 17,
            page_limit: 20,
          },
          dataType: 'json',
          error() {
            callback();
          },
          success(res) {
            isLoadedOnceOrCleared = true;
            callback(res);
          },
          type: 'POST',
          url: '/ajax_api.php',
        });
      },
      onFocus() {
        this.close();
      },
      openOnFocus: true,
      plugins: ['restore_on_backspace'],
      searchField: 'label',
      sortField: 'label',
      valueField: 'id',
    });
    dataTableFixedColumnsUpdate(datatable);

    const $x = $('<span class="selectize-x">&times;</span>');
    select.parent().find('.selectize-control').append($x);
    let mouseIsOver$x = false;

    const selectizeAPI = (selectized[0] as any).selectize;
    selectizeAPI.on('blur', async () => {
      if (mouseIsOver$x) return;

      await doSubmit();
      enableTooltip();
    });

    $x.on('mouseover', () => {
      mouseIsOver$x = true;
    });

    $x.on('mouseleave', () => {
      mouseIsOver$x = false;
    });

    $x.on('click', async (e) => {
      e.stopPropagation();
      selectizeAPI.clear(false);
      isLoadedOnceOrCleared = true;
      await doSubmit();
      enableTooltip();
    });

    const selectizeInput = select
      .next('.selectize-control')
      .find('.selectize-input');

    selectizeInput.width(initialWidth - 7); //minus border + adjustment

    //setEnterKeyToNextInCol(selectizeInput, submitValue); //todo
    //setTabKeyToNextInRow(selectizeInput, submitValue);
    if (initialText) {
      selectizeAPI.setTextboxValue(initialText);
    }
    selectizeAPI.open();
  }

  function onConfirmationListField(fieldWrapper) {
    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();
    const textEl = fieldWrapper.find('span');
    const fieldName = textEl.data('field');

    const onSubmit = (response: ConfirmationListModalSubmitResult) => {
      return reloadDT(datatable, {
        fieldName,
        m_row,
        response,
        rowData,
        status: 'success',
      });
    };

    return confirmationListModal({
      baseURL: '/ajax_api.php',
      fetchParams: {
        a: $a,
        fquery: textEl.data('rowid'),
        fsess: fsessData['f_' + fieldName],
        id: 24,
        zsess: window.data_hash,
      },
      nr: rowData['s_0'] || '',
      onSubmit,
      submitParams: {
        a: $a,
        fquery: textEl.data('rowid'),
        fsess: fsessData[fieldName],
        id: 24,
        zsess: window.data_hash,
      },
    });
  }

  function onSelectField(fieldWrapper) {
    const textEl = fieldWrapper.find('span');
    const initialWidth = fieldWrapper.width();

    const $spinner = getSpinner();
    fieldWrapper.hide();
    fieldWrapper.parent().append($spinner);

    const initialVal = textEl.data('init') + '';

    const select = $('<select class="form-control didah-editable-select"/>');
    select.css('text-align', textEl.css('text-align'));

    const row = fieldWrapper.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();
    const getPostCreatedRowData = () => rowData;
    const onCancel = () =>
      datatable
        .cell(m_row, textEl.data('field') + ':name')
        .data(rowData[textEl.data('field')])
        .draw(false);
    const getCurrentVal = () => selectizeAPI.getValue();
    const doSubmit = async (onSubmitEnd?: () => void) => {
      await submitValue({
        editableField: () => select.next('.selectize-control'),
        fieldWrapper,
        getCurrentVal,
        getPostCreatedRowData,
        initialVal,
        m_row,
        onCancel,
        onSubmitEnd,
        row,
        rowData,
        textEl,
      });

      td.css({
        position: '',
      });
    };

    $spinner.remove();
    const td = fieldWrapper.parent();
    td.css({
      position: 'relative',
    });

    td.append(select);

    const selectized = select.selectize({
      create: false,
      dropdownParent: 'body',
      items: [initialVal],
      labelField: 'text',
      options: getOptions(textEl.data('field')),
      render: {
        option(data, escape) {
          return (
            '<div class="item" style="' +
            (data['bgColor'] ? 'background-color: ' + data.bgColor + ';' : '') +
            (data['color'] ? 'color: ' + data.color + ';' : '') +
            '">' +
            data.text +
            '</div>'
          );
        },
      },
      valueField: 'value',
    });
    dataTableFixedColumnsUpdate(datatable);

    const $x = $('<span class="selectize-x">&times;</span>');
    select.parent().find('.selectize-control').append($x);
    let mouseIsOver$x = false;

    const selectizeAPI = (selectized[0] as any).selectize;
    selectizeAPI.on('blur', async () => {
      if (mouseIsOver$x) return;

      await doSubmit();
      enableTooltip();
    });

    $x.on('mouseover', () => {
      mouseIsOver$x = true;
    });

    $x.on('mouseleave', () => {
      mouseIsOver$x = false;
    });

    $x.on('click', async (e) => {
      e.stopPropagation();
      selectizeAPI.clear(false);
      await doSubmit();
      enableTooltip();
    });

    const selectizeInput = select
      .next('.selectize-control')
      .find('.selectize-input');

    selectizeInput.width(initialWidth - 7); //minus border + adjustment

    //setEnterKeyToNextInCol(selectizeInput, submitValue); //todo
    //setTabKeyToNextInRow(selectizeInput, submitValue);
    selectizeAPI.open();
  }

  function blinkRow(row, cls) {
    row.addClass(cls);
    setTimeout(function () {
      row.removeClass(cls);
    }, 1000);
  }

  function dataTableFixedColumnsUpdate(dt) {
    // todo check if this is needed
  }

  function onCheckField(checkbox, isCheckBoxClicked) {
    const checked = checkbox.is(':checked');
    const initialVal = isCheckBoxClicked ? !checked : checked;

    const row = checkbox.closest('tr');
    const m_row = datatable.row(row);
    const rowData = m_row.data();
    const getCurrentVal = () => !initialVal;
    const onCancel = () => checkbox.prop('checked', initialVal);
    const doSubmit = async (onSubmitEnd?: () => void) => {
      await submitValue({
        editableField: checkbox,
        getCurrentVal,
        initialVal,
        m_row,
        onCancel,
        onSubmitEnd,
        row,
        rowData,
        textEl: checkbox,
      });
    };

    doSubmit();
  }

  function revertCheckField(checkbox, isCheckBoxClicked) {
    if (isCheckBoxClicked) {
      checkbox.prop('checked', !checkbox.is(':checked'));
    }
  }

  function checkField(row, data, dt, me, s_0) {
    s_0 = typeof s_0 !== 'undefined' ? s_0 : 's_0';
    const datatable = dt;
    const m_row = datatable.row(row);
    const rowData = data;
    const fieldName = me.name;
    const fsess = fsessData[fieldName]; //me.value;
    const newValue = $(me).prop('checked') ? '1' : '0';
    const recId = rowData[s_0];

    $(me).replaceWith(getSpinner());

    $.ajax({
      data: {
        a: $a,
        fquery: recId,
        fsess,
        id: 24,
        val: newValue,
        zsess: window.data_hash,
      },
      dataType: 'json',
      error(err) {
        datatable
          .cell(m_row, fieldName + ':name')
          .data(rowData[fieldName])
          .draw(false);
        blinkRow($(row), 'erroneous');
      },
      success(response, textStatus, XMLHttpRequest) {
        if (response.res === 'ok') {
          if (response['data']) {
            m_row.data(response.data[0]).draw(false);
            postCreatedRow(m_row.node(), response.data[0], 0, datatable);
          } else {
            rowData[fieldName] = newValue;
            m_row.data(rowData).draw(false);
            postCreatedRow(m_row.node(), rowData, 0, datatable);
          }
          dataTableFixedColumnsUpdate(datatable);
          blinkRow($(row), 'updated');
        } else {
          datatable
            .cell(m_row, fieldName + ':name')
            .data(rowData[fieldName])
            .draw(false);
          blinkRow($(row), 'erroneous');
        }
      },
      type: 'POST',
      url: '/ajax_api.php',
    });
  }

  window.checkField = checkField;
  window.dataTableFixedColumnsUpdate = dataTableFixedColumnsUpdate;
  window.activateCell = activateCell;

  setTooltip();
  window.toggleDatatableEdit = () => {
    window.isDatatableLocked = !window.isDatatableLocked;
    localStorage.setItem(userid, window.isDatatableLocked ? 'true' : 'false');

    setTooltip();
  };
}

export { datatableEditableFields, getDatatableLockState };

export default datatableEditableFields;
