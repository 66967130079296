enum Commands {
  ReloadCalender = 'ReloadCalender',
  ReloadDidahCalender = 'ReloadDidahCalender',
  ReloadParent = 'ReloadParent',
  ReloadTop = 'ReloadTop',
  UpdateCustomForm = 'UpdateCustomForm',
}

type HookAction = {
  command: Commands;
  data?: any;
};

type IFrameAppParams = {
  backArrow?: boolean;
  level: number;
  minimize?: boolean;
  onClosed?: (
    frameId: string,
    params?: { actions?: HookAction[]; doSkipActions?: boolean },
  ) => void;
  onOpened?: VoidFunction;
  onRestored?: (iframe: Window) => void;
  url: string;
};

type IFrameWindowHandle = {
  close: (actions?: HookAction[]) => void;
  fadeIn: VoidFunction;
  fadeOut: VoidFunction;
  forceClose: VoidFunction;
  getWindow: () => Window;
  id: string;
  reload: VoidFunction;
};

const DIDAH_BOX_IFRAME_PREFIX = 'didah-shadowbox:';

export {
  Commands,
  DIDAH_BOX_IFRAME_PREFIX,
  HookAction,
  IFrameAppParams,
  IFrameWindowHandle,
};
