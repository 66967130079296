import type { Moment } from 'moment';
import moment from 'moment';

type bsdtpChangeEvent = {
  date: Moment;
  oldDate: Moment;
};

export default function ($inputGroup) {
  const dpApi = $inputGroup.data('DateTimePicker');

  function setTodayClick() {
    $inputGroup.find('.datepicker-days td.day.today').on('click', () => {
      dpApi.date(moment());
    });
  }

  function setActiveDayClick() {
    $inputGroup.find('.datepicker-days td.day.active').on('click', () => {
      const selectedMoment = dpApi.date().clone();
      const currentMoment = moment();
      selectedMoment.hour(currentMoment.hour());
      selectedMoment.minute(currentMoment.minute());
      selectedMoment.second(currentMoment.second());

      dpApi.date(selectedMoment);
    });
  }

  function setOnChange() {
    $inputGroup.on('dp.change', (event) => {
      const e = event as bsdtpChangeEvent;
      setTodayClick();
      setActiveDayClick();
      if (e.oldDate) {
        if (
          e.oldDate.hour() === e.date.hour?.() &&
          e.oldDate.minute() === e.date.minute?.() &&
          e.oldDate.second() === e.date.second?.() &&
          e.oldDate.format() !== e.date.format?.()
        ) {
          const newDate = e.date.clone();
          const currentMoment = moment();
          newDate.hour(currentMoment.hour());
          newDate.minute(currentMoment.minute());
          newDate.second(currentMoment.second());
          dpApi.date(newDate);
        }
      }
    });

    $inputGroup.on('dp.show', () => {
      setTodayClick();
      setActiveDayClick();
    });
  }

  setOnChange();

  return dpApi;
}
