import $ from 'jquery';

let pxRatio =
  window.devicePixelRatio ||
  window.screen.availWidth / document.documentElement.clientWidth;

function addOnZoomChangeListener(cb: (e: any) => void) {
  $(window).on('resize', (e) => {
    const newPxRatio =
      window.devicePixelRatio ||
      window.screen.availWidth / document.documentElement.clientWidth;
    if (newPxRatio != pxRatio) {
      pxRatio = newPxRatio;
      cb(e);
    }
  });
}

function removeOnZoomChangeListener(cb: (e: any) => void) {
  $(window).off('resize', cb);
}

export { addOnZoomChangeListener, removeOnZoomChangeListener };
