function checkHasFullGeo(geolocation?: GeolocationCoordinates) {
  if (!geolocation) {
    return false;
  }

  const { latitude, longitude } = geolocation;
  return latitude != null && longitude != null;
}

export { checkHasFullGeo };
