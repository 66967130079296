import { translate } from '@/common';
import { Dialog, ModalTypes } from '@/react-app/shared/components';
import { ModalSizes } from '@/react-app/shared/components';
import { faFile, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FileLibrary } from './FileLibrary';
import { FileLibraryProvider } from './FileLibraryProvider';
import type { FileLibraryParams } from './types';
import { usePrevious } from '@/react-app/shared/hooks';

type Props = {
  closeWindow: VoidFunction;
  initialSelectedFiles?: string[];
  isShown: boolean;
  onSubmit: (selectedFilesUrls: string[]) => void;
  params: FileLibraryParams;
};

const defaultParams: FileLibraryParams = {
  filesBaseURL: '',
  queryURL: '',
};

function FileLibraryDialog({
  closeWindow,
  initialSelectedFiles,
  isShown,
  onSubmit,
  params = defaultParams,
}: Props) {
  const [selectedFilesUrls, setSelectedFilesUrls] = useState<string[]>([]);
  const wasShown = usePrevious(isShown);

  useEffect(() => {
    if (!wasShown && isShown) {
      setSelectedFilesUrls(initialSelectedFiles || []);
    }
  }, [initialSelectedFiles, isShown, wasShown]);

  return (
    <Dialog
      footer={
        <>
          <button
            className="btn btn-success tw-inline-flex tw-items-center tw-gap-2"
            disabled={!selectedFilesUrls.length}
            type="button"
            onClick={() => {
              closeWindow();
              onSubmit(selectedFilesUrls);
            }}
          >
            <FontAwesomeIcon icon={faPaperclip} />
            <span className="badge">{selectedFilesUrls.length}</span>
            {translate('common.apply')}
          </button>
          <button
            className="btn btn-default"
            type="button"
            onClick={() => closeWindow()}
          >
            {translate('common.close')}
          </button>
        </>
      }
      header={
        <div className="tw-flex tw-items-center tw-gap-2">
          <FontAwesomeIcon icon={faFile} />
          <h4 className="tw-m-0">{translate('documents_viewer.files')}</h4>
        </div>
      }
      isPortal={true}
      isShown={isShown}
      movable={true}
      size={ModalSizes.Large}
      type={ModalTypes.Default}
      onClose={closeWindow}
    >
      <FileLibraryProvider
        selectedFilesUrls={selectedFilesUrls}
        setSelectedFilesUrls={setSelectedFilesUrls}
        {...params}
      >
        <FileLibrary />
      </FileLibraryProvider>
    </Dialog>
  );
}

export { FileLibraryDialog };
