import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MediaServiceContext } from '../context';
import type { DocumentFile, RequestsParams } from '../../../../types';
import {
  deleteDocumentRequest,
  fetchDocumentsRequest,
  uploadDocumentsRequest,
} from './service';
import { compressFile } from '@/utils/dom/images';
import { processFormDataForMediaUploader } from '@/utils';

type Params = PropsWithChildren<{
  baseURL: string;
  initialDocuments?: (DocumentFile | string)[];
  requestsParams: RequestsParams;
}>;

function MediaServiceProvider({
  baseURL,
  children,
  initialDocuments,
  requestsParams,
}: Params) {
  const meta = useMemo(
    () => ({ baseURL, requestsParams }),
    [baseURL, requestsParams],
  );
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [isDocumentsLoading, setDocumentsLoading] = useState(true);
  const [isDocumentsUploading, setDocumentsUploading] = useState(false);

  const mainDocuments = useMemo(
    () => documents.filter((document) => !document.group),
    [documents],
  );
  const extraDocuments = useMemo(
    () => documents.filter((document) => document.group),
    [documents],
  );

  const fetchInitialDocuments = useCallback(async () => {
    try {
      let docs = initialDocuments;
      if (docs.length && docs.every((doc) => typeof doc === 'string')) {
        const data = await fetchDocumentsRequest(meta, {
          fileLinkList: docs.join(' '),
        });

        docs = data;
      }

      return docs as DocumentFile[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [initialDocuments, meta]);

  useEffect(() => {
    (async () => {
      try {
        setDocumentsLoading(true);
        if (initialDocuments) {
          const data = await fetchInitialDocuments();
          setDocuments(data);
        } else {
          const data = await fetchDocumentsRequest(meta);
          setDocuments(data);
        }
      } finally {
        setDocumentsLoading(false);
      }
    })();
  }, [fetchInitialDocuments, initialDocuments, meta]);

  const updateDocumentsViaLinks = useCallback(
    async (links: string[]) => {
      try {
        setDocumentsUploading(true);

        const data = await fetchDocumentsRequest(meta, {
          fileLinkList: links.join(' '),
        });

        setDocuments([...data, ...extraDocuments]);
      } finally {
        setDocumentsUploading(false);
      }
    },
    [extraDocuments, meta],
  );

  const deleteDocument = async (deletedDocument: DocumentFile) => {
    try {
      await deleteDocumentRequest(meta, { fileid: deletedDocument.id });

      setDocuments((prev) =>
        prev.filter((doc) => doc.id !== deletedDocument.id),
      );
    } catch (e) {}
  };

  const uploadDocuments = useCallback(
    async (formData: FormData) => {
      setDocumentsUploading(true);

      try {
        const newFormData = await processFormDataForMediaUploader(formData);

        const data = await uploadDocumentsRequest(meta, newFormData);

        const existingDocuments = [...documents];
        const existingDocumentsIds = documents.map((doc) => doc.id);
        const newDocuments: DocumentFile[] = [];

        data.forEach((documentFile) => {
          if (!existingDocumentsIds.includes(documentFile.id)) {
            newDocuments.push(documentFile);
          }
        });

        setDocuments([...existingDocuments, ...newDocuments]);

        return data;
      } finally {
        setDocumentsUploading(false);
      }
    },
    [documents, meta],
  );

  const uploadViaButton = useCallback(
    async (formData: FormData) => {
      const newFormData = new FormData();
      for (const [, entryValue] of formData.entries()) {
        newFormData.append('files[]', entryValue);
      }

      return await uploadDocuments(newFormData);
    },
    [uploadDocuments],
  );

  const getDocumentId = useCallback((document: DocumentFile) => {
    return document.id;
  }, []);

  return (
    <MediaServiceContext.Provider
      value={{
        deleteDocument,
        documents,
        extraDocuments,
        getDocumentId,
        isDocumentsLoading,
        isDocumentsUploading,
        mainDocuments,
        updateDocumentsViaLinks,
        uploadDocuments,
        uploadViaButton,
      }}
    >
      {children}
    </MediaServiceContext.Provider>
  );
}

export { MediaServiceProvider };
