import $ from 'jquery';
import { styleToString } from '@/utils';

export type ContainerLocation = Partial<{
  bottom: string;
  left: string;
  right: string;
  top: string;
}>;

let $container = null;
const createContainer = (location: ContainerLocation) => {
  if ($container) {
    return;
  }

  $container = $(
    `<div class="tw-fixed tw-flex tw-flex-col tw-z-10" style="${styleToString(
      location
    )}"/>`
  );
  $container.appendTo(document.body);
};

const addButton = ($button: JQuery, location: ContainerLocation) => {
  createContainer(location);

  $container.append($button);
};

export { addButton };
