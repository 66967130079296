let timeoutId: Nullable<number> = null;

function hideBodyOverflow() {
  if (timeoutId != null) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    document.body.classList.add('tw-overflow-hidden');
  }, 0) as unknown as number;
}

function showBodyOverflow() {
  if (timeoutId != null) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    document.body.classList.remove('tw-overflow-hidden');
  }, 5) as unknown as number;
}

export { hideBodyOverflow, showBodyOverflow };
