import $ from 'jquery';

function multiselect() {
  $('.multiselect:not(.didah-vue-multiselect-control)').multiselect({
    height: 400,
    selectedList: 4,
  });
}

export { multiselect };
