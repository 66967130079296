import moment from 'moment';

import { et } from './moment-et';
import { fi } from './moment-fi';
import { lv } from './moment-lv';
import { ru } from './moment-ru';

// adds locales to moment in `import moment from 'moment'`
ru(moment);
lv(moment);
et(moment);
fi(moment);
