import $ from 'jquery';
import moment from 'moment';

import '@/common/plugins/jquery.ajaxTransport.ts';
import '@/common/plugins/bootstrap-datetimepicker';
import type { IFrameGlobalConfig } from '@/common/features/iframe-windows';
import { useIFrameWindows } from '@/common/features/iframe-windows';
import { useDidahViewer } from '@/common/features/didah-viewer';

import './alert-groups';
import './modal-fix';
import './legacy-form-selectize-item';
import { sticky } from './sticky';
import { tooltips } from './tooltips';
import { submenu } from './submenu';
import { topnav } from './topnav';
import { foldable } from './foldable';
import { addIFrameClass } from '@/common/features/site/iframe-style';
import { jsBtn } from './js-btn';
import { multiselect } from './multiselect';
import { sortable } from './sortable';
import { spinnerOnLeave } from './spinner-on-leave';
import { darkTheme } from './dark';
import { cancelProductionScheduleRefresh } from './calendar-reload';

type Params = {
  iFrameConfig?: IFrameGlobalConfig;
  isLoggedIn: boolean;
  lang: string;
};

export default function siteFeatures({
  iFrameConfig,
  isLoggedIn,
  lang,
}: Params) {
  darkTheme({
    isLoggedIn,
  });

  $(function () {
    sortable();
    topnav();
    jsBtn();
    multiselect();
    foldable();
    sticky();
    moment.locale(lang);
    tooltips();
    submenu();
    useIFrameWindows(iFrameConfig);
    addIFrameClass();
    useDidahViewer();
    spinnerOnLeave();
    if (!isLoggedIn) {
      cancelProductionScheduleRefresh();
    }
  });
}
