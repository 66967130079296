import $ from 'jquery';

import {
  makeModalWindow,
  removeModalLoadingOverlay,
  setModalLoadingOverlay,
} from '@/utils';
import { translate } from '@/common/features/translations';

type Options = {
  allowOuterSource?: boolean;
  fuzzy?: boolean;
  items: any[];
  labelField: string;
  loadThrottle: string;
  onSaveCallback: (value: string) => void;
  options: Dictionary;
  optionsAjaxParams: Dictionary;
  optionsUrl: string;
  plugins: string[];
  saveAjaxParams: Dictionary;
  saveUrl: string;
  searchAjaxParams: Dictionary;
  searchField: string;
  searchUrl: string;
  selector: string;
  valueField: string;
};

export default function makeOuterSourceSelectize({
  allowOuterSource = true,
  fuzzy,
  items,
  labelField = 'label',
  loadThrottle,
  onSaveCallback = () => alert('onSaveCallback is mandatory!'),
  options,
  optionsAjaxParams,
  optionsUrl = '/ajax_api.php',
  plugins = ['restore_on_backspace'],
  saveAjaxParams,
  saveUrl = '/ajax_api.php',
  searchAjaxParams,
  searchField,
  searchUrl = '/ajax_api.php',
  selector,
  valueField = 'id',
}: Options) {
  let currentInput;

  function addWrappers() {
    const $input = $(selector);
    $input.wrap(
      '<div class="didah-has-buttons-field-container-oldmode didah-has-buttons-field-container-oldmode-select-info"></div>'
    );
    if (allowOuterSource) {
      $input.after(
        '<div class="didah-inform-select-buttons"><button class="btn btn-primary didah-inform-select-button didah-outer-source-button ignore-sizes" type="button"><span class="glyphicon glyphicon-globe"></span></button></div>'
      );
    }
    const $btn = $input.parent().find('.didah-outer-source-button');
    return { $btn, $input };
  }

  function makeSelectize($input, $btn?) {
    let selectizeAPI = null;
    function getInitializationSelectizeOptions() {
      return {
        closeAfterSelect: true,
        create: false,
        delimiter: '@del@',
        items,
        labelField,
        load: optionsUrl ? getOptionsAjax : undefined,
        loadThrottle,
        maxItems: 1,
        onChange: (value) => {
          manageButton();
          onSaveCallback(value);
        },
        onItemRemove: (value) => {
          const options = selectizeAPI.options;
          currentInput = options[value][labelField];
        },
        onType: (str) => {
          currentInput = str;
        },
        options,
        plugins,
        searchField,
        silent: false,
        valueField,
        ...(fuzzy && {
          score(search) {
            const score = this.getScoreFunction(search);
            return function (item) {
              return 1 + score(item);
            };
          },
        }),
      };
    }

    function getOptionsAjax(query, callback) {
      $.ajax({
        data: {
          ...optionsAjaxParams,
          fquery: query,
        },
        error() {
          callback();
        },
        success(res) {
          if (fuzzy) {
            selectizeAPI?.clearOptions(true);
          }
          callback(res);
        },
        type: 'POST',
        url: optionsUrl,
      });
    }

    function manageButton() {
      const $selectizeInput = $input.parent().find('.selectize-input');
      if (selectizeAPI.items.length || !$btn.length) {
        $btn.addClass('didah-hidden');
        $selectizeInput.addClass('selectize-input-rounded');
      } else {
        $btn.removeClass('didah-hidden');
        $selectizeInput.removeClass('selectize-input-rounded');
      }
    }

    $input.selectize(getInitializationSelectizeOptions());

    selectizeAPI = ($input[0] as any).selectize;
    manageButton();
    return selectizeAPI;
  }

  function setButtonOnClick($btn) {
    if (!$btn.length) {
      return;
    }

    function openModal() {
      const $body = $(`<div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control firmname-text high-selectize" placeholder="${translate(
                              'common.label'
                            )}">
                            <span class="input-group-addon btn-primary find-btn1">
                                <i class="fa fa-search" aria-hidden="true"></i> ${translate(
                                  'common.find'
                                )}
                            </span>
                        </div>
                   </div>
                   <div class="form-group tw-overflow-hidden"><div class="form-control firm-list ignore-sizes high-selectize !tw-h-36 tw-overflow-auto" size="10"></div>
                   <input type="hidden" name="regnr"/></div> 
                `);
      const $modal = makeModalWindow(
        translate('outer_source_selectize_translations.modal_title'),
        $body,
        translate('outer_source_selectize_translations.close_btn'),
        translate('outer_source_selectize_translations.save_btn')
      );
      const $firmNameText = $modal.find('.firmname-text');
      const $btnSearch = $modal.find('.find-btn1');
      const $btnSave = $modal.find('button.m-save');
      const $list = $modal.find('div.firm-list');
      const $regnr = $modal.find('input[name="regnr"]');

      const clearList = () => $list.find('div[data-id]').remove();

      function runInModalSearch(query) {
        if (query) {
          const params: { [key: string]: any } = {};
          Object.assign(params, searchAjaxParams);
          params.fname = query;
          setModalLoadingOverlay($modal);
          $.ajax({
            data: params,
            dataType: 'json',
            error(e) {
              removeModalLoadingOverlay($modal);
              alert('Error processing your request: ' + e.responseText);
            },
            success(response) {
              removeModalLoadingOverlay($modal);
              clearList();
              processSearchResult(response);
            },
            type: 'POST',
            url: searchUrl,
          });
        }
      }

      function processSearchResult(response) {
        if (response.res === 'ok') {
          $.each(response.data, function (i, val) {
            $list.append(
              $('<div />', {
                class: 'tw-p-2 tw-cursor-pointer',
                'data-id': val['org-id'],
                text: val['org-id'] + ' ' + val['legalName'],
              })
            );
          });
        } else alert(response.error);
      }

      function onSave() {
        function showInfoModal(messageHTML, actions) {
          const $body = $(`<div>${messageHTML}</div>`);
          const $infoModal = makeModalWindow(
            translate('outer_source_selectize_translations.info_modal_title'),
            $body,
            translate('outer_source_selectize_translations.ok_btn'),
            undefined
          );
          $modal.find('button.m-save').click(() => $infoModal.modal('hide'));
          $infoModal.on('hidden.bs.modal', () => {
            actions();
          });
          $infoModal.modal('show');
        }

        setModalLoadingOverlay($modal);
        const params: { [key: string]: any } = {};
        Object.assign(params, saveAjaxParams);
        params.regnr = $regnr.val();

        $.ajax({
          data: params,
          dataType: 'json',
          error(e) {
            removeModalLoadingOverlay($modal);
            alert('Error processing your request: ' + e.responseText);
          },
          success(response) {
            removeModalLoadingOverlay($modal);
            if (response.res === 'ok') {
              $modal.modal('hide');
              const actions = () => {
                addOption(response.id, response.legalName);
                currentInput = response.legalName;
                onSaveCallback(response.id);
              };
              showInfoModal(response.message, actions);
            } else alert(response.error);
          },
          type: 'POST',
          url: saveUrl,
        });
      }

      function setEventListeners() {
        $modal.one('show.bs.modal', () => {
          runInModalSearch(currentInput);
        });
        $btnSearch.on('click', () => {
          runInModalSearch($firmNameText.val());
        });
        $firmNameText.on('input', () => {
          $btnSearch.prop('disabled', !$firmNameText.val());
        });
        $firmNameText.on('keypress', (e) => {
          if (e.which === 13 || e.keyCode === 13) {
            runInModalSearch($firmNameText.val());
            return false;
          }
          return true;
        });

        $modal.on('hidden.bs.modal', () => {
          currentInput = '';
        });
        $list.on('click', (e) => {
          const options = $list.find('[data-id]');
          options.removeClass('tw-bg-foreground-primary tw-text-white');
          $regnr.val(e.target.dataset.id);

          if (e.target.dataset.id === $regnr.val()) {
            e.target.classList.add('tw-bg-foreground-primary', 'tw-text-white');
          }

          return $btnSave.prop('disabled', !$regnr.val());
        });
        $btnSave.on('click', () => onSave());
      }

      setEventListeners();
      !currentInput && $btnSearch.prop('disabled', true);
      $firmNameText.val(currentInput);
      clearList();
      $btnSave.prop('disabled', true);
      $modal.modal('show');
    }

    $btn.click(() => {
      openModal();
    });
  }

  const { $btn, $input } = addWrappers();
  const selectizeAPI = makeSelectize($input, $btn);

  function addOption(id, label) {
    selectizeAPI.addOption({ id, label });
    selectizeAPI.addItem(id);
  }

  setButtonOnClick($btn);
}
