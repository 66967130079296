import { useCallback, useMemo, useState } from 'react';
import type { AllMedia, MediaDialogFile } from '../../types';

type Params = {
  documents?: MediaDialogFile[];
  initialIndex?: number;
};

const defaultFiles = [];

const useMediaDialog = ({ documents, initialIndex }: Params) => {
  const [isMediaDialogActive, setMediaDialogActive] = useState(false);
  const closeMediaDialog = useCallback(() => setMediaDialogActive(false), []);

  const [activeFile, setActiveFile] = useState(initialIndex ?? 0);

  const media = useMemo(
    () => ({
      activeFile,
      files: documents ?? defaultFiles,
    }),
    [activeFile, documents],
  );

  const updateMedia = useCallback((media: AllMedia) => {
    setActiveFile(media.activeFile);
  }, []);

  const setActiveFileByUrl = (url: string) =>
    setActiveFile(
      documents?.findIndex((document) => document.url === url) ?? -1,
    );

  const setActiveFileByIndex = setActiveFile;

  return {
    closeMediaDialog,
    isMediaDialogActive,
    media,
    setActiveFileByIndex,
    setActiveFileByUrl,
    setMediaDialogActive,
    updateMedia,
  };
};

export { type Params as MediaDialogHelperParams, useMediaDialog };
