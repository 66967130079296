import type { LockButton } from './lock-button';
import type { SaveButton } from './save-button';

type ButtonsStore = {
  lockButton?: Nullable<LockButton>;
  saveButton?: Nullable<SaveButton>;
};

const autoTablesButtonsStore: ButtonsStore = {
  lockButton: null,
  saveButton: null,
};

export { autoTablesButtonsStore };
