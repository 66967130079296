function debounce<P, R>(
  f: (...args: P[]) => R,
  time: number,
): (...args: P[]) => R {
  let lastTime = 0;
  return function (...args: P[]) {
    if (lastTime + time < new Date().getTime()) {
      const result = f(...args);
      lastTime = new Date().getTime();
      return result;
    }
  };
}

function deleteFromArrayMutable<T = any>(array: T[], item: T) {
  array.splice(array.indexOf(item), 1);
}

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function guid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

function ensureArray<T = any>(items: T[] | T): T[] {
  return Array.isArray(items) ? items : [items];
}

const createCachedFunction = <T>(f: () => T) => {
  let result = null as T | null;
  let isRun = false;
  return () => {
    if (!isRun) {
      isRun = true;
      result = f();
    }

    return result as T;
  };
};

function isObject(data) {
  return data !== null && data.constructor?.name === 'Object';
}

const isDev = process.env.NODE_ENV === 'development';

function moveInArrayMutable<T>(array: T[], fromIndex: number, toIndex: number) {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

const nonEmptyOrUndefined = <T>(array?: T[]) =>
  array?.length ? array : undefined;

function pipe<T>(value: T, ...fns: ((value: T) => T)[]): T {
  return fns.reduce((value, fn) => fn(value), value);
}

const asyncNoop = async () => {};

export {
  asyncNoop,
  createCachedFunction,
  debounce,
  deleteFromArrayMutable,
  ensureArray,
  groupBy,
  guid,
  isDev,
  isObject,
  moveInArrayMutable,
  nonEmptyOrUndefined,
  pipe,
};
