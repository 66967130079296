import { postMessageToAllWindows } from '@/utils';
import type {
  CloseWindowMessage,
  ClosedWindowMessage,
  EscKeyMessage,
  OpenWindowMessage,
} from './types';
import {
  WINDOW_STACK_CLOSE,
  WINDOW_STACK_CLOSED,
  WINDOW_STACK_ESC_KEY,
  WINDOW_STACK_OPEN,
} from './constants';

const createEscMessage = () => {
  const message: EscKeyMessage = {
    type: WINDOW_STACK_ESC_KEY,
  };

  postMessageToAllWindows(message);
};

const createCloseMessage = (id: string) => {
  const message: CloseWindowMessage = {
    payload: id,
    type: WINDOW_STACK_CLOSE,
  };

  postMessageToAllWindows(message);
};

const createClosedMessage = (id: string, removeAllFollowing?: boolean) => {
  const message: ClosedWindowMessage = {
    payload: { id, removeAllFollowing },
    type: WINDOW_STACK_CLOSED,
  };

  postMessageToAllWindows(message);
};

const createOpenMessage = (id: string) => {
  const message: OpenWindowMessage = {
    payload: id,
    type: WINDOW_STACK_OPEN,
  };

  postMessageToAllWindows(message);
};

export {
  createCloseMessage,
  createClosedMessage,
  createEscMessage,
  createOpenMessage,
};
