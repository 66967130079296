import { range } from 'lodash';
import { useMemo } from 'react';

type Props = {
  count: number;
};

const MiniItems = ({ count }: Props) => {
  const countRange = useMemo(() => range(count), [count]);

  return (
    <div className="tw-grid tw-grid-cols-[repeat(3,1rem)] tw-gap-0.5">
      {countRange.map((item) => {
        return (
          <div
            key={item}
            className="tw-size-4 tw-rounded-md tw-border tw-border-border-default tw-bg-foreground-default-3 tw-shadow-md dark:tw-border-border-default-dark dark:tw-bg-foreground-default-3-dark"
          />
        );
      })}
    </div>
  );
};

export { MiniItems };
