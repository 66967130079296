import type { AutoTableHandle } from '@/react-app/auto-table';
import type { TableParams } from './auto-tables-controller';
import { controller } from './auto-tables-controller';

function addToAutoTablesController(
  id: string,
  instance: AutoTableHandle,
  params: TableParams,
) {
  controller.add(id, instance, params);
}

export * from './columns';
export * from './lock-button';
export * from './request';
export * from './save-button';

export { addToAutoTablesController };
