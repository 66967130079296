import { cn } from '@/utils';
import { forwardRef } from 'react';

const Error = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('small text-danger dark:text-danger-dark', className)}
        {...props}
      />
    );
  },
);
Error.displayName = 'Error';

export { Error };
