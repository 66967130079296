import type { DatatableFilterOption } from '@/react-app/datatable-filter-app';
import { datatableFilter } from '@/react-app/datatable-filter-app';
import { escapeRegExp, extractHtmlText } from '@/utils';
import debounce from 'lodash/debounce';
import type { UpdateColumnSearchHandler } from './save-settings/storage';
import {
  evaluateOptionsHaveHtml,
  getOptionData,
} from '@/react-app/datatable-filter-app/utils';

type ColumnSearch = {
  options: DatatableFilterOption[];
  search?: string;
  selectedOptions: DatatableFilterOption[];
};

const searchOptionsStorage = new Map<string, ColumnSearch>();

type ApplySearchParams = {
  $icon: JQuery;
  colId: string;
  datatable: any;
  values: string[];
};

const applySearch = ({
  $icon,
  colId,
  datatable,
  values,
}: ApplySearchParams) => {
  const col = datatable.column(colId + ':name');
  const processedValues = values
    .map((option) => {
      if (typeof option !== 'string') {
        return option;
      }

      return extractHtmlText(option);
    })
    .map((val) => escapeRegExp(val));

  const search = processedValues.length ? `^${processedValues.join('|')}$` : '';
  col.search(search, true, false).draw();

  if (processedValues.length) {
    $icon.addClass('tw-text-primary');
  } else {
    $icon.removeClass('tw-text-primary');
  }
};

type ApplyInitialSearchStateParams = {
  datatable: any;
  initialState: Dictionary<DatatableFilterOption[]>;
};

const applyInitialColumnSearchState = ({
  datatable,
  initialState,
}: ApplyInitialSearchStateParams) => {
  const $container = $(datatable.table().container());
  Object.entries(initialState).forEach(([fieldName, values]) => {
    const $filter = $container.find(
      `.dataTables_scrollHead [data-advanced-search][data-field-name="${fieldName}"]`,
    );
    const colId = $filter.data('col-id');

    const $icon = $filter.find('i');

    const hasHtmlOptions = evaluateOptionsHaveHtml(values);

    applySearch({
      $icon,
      colId,
      datatable,
      values: values.map(
        (value) =>
          getOptionData(value, {
            decimals: $filter.data('number-decimals'),
            hasHtmlOptions,
            type: $filter.data('type'),
          }).formattedValue,
      ),
    });

    const columnSearch = searchOptionsStorage.get(fieldName);
    searchOptionsStorage.set(fieldName, {
      ...columnSearch,
      selectedOptions: values,
    });
  });
};

type SetupColumnSearchParams = {
  datatable: any;
  onUpdateColumnSearch: UpdateColumnSearchHandler;
};

const setupColumnSearch = ({
  datatable,
  onUpdateColumnSearch,
}: SetupColumnSearchParams) => {
  // иконки в заголовках
  $(datatable.table().container())
    .find('[data-advanced-search]')
    .on('click', function (e) {
      e.stopPropagation();
      const $search = $(this);
      const $icon = $search.find('i');
      const colId = $search.data('col-id') + '';
      const fieldName = $search.data('field-name') + '';
      const decimals = $search.data('number-decimals') + '';
      const type = $search.data('type');

      const name = extractHtmlText($search.data('label') + '');
      const columnSearch = searchOptionsStorage.get(fieldName);

      if (!columnSearch) {
        return;
      }

      const { options, search, selectedOptions } = columnSearch;

      datatableFilter({
        decimals,
        initialSearch: search,
        name,
        onSubmit({ formattedValues, searchInputValue, values }) {
          applySearch({
            $icon,
            colId,
            datatable,
            values: formattedValues,
          });

          searchOptionsStorage.set(fieldName, {
            ...columnSearch,
            search: searchInputValue,
            selectedOptions: values,
          });

          onUpdateColumnSearch(fieldName, values);
        },
        options,
        selectedOptions,
        type,
      });
    });

  // инпуты
  $(datatable.table().container())
    .on(
      'keyup change clear',
      '.search-panel input:text',
      debounce(function () {
        const col = datatable.columns($(this).data('col-id') + '');
        if (col.search() !== this.value) {
          col.search(this.value).draw();
        }
      }, 1000),
    )
    .on('keypress', function (event) {
      if (event.key == '13') {
        event.preventDefault();
      }
    });
};

const fillSearchOptionsStorage = (dt) => {
  $('[data-advanced-search]').each(function () {
    const $el = $(this);
    const colId = $el.data('col-id') + '';
    const fieldName = $el.data('field-name') + '';

    const col = dt.column(colId + ':name');

    setTimeout((): void => {
      const rawOptions = col.data()?.sort().unique();
      if (!rawOptions) return;

      const options = Array.from<string | number>(rawOptions).filter(
        (value) => value != null && value !== '',
      );

      const existingEntry = searchOptionsStorage.get(fieldName);
      searchOptionsStorage.set(fieldName, {
        options,
        selectedOptions: existingEntry?.selectedOptions ?? [],
      });
    }, 0);
  });
};

export {
  ApplyInitialSearchStateParams,
  applyInitialColumnSearchState,
  fillSearchOptionsStorage,
  setupColumnSearch,
};
