import $ from 'jquery';

import { getPxValue } from '../units';
import { makeAbsolute } from './utils';

function moveAt({
  contain,
  element,
  height,
  pageX,
  pageY,
  shiftX,
  shiftY,
  width,
}) {
  let left = pageX - shiftX;
  let top = pageY - shiftY;

  if (contain) {
    if (left < 0) {
      left = 0;
    } else if (left > window.innerWidth - width) {
      left = window.innerWidth - width;
    }

    if (top < 0) {
      top = 0;
    } else if (top > window.innerHeight - height) {
      top = window.innerHeight - height;
    }
  }

  const deltaX = left - getPxValue(element.style.left);
  const deltaY = top - getPxValue(element.style.top);
  const bottom = getPxValue(element.style.bottom) - deltaY;
  const right = getPxValue(element.style.right) - deltaX;

  element.style.left = `${left}px`;
  element.style.top = `${top}px`;
  element.style.bottom = `${bottom}px`;
  element.style.right = `${right}px`;
  return {
    bottom,
    left,
    right,
    top,
    x: left,
    y: top,
  };
}

type Args = {
  contain?: boolean;
  element: HTMLElement;
  handle?: HTMLElement;
  ignoreHandleChildren?: boolean;
  keepSize?: boolean;
  onMoveEnd?: (coords: {
    bottom: number;
    left: number;
    right: number;
    top: number;
    x: number;
    y: number;
  }) => void;
  onMoveStart?: () => void;
};

function makeMovable({
  contain = false,
  element,
  handle,
  ignoreHandleChildren,
  keepSize,
  onMoveEnd,
  onMoveStart,
}: Args) {
  if (!handle) {
    handle = element;
  }

  handle.addEventListener('pointerdown', function (event) {
    if (ignoreHandleChildren && event.target !== handle) {
      return;
    }

    const isResizing = $(element).data('resizing');
    if (isResizing) {
      return;
    }

    onMoveStart?.();
    makeAbsolute(element, { keepSize });

    const bcr = element.getBoundingClientRect();
    const shiftX = event.clientX - bcr.left;
    const shiftY = event.clientY - bcr.top;

    let {
      bottom: newBottom,
      left: newLeft,
      right: newRight,
      top: newTop,
      x: newX,
      y: newY,
    } = moveAt({
      contain,
      element,
      height: bcr.height,
      pageX: event.pageX - window.scrollX,
      pageY: event.pageY - window.scrollY,
      shiftX,
      shiftY,
      width: bcr.width,
    });

    function onPointerMove(moveEvent) {
      const { bottom, left, right, top, x, y } = moveAt({
        contain,
        element,
        height: bcr.height,
        pageX: moveEvent.pageX - window.scrollX,
        pageY: moveEvent.pageY - window.scrollY,
        shiftX,
        shiftY,
        width: bcr.width,
      });

      newX = x;
      newY = y;
      newTop = top;
      newBottom = bottom;
      newLeft = left;
      newRight = right;
    }

    function onPointerUp() {
      document.body.removeEventListener('pointermove', onPointerMove);
      document.body.removeEventListener('pointerup', onPointerUp);

      onMoveEnd?.({
        bottom: newBottom,
        left: newLeft,
        right: newRight,
        top: newTop,
        x: newX,
        y: newY,
      });
    }

    document.body.addEventListener('pointermove', onPointerMove);
    document.body.addEventListener('pointerup', onPointerUp);
  });

  element.ondragstart = function () {
    return false;
  };
}

export { makeMovable };
