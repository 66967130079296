import { useCallback, useEffect, useState } from 'react';

const ZOOM_UNIT = 0.15;

type Params = {
  activeFile: number;
};

const useZoom = ({ activeFile }: Params) => {
  const [scale, setScale] = useState(1);

  const onZoomIn = () => setScale(scale + ZOOM_UNIT);

  const isZoomOutDisabled = scale === 1;
  const onZoomOut = () => {
    const newScale = scale - ZOOM_UNIT;
    if (newScale < 1) {
      setScale(1);
    } else {
      setScale(newScale);
    }
  };

  const resetImageState = useCallback(() => {
    setScale(1);
  }, []);

  useEffect(() => {
    resetImageState();
  }, [activeFile, resetImageState]);

  return {
    isZoomOutDisabled,
    onZoomIn,
    onZoomOut,
    resetImageState,
    scale,
  };
};

export { useZoom };
