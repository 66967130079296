import { translate } from '@/common';
import { z } from 'zod';

function getValidationSchema() {
  const requiredMessage = translate('common.forms.validation.required');
  const minLengthMessage =
    translate('registration_form.hint_symbols_min_number_is') +
    ' - ' +
    translate('registration_form.hint_symbols_count');

  return z
    .object({
      email: z
        .string()
        .min(1, { message: requiredMessage })
        .email({ message: translate('common.forms.validation.email') }),
      firstname: z.string().trim().min(1, { message: requiredMessage }),
      lastname: z.string().trim().min(1, { message: requiredMessage }),
      login: z
        .string()
        .trim()
        .min(1, { message: requiredMessage })
        .min(8, { message: minLengthMessage }),
      mobilephone: z.string().trim().min(1, { message: requiredMessage }),
      password: z
        .string()
        .trim()
        .min(1, { message: requiredMessage })
        .min(8, { message: minLengthMessage }),
      password_repeat: z
        .string()
        .trim()
        .min(1, { message: requiredMessage })
        .min(8, { message: minLengthMessage }),
      token: z.string().min(1),
    })
    .refine(
      (values) => {
        return values.password === values.password_repeat;
      },
      {
        message: translate('errors.password_repeat.same_as_password'),
        path: ['password_repeat'],
      }
    );
}

function getDefaultValues() {
  return {
    email: '',
    firstname: '',
    lastname: '',
    login: '',
    mobilephone: '',
    password: '',
    password_repeat: '',
    token: '',
  };
}

export { getDefaultValues, getValidationSchema };
