import { cn } from '@/utils';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faArrows, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, type ReactNode } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components';

type Props<I extends Dictionary> = {
  className?: string;
  disabled?: boolean;
  isDragItem?: boolean;
  isLoading?: boolean;
  itemActions?: {
    label: ReactNode;
    onClick: (itemId: UniqueIdentifier) => void;
  }[];
  itemData: I;
  itemId: UniqueIdentifier;
  itemLabel?: string;
  renderItem?: ({ item }: { item: I }) => ReactNode;
};

function PipelineItemTile<I extends Dictionary>({
  className,
  disabled,
  isDragItem,
  isLoading,
  itemActions,
  itemData,
  itemId,
  itemLabel,
  renderItem,
}: Props<I>) {
  const [isDragDeactivatorHovered, setDragDeactivatorHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    data: {
      type: 'item',
    },
    disabled: disabled || isDragDeactivatorHovered || open || isLoading,
    id: itemId,
  });

  const dragDeactivatorProps = {
    onMouseEnter: () => setDragDeactivatorHovered(true),
    onMouseLeave: () => setDragDeactivatorHovered(false),
  };

  return (
    <div
      ref={setNodeRef}
      className={cn(
        'panel panel-default tw-relative tw-mb-0 tw-pr-4 tw-transition-all',
        {
          'tw-cursor-move hover:-tw-translate-y-0.5 hover:tw-ring-2 hover:tw-ring-border-primary hover:tw-ring-offset-0 dark:hover:tw-ring-border-primary-dark':
            isDragItem && !isDragDeactivatorHovered && !isLoading,
          'tw-cursor-not-allowed tw-opacity-75': isLoading,
          'tw-opacity-50': isSorting && !isDragging,
        },
        className,
      )}
      style={
        isDragging
          ? {
              transform: CSS.Translate.toString(transform),
              transition,
            }
          : {}
      }
      {...listeners}
      {...attributes}
    >
      <div className="tw-cursor-pointer" {...dragDeactivatorProps}>
        {renderItem ? renderItem({ item: itemData }) : itemLabel || itemId}
      </div>
      {itemActions?.length > 0 && (
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <div
              className={cn(
                'tw-absolute tw-right-0 tw-top-0 tw-flex tw-size-6 tw-cursor-pointer tw-items-center tw-justify-center tw-transition-opacity',
                {
                  'tw-pointer-events-none tw-opacity-10': isLoading,
                },
              )}
              {...dragDeactivatorProps}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="start" className="tw-w-auto">
            {itemActions?.map((option, index) => (
              <DropdownMenuItem
                key={index}
                className="tw-cursor-pointer tw-rounded-md tw-px-1 tw-py-1.5 hover:tw-bg-foreground-default dark:hover:tw-bg-foreground-default-dark"
                onClick={() => {
                  option.onClick(itemId);
                  setOpen(false);
                }}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {isDragItem && (
        <FontAwesomeIcon
          className={cn(
            'tw-absolute tw-bottom-1.5 tw-right-1.5 tw-text-sm tw-transition-opacity',
            {
              'tw-opacity-10': isLoading,
            },
          )}
          icon={faArrows}
        />
      )}
    </div>
  );
}

export { PipelineItemTile };
export type { Props as PipelineItemTileProps };
