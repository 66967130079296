import { cn } from '@/utils';
import { forwardRef, type ReactNode, type SelectHTMLAttributes } from 'react';
import { Error } from './Error';
import { RequiredStar } from './RequiredStar';
import type { SelectOption } from './types';

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  errorMessage?: ReactNode;
  inlineLabel?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  options: SelectOption[];
  selectByDefault?: boolean;
  selectClassName?: string;
  selectedValue?: string;
};

const SelectComponent = (
  {
    className,
    errorMessage,
    inlineLabel,
    isRequired,
    label,
    options,
    selectByDefault = true,
    selectClassName,
    selectedValue,
    ...restProps
  }: Props,
  ref: React.ForwardedRef<HTMLSelectElement>,
) => {
  const getIsSelected = (value: string, index: number) => {
    if (selectedValue && selectedValue === value) {
      return true;
    }

    return (
      !selectedValue &&
      ((selectByDefault && index === 0) || (!selectByDefault && index === -1))
    );
  };

  const select = (
    <select
      ref={ref}
      className={cn('form-control', selectClassName)}
      {...restProps}
    >
      {!selectByDefault && <option selected={getIsSelected('', -1)} value="" />}
      {options.map((option, index) => (
        <option
          key={option.value}
          selected={getIsSelected(option.value, index)}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  );

  let content = select;

  if (label) {
    content = (
      <>
        <label
          className={cn({
            'tw-mb-0 tw-mr-1.5 tw-whitespace-nowrap': inlineLabel,
          })}
          htmlFor={restProps.name}
        >
          {label}
          {isRequired && <RequiredStar />}
        </label>
        {select}
      </>
    );
  }

  return (
    <div
      className={cn(
        'form-group',
        {
          'tw-flex tw-items-center tw-justify-start': inlineLabel,
        },
        className,
      )}
    >
      {content}
      {errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
};

const Select = forwardRef<HTMLSelectElement, Props>(SelectComponent);

export { Select };
