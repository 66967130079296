import type { OpenIFrameInput, OpenIFrameMessage } from '../types';
import { MessageTypes } from '../types';

function postOpeningMessage(input: OpenIFrameInput) {
  const message: OpenIFrameMessage = {
    payload: input,
    type: MessageTypes.OpenIframe,
  };
  window.top.postMessage(message, window.top.origin);
}

function postRestoredMessage(window: Window) {
  const message = {
    type: MessageTypes.Restored,
  };
  window.postMessage(message, window.origin);
}

export { postOpeningMessage, postRestoredMessage };
