import { inIframe } from '@/utils';
import { createCloseMessage } from './message-creators';

// стек окон отслеживается в верхнем окне
let windowStackStorage: string[] = [];
const windowStackManager = {
  pop() {
    const id = windowStackStorage.pop();

    createCloseMessage(id);
  },
  push(id: string) {
    if (inIframe() || windowStackStorage.includes(id)) return;

    windowStackStorage.push(id);
  },
  remove(id: string) {
    windowStackStorage = windowStackStorage.filter(
      (currentId) => currentId !== id
    );
  },
  removeStartingAt(id: string) {
    // remove all ids in the array starting from this id
    const index = windowStackStorage.indexOf(id);
    if (index === -1) return;

    windowStackStorage = windowStackStorage.slice(0, index);
  },
};

export { windowStackManager };
