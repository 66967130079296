import $ from 'jquery';

import { openDidahBox } from '@/common';
import type { MediaFileType } from '@/react-app/media';
import { mediaViewer } from '@/react-app/media';
import type { DocumentFile } from '@/react-app/media/types';

const prefix = 'shadowbox';

function createRel(id: string, type: string) {
  return `rel="${prefix}[${id}];type=${type}"`;
}

const regex = /^(shadowbox(\[([\wа-яА-ЯёЁÀ-ÿ/\\._]+)])?);?(type=([a-zA-Z]+))?$/;
const allowedTypes: MediaFileType[] = ['img', 'pdf'];

function parseRel(rel: string) {
  const parsed = regex.exec(rel);
  if (!parsed) {
    return null;
  }

  const id = parsed[3];
  const type = parsed[5];

  if (id && type) {
    return [id, type];
  }

  return [];
}

function showAllWithSameId(id: string, initialUrl: string) {
  const $fileEls = $(`a[rel^="${prefix}[${id}]"]`);

  const documents: DocumentFile[] = [];

  $fileEls.each(function () {
    const $el = $(this);
    const url = $el.attr('href');
    const rel = $el.attr('rel');

    const [, type] = parseRel(rel);
    const thisType = type as MediaFileType;

    if (!allowedTypes.includes(thisType)) {
      return;
    }

    documents.push({
      id: url,
      type: thisType,
      url,
    });
  });

  mediaViewer({
    documents,
    initialIndex: documents.findIndex((doc) => doc.url === initialUrl),
  });
}

function useDidahViewer() {
  $(document.body).on('click', `a[rel^="${prefix}"]`, function (e) {
    e.preventDefault();
    const $el = $(this);
    const rel = $el.attr('rel');
    const href = $el.attr('href');
    const parsedRel = parseRel(rel);
    if (!parsedRel) {
      return;
    }

    const [id, type] = parsedRel;
    const thisType = type as MediaFileType;

    if (id && thisType && allowedTypes.includes(thisType)) {
      showAllWithSameId(id, href);
    } else {
      openDidahBox(href);
    }
  });
}

export { createRel, useDidahViewer };
