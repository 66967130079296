import { cn, createFilesFormData } from '@/utils';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { UploaderProps } from './Uploader';
import { Uploader } from './Uploader';
import { Spinner } from '../loading';

type Props = {
  buttonText: ReactNode;
  className?: string;
  disabled?: boolean;
  draggingHint: ReactNode;
  zoneHint: ReactNode;
} & Pick<UploaderProps, 'accept' | 'onUpload'>;

function DndUploaderZone({
  accept,
  buttonText,
  className,
  disabled,
  draggingHint,
  onUpload,
  zoneHint,
}: Props) {
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    if (disabled) {
      return;
    }

    setIsDragging(true);
  };

  const handleDragLeave = () => {
    if (disabled) {
      return;
    }

    setIsDragging(false);
  };

  return (
    <div
      className={cn(
        'tw-relative tw-rounded tw-border tw-border-dashed tw-p-4 tw-text-center',
        {
          'tw-border-border-primary dark:tw-border-border-primary-dark':
            isDragging,
        },
        className,
      )}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={async (e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        if (e.dataTransfer.files?.length > 0) {
          const formData = createFilesFormData(e.dataTransfer.files);
          setIsDragging(false);
          try {
            setIsUploading(true);
            await onUpload(formData);
          } finally {
            e.dataTransfer.clearData();
            setIsUploading(false);
          }
        }
      }}
    >
      <div
        className={cn('tw-transition-opacity', {
          'tw-opacity-0': isUploading || isDragging,
        })}
      >
        <p className="tw-mb-2 tw-text-xs tw-text-gray-500 dark:tw-text-text-primary-dark">
          {zoneHint}
        </p>
        <Uploader accept={accept} onUpload={onUpload}>
          <button className="btn btn-primary" disabled={disabled} type="button">
            {buttonText}
          </button>
        </Uploader>
      </div>
      {!!isDragging && (
        <div className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-transform tw-text-center tw-text-xs tw-text-gray-500 dark:tw-text-text-primary-dark">
          {draggingHint}
        </div>
      )}
      {!!isUploading && (
        <div className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-center tw-text-gray-500 dark:tw-text-text-primary-dark">
          <Spinner size="large" />
        </div>
      )}
    </div>
  );
}

export { DndUploaderZone };
