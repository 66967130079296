import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@/utils';
import { useMediaDialogContext } from '../../../context';
import { useMediaNavigation } from '../../../hooks';

type Props = {
  className?: string;
};

function NextPrevButtons({ className }: Props) {
  const { buttonsDisabled, currentFile, next, prev, totalFiles } =
    useMediaNavigation();
  const { allMedia } = useMediaDialogContext();

  if (allMedia.files.length <= 1) {
    return null;
  }

  return (
    <div className={cn('btn-group tw-inline-flex', className)}>
      <button
        className="btn btn-default"
        disabled={buttonsDisabled}
        type="button"
        onClick={() => prev()}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button className="btn btn-default tw-pointer-events-none" type="button">
        {`${currentFile} / ${totalFiles}`}
      </button>
      <button
        className="btn btn-default"
        disabled={buttonsDisabled}
        type="button"
        onClick={() => next()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
}

export { NextPrevButtons };
