import { useCallback, useMemo, useState, type PropsWithChildren } from 'react';

import saveAs from 'file-saver';

import type { MediaDialogFile, MediaDialogParams } from '../../types';

import type { MediaDialogContextType } from './context';
import { MediaDialogContext } from './context';
import { Mode } from './types';
import { useDrawing } from './use-drawing';
import { useZoom } from './use-zoom';

type Props = PropsWithChildren<{
  activeParams: MediaDialogParams;
}>;

function MediaDialogProvider({ activeParams, children }: Props) {
  const [mode, setMode] = useState(Mode.Normal);

  const {
    closeActiveDialog,
    media: allMedia,
    options,
    updateMedia,
    uploadFiles,
  } = activeParams;
  const currentFile = allMedia.files[
    allMedia.activeFile
  ] as Nullable<MediaDialogFile>;

  const canEdit = !!uploadFiles;

  const { isZoomOutDisabled, onZoomIn, onZoomOut, resetImageState, scale } =
    useZoom({ activeFile: allMedia.activeFile });

  const {
    canvasData,
    drawingCanvasRef,
    onDraw,
    onDrawCancel,
    onDrawSave,
    resetDrawingState,
    setCanvasData,
    setTool,
    tool,
  } = useDrawing({
    currentFile,
    setMode,
    uploadFiles,
  });

  const onClose = useCallback(() => {
    closeActiveDialog();
    resetImageState();
    resetDrawingState();
  }, [closeActiveDialog, resetDrawingState, resetImageState]);

  const onDownload = useCallback(() => {
    if (!currentFile) {
      return;
    }

    saveAs(currentFile.url, currentFile.name);
  }, [currentFile]);

  const contextValue = useMemo<MediaDialogContextType>(
    () => ({
      allMedia,
      canEdit,
      currentFile,
      drawing: {
        onDraw,
        onDrawCancel,
        onDrawSave,
        setTool,
        tool,
      },
      drawingCanvasRef,
      image: {
        data: canvasData,
        setCanvasImage: setCanvasData,
      },
      mode,
      onClose,
      onDownload,
      options,
      updateMedia,
      uploadFiles,
      zoom: {
        isZoomOutDisabled,
        onZoomIn,
        onZoomOut,
        scale,
      },
    }),
    [
      allMedia,
      drawingCanvasRef,
      canEdit,
      onDraw,
      onDrawCancel,
      onDrawSave,
      setTool,
      tool,
      canvasData,
      setCanvasData,
      isZoomOutDisabled,
      currentFile,
      mode,
      onClose,
      onDownload,
      onZoomIn,
      onZoomOut,
      scale,
      updateMedia,
      uploadFiles,
      options,
    ],
  );
  return (
    <MediaDialogContext.Provider value={contextValue}>
      {children}
    </MediaDialogContext.Provider>
  );
}

export { MediaDialogProvider };
