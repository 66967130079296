function getGeolocation() {
  return new Promise<
    Nullable<{
      latitude: number;
      longitude: number;
    }>
  >((resolve) => {
    if (!navigator.geolocation) {
      return resolve(null);
    }

    return navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => resolve(null)
    );
  });
}

export { getGeolocation };
