import { compressFile } from './dom/images';

async function processFormDataForMediaUploader(formData: FormData) {
  const newFormData = new FormData();
  const filePromises = [];

  for (const [, entryValue] of formData.entries()) {
    if (entryValue instanceof File) {
      filePromises.push(
        compressFile(entryValue).then((compressedFile) => {
          newFormData.append('files[]', compressedFile);
        }),
      );
    } else {
      newFormData.append('files[]', entryValue);
    }
  }

  await Promise.all(filePromises);

  return newFormData;
}

export { processFormDataForMediaUploader };
