import $ from 'jquery';

import { getFacingBackCameraStream, isPortrait, makeMovable } from '@/utils';
import type { ContainerLocation } from '@/common/widgets/floating-buttons';
import { addButton } from '@/common/widgets/floating-buttons';

import './style.scss';

const LS_KEY = `scanner.position.${window.innerWidth}.${window.innerHeight}`;

export type CameraReaderToggleOptions = {
  icon?: string;
  location?: ContainerLocation;
  title?: string;
};

function read(stream: MediaStream) {
  return import('./qr-reader').then(({ default: codeReader }) => {
    return codeReader.decodeOnceFromStream(stream);
  });
}

function stopRead() {
  return import('./qr-reader').then(({ default: codeReader }) => {
    return codeReader.stopAsyncDecode();
  });
}

export default class CameraReader {
  private isShowingAutoScan: boolean = false;
  private isReading: boolean = false;
  private isSetupDone = false;
  private $toggleAutoScan: JQuery<HTMLButtonElement>;
  private $videoContainer: JQuery<HTMLVideoElement> = $(
    '<div class="camera-reader"><div class="qr-video-faded"/><video/></div>',
  );

  private autoScannerShowedOnce = false;

  constructor(
    private onRead: (result: string, cameraReader: CameraReader) => void,
    private $targetInput?: JQuery<HTMLInputElement>,
    {
      icon = 'camera',
      location = { right: '0', top: '100px' },
      title = 'Toggle camera',
    }: CameraReaderToggleOptions = {},
  ) {
    this.$toggleAutoScan = $(`
            <button class="floating-button btn btn-default" title="${title}">
                <i class="fa fa-${icon}" aria-hidden="true"/>
            </button>
        `);
    this.initialization(location);
  }

  private static async getStream() {
    try {
      return await getFacingBackCameraStream();
    } catch (e) {
      return null;
    }
  }

  private async setup() {
    const stream = await CameraReader.getStream();

    if (!stream) {
      // eslint-disable-next-line no-console
      console.warn('Unable to get camera media stream');
      this.$toggleAutoScan.remove();
      return;
    }

    const videoElement = this.$videoContainer.find('video').get(0);
    this.$videoContainer.appendTo(document.body);

    if (!this.isShowingAutoScan) {
      this.$videoContainer.hide();
    }

    videoElement.srcObject = stream;
    videoElement.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
    videoElement.play();

    this.$toggleAutoScan.on('click', () =>
      this.isShowingAutoScan ? this.hideAutoScanner() : this.showAutoScanner(),
    );

    this.showAutoScanner();
    this.$targetInput?.blur();
    this.setSizes();
    this.setVideoCoordinates();
    this.handleResize();

    this.isSetupDone = true;
  }

  static getSavedCoordinates(): { x: number; y: number } | null {
    const savedJSON = localStorage.getItem(LS_KEY);
    if (!savedJSON || !savedJSON.length) {
      return null;
    }

    try {
      return JSON.parse(savedJSON);
    } catch (e) {
      return null;
    }
  }

  static setSavedCoordinates({ x, y }) {
    localStorage.setItem(LS_KEY, JSON.stringify({ x, y }));
  }

  private setVideoCoordinates() {
    const { x, y } = CameraReader.getSavedCoordinates() || {
      x: (window.innerWidth - this.$videoContainer.width()) / 2,
      y: window.innerHeight * 0.18,
    };

    this.$videoContainer.css('left', `${x}px`);
    this.$videoContainer.css('top', `${y}px`);

    makeMovable({
      contain: true,
      element: this.$videoContainer.get(0),
      keepSize: true,
      onMoveEnd: CameraReader.setSavedCoordinates,
    });
  }

  private initialization(location: ContainerLocation) {
    this.$toggleAutoScan.one('click', () => this.setup());
    addButton(this.$toggleAutoScan, location);
  }

  private showAutoScanner() {
    if (this.isShowingAutoScan) {
      return;
    }
    this.autoScannerShowedOnce = true;
    this.isShowingAutoScan = true;
    this.$toggleAutoScan.removeClass('btn-default');
    this.$toggleAutoScan.addClass('btn-success');
    this.$videoContainer.show();
    this.tryRead();
  }

  private async hideAutoScanner() {
    if (!this.isShowingAutoScan) {
      return;
    }
    this.isShowingAutoScan = false;
    await stopRead();
    this.isReading = false;
    this.$videoContainer.hide();
    this.$toggleAutoScan.addClass('btn-default');
    this.$toggleAutoScan.removeClass('btn-success');
  }

  private handleResize() {
    const prevIsPortrait = isPortrait();
    window.addEventListener('resize', () => {
      const newIsPortrait = isPortrait();
      if (
        (prevIsPortrait && newIsPortrait) ||
        (!prevIsPortrait && !newIsPortrait)
      ) {
        return;
      }
      const quantifier = this.$targetInput?.is(':focus') ? 2 : 1;
      this.setSizes(quantifier);
    });
  }

  private setSizes(quantifier = 1) {
    if (isPortrait()) {
      this.$videoContainer.css('max-height', `${65 * quantifier}vh`);
      this.$videoContainer.css('width', 'calc((65vw / 4) * 3)');
    } else {
      this.$videoContainer.css('max-height', `${65 * quantifier}vh`);
      this.$videoContainer.css('width', 'calc((50vw / 3) * 4)');
    }
  }

  get isShowedOnce() {
    return this.autoScannerShowedOnce;
  }

  async tryRead() {
    if (!this.isShowingAutoScan || this.isReading) {
      return;
    }

    try {
      this.isReading = true;
      const result = await read(await CameraReader.getStream());
      this.isReading = false;

      if (!this.isShowingAutoScan) {
        return;
      }

      const resultText = result.getText();

      this.onRead(resultText, this);
    } catch (e) {}
  }

  openCamera(): void {
    if (this.isSetupDone) {
      this.showAutoScanner();
    } else {
      this.setup();
    }
  }

  closeCamera(): void {
    if (this.isSetupDone) {
      this.hideAutoScanner();
    }
  }
}
