import { MultiToggle } from '@/react-app/shared/components';
import {
  faBrush,
  faMapPin,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaDialogContext } from '../../context';
import { TopControls } from '../../controls';
import { DrawingTool, Mode } from '../../types';

const toggleOptions = [
  { icon: <FontAwesomeIcon icon={faBrush} />, id: DrawingTool.Brush },
  { icon: <FontAwesomeIcon icon={faMapPin} />, id: DrawingTool.Pin },
];
function DrawingControls() {
  const { drawing, mode } = useMediaDialogContext();

  const { onDrawCancel, onDrawSave, setTool, tool } = drawing;

  if (mode !== Mode.Drawing) {
    return null;
  }

  return (
    <TopControls>
      <button
        className="btn btn-success tw-text-base"
        type="button"
        onClick={() => onDrawSave()}
      >
        <FontAwesomeIcon icon={faSave} />
      </button>
      <MultiToggle options={toggleOptions} value={tool} onSelect={setTool} />
      <button
        className="btn btn-danger tw-text-base"
        type="button"
        onClick={() => onDrawCancel()}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </TopControls>
  );
}

export { DrawingControls };
