/**
 * Хак для ситуации, когда открыли несколько бутстраповых модалок одновременно.
 * Когда кликаешь по бэкдропу первый раз, закрывает одну модалку. С помощью этого хака,
 * клик по оставшемуся бэкдропу уберет его.
 */
$(document.body).on('click', '.modal-backdrop.fade.in', function () {
  $('.modal.fade.in').each(function () {
    $(this).modal('hide');
  });
});
