const globalErrorHandler: OnErrorEventHandler = (event: ErrorEvent) => {
  if (typeof event === 'string') {
    return;
  }

  const stack = event.error.stack;
  const page = window.location.href;
  const message = event.message;
  const date = new Date();

  const res = {
    date,
    message,
    page,
    stack,
  };

  fetch('/api/v2/js_errors', {
    body: JSON.stringify(res),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

export { globalErrorHandler };
