import type { CreateRefreshIncomingDataParams } from './create';
import { createRefreshIncomingData } from './create';
import Visibility from 'visibilityjs';

type Params = CreateRefreshIncomingDataParams & {
  interval: number;
  isEnabled?: boolean;
  isTitleChangeEnabled?: boolean;
};

function initRefreshIncomingData({
  interval,
  isEnabled,
  isTitleChangeEnabled,
  ...createParams
}: Params) {
  const refreshIncomingData = createRefreshIncomingData(
    createParams,
    isTitleChangeEnabled,
  );
  window.refreshIncomingData = refreshIncomingData;

  if (isEnabled) {
    $(function () {
      refreshIncomingData();
      window.refreshId = Visibility.every(
        interval,
        5 * 60 * 1000,
        refreshIncomingData,
      );
    });
  }
}

export { initRefreshIncomingData };
