import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cn } from '@/utils';
import { forwardRef } from 'react';

const Toggle = forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root>
>(({ className, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(
      'tw-group/toggle tw-relative tw-h-6 tw-w-[2.25rem] tw-rounded-full tw-border tw-border-border-default tw-bg-foreground-primary-subtle tw-transition-colors tw-duration-500 enabled:tw-cursor-pointer enabled:tw-border-border-primary enabled:tw-border-opacity-60 enabled:hover:tw-opacity-90 disabled:tw-cursor-not-allowed disabled:tw-bg-foreground-default-3 data-[state=on]:tw-bg-foreground-primary data-[state=on]:enabled:tw-bg-foreground-primary-2',
      className,
    )}
    {...props}
  >
    <div className="tw-absolute tw-left-1 tw-top-1/2 tw-size-4 -tw-translate-y-1/2 tw-rounded-full tw-bg-foreground-default-0 tw-transition-all tw-duration-300 group-disabled/toggle:tw-bg-foreground-default group-[[data-state='on']]/toggle:tw-left-[.875rem]" />
  </TogglePrimitive.Root>
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle };
