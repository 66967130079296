const lightClass = 'tw-light';
const darkClass = 'tw-dark';

const lsKey = 'didah-theme';

function setSystemTheme() {
  const isSystemDark = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;

  if (isSystemDark) {
    document.documentElement.classList.add(darkClass);
  } else {
    document.documentElement.classList.add(lightClass);
  }
}

// добавляем класс чтобы классы типа dark:tw-bg-foreground-default-dark работали
function setSystemThemeIfApplicable() {
  if (
    document.documentElement.classList.contains(lightClass) ||
    document.documentElement.classList.contains(darkClass) ||
    document.body.getAttribute('mode') === 'print'
  ) {
    return;
  }

  setSystemTheme();
}

function saveThemeToLocalStorage() {
  localStorage.setItem(
    lsKey,
    document.documentElement.classList.contains(darkClass) ? 'dark' : 'light'
  );
}

function setThemeFromLocalStorage() {
  const savedTheme = localStorage.getItem(lsKey);

  if (savedTheme === 'dark') {
    document.documentElement.classList.add(darkClass);
  } else if (savedTheme === 'light') {
    document.documentElement.classList.add(lightClass);
  } else {
    setSystemTheme();
  }
}

type DarkThemeParams = {
  isLoggedIn: boolean;
};

function darkTheme({ isLoggedIn }: DarkThemeParams) {
  if (isLoggedIn) {
    setSystemThemeIfApplicable();
    saveThemeToLocalStorage();
  } else {
    setThemeFromLocalStorage();
  }
}

export { darkTheme };
