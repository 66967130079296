import { openDidahBox } from '@/common';

import 'clipboard';
import 'jszip';
import 'lscache';
import 'jquery';
import 'lodash';
import 'moment';
import 'dragula';
import 'selectize';
import 'visibilityjs';
import 'mobile-detect';
import 'json-form-data';

window.openDidahBox = openDidahBox;
