import { DialogContext } from './context';
import type { PropsWithChildren } from 'react';
import { useStandaloneModalWindow } from './use-standalone-modal-window';

type Props = PropsWithChildren;

const DialogApp = ({ children }: Props) => {
  const dialog = useStandaloneModalWindow();
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
};

export { DialogApp };
