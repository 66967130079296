import { cn } from '@/utils';
import type { ComponentProps } from 'react';
import type { ClassNamesConfig, GroupBase } from 'react-select';
import Select from 'react-select';
import type { AsyncCreatableProps } from 'react-select/async-creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import type { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import type { StateManagerProps as SearchSelectProps } from 'react-select/dist/declarations/src/stateManager';

type ClassNames = ComponentProps<typeof Select>['classNames'];

type SharedProps = {
  ignoreSizes?: boolean;
};

function mergeClassNames(
  defaultClassNames: ClassNames,
  classNames?: ClassNames,
) {
  if (!classNames) {
    return defaultClassNames;
  }

  const mergedClassNames = Object.fromEntries(
    Object.entries(classNames).map(([key, value]) => {
      return [
        key,
        (...args: any) => {
          const defaultClassNamesResult =
            defaultClassNames[key]?.(...args) ?? '';

          const classNamesResult =
            typeof value === 'function'
              ? // @ts-expect-error args is not a tuple
                value(...args)
              : value;
          return cn(defaultClassNamesResult, classNamesResult);
        },
      ];
    }),
  );

  return {
    ...defaultClassNames,
    ...mergedClassNames,
  };
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const getClassNames = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  classNames: ClassNamesConfig<Option, IsMulti, Group>,
) =>
  mergeClassNames(
    {
      clearIndicator: () => '!tw-p-0',
      container: () => '!tw-w-full',
      control: (state) => {
        return cn(
          'form-control !tw-h-auto !tw-min-h-[var(--form-controls-height)] !tw-p-0',
          {
            'focus !tw-border-[#66afe9]': state.isFocused,
          },
        );
      },
      dropdownIndicator: () => '!tw-p-0',
      indicatorSeparator: () => 'tw-hidden',
      menu: () => 'dark:!tw-bg-foreground-default-dark',
      multiValue: () =>
        '!tw-text-inherit !tw-m-0 !tw-max-h-[1rem] tw-items-center tw-py-1 tw-pr-0 tw-pl-1 tw-rounded-md tw-text tw-text-base',
      multiValueLabel: () => '!tw-p-0',
      multiValueRemove: () =>
        '!tw-text-text-default hover:!tw-bg-foreground-default-2 [&_svg]:tw-size-[1rem]',
      option: (state) =>
        cn({
          '!tw-bg-foreground-primary-subtle dark:!tw-bg-foreground-primary-4-dark':
            state.isFocused,
          '!tw-bg-transparent': !state.isSelected && !state.isFocused,
          '!tw-text-text-default': state.isFocused,
          'hover:!tw-bg-foreground-primary-subtle dark:hover:!tw-bg-foreground-primary-4-dark':
            !state.isSelected,
        }),
      singleValue: () => '!tw-text-inherit',
      valueContainer: ({ isMulti }) =>
        cn(
          '[&_[data-value]]:tw-m-0 [&_[data-value]]:tw-p-0 [&_[data-value]]:tw-text-inherit',
          {
            '!tw-py-0': !isMulti,
            'tw-gap-x-1 tw-gap-y-0.5 !tw-py-0.5': isMulti,
          },
        ),
    },
    classNames,
  );

// eslint-disable-next-line @typescript-eslint/comma-dangle
const filterOption = <Option = unknown,>(
  option: FilterOptionOption<Option>,
  inputValue: string,
) => {
  try {
    const labelLowerCase = option.label.toLowerCase();

    const inputLowercaseParts = inputValue.toLowerCase().split(' ');
    const regex = new RegExp(`${inputLowercaseParts.join('.*')}`);
    const regex2 = new RegExp(`${inputLowercaseParts.reverse().join('.*')}`);
    return regex.test(labelLowerCase) || regex2.test(labelLowerCase);
  } catch (e) {
    return true;
  }
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
function SearchSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  classNames,
  ignoreSizes,
  ...restProps
}: SearchSelectProps<Option, IsMulti, Group> & SharedProps) {
  return (
    <Select
      className={cn(
        {
          'ignore-sizes': ignoreSizes,
        },
        className,
      )}
      classNames={getClassNames(classNames)}
      filterOption={filterOption}
      {...restProps}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
function SearchSelectAsyncCreatable<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  classNames,
  ignoreSizes,
  ...restProps
}: AsyncCreatableProps<Option, IsMulti, Group> & SharedProps) {
  return (
    <AsyncCreatableSelect
      className={cn(
        {
          'ignore-sizes': ignoreSizes,
        },
        className,
      )}
      classNames={getClassNames(classNames)}
      filterOption={filterOption}
      {...restProps}
    />
  );
}

export { SearchSelect, SearchSelectAsyncCreatable, type SearchSelectProps };
