// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-inner-declarations */
/* eslint-disable prefer-rest-params */

import $ from 'jquery';

/**
 * Alert groups functionality
 */
import { checkMobile, debounce, getCoords, pxStringToNumber } from '@/utils';

/**
 * Popups
 */

const POPUP_FADE_MS = 500;
let didah_popups = [];

function DidahPopup({ $anchor, maxheight, maxwidth = '150', msg, position }) {
  this.$anchor = $anchor;
  this.msg = msg;
  this.maxheight = maxheight;
  this.maxwidth = maxwidth;
  this.position = position || this.defaultPosition;
  this.hiddenByDefault = false;
}

DidahPopup.prototype.extraClasses = '';
DidahPopup.prototype.defaultPosition = checkMobile() ? 'bottom' : 'right';

DidahPopup.prototype.init = function () {
  this.draw();
  const self = this;

  const reposition = debounce(() => self.setPosition(), 200);
  $(window).resize(reposition);
  $(window).scroll(reposition);

  didah_popups.push(this);

  return this;
};

DidahPopup.prototype.hide = function () {
  this.$.fadeOut(POPUP_FADE_MS);
};

DidahPopup.prototype.show = function () {
  this.$.fadeIn(POPUP_FADE_MS);
};

DidahPopup.prototype.draw = function () {
  const $popup = $(`
                    	<div class="didah-popup ${this.extraClasses}">
                            <div class="didah-popup-container">
                                <div class="didah-popup-arrow"></div>
                                <div class="didah-popup-bubble">
                                <span class="didah-popup-msg">${this.msg}</span>
                                <span class="didah-popup-close">×</span>
                                </div>
                                <div class="didah-popup-bar"></div>
                            </div>
                        </div>
                    `);
  const $bubble = $popup.find('.didah-popup-bubble');
  this.$ = $popup;

  const self = this;
  $popup.find('.didah-popup-close').on('click', function () {
    self.hide();
  });

  if (this.maxheight) {
    $bubble.css('max-height', +this.maxheight);
  }

  if (this.maxwidth) {
    $bubble.css('max-width', +this.maxwidth);
  }

  $popup.addClass(this.position);
  if (this.hiddenByDefault) {
    $popup.hide();
  }
  $popup.appendTo($(document.body));

  this.setPosition();
};

DidahPopup.prototype.setPosition = function () {
  const coord = getCoords(this.$anchor.get(0));
  const $anchor = this.$anchor;
  const $popup = this.$;
  const position = this.position;
  if (position === 'right') {
    $popup.css(
      'top',
      coord.top + (coord.bottom - coord.top) / 2 - $popup.height() / 2,
    );
    $popup.css('left', coord.right + 17);
  } else if (position === 'left') {
    $popup.css(
      'top',
      coord.top + (coord.bottom - coord.top) / 2 - $popup.height() / 2,
    );
    $popup.css('right', $(document.body).width() - coord.left + 20);
  } else if (position === 'top') {
    $popup.css('top', coord.top - $popup.height() - 20);
    $popup.css('left', coord.left + $anchor.width() / 2 - $popup.width() / 2);
  } else if (position === 'bottom') {
    $popup.css('top', coord.bottom + 20);
    $popup.css('left', coord.left + $anchor.width() / 2 - $popup.width() / 2);
  } else if (position === 'top-right') {
    $popup.css('top', coord.top - $popup.height() - 15);
    $popup.css('left', coord.right + 4);
  } else if (position === 'bottom-right') {
    $popup.css('top', coord.bottom + 15);
    $popup.css('left', coord.right + 4);
  } else if (position === 'bottom-left') {
    $popup.css('top', coord.bottom + 15);
    $popup.css('right', $(document.body).width() - coord.left + 5);
  } else if (position === 'top-left') {
    $popup.css('top', coord.top - $popup.height() - 15);
    $popup.css('right', $(document.body).width() - coord.left + 5);
  }
};

DidahPopup.prototype.destroyPopup = function () {
  didah_popups = didah_popups.filter((dp) => dp !== this);
  this.hide();
  setTimeout(() => this.$.remove(), POPUP_FADE_MS);
};

/****************************/
function DidahClosablePopup(opts) {
  DidahPopup.apply(this, arguments);
  this.isClosable = !!opts.isClosable;
}

DidahClosablePopup.prototype = Object.create(DidahPopup.prototype);

DidahClosablePopup.prototype.init = function () {
  DidahPopup.prototype.init.apply(this, arguments);
  if (this.isClosable) {
    this.makeClosable();
  }

  return this;
};

DidahClosablePopup.prototype.makeClosable = function () {
  const self = this;

  function setClosedState($popup) {
    $popup.addClass('didah-popup-closed');
    $popup.find('.didah-popup-msg').text('...');
  }

  function setOpenedState($popup, text) {
    $popup.removeClass('didah-popup-closed');
    $popup.find('.didah-popup-msg').text(text);
  }

  if (this.$) {
    const $popup = this.$;
    const $bubble = $popup.find('.didah-popup-bubble');
    const position = this.position;
    const originalHeight = $bubble.height();
    const originalWidth = $bubble.width();
    const closedHeight = 13;
    const closedWidth = 9;

    const originalTop = pxStringToNumber($popup.css('top'));

    function getClosedTop() {
      if (position === 'right') {
        return originalTop + (originalHeight - closedHeight) / 2;
      } else if (position === 'left') {
        return originalTop + (originalHeight - closedHeight) / 2;
      } else if (position === 'top') {
        return originalTop + (originalHeight - closedHeight);
      } else if (position === 'bottom') {
        return originalTop;
      } else if (position === 'top-right') {
        return originalTop + (originalHeight - closedHeight);
      } else if (position === 'bottom-right') {
        return originalTop;
      } else if (position === 'bottom-left') {
        return originalTop;
      } else if (position === 'top-left') {
        return originalTop + (originalHeight - closedHeight);
      }
    }

    const closedTop = getClosedTop();

    const originalLeft = pxStringToNumber($popup.css('left'));
    const closedLeft = originalLeft + (originalWidth - closedWidth) / 2;

    function open() {
      setOpenedState($popup, self.msg);
      $bubble.height(originalHeight);
      $popup.css('top', originalTop);
      if (position === 'top' || position === 'bottom') {
        $popup.css('left', originalLeft);
      }
    }

    function close() {
      setClosedState($popup);
      $bubble.height(closedHeight);
      $popup.css('top', closedTop);
      if (position === 'top' || position === 'bottom') {
        $popup.css('left', closedLeft);
      }
    }

    $popup.on('click', function (e) {
      if ($(e.target).hasClass('didah-popup-close')) return;

      if ($popup.hasClass('didah-popup-closed')) {
        open();
      } else {
        close();
      }
    });
  }
};

/****************************/

function DidahErroneosPopup(opts) {
  DidahClosablePopup.apply(this, arguments);
  this.$input = opts.$input || this.$anchor;
  this.closeOnDocumentClick = opts.closeOnDocumentClick !== false;
}

DidahErroneosPopup.prototype = Object.create(DidahClosablePopup.prototype);
DidahErroneosPopup.prototype.inputClass = '';
DidahErroneosPopup.prototype.init = function () {
  DidahClosablePopup.prototype.init.apply(this, arguments);
  this.$input.addClass(this.inputClass);
  return this;
};

function DidahErrorPopup() {
  DidahErroneosPopup.apply(this, arguments);
}

DidahErrorPopup.prototype = Object.create(DidahErroneosPopup.prototype);
DidahErrorPopup.prototype.extraClasses = 'input-error-popup';
DidahErrorPopup.prototype.inputClass = 'input-with-error';

function DidahWarningPopup() {
  DidahErroneosPopup.apply(this, arguments);
}

DidahWarningPopup.prototype = Object.create(DidahErroneosPopup.prototype);
DidahWarningPopup.prototype.extraClasses = 'input-warning-popup';
DidahWarningPopup.prototype.inputClass = 'input-with-warning';

/****************************/

function DidahInfoPopup({ $qIcon }) {
  DidahPopup.apply(this, arguments);
  this.extraClasses = 'info-popup';
  this.hiddenByDefault = true;
  this.$qIcon = $qIcon;
}

DidahInfoPopup.prototype = Object.create(DidahPopup.prototype);
DidahInfoPopup.prototype.defaultPosition = 'bottom';

DidahInfoPopup.prototype.hide = function () {
  DidahPopup.prototype.hide.apply(this, arguments);
  this.$qIcon.removeClass('question-popup-icon-active');
};

export { DidahErrorPopup, DidahInfoPopup, DidahWarningPopup };

/****************************/

$(function () {
  const $document = $(document);

  function initOnDocumentClickClosablePopups() {
    const closePopups = (e) => {
      const $target = $(e.target);
      if ($target.hasClass('didah-popup-close')) {
        const $popup = $target.closest('.didah-popup');
        const popup = didah_popups.find((popup) => popup.$ === $popup);
        if (popup) {
          popup.destroyPopup();
        }
      } else {
        didah_popups.forEach((popup) => {
          if (
            popup instanceof DidahErroneosPopup &&
            (popup as any).closeOnDocumentClick
          ) {
            popup.destroyPopup();
          }
        });
        $document.off('click', closePopups);
      }
    };
    $document.on('click', closePopups);
  }

  function initQuestionEvents() {
    $document.on('click', function (e) {
      const $target = $(e.target);
      if (
        $target.hasClass('question-popup-icon') ||
        $target.closest('.question-popup-icon').length ||
        $target.hasClass('info-popup') ||
        $target.closest('.info-popup').length
      ) {
        e.stopPropagation();
      } else {
        didah_popups.forEach(function (item) {
          if (item instanceof DidahInfoPopup) item.hide();
        });
      }
    });
  }

  function initQuestionPopup() {
    const self = this;
    const $qIcon = $(self);
    const findField = (tag) => $qIcon.closest('.form-group').find(tag);

    function getAnchor() {
      if (findField('input').length) return findField('input').get(0);
      else if (findField('select').length) return findField('select').get(0);
      else if (findField('textarea').length)
        return findField('textarea').get(0);
      else if (findField('.other-question-popup-anchor').length)
        return findField('.other-question-popup-anchor').get(0);
      else return self;
    }

    const popup = (
      new DidahInfoPopup({
        $qIcon,
        anchor: getAnchor(),
        msg: $qIcon.data('msg'),
      }) as any
    ).init();

    $qIcon.on('click', function (e) {
      $qIcon.toggleClass('question-popup-icon-active');
      if ($qIcon.hasClass('question-popup-icon-active')) popup.show();
      else popup.hide();
      e.preventDefault();
    });
    return popup;
  }

  function initExistingQuestionPopups() {
    $('.question-popup-icon').each(initQuestionPopup);
  }

  initQuestionEvents();
  initExistingQuestionPopups();
  initOnDocumentClickClosablePopups();
});

const createPopup = (constructor: () => any) => (data: any) =>
  (new constructor(data) as any).init();
const createErrorPopup = createPopup(DidahErrorPopup);
const createInfoPopup = createPopup(DidahInfoPopup);
const createWarningPopup = createPopup(DidahWarningPopup);

export { createErrorPopup, createInfoPopup, createWarningPopup };
