import $ from 'jquery';
import './style.scss';
import { asyncPause, cn } from '@/utils';

enum NotificationType {
  Danger = 'danger',
  Default = 'default',
  Info = 'info',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
}

type Options = {
  displayTime?: number;
  text?: string;
  title: string;
  type: NotificationType;
};

async function handleLifeCycle($win: JQuery, displayTime: number) {
  await asyncPause(10);

  $win.addClass('opened');
  await asyncPause(displayTime);

  $win.removeClass('opened');
  await asyncPause(250);

  $win.remove();
}

function getBgClass(type: NotificationType) {
  switch (type) {
    case NotificationType.Danger: {
      return 'bg-danger';
    }
    case NotificationType.Info: {
      return 'bg-info';
    }
    case NotificationType.Primary: {
      return 'bg-primary';
    }
    case NotificationType.Success: {
      return 'bg-success';
    }
    case NotificationType.Warning: {
      return 'bg-warning';
    }
    case NotificationType.Default: {
      return 'bg-default';
    }
  }
}

function checkContainerExists() {
  let $container = $('#notifications-container');
  if ($container.length) {
    return $container;
  }

  $container = $(
    '<div id="notifications-container" class="tw-px-3 tw-fixed tw-top-[200px] md:tw-top-[120px] tw-right-0 tw-z-[1051]"></div>',
  );
  $container.appendTo('body');

  return $container;
}

function showNotification({
  displayTime = 4000,
  text = '',
  title,
  type = NotificationType.Default,
}: Options) {
  const $win = $(
    `<div class="${cn(
      'sliding-notification tw-mb-4 tw-w-[400px] tw-max-w-[80vw] tw-p-3',
      getBgClass(type),
    )}">
            <h3 class="tw-mt-0 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-lg">
                ${title}
            </h3>
            <div class="tw-text-xs tw-text-text-default-2 dark:tw-text-text-default-2-dark empty:tw-hidden">
                ${text}
            </div>
        </div>`,
  );

  const $container = checkContainerExists();

  $win.appendTo($container);
  handleLifeCycle($win, displayTime);
  return $win;
}

export { NotificationType, showNotification };
