import type { PropsWithChildren } from 'react';

import { useModalShow } from '../shared';
import { ModalInner } from './ModalInner';
import './style.scss';
import { ReactPortal } from '../portal';

type Props = PropsWithChildren<{
  className?: string;
  isPortal?: boolean;
  isShown?: boolean;
}>;

function Modal({ isPortal, ...props }: Props) {
  const debouncedIsShown = useModalShow(props.isShown);
  if (!debouncedIsShown) return null;

  const inner = <ModalInner {...props} />;
  return isPortal ? <ReactPortal>{inner}</ReactPortal> : inner;
}

export { Modal };
