import { translate } from '@/common';
import { getFacingBackCameraStream } from '@/utils';
import {
  faCamera,
  faSpinner,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  onCaptured: (blob: Blob) => void;
};

function PhotoImage({ onCaptured }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    data: stream,
    isError,
    isLoading,
  } = useQuery({
    queryFn: getFacingBackCameraStream,
    queryKey: ['getFacingBackCameraStream'],
  });

  const [isFlashing, setIsFlashing] = useState(false);

  const flash = useCallback(() => {
    setIsFlashing(true);
    setTimeout(() => {
      setIsFlashing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!stream) {
      return;
    }

    const video = videoRef.current;
    video.srcObject = stream;
    video.play();
  }, [stream]);

  const onCapture = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    flash();
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const context = canvas.getContext('2d');
    context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }

      onCaptured(blob);
    });
  }, [flash, onCaptured]);

  if (isLoading) {
    return (
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-white">
        <FontAwesomeIcon
          className="tw-animate-spin tw-text-4xl"
          icon={faSpinner}
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-white">
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-5">
          <FontAwesomeIcon className="tw-text-4xl" icon={faWarning} />
          <p className="tw-text-center tw-text-lg">
            {translate('documents_viewer.missing_camera_permission')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
        <div className="tw-relative tw-w-full tw-overflow-hidden tw-rounded-lg">
          <video
            ref={videoRef}
            autoPlay
            className="tw-block tw-w-full"
            controls={false}
            id="player"
            playsInline
          />
          {isFlashing && (
            <div className="tw-absolute tw-inset-0 tw-animate-photo-flash tw-bg-white tw-opacity-50" />
          )}
        </div>
      </div>
      <div className="tw-absolute tw-bottom-8 tw-left-1/2 tw-z-10 tw-flex -tw-translate-x-1/2 tw-gap-4 md:tw-bottom-auto md:tw-left-auto md:tw-right-2 md:tw-top-1/2 md:-tw-translate-y-1/2 md:tw-translate-x-0">
        <button
          className="btn btn-default tw-relative !tw-inline-flex tw-size-14 tw-items-center tw-justify-center !tw-rounded-full !tw-border-black md:tw-size-20"
          type="button"
          onClick={onCapture}
        >
          <div className="tw-absolute tw-inset-1 tw-rounded-full tw-border tw-border-black" />
          <FontAwesomeIcon
            className="tw-text-lg tw-text-text-default-2 dark:tw-text-text-default-2-dark md:tw-text-2xl"
            icon={faCamera}
          />
        </button>
      </div>
    </>
  );
}

export { PhotoImage };
