import $ from 'jquery';

type AddToggleEllipsisButtonParams = {
  datatable: any;
  renderBtn: (btn: JQuery) => void;
};

const LS_KEY = 'DataTables_show_all_popups';

let isShowingAll = localStorage.getItem(LS_KEY) === 'true';

function updateLocalStorage() {
  localStorage.setItem(LS_KEY, isShowingAll + '');
}

function addButtonClasses($btn: JQuery) {
  if (isShowingAll) {
    $btn.removeClass('btn-default');
    $btn.addClass('btn-success');
  } else {
    $btn.addClass('btn-default');
    $btn.removeClass('btn-success');
  }
}

function update(datatable: any) {
  const $container = $(datatable.table().container());
  if (isShowingAll) {
    $container.removeClass('dataTables_wrapper-nowrap');
  } else {
    $container.addClass('dataTables_wrapper-nowrap');
  }
}

function reload(datatable: any) {
  datatable.ajax.reload();
  datatable.fixedColumns().update();
}

function addToggleEllipsisButton({
  datatable,
  renderBtn,
}: AddToggleEllipsisButtonParams) {
  const $button = $(`
        <button type="button" class="btn btn-default">
            <i class="fa fa-commenting-o" aria-hidden="true"></i>
        </button>`);

  const updateButton = () => addButtonClasses($button);
  updateButton();
  update(datatable);
  $button.on('click', () => {
    isShowingAll = !isShowingAll;
    updateButton();
    updateLocalStorage();

    update(datatable);
    reload(datatable);
  });

  renderBtn($button);
}

function getIsEllipsis() {
  return isShowingAll;
}

export { addToggleEllipsisButton, getIsEllipsis };
