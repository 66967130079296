import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';

type Params = {
  target: RefObject<HTMLElement>;
};

const usePreventTouch = ({ target }: Params) => {
  const prevent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (e.target === target && e.touches.length === 1) {
        e.preventDefault();
      }
    },
    [target]
  );

  useEffect(() => {
    document.body.addEventListener('touchstart', prevent, { passive: false });
    document.body.addEventListener('touchend', prevent, { passive: false });
    document.body.addEventListener('touchmove', prevent, { passive: false });
    return () => {
      document.body.removeEventListener('touchstart', prevent);
      document.body.removeEventListener('touchend', prevent);
      document.body.removeEventListener('touchmove', prevent);
    };
  }, [prevent]);
};

export { usePreventTouch };
