import $ from 'jquery';
import dragula from 'dragula';

function getRowId(el) {
  return $(el).data('row-id');
}

let isLaunched = false;

export default function setRowDragSort({ id, url }) {
  if (isLaunched) {
    return;
  }
  isLaunched = true;

  $('.line-drag-sort-table').each(function () {
    const $table = $(this);

    const $tbody = $table.find('.line-drag-sort-table-tbody');
    const tbody = $tbody.get(0);
    dragula([tbody], {
      moves: (el, source, handle) => {
        const $el = $(el);
        const $handle = $(handle);
        return (
          $el.hasClass('line-drag-sort-table-row') &&
          $handle.hasClass('line-drag-sort-table-handle')
        );
      },
      revertOnSpill: true,
    })
      .on('drop', (el, target) => {
        const orderNum = $(target)
          .children('.line-drag-sort-table-row')
          .index($('.gu-transit'));
        const data = {
          fsess: getRowId(el),
          id,
          orderNum,
        };

        $.ajax({
          data,
          dataType: 'json',
          method: 'POST',
          timeout: 10000,
          url,
        })
          .done(() => {
            location.reload();
          })
          .fail(() => {
            location.reload();
          });
      })
      .on('cloned', (clone) => {
        const tds = $tbody.children('tr:first-child').children('td');
        const widths = [];
        for (let i = 0; i < tds.length; i++) {
          widths.push($(tds[i]).width());
        }

        const cloneTds = $(clone).children('td');
        for (let i = 0; i < cloneTds.length; i++) {
          $(cloneTds[i]).width(widths[i]);
        }
      });
  });
}
