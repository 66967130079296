import type { HookAction, IFrameWindowHandle } from '@/react-app/iframe/types';
import { openIFrameWindow } from '@/react-app/iframe';
import type { OpenIFrameMessage } from '../types';
import { MessageTypes } from '../types';

import config from '../config';
import { dispatch } from '../hook-dispatcher';
import { getAndClearStoredActions, windows } from '../storage';
import {
  createClosedMessage,
  createOpenMessage,
  windowKillExecutor,
} from '../../window-stack-manager';
import { closeTopChildFromRoot } from '../iframe-closing';
import { postRestoredMessage } from './message-creators';
import { removeLoadingOverlay } from '@/utils';

const DESKTOP_FADE_IN_OUT_ANIMATION_DURATION = 300;

async function handleOpeningMessage({ data, source }: MessageEvent) {
  const { payload, type } = data as OpenIFrameMessage;
  if (type !== MessageTypes.OpenIframe) {
    return;
  }

  const { onClosed = [] as HookAction[], onOpened, url } = payload;

  const previousWindow: Optional<IFrameWindowHandle> = windows.slice(-1)[0];
  const restorePrevious = () => previousWindow?.fadeIn();

  const id = crypto.randomUUID();
  const newWindow = await openIFrameWindow({
    backArrow: config.get()?.backArrow,
    level: windows.length + 1,
    minimize: !!config.get().skippableCommands,
    onClosed: (
      frameId: string,
      params: { actions?: HookAction[]; doSkipActions?: boolean } = {},
    ) => {
      const { actions = [] as HookAction[], doSkipActions } = params;
      // will remove this 'newWindow' from windows storage
      windows.pop();

      restorePrevious();
      const storedActions = getAndClearStoredActions(frameId) || [];

      let finalActions = [...onClosed, ...storedActions, ...actions];

      if (doSkipActions) {
        const skippableCommands = config.get()?.skippableCommands;
        if (skippableCommands === true) {
          finalActions = [];
        } else if (Array.isArray(skippableCommands)) {
          finalActions = finalActions.filter(
            (action) => !skippableCommands.includes(action.command),
          );
        }
      }

      dispatch(source as Window, finalActions);

      createClosedMessage(id, true);

      // delay чтобы вызов при spinner-on-leave успел отработать
      setTimeout(() => removeLoadingOverlay(), 0);
    },
    onOpened: () => {
      dispatch(source as Window, onOpened);

      //will hide previous window only after new window animation finishes
      // affects only desktop
      setTimeout(
        () => previousWindow?.fadeOut(),
        DESKTOP_FADE_IN_OUT_ANIMATION_DURATION * 0.7,
      );

      // стек окон
      createOpenMessage(id);
      windowKillExecutor.add(id, closeTopChildFromRoot);
    },
    onRestored: (iframe: Window) => {
      postRestoredMessage(iframe);
    },
    url,
  });

  windows.push(newWindow);
}

export { handleOpeningMessage };
