import cloneDeep from 'lodash/cloneDeep';

const createConfig = <T>(defaultConfig: T | null = null) => {
  let config: Readonly<T> | null = defaultConfig;
  return {
    get() {
      return config;
    },
    set(newConfig: T) {
      config = Object.freeze(cloneDeep(newConfig)) as Readonly<T>;
    },
  };
};

export default createConfig;
