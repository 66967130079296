const simpleDateFormat = 'DD.MM.YYYY HH:mm';
const simpleDateOnlyFormat = 'DD.MM.YYYY';
const simpleHoursMinutesFormat = 'HH:mm';
const REGEX_EMAIL =
  "([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
  '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';
const REGEX_PHONE_SIMPLE = /^[\d\s.-]{5,15}$/;
const REGEX_PHONE =
  /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?[\d\s.-]{5,15}$/;

export * from './app';
export {
  REGEX_EMAIL,
  REGEX_PHONE,
  REGEX_PHONE_SIMPLE,
  simpleDateFormat,
  simpleDateOnlyFormat,
  simpleHoursMinutesFormat,
};
