function b64toBlob(
  b64Data: string,
  contentType: string = '',
  sliceSize: number = 512
) {
  if (b64Data.startsWith('data:')) {
    b64Data = b64Data.substring(b64Data.indexOf(';base64,') + 8);
  }
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function blobToBase64(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
}

function fetchImageAsBase64(url, outputFormat = 'image/png') {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const self = this as HTMLImageElement;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = self.naturalHeight;
      canvas.width = self.naturalWidth;
      ctx.drawImage(self, 0, 0);
      const dataURL = canvas.toDataURL(outputFormat);
      resolve(dataURL);
    };
    img.src = url;
  });
}

function formatSize(length) {
  let i = 0;
  const type = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  while ((length / 1000) | 0 && i < type.length - 1) {
    length /= 1024;
    i++;
  }
  return length.toFixed(2) + ' ' + type[i];
}

function getBase64Extension(base64Data: string) {
  return base64Data.substring(
    'data:image/'.length,
    base64Data.indexOf(';base64')
  );
}

const isValidFileName = (function () {
  // eslint-disable-next-line
  const rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  const rg2 = /^\./; // cannot start with dot (.)
  const rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  return function (fname: string) {
    return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
  };
})();

function splitNameAndExtension(fileName: string) {
  const re = /(?:(.*)\.([^.]+))?$/;

  const res = re.exec(fileName);
  return [res[1], res[2]];
}

export {
  b64toBlob,
  blobToBase64,
  fetchImageAsBase64,
  formatSize,
  getBase64Extension,
  isValidFileName,
  splitNameAndExtension,
};
