import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaDialogContext } from '../../../context';

function StartDrawing() {
  const { canEdit, drawing } = useMediaDialogContext();

  const { onDraw } = drawing;

  if (!canEdit) {
    return null;
  }

  return (
    <button className="btn btn-primary" type="button" onClick={() => onDraw()}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );
}

export { StartDrawing };
