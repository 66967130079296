function copyToClipboard(str: string): void {
  console.log('copyToClipboard', str);
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export { copyToClipboard };
