import type { SignatureAjaxAppOpts } from '../../types';

function signatureAjax(
  selector: HTMLElement | string,
  params: SignatureAjaxAppOpts,
) {
  return import('./init').then(({ signatureAjax: fn }) => fn(selector, params));
}

export { signatureAjax };
