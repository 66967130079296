import type { DatatableFilterOption } from '@/react-app/datatable-filter-app';
import type {
  GroupingState,
  GroupingStateUpdatedHandler,
} from '../../table-groups-dragula';

type UpdateColumnSearchHandler = (
  key: string,
  values: DatatableFilterOption[]
) => void;

type AddColumnSearchHandler = (
  key: string,
  values: DatatableFilterOption[]
) => void;

type RemoveColumnSearchHandler = (key: string) => void;

type ColumnSearch = Dictionary<DatatableFilterOption[]>;

type TableSettings = {
  columnSearch: ColumnSearch;
  grouping: GroupingState;
  search: string;
};

type SaveSettingsParams = {
  initialSettings: TableSettings;
};

const setupSaveSettings = ({ initialSettings }: SaveSettingsParams) => {
  let currentSettings: TableSettings = {
    columnSearch: {},
    search: '',
    ...initialSettings,
  };

  const onAddColumnSearch: AddColumnSearchHandler = (key, values) => {
    currentSettings = {
      ...currentSettings,
      columnSearch: {
        ...currentSettings.columnSearch,
        [key]: values,
      },
    };
  };

  const onRemoveColumnSearch: RemoveColumnSearchHandler = (key) => {
    currentSettings = {
      ...currentSettings,
      columnSearch: Object.fromEntries(
        Object.entries(currentSettings.columnSearch).filter(([entryKey]) => {
          entryKey !== key;
        })
      ),
    };
  };

  const onUpdateColumnSearch: UpdateColumnSearchHandler = (key, values) => {
    if (values.length) {
      onAddColumnSearch(key, values);
    } else {
      onRemoveColumnSearch(key);
    }
  };

  const onUpdateSearch = (search: string) => {
    currentSettings.search = search;
  };

  const onUpdateGrouping: GroupingStateUpdatedHandler = (
    currentGroups,
    minimizedGroups
  ) => {
    currentSettings.grouping = {
      currentGroups,
      minimizedGroups,
    };
  };

  const getCurrentSettings = () => currentSettings;

  return {
    getCurrentSettings,
    onUpdateColumnSearch,
    onUpdateGrouping,
    onUpdateSearch,
  };
};

export { TableSettings, UpdateColumnSearchHandler, setupSaveSettings };
