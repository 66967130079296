import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEffect } from 'react';

const isMobile = checkMobile();

import { useMediaNavigation } from '../hooks';
import { KeyboardEventKey, checkMobile } from '@/utils';
import { InnerContainer } from './InnerContainer';
import { useMediaDialogContext } from '../context';

function NextPrev() {
  const { next, prev } = useMediaNavigation();
  const { allMedia, options } = useMediaDialogContext();

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === KeyboardEventKey.ArrowRight) {
        next();
      } else if (e.key === KeyboardEventKey.ArrowLeft) {
        prev();
      }
    };

    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [next, prev]);

  if (isMobile || options?.mode === 'full' || allMedia.files.length <= 1) {
    return null;
  }

  return (
    <>
      <InnerContainer variant="left" onClick={() => prev()}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </InnerContainer>
      <InnerContainer variant="right" onClick={() => next()}>
        <FontAwesomeIcon icon={faArrowRight} />
      </InnerContainer>
    </>
  );
}

export { NextPrev };
