import $ from 'jquery';

$(function () {
  function closeAlertGroup($group) {
    const $labels = $group.find('.alert:not(.alert-group-label)');
    $group.addClass('alert-group-closed');
    $labels.slideUp();
  }

  function openAlertGroup($group) {
    const $labels = $group.find('.alert:not(.alert-group-label)');
    $group.removeClass('alert-group-closed');
    $labels.slideDown();
  }

  // $('.alert-group-label').on('click', function () {
  $(document).on('click', '.alert-group-label', function () {
    const $group = $(this).closest('.alert-group');

    if ($group.hasClass('alert-group-closed')) {
      openAlertGroup($group);
    } else {
      closeAlertGroup($group);
    }
  });
});
