type Props = {
  name: string;
  values: string[];
};

const HiddenInputsWithSameName = ({ name, values }: Props) => {
  return (
    <>
      {values.map((value, i) => (
        <input key={i} name={name + '[]'} type="hidden" value={value} />
      ))}
    </>
  );
};

export { HiddenInputsWithSameName };
