import { useMediaDialogContext } from '../../context';
import {
  BottomControls,
  Close,
  Download,
  NextPrevButtons,
  TopControls,
} from '../../controls';
import { Mode } from '../../types';

function BasicControls() {
  const { mode, options } = useMediaDialogContext();

  if (mode !== Mode.Normal) {
    return null;
  }

  return (
    <>
      <TopControls>
        {options?.mode === 'full' && <NextPrevButtons />}
        <Download />
        {!options?.noClose && <Close />}
      </TopControls>
      <BottomControls>
        <NextPrevButtons className="tw-mx-auto" />
      </BottomControls>
    </>
  );
}

export { BasicControls };
