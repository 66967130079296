import { useCallback, useRef } from 'react';

import { useMediaDialogContext } from '../../context';
import { useExtractCanvas, useContainerScroll } from '../../hooks';

import { ImageControls } from './controls';
import { useSizes } from './use-sizes';
import { cn } from '@/utils';

function ImageViewer() {
  const { currentFile, zoom } = useMediaDialogContext();
  const { scale } = zoom;
  const url = currentFile?.url ?? '';

  const imageRef = useRef<HTMLImageElement>(null);

  const getContainer = useCallback(() => imageRef.current?.parentElement, []);

  const containerScroll = useContainerScroll({ getContainer });
  const { getSizes, scaledSizesPx } = useSizes({
    imageRef,
    scale,
    url,
  });

  const getCanvas = useCallback((sizes: { height: number; width: number }) => {
    const imgElement = imageRef.current;
    if (!imgElement) return null;

    const tempCanvas = document.createElement('canvas');

    tempCanvas.width = sizes.width;
    tempCanvas.height = sizes.height;
    const ctx = tempCanvas.getContext('2d');
    ctx?.drawImage(imgElement, 0, 0, tempCanvas.width, tempCanvas.height);

    return tempCanvas;
  }, []);

  const { display } = useExtractCanvas({
    containerScroll,
    getCanvas,
    getSizes,
  });

  return (
    <>
      <ImageControls />
      <div className="tw-max-h-[65vh] tw-max-w-full tw-overflow-auto md:tw-max-h-[80vh]">
        <img
          ref={imageRef}
          className={cn('tw-pointer-events-none', {
            'tw-hidden': !display,
            'tw-w-auto tw-max-w-none': scale !== 1,
          })}
          src={url}
          style={scaledSizesPx}
        />
      </div>
    </>
  );
}

export { ImageViewer };
