import { removeLoadingOverlay, setLoadingOverlay } from '@/utils';
import { logger } from '@/utils/logger';

// показывать спиннер по переходу на другую страницу
function spinnerOnLeave() {
  window.addEventListener('beforeunload', function () {
    if (!window.beforeUnloadCancelled) {
      logger.info('beforeUnloadCancelled. Calling setLoadingOverlay');
      const isInvoked = setLoadingOverlay({
        onlyIfFirstInvoke: true,
      });

      if (isInvoked) {
        const intervalId = setInterval(() => {
          if (window.beforeUnloadCancelled) {
            logger.info('beforeUnloadCancelled. Calling removeLoadingOverlay');
            removeLoadingOverlay();
            window.beforeUnloadCancelled = false;
            clearInterval(intervalId);
          }
        }, 1000);
      }
    }
  });
}

export { spinnerOnLeave };
