import { checkMobile } from '@/utils';
import { useMediaDialogContext } from '../context';

import { AudioViewer } from './audio';
import { IFrameViewer } from './iframe';
import { ImageViewer } from './image';
import { MinimalViewer } from './minimal';

const iframeTypes = ['pdf', 'text'];
const isMobile = checkMobile();

function RootViewer() {
  const { currentFile } = useMediaDialogContext();
  if (!currentFile) {
    return null;
  }

  if (
    currentFile.type === 'img' ||
    (!currentFile.type && currentFile?.id.toLowerCase().endsWith('.png')) ||
    (!currentFile.type && currentFile?.id.toLowerCase().endsWith('.jpeg')) ||
    (!currentFile.type && currentFile?.id.toLowerCase().endsWith('.jpg'))
  ) {
    return <ImageViewer />;
  }

  if (
    (iframeTypes.includes(currentFile.type) && !isMobile) ||
    currentFile.type === 'text'
  ) {
    return <IFrameViewer />;
  }

  if (
    currentFile.type === 'audio' ||
    currentFile.id?.toLowerCase().endsWith('.mpeg')
  ) {
    return <AudioViewer />;
  }

  return <MinimalViewer />;
}

export { RootViewer };
