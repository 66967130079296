import $ from 'jquery';
import { checkMobile } from '@/utils';
import { minimizePersistKey } from '@/constants';

type Params = {
  addFunction?: (toggleContainer: JQuery, $btn: JQuery) => void;
  isFlexContainer?: boolean;
  minimizable: JQuery;
  toggleContainer: JQuery;
};

const setMinimized = (newValue: boolean) =>
  localStorage.setItem(minimizePersistKey, newValue + '');

const isMinimized = () => localStorage.getItem(minimizePersistKey) !== 'false';

function createMinimizeButton() {
  const $btn = $(
    '<button class="btn btn-light toggle-minimize-btn" type="button"></button>'
  );
  const updateBtn = () => {
    const content = isMinimized()
      ? '<i class="fa fa-chevron-down fa-fw"/></a>'
      : '<i class="fa fa-chevron-up fa-fw"/></a>';
    $btn.html(content);
  };

  return {
    $btn,
    updateBtn,
  };
}

function minimizable({
  addFunction,
  isFlexContainer,
  minimizable,
  toggleContainer,
}: Params) {
  const isMobile = checkMobile();
  if (!isMobile) {
    return;
  }

  if (isMinimized()) {
    minimizable.addClass('minimized-active');
  }

  const { $btn, updateBtn } = createMinimizeButton();
  toggleContainer.addClass('toggle-minimize-container');
  toggleContainer.addClass(
    isFlexContainer
      ? 'toggle-minimize-container-flex'
      : 'toggle-minimize-container-block'
  );

  if (addFunction) {
    addFunction(toggleContainer, $btn);
  } else {
    toggleContainer.append($btn);
  }

  updateBtn();
  $btn.on('click', () => {
    minimizable.toggleClass('minimized-active');
    setMinimized(minimizable.hasClass('minimized-active'));

    updateBtn();
  });
}

export { minimizable };
