/* eslint-disable no-console */
import { createConsola } from 'consola/browser';

const consolaInstance = createConsola({
  level: 4, // debug logs
});

const logger = {
  error(...messages: string[]) {
    if (!process.env.DIDAH_TESTING_ENV) {
      return;
    }

    return consolaInstance.error(messages.join(' '));
  },
  info(...messages: string[]) {
    if (!process.env.DIDAH_TESTING_ENV) {
      return;
    }

    return consolaInstance.info(messages.join(' '));
  },
  success(...messages: string[]) {
    if (!process.env.DIDAH_TESTING_ENV) {
      return;
    }

    return consolaInstance.success(messages.join(' '));
  },

  warn(...messages: string[]) {
    if (!process.env.DIDAH_TESTING_ENV) {
      return;
    }

    return consolaInstance.warn(messages.join(' '));
  },
};

export { logger };
