import type { RefObject } from 'react';
import { createContext, useContext } from 'react';

import { noop } from 'lodash';

import type { AllMedia, MediaDialogFile, MediaDialogParams } from '../../types';

import type { CanvasData, CanvasForwardedMethods } from './types';
import { DrawingTool, Mode } from './types';

type MediaDialogContextType = {
  allMedia: AllMedia;
  canEdit: boolean;
  currentFile: Nullable<MediaDialogFile>;
  drawing: {
    onDraw: VoidFunction;
    onDrawCancel: VoidFunction;
    onDrawSave: VoidFunction;
    setTool: (tool: DrawingTool) => void;
    tool: DrawingTool;
  };
  drawingCanvasRef: Nullable<RefObject<CanvasForwardedMethods>>;
  image: {
    data: Nullable<CanvasData>;
    setCanvasImage: (data: CanvasData) => void;
  };
  mode: Mode;
  onClose: VoidFunction;
  onDownload: VoidFunction;
  updateMedia: MediaDialogParams['updateMedia'];
  zoom: {
    isZoomOutDisabled: boolean;
    onZoomIn: VoidFunction;
    onZoomOut: VoidFunction;
    scale: number;
  };
} & Pick<MediaDialogParams, 'options' | 'uploadFiles'>;

const MediaDialogContext = createContext<MediaDialogContextType>({
  allMedia: {
    activeFile: -1,
    files: [],
  },
  canEdit: true,
  currentFile: null,
  drawing: {
    onDraw: noop,
    onDrawCancel: noop,
    onDrawSave: noop,
    setTool: noop,
    tool: DrawingTool.Brush,
  },
  drawingCanvasRef: null,
  image: {
    data: null,
    setCanvasImage: noop,
  },
  mode: Mode.Normal,
  onClose: noop,
  onDownload: noop,
  updateMedia: noop,
  zoom: {
    isZoomOutDisabled: false,
    onZoomIn: noop,
    onZoomOut: noop,
    scale: 0,
  },
});

const useMediaDialogContext = () => useContext(MediaDialogContext);

export { MediaDialogContext, useMediaDialogContext };

export type { MediaDialogContextType };
