import $ from 'jquery';

import { didahH, QC } from './helpers';

function contentLanguage(divId, id, arrLanguage, img, unhideAll) {
  const arr = arrLanguage.split(',');
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] != img) {
      const GE = document.getElementById(divId + 'Val_' + arr[i]);
      const ME = document.getElementById(divId + 'Act_' + arr[i]);

      if (arr[i] == id) {
        GE.style.display = 'block';
        ME.className = 'AAC';
      } else {
        if (unhideAll) {
          GE.style.display = 'block';
          ME.className = 'AAC';
        } else {
          GE.style.display = 'none';
          ME.className = 'AAB';
        }
      }
    }
  }
}

function didahL(E: Element) {
  while (E.tagName != 'TR') {
    E = E.parentElement;
  }
  E.className = 'KKP';
}

function CA(VAL, noCH) {
  let chMa = 0;

  for (let i = 0; i < window.didah.elements.length; i++) {
    const e = window.didah.elements[i] as HTMLInputElement;
    if (
      e.name == e.value &&
      e.type == 'checkbox' &&
      VAL.length + 1 == e.name.length &&
      e.checked
    ) {
      chMa = chMa + Number(e.name.substr(VAL.length, 1));
    }
  }

  for (let i = 0; i < window.didah.elements.length; i++) {
    const e = window.didah.elements[i] as HTMLInputElement;
    if (e.type == 'checkbox' && e.name != 'notchecked') {
      if (
        e.name != e.value ||
        (e.name == e.value && VAL.length + 1 != e.name.length)
      ) {
        e.checked = eval('didah.' + VAL + '.checked');
        if (!noCH) {
          if (e.checked) {
            didahL(e);
          } else {
            didahH(e);
          }
        } else {
          //alert(e.value.substr(VAL.length+1,1));
          switch (chMa) {
            default:
              e.checked = false;
              didahH(e);
              break;
            case 1:
              if (e.value.substr(VAL.length + 1, 1) == '1') {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 2:
              if (e.value.substr(VAL.length + 1, 1) == '2') {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 4:
              if (e.value.substr(VAL.length + 1, 1) == '4') {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 3:
              if (
                e.value.substr(VAL.length + 1, 1) == '1' ||
                e.value.substr(VAL.length + 1, 1) == '2'
              ) {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 5:
              if (
                e.value.substr(VAL.length + 1, 1) == '1' ||
                e.value.substr(VAL.length + 1, 1) == '4'
              ) {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 6:
              if (
                e.value.substr(VAL.length + 1, 1) == '2' ||
                e.value.substr(VAL.length + 1, 1) == '4'
              ) {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
            case 7:
              if (
                e.value.substr(VAL.length + 1, 1) == '1' ||
                e.value.substr(VAL.length + 1, 1) == '2' ||
                e.value.substr(VAL.length + 1, 1) == '4'
              ) {
                e.checked = true;
                didahL(e);
              } else {
                e.checked = false;
                didahH(e);
              }
              break;
          }
        }
      }
    }
  }
}

function CCA(VAL, CB, noHL) {
  if (!noHL) {
    if (CB.checked) didahL(CB);
    else didahH(CB);
  }
  let TB = 0;
  let TO = 0;
  for (let i = 0; i < window.didah.elements.length; i++) {
    const e = window.didah.elements[i] as HTMLInputElement;
    if (e.name != e.value && e.type == 'checkbox' && e.name != 'notchecked') {
      TB++;
      if (e.checked) TO++;
    }
  }
  $("input[name='" + VAL + "']", $(window.didah)).prop('checked', TO == TB);
}

function getOffset(elem) {
  if (elem.getBoundingClientRect) {
    // 'правильный' вариант
    return getOffsetRect(elem);
  } else {
    // пусть работает хоть как-то
    return getOffsetSum(elem);
  }
}

function getOffsetSum(elem) {
  let top = 0,
    left = 0;
  while (elem) {
    top = top + parseInt(elem.offsetTop);
    left = left + parseInt(elem.offsetLeft);
    elem = elem.offsetParent;
  }

  return { left, top };
}

function getOffsetRect(elem) {
  // (1)
  const box = elem.getBoundingClientRect();

  // (2)
  const body = document.body;
  const docElem = document.documentElement;

  // (3)
  const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
  const scrollLeft =
    window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

  // (4)
  const clientTop = docElem.clientTop || body.clientTop || 0;
  const clientLeft = docElem.clientLeft || body.clientLeft || 0;

  // (5)
  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { left: Math.round(left), top: Math.round(top) };
}

function setPeriodDatePicker(dp1, dp1Date, dp2, dp2Date) {
  const dp1Ctrl = $(dp1).data('DateTimePicker'),
    dp2Ctrl = $(dp2).data('DateTimePicker');

  if (!dp1Ctrl || !dp2Ctrl) return;

  dp2Ctrl.minDate(false);
  dp2Ctrl.maxDate(false);
  dp1Ctrl.minDate(false);
  dp1Ctrl.maxDate(false);

  dp2Ctrl.date(dp2Date);
  dp1Ctrl.date(dp1Date);
}

const DoA = (fa, me, tp) => import('./doa').then(({ DoA }) => DoA(fa, me, tp));
const DoAction = (action, aNext, aBack, quest, confirmTxt, sn, VAL) =>
  import('./doa').then(({ DoAction }) =>
    DoAction(action, aNext, aBack, quest, confirmTxt, sn, VAL),
  );

export {
  CA,
  CCA,
  DoA,
  DoAction,
  QC,
  contentLanguage,
  getOffset,
  setPeriodDatePicker,
};
