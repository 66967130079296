import type { PasswordRecoveryInitFormOpts } from './types';

function passwordRecoveryInit(
  selector: string,
  params: PasswordRecoveryInitFormOpts,
) {
  return import('./init').then(({ passwordRecoveryInit: fn }) =>
    fn(selector, params),
  );
}

export { passwordRecoveryInit };
