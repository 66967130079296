import { noop } from 'lodash';

import type { MediaDialogParams } from '../../types';
import { MediaDialogModal } from './MediaDialogModal';
import { MediaDialogProvider } from './MediaDialogProvider';

const defaultActiveParams: MediaDialogParams = {
  closeActiveDialog: noop,
  isActive: false,
  media: {
    activeFile: -1,
    files: [],
  },
  updateMedia: noop,
};

function MediaDialog(activeParams: MediaDialogParams) {
  const params = activeParams ?? defaultActiveParams;

  return (
    <MediaDialogProvider activeParams={params}>
      <MediaDialogModal activeParams={params} />
    </MediaDialogProvider>
  );
}

export { MediaDialog };
