import { cn } from '@/utils';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  className?: string;
}>;

function GhostTile({ children, className }: Props) {
  return (
    <div
      className={cn(
        'tw-relative tw-h-20 tw-w-24 tw-animate-pulse tw-cursor-pointer tw-rounded-md tw-border tw-border-border-default tw-bg-foreground-default-2 tw-transition-all dark:tw-border-border-default-dark dark:tw-bg-foreground-default-4-dark',
        className
      )}
    >
      {children}
    </div>
  );
}

export { GhostTile };
