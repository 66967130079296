import type { HiddenControlsToggleParams } from './types';

function hiddenControlsToggle(
  selector: HTMLElement | string,
  params: HiddenControlsToggleParams,
) {
  return import('./init').then(({ hiddenControlsToggle: fn }) =>
    fn(selector, params),
  );
}

export { hiddenControlsToggle };
