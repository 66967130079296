import $ from 'jquery';

const setupPrint = ({ datatable, id }) => {
  $(`#Print${id}`)
    .removeAttr('onclick')
    .on('click', function () {
      datatable
        .button(
          (datatable.rows({ selected: true }).any()
            ? 'printselect'
            : 'printall') + ':name'
        )
        .node()
        .click();
      return false;
    });
};

export { setupPrint };
