import { cn } from '@/utils';
import { forwardRef, type InputHTMLAttributes, type ReactNode } from 'react';

import { Error } from './Error';
import { RequiredStar } from './RequiredStar';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  addon?: {
    icon: ReactNode;
    position?: 'before' | 'after';
    restProps?: React.HTMLAttributes<HTMLSpanElement>;
  };
  classNames?: {
    formGroup?: string;
  };
  errorMessage?: ReactNode;
  inlineLabel?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      addon,
      className,
      classNames,
      errorMessage,
      inlineLabel,
      isRequired,
      label,
      ...restProps
    },
    ref,
  ) => {
    const input = (
      <input
        ref={ref}
        className={cn('form-control', className)}
        {...restProps}
      />
    );

    let content = input;

    if (addon?.icon) {
      const addonElement = (
        <span
          className="input-group-addon tw-min-w-fit tw-flex-none"
          {...addon.restProps}
        >
          {addon.icon}
        </span>
      );

      const positionedElements =
        addon.position === 'after' ? (
          <>
            {input}
            {addonElement}
          </>
        ) : (
          <>
            {addonElement}
            {input}
          </>
        );

      content = (
        <div
          className={cn('input-group !tw-flex', { 'tw-flex-1': inlineLabel })}
        >
          {positionedElements}
        </div>
      );
    } else if (label) {
      content = (
        <>
          <label
            className={cn({
              'tw-mb-0 tw-mr-[6px] tw-whitespace-nowrap': inlineLabel,
            })}
            htmlFor={restProps.name}
          >
            {label}
            {isRequired && <RequiredStar />}
          </label>
          {input}
        </>
      );
    }

    return (
      <div
        className={cn(
          'form-group',
          {
            'tw-flex tw-items-center tw-justify-start': inlineLabel,
          },
          classNames?.formGroup,
        )}
      >
        {content}
        {errorMessage && <Error>{errorMessage}</Error>}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input, Props as InputProps };
