import type { MessageBoxOpts } from './types';

import type {
  UniqueExternalMessageOpts,
  UniqueInternalMessageOpts,
} from '../send-message/message-types';

function messageBox(
  opts: MessageBoxOpts,
  uniqueInternalOpts: UniqueInternalMessageOpts,
  uniqueExternalOpts: UniqueExternalMessageOpts,
) {
  return import('./init').then(({ messageBox: fn }) =>
    fn(opts, uniqueInternalOpts, uniqueExternalOpts),
  );
}

export { messageBox };
