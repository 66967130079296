import {
  MinMax,
  onLoadResizeIframe,
  setContainerHeightTillEndOfPage,
} from '@/utils';

function resizeMessages() {
  window.addEventListener('message', (e) => {
    const { data } = e;
    try {
      const obj = JSON.parse(data);
      if (obj?.type === 'resize') {
        const $el = $(`#${obj.data}`);
        if ($el.hasClass('full_screen')) {
          setContainerHeightTillEndOfPage({
            bottomMargin: -100,
            container: $el.get(0),
            minMax: MinMax.min,
          });
        } else {
          onLoadResizeIframe(obj.data, 10);
        }

        e.source?.postMessage(
          JSON.stringify({
            type: 'resize-done',
          })
        );
      }
    } catch (e) {
    }
  });
}

export { resizeMessages };
