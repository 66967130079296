import { createFilesFormData } from '@/utils';
import moment from 'moment';

function getFileExtensionFromMimeType(blob: Blob) {
  const mimeType = blob.type;
  const match = /audio\/([^;]+)/.exec(mimeType);

  return match?.[1] ?? null;
}

function formatMediaDate(date: Date) {
  return moment(date).format('yyyy-MM-dd HH-mm-ss');
}

function uploadAudioFile(blob: Blob, uploadFunc: (files: FormData) => void) {
  if (!blob) {
    return;
  }

  const now = new Date();
  const formattedDate = formatMediaDate(now);

  const filesFormData = createFilesFormData(
    [
      new File(
        [blob],
        `AudioRecord from ${formattedDate}.${getFileExtensionFromMimeType(
          blob
        )}`
      ),
    ],
    'files[]'
  );

  if (!filesFormData) {
    return;
  }

  uploadFunc(filesFormData);
}

export { uploadAudioFile };
