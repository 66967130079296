import $ from 'jquery';

export default function ($table) {
  $table.find('.scan-table__toggle').each(function () {
    const $toggle = $(this);
    $toggle.on('click', function () {
      const $header = $toggle.closest('.scan-table__header');
      const targetClass = $header
        .get(0)
        .className.split(/\s+/)
        .find((c) => c.startsWith('scan-table__group--'));
      if (!targetClass) {
        throw new Error('Group class not added');
      }

      $table
        .find(`.scan-table__row.${targetClass}`)
        .toggleClass('scan-table__row--visible');
    });
  });
}
