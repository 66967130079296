import { useEffect, useRef } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';

import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';

import {
  faCircle,
  faMicrophone,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { uploadAudioFile } from './utils';

type Props = {
  isLoading?: boolean;
  maxAudioLength: number;
  uploadFiles: (files: FormData) => void;
};

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;

function Recorder({ isLoading, maxAudioLength, uploadFiles }: Props) {
  const {
    isRecording,
    recordingBlob,
    recordingTime,
    startRecording,
    stopRecording,
  } = useAudioRecorder(
    {
      echoCancellation: true,
      noiseSuppression: true,
    },
    // eslint-disable-next-line no-console
    (err) => console.table(err)
  );
  const isNeedUpload = useRef(false);

  useEffect(() => {
    if (isRecording && recordingTime >= maxAudioLength) {
      isNeedUpload.current = true;
      stopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, recordingTime, maxAudioLength]);

  useEffect(() => {
    if (!recordingBlob) return;

    if (isNeedUpload.current) {
      uploadAudioFile(recordingBlob, uploadFiles);

      isNeedUpload.current = false;
    }
  }, [recordingBlob, uploadFiles]);

  useEffect(
    () => () => {
      isNeedUpload.current = false;

      // TODO запись всё равно остается
      stopRecording();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isRecording) {
    return (
      <button
        className="btn btn-default tw-relative !tw-flex tw-h-20 tw-w-24 tw-items-stretch !tw-rounded-md !tw-bg-foreground-info tw-transition-colors hover:!tw-bg-foreground-info-2 dark:!tw-bg-foreground-info-dark dark:hover:!tw-bg-foreground-info-2-dark"
        disabled={isLoading}
        title="Click to stop"
        type="button"
        onClick={(e) => {
          e.preventDefault();

          isNeedUpload.current = true;
          stopRecording();
        }}
      >
        <div className="tw-relative tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center">
          <FontAwesomeIcon className="tw-mx-auto tw-text-2xl" icon={faStop} />
          <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0">
            {`${moment(recordingTime * 1000)
              .format('mm:ss')
              .toString()} / ${moment(maxAudioLength * 1000)
              .format('mm:ss')
              .toString()}`}
          </div>
        </div>
        <FontAwesomeIcon
          className="tw-absolute tw-left-1 tw-top-1 tw-mx-auto tw-animate-pulse tw-text-sm tw-text-red-700"
          icon={faCircle}
        />
      </button>
    );
  }

  return (
    <button
      className="btn btn-default !tw-flex tw-h-20 tw-w-24 tw-items-center tw-justify-center !tw-rounded-md !tw-bg-foreground-primary-subtle tw-transition-colors hover:!tw-bg-foreground-primary-subtle-2 dark:!tw-bg-foreground-primary-subtle-dark dark:hover:!tw-bg-foreground-primary-dark"
      disabled={isLoading}
      type="button"
      onClick={(e) => {
        e.preventDefault();

        startRecording();
      }}
    >
      <FontAwesomeIcon className="tw-text-4xl" icon={faMicrophone} />
    </button>
  );
}

export { Recorder };
