import { useEffect, useState } from 'react';

type Options<T> = {
  cancelDelay?: (value: T) => boolean;
};

const useDebounce = <T>(value: T, delay: number, options: Options<T> = {}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const { cancelDelay } = options;

  useEffect(() => {
    const handler = setTimeout(
      () => {
        setDebouncedValue(value);
      },
      cancelDelay?.(value) ? 0 : delay
    );

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, cancelDelay]);

  return debouncedValue;
};

export { useDebounce };
