import merge from 'lodash/merge';
import isObject from 'lodash/isObject';

const globalTranslations: Dictionary = {};

function findInDictionaryByPath(path: string, dictionary: Dictionary): string {
  return path.split('.').reduce((value, key) => value[key] || {}, dictionary);
}

function findInDictionariesByPath(
  path: string,
  dictionaries: Dictionary[]
): Nullable<string> {
  let i = 0;
  while (i < dictionaries.length) {
    const res = findInDictionaryByPath(path, dictionaries[i]);
    i++;
    if (typeof res === 'string') {
      return res;
    }
  }

  return null;
}

const createTranslator = (...dictionaries: Optional<Dictionary>[]) => {
  const actualDictionaries = [...dictionaries, globalTranslations].filter(
    Boolean
  );
  return (path: string, ...values: string[]) => {
    let res = findInDictionariesByPath(path, actualDictionaries);

    if (!res) {
      return path;
    }

    if (values?.length) {
      res = res.replace(/{(\d+)}/g, (match, number) =>
        typeof values[number] !== 'undefined' ? values[number] : match
      );
    }

    return res;
  };
};

const getTranslations = createTranslator();
const addTranslations = (translations: Dictionary | string) => {
  let translationsToAdd = translations;

  if (typeof translations === 'string') {
    try {
      translationsToAdd = JSON.parse(translations);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        'Something went wrong when adding translations',
        translations
      );
    }
  }

  if (!isObject(translationsToAdd)) {
    return;
  }

  merge(globalTranslations, translationsToAdd);
};
const _translate: any = getTranslations;
_translate.add = addTranslations;

const translate: typeof getTranslations & { add: typeof addTranslations } =
  _translate;

export { createTranslator, translate };
